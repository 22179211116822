import React, { Fragment, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import ActionModal from '../Pages/Qualification/components/ActionModal'

const ClaimButtons = ({ ClaimStore: { isClaimClosed, isCancelButtonAvailable } }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState('')

  if (isClaimClosed) {
    return null
  }

  if (!isCancelButtonAvailable) {
    return null
  }

  return (
    <Fragment>
      <ActionModal showModal={showModal} closeModal={() => setShowModal(null)} />
      <button className="btn btn-danger btn-block" onClick={() => setShowModal('cancel')}>
        {t('mission.qualification.longCancel')}
      </button>
    </Fragment>
  )
}

export default compose(
  inject('ClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(ClaimButtons)
