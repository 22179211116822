import { observable, computed, action, decorate } from 'mobx'

class Rate {
  hourPrice = 0
  originalHourPrice = 0

  cde = null
  label = ''

  get changed() {
    return this.originalHourPrice !== this.hourPrice
  }

  constructor(rate) {
    this.cde = rate.cde
    this.label = rate.label
    this.changeHourPrice(rate.hourPrice)
    this.originalHourPrice = this.hourPrice
  }

  changeHourPrice(hourPrice) {
    hourPrice = parseFloat(hourPrice)
    if (isNaN(hourPrice)) return
    this.hourPrice = hourPrice
  }

  setOriginalData() {
    this.originalHourPrice = this.hourPrice
  }
}

const DecoratedRate = decorate(Rate, {
  hourPrice: observable,
  originalHourPrice: observable,

  changeHourPrice: action,
  setOriginalData: action,

  changed: computed,
})
export default DecoratedRate
