import React from 'react'
import classNames from 'classnames'
import { noop } from 'utils'

export default ({ className, onClick, disabled, loading, children, dataCy = '' }) => {
  const loadingSpinner = loading ? <span className={classNames('fa fa-spinner fa-spin')} /> : null

  return (
    <button
      tabIndex="0"
      className={classNames(className, {
        disabled,
        clickable: !disabled,
        'not-allowed': disabled,
      })}
      onClick={!disabled && !loading ? onClick : noop}
      data-cy={dataCy}
      disabled={disabled || loading}
    >
      {loadingSpinner || children}
    </button>
  )
}
