import request from './httpRequest'
import { keys, values } from 'ramda'

export const fetchExperts = async cfiWan => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/${cfiWan}/missions-insurer/search-experts-intervention`,
      params: {
        'page[size]': 'all',
      },
    })
    return res.data.map(data => data.attributes)
  } catch (err) {
    console.log(err)
  }
}

export const fetchSchedule = async ({ cfiWan, startDate, endDate }) => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/${cfiWan}/missions-insurer/expert-busy-schedule/${startDate}/${endDate}`,
      params: {
        'page[size]': 'all',
      },
    })
    const dataKeys = keys(res.data.attributes)
    const dataValues = values(res.data.attributes)
    return dataValues.map((data, index) => ({ ...data, userId: dataKeys[index] }))
  } catch (err) {
    console.log(err)
  }
}
