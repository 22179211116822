import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { observer } from 'mobx-react'

const LineSelectShow = ({
  label,
  price,
  isMissionClosed,
  onEdit,
  onDelete,
  recap = null,
  selfRepairButtons = null,
}) => {
  const { t } = useTranslation()

  return (
    <div className="row no-gutters align-items-center rounded p-2 mb-2">
      <div className="col-5">{label}</div>

      {!selfRepairButtons && (
        <Fragment>
          <div className="col text-right"></div>
          <div className="col d-flex justify-content-end">{price}</div>
        </Fragment>
      )}

      {selfRepairButtons}

      {!isMissionClosed && (
        <div className="col d-flex align-items-cente justify-content-end">
          {recap}
          <div>
            <button className="btn btn-outline-primary rounded-circle mr-1" onClick={onEdit}>
              <i className="fa fa-edit" />
            </button>
          </div>

          <ButtonWithConfirmation
            className="btn btn-outline-primary rounded-circle"
            icon
            iconClassName="fa fa-trash"
            onClick={onDelete}
            confirmText={t('mission.calculation.cart.confirmDelete')}
          />
        </div>
      )}
    </div>
  )
}

LineSelectShow.prototype = {
  label: PropTypes.string,
  price: PropTypes.number,
  isMissionClosed: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default observer(LineSelectShow)
