import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import ExpertSummary from './ExpertSummary'
import ExpertConfirmed from './ExpertConfirmed'

function ConfirmExpert({
  t,
  PlannerStore: { showConfirmExpert, setProperty, selectedExpertData },
  CreateMissionCtrl: { showSummary, showConfirmation },
}) {
  if (!selectedExpertData) return null
  const closeModal = () => {
    setProperty('showConfirmExpert', false)
  }

  return (
    <ModalWithBackdrop size="modal-md" show={showConfirmExpert} close={closeModal}>
      <div className="modal-header">
        <h5 className="modal-title">{t('claim.planner.confirmExpert.title')}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>

      {showSummary && <ExpertSummary closeModal={closeModal} />}
      {showConfirmation && <ExpertConfirmed closeModal={closeModal} />}
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('PlannerStore', 'CreateMissionCtrl'),
  withTranslation(),
  observer,
)(ConfirmExpert)
