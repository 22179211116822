import { useEffect } from 'react'

const useOutsideAlerter = (ref, onClickedOutside) => {
  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) onClickedOutside()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })
}

export default useOutsideAlerter
