import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { path } from 'ramda'

import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import Dropdown from 'pages/EasyEstimation/components/Dropdown'
import { euro } from 'utils/currency'
import { relatedCostsPrice } from 'utils/cart'
import LineSelectEdit from 'pages/EasyEstimation/components/LineSelectEdit'
import LineSelectShow from 'pages/EasyEstimation/components/LineSelectShow'
import RelatedCostRecap from './RelatedCostRecap'
import SelfRepairButtons from './SelfRepairButtons'

const RelatedCost = ({
  className,
  CartStore: { relatedCostPackages, addRelatedCost, removeRelatedCost, replaceRelatedCost },
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
  UserStore: { isClaimManager },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const [showSelectAdd, setShowSelectAdd] = useState(false)
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  const { relatedCost, relatedCostType, setRelatedCost, editionId } = AddRelatedCostCtrl

  const closeSelectAdd = () => setShowSelectAdd(false)
  const onChangeCde = value => {
    setRelatedCost(value, null, relatedCostType, 'relatedCost', true)
    relatedCost.setProperty('vat', 0)
  }

  const filtreRelatedCostType = (keepCode = null) => {
    const filtreRelatedCostType = []
    relatedCostType.forEach(rc => {
      if (rc.value === keepCode) {
        filtreRelatedCostType.push(rc)
      } else {
        const found = relatedCostPackages.find(rcp => rc.value === rcp.cde)
        if (!found) {
          filtreRelatedCostType.push(rc)
        }
      }
    })

    return filtreRelatedCostType
  }

  return (
    <div className={className}>
      <Dropdown
        title={t('mission.calculation.cart.titleRelatedCost')}
        showDropdown={true}
        total={relatedCostsPrice(relatedCostPackages, 'relatedCost')}
        priceType={`${t('mission.calculation.cart.totalWithVAT')} :`}
        addAction={() => {
          AddRelatedCostCtrl.setProperty('editionId', null)
          AddRelatedCostCtrl.setNewRelatedCostWithVatZero('relatedCost')
          setShowSelectAdd(true)
        }}
        showAdd={!isClosed}
      >
        {relatedCostPackages.map(rc => {
          return (
            <div className="cart" key={`relatedCost-${rc.id}`}>
              {rc.cde === editionId && (
                <LineSelectEdit
                  label={t('mission.calculation.relatedCost.type')}
                  amountLabel={t('mission.calculation.cart.finalTotal')}
                  packageCde={path(['cde'], relatedCost)}
                  onChangeCde={e => onChangeCde(e.target.value)}
                  packageType={filtreRelatedCostType(rc.cde)}
                  price={path(['priceWithVAT'], relatedCost) || 0}
                  targetPrice="priceWithVAT"
                  onChangePrice={e => relatedCost.setProperty(e.target.name, e.target.value)}
                  onCancel={() => {
                    AddRelatedCostCtrl.setProperty('editionId', null)
                  }}
                  onConfirm={() => {
                    if (relatedCost.validRelatedCostEE) {
                      replaceRelatedCost(editionId, relatedCost)
                      AddRelatedCostCtrl.setProperty('editionId', null)
                    }
                  }}
                  descriptionRequired={relatedCost.descriptionRequired}
                  description={relatedCost.description}
                  onChangeDescription={e => relatedCost.setProperty(e.target.name, e.target.value)}
                />
              )}

              {rc.cde !== editionId && (
                <LineSelectShow
                  label={`${rc.label} ${rc.descriptionRequired ? ' : ' + rc.description : ''}`}
                  price={euro(rc.priceWithVAT)}
                  isMissionClosed={isClosed}
                  onEdit={() => {
                    AddRelatedCostCtrl.setProperty('editionId', rc.cde)
                    AddRelatedCostCtrl.setProperty('relatedCost', rc)
                  }}
                  onDelete={() => removeRelatedCost(rc.id)}
                  recap={<RelatedCostRecap relatedCost={rc} />}
                  selfRepairButtons={
                    isClaimManager ? (
                      <SelfRepairButtons relatedCost={rc} isClosed={isClosed} />
                    ) : null
                  }
                />
              )}
            </div>
          )
        })}
      </Dropdown>

      {showSelectAdd && editionId === null && (
        <LineSelectEdit
          label={t('mission.calculation.relatedCost.type')}
          amountLabel={t('mission.calculation.cart.finalTotal')}
          packageCde={path(['cde'], relatedCost)}
          onChangeCde={e => onChangeCde(e.target.value)}
          packageType={filtreRelatedCostType()}
          price={path(['priceWithVAT'], relatedCost) || 0}
          targetPrice="priceWithVAT"
          onChangePrice={e => relatedCost.setProperty(e.target.name, e.target.value)}
          onCancel={closeSelectAdd}
          onConfirm={() => {
            if (relatedCost.validRelatedCostEE) {
              addRelatedCost(relatedCost)
              setShowSelectAdd(false)
            }
          }}
          descriptionRequired={relatedCost.descriptionRequired}
          description={relatedCost.description}
          onChangeDescription={e => relatedCost.setProperty(e.target.name, e.target.value)}
        />
      )}
    </div>
  )
}

export default inject('CartStore', 'MissionStore', 'ManagerClaimStore', 'UserStore')(
  observer(RelatedCost),
)
