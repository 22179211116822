import { observable, decorate, computed, action } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class SupportingDocuments extends AbstractField {
  value = []
  selectingDocuments = []
  show = true
  showModal = false
  type = 'supportingDocumentsType'

  constructor(data) {
    super(data)
    this.type = data.type || 'supportingDocumentsType'
    this.show = data.show

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = []
    this.originalValue = this.value
  }

  get changed() {
    return this.value !== this.originalValue
  }

  selectDocument = documentId => {
    if (this.selectingDocuments.indexOf(documentId) === -1) this.selectingDocuments.push(documentId)
    else
      this.selectingDocuments = this.selectingDocuments.filter(document => document !== documentId)
  }

  removeDocument = documentId => {
    this.value = this.value.filter(id => id !== documentId)
  }

  confirmSelection = () => {
    this.showModal = false
    this.value = [...this.selectingDocuments]
    this.selectingDocuments = []
  }

  setShowModal = value => {
    this.showModal = value
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedSupportingDocuments = decorate(SupportingDocuments, {
  value: observable,
  showModal: observable,
  selectingDocuments: observable,
  originalValue: observable,
  show: observable,

  setShowModal: action,
  selectDocument: action,
  removeDocument: action,
  confirmSelection: action,

  changed: computed,
})

export default DecoratedSupportingDocuments
