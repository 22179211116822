import React from 'react'
import { isNil } from 'ramda'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { currency_symbols } from 'utils/currency'
import { concatNames } from 'utils'

const Field = ({ name, value, unit, className, email, displayNull = false, children }) => {
  if (
    displayNull === false &&
    ((!Array.isArray(value) && isNil(value)) || (Array.isArray(value) && !value.length))
  ) {
    return null
  }

  const valueToDisplay = concatNames(value, currency_symbols[unit] || unit)

  return (
    <div className="row">
      <div className="col-4 col-sm-3 col-md-3 col-lg-3">{name}</div>
      <div className="col">
        {!Array.isArray(value) && (
          <span className={className}>
            {email ? (
              <a href={`mailto:${valueToDisplay}`}>{valueToDisplay}</a>
            ) : (
              <span>{valueToDisplay}</span>
            )}
          </span>
        )}

        {Array.isArray(value) && (
          <ul className={classNames('no-bullet px-0', className)}>
            {value.map(val => (
              <li key={val}>{val}</li>
            ))}
          </ul>
        )}
        {children}
      </div>
    </div>
  )
}

Field.defaultProps = {
  unit: '',
  className: '',
  email: false,
}

Field.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  unit: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.bool,
  displayNull: PropTypes.bool,
}

export default Field
