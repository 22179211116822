import request from './httpRequest'

export const fetchFirms = async cfiWan => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/${cfiWan}/external-experts`,
    })
    return res.data.map(data => data.attributes)
  } catch (error) {
    throw error
  }
}

export const assignFirm = async ({
  cfiWan,
  firm,
  expertName,
  expertPhoneNumber,
  comment,
  expertType,
}) => {
  const codeIrd = firm.irdCode
  const firmName = firm.firmName
  if (!codeIrd) {
    throw new Error('Cannot assign without code IRD')
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/darva/v1/change/expert/${cfiWan}`,
      data: {
        data: {
          type: 'trackingChangeExpert',
          attributes: {
            expertName: expertName,
            expertPhone: expertPhoneNumber,
            refExpert: cfiWan,
            firmName: firmName,
            expertType,
            codeIrd: codeIrd,
            manual: false,
            comment,
          },
        },
      },
    })
    return res
  } catch (error) {
    throw error
  }
}
