import React from 'react'
import { inject, observer } from 'mobx-react'
import { isNil } from 'ramda'
import SendReportButton from './SendReportButton'

const PostReportActions = ({
  sendDarva,
  saveReportToSD,
  setShowModal,
  MissionStore: { loading: loadingMission, id },
  ReportCtrl: { loading: loadingReport, toggleModal, currentReportObj },
  CartStore: { saving },
}) => {
  if (isNil(currentReportObj)) return null
  return (
    <div className="center">
      {/* SEND REPORT BUTTON*/}
      <SendReportButton
        sendDarva={sendDarva}
        setShowModal={setShowModal}
        saveReportToSD={saveReportToSD}
      />
    </div>
  )
}

export default inject('ReportCtrl', 'MissionStore', 'CartStore')(observer(PostReportActions))
