import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useLocation } from 'react-router-dom'
import { path } from 'ramda'
import i18next from 'i18next'

import './Conclusion.css'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import Form from 'components/DynForm/Form'
import Button from 'components/Button/Button'
import InfoModal from 'components/InfoModal'
import { CONVENTION_CONTRACT } from 'services/contracts'

const Conclusion = ({
  ConclusionCtrl: { loading, form, save, getApplicableConvention, applicableConventionLoading },
  CartStore: { settlementsChanged, precisionsChanged, resetPrecisions, resetSettlements },
  MissionStore,
  UserStore,
}) => {
  const { id } = useParams()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null
  const applicableConvention = MissionStore.applicableConvention

  useEffect(() => {
    return () => {
      if (path(['changed'], form)) form.resetForm()
      resetSettlements()
      resetPrecisions()
    }
    // eslint-disable-next-line
  }, [form])

  if (loading) return <Loader />
  return (
    <CardLayout>
      {UserStore.hasContract(CONVENTION_CONTRACT) && (
        <Button
          className="btn btn-primary"
          onClick={getApplicableConvention}
          disabled={applicableConventionLoading}
        >
          {`${i18next.t('convention.getApplicableConvention')}`}
        </Button>
      )}
      {applicableConvention && (
        <InfoModal
          {...applicableConvention}
          onClose={applicableConvention => {
            MissionStore.setProperty('applicableConvention', {
              openModal: false,
              ...applicableConvention,
            })
          }}
        />
      )}
      <div className="conclusion">
        <Form
          formObj={form}
          focus={focus}
          saveService={save.bind(this, id)}
          additionalSaveCondition={settlementsChanged || precisionsChanged}
        />
      </div>
    </CardLayout>
  )
}

export default inject('ConclusionCtrl', 'CartStore', 'MissionStore', 'UserStore')(
  observer(Conclusion),
)
