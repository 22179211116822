import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'
import { format } from 'date-fns'

import Week from './Week'
import Month from './Month'
import Day from './Day'
import DateDay from './DateDay'
import Navigation from './Navigation'

function CalendarContainer({
  t,
  UserStore: { isExpert },
  CalendarExpertStore: { viewType, selectedExperts, min, max, expand },
}) {
  if (viewType === 'week') return <Week />
  if (viewType === 'month') return <Month />

  const minHourCondition = format(min, 'k') === '8'
  const maxHourCondition = format(max, 'k') === '19'

  if (isExpert) {
    return (
      <div className="single-calendar pb-5">
        <Navigation />
        <DateDay />

        <button className="btn btn-primary rounded-circle expand-min" onClick={() => expand('min')}>
          <i
            className={classNames('fa', {
              'fa-chevron-up': minHourCondition,
              'fa-chevron-down': !minHourCondition,
            })}
          />
        </button>

        <Day expert={selectedExperts[0]} events={selectedExperts[0].calendar} />

        <button className="btn btn-primary rounded-circle expand-max" onClick={() => expand('max')}>
          <i
            className={classNames('fa', {
              'fa-chevron-down': maxHourCondition,
              'fa-chevron-up': !maxHourCondition,
            })}
          />
        </button>
      </div>
    )
  }

  if (selectedExperts.length === 0) {
    return (
      <div className="row">
        <div className="col-12 text-center mt-5 pt-5 no-expert-selected">
          <h2>{t('calendar.filters.selectExpert')}</h2>
        </div>
      </div>
    )
  }

  return (
    <div className="multi-calendar pb-5">
      <Navigation />

      <div className="row day-calendar">
        <button className="btn btn-primary rounded-circle expand-min" onClick={() => expand('min')}>
          <i
            className={classNames('fa', {
              'fa-chevron-up': minHourCondition,
              'fa-chevron-down': !minHourCondition,
            })}
          />
        </button>

        {selectedExperts.map((expert, index) => {
          return (
            <div
              key={`calendar-${expert.id}`}
              className={classNames('col h-100', { 'no-gutter-time': index !== 0 })}
            >
              <DateDay />
              <div
                className={classNames('expert-name text-center bg-primary text-white p-1', {
                  'ml-5': index === 0,
                })}
              >
                {expert.fullName}
              </div>
              <Day expert={expert} events={expert.calendar} />
            </div>
          )
        })}

        <button className="btn btn-primary rounded-circle expand-max" onClick={() => expand('max')}>
          <i
            className={classNames('fa', {
              'fa-chevron-down': maxHourCondition,
              'fa-chevron-up': !maxHourCondition,
            })}
          />
        </button>
      </div>
    </div>
  )
}

export default compose(
  inject('UserStore', 'CalendarExpertStore'),
  withTranslation(),
  observer,
)(CalendarContainer)
