import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { compose } from 'recompose'

import TableFields from 'components/Table/TableFields'
import { formatPhone } from 'utils'
import CardLayout from 'components/CardLayout/CardLayout'
import Field from 'components/Field'
import FieldsBloc from 'components/FieldsBloc'
import { concatAddress, formatDate } from 'utils'
import CollapseRow from 'components/CollapseRow/CollapseRow'
import { formatCurrency } from 'utils/currency'
import ClaimMap from 'components/AppointmentMap/ClaimMap'
import MapCtrl from 'stores/Mission/view/MapCtrl'

const Policy = ({ t, ManagerClaimStore: { claim, loading } }) => {
  const [showStreetView, setShowStreetView] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const streetViewButon = !showStreetView
    ? t('mission.infoMission.street-view')
    : t('mission.infoMission.google-map')

  const setView = () => {
    if (MapCtrl.collapsed === true) {
      MapCtrl.toggleCollapsed()
    }
    setTimeout(function() {
      setShowStreetView(showStreetView => !showStreetView)
    }, 1000)
  }

  useEffect(() => {
    if (loaded === false) {
      MapCtrl.collapseMap()
      setTimeout(function() {
        setShowStreetView(true)
      }, 1000)
      setLoaded(true)
    }
  }, [loaded])

  if (loading) return null

  const dataSampleContact = [
    {
      key: t('mission.policy.insuredInformation.quality'),
      val: path(['insuredInformation', 'insured', 'qualifying'], claim) || '-',
    },
    {
      key: t('mission.contact.address'),
      val: concatAddress(path(['insuredInformation', 'insured', 'address'], claim)) || '-',
    },
    {
      key: t('mission.contact.phoneNumber'),
      val: formatPhone(path(['insuredInformation', 'insured', 'phoneNumber'], claim)) || '-',
    },
    {
      key: t('mission.contact.email'),
      val: path(['insuredInformation', 'insured', 'email'], claim) || '-',
    },
  ]

  return (
    <CardLayout className="policy">
      <Field
        name={t('mission.policy.insuranceCompany')}
        displayNull={true}
        value={`${path(['contract', 'insurer', 'companyName'], claim) || ''} ${path(
          ['contract', 'insurer', 'companyName'],
          claim,
        ) || ''}`}
      />
      <Field
        displayNull={true}
        name={t('mission.policy.characteristicContract.insuredNumber')}
        value={path(['contract', 'policyNumber'], claim)}
      />
      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.policy.insured')}</div>
      </div>
      <CollapseRow
        rowLabel={t('mission.policy.fullName')}
        rowValue={path(['insuredInformation', 'insured', 'name'], claim)}
        rows={dataSampleContact}
      />
      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.policy.characteristicContract.title')}</div>
      </div>

      <Field
        name={t('mission.policy.characteristicContract.contractName')}
        value={
          path(['contract', 'typeOfContract', 'value'], claim)
            ? path(['contract', 'typeOfContract', 'value'], claim)
            : path(['contract', 'typeOfContractString'], claim)
        }
      />
      <Field
        name={t('mission.policy.characteristicContract.contractFormula')}
        value={path(['contract', 'formulaContract', 'value'], claim)}
      />
      <Field
        name={t('mission.policy.characteristicContract.effectiveDateOfTheContract')}
        value={formatDate(path(['contract', 'lastAmendmentDate'], claim))}
      />

      <Field
        name={t('mission.policy.characteristicContract.riskAddress')}
        value={
          path(['contract', 'addressOfRisk'], claim)
            ? concatAddress(path(['contract', 'addressOfRisk'], claim))
            : null
        }
      />
      <div className="row">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3">
          <button className="btn btn-primary" onClick={setView}>
            {streetViewButon}
          </button>
        </div>
      </div>
      {!!path(['contract', 'addressOfRisk', 'geometry', 'lat'], claim) ? (
        <ClaimMap
          destination={path(['contract', 'addressOfRisk', 'geometry'], claim)}
          streetView={showStreetView}
        />
      ) : (
        <div className="noGeometry col-8 col-sm-9 col-md-9 col-lg-9 offset-4 offset-sm-3 offset-md-3 offset-lg-3">
          {t('mission.information.map.noGeometry')}
        </div>
      )}
      <FieldsBloc
        displayNull={true}
        noTopDivider={true}
        // title={t('mission.policy.characteristicContract.title')}
        fields={[
          {
            name: t('mission.policy.characteristicContract.contractualFranchise'),
            value: formatCurrency(
              path(['contract', 'guarantee', 'guaranteeDeductible', 'absolute', 'amount'], claim),
              path(['contract', 'guarantee', 'guaranteeDeductible', 'absolute', 'currency'], claim),
            ),
          },
          {
            name: t('mission.policy.characteristicContract.realEstateCapital'),
            value: formatCurrency(
              path(['contract', 'guarantee', 'guaranteeAmounts', 'property', 'amount'], claim),
              path(['contract', 'guarantee', 'guaranteeAmounts', 'property', 'currency'], claim),
            ),
          },
          {
            name: t('mission.policy.characteristicContract.capitalOfMovables'),
            value: formatCurrency(
              path(['contract', 'guarantee', 'guaranteeAmounts', 'furniture', 'amount'], claim),
              path(['contract', 'guarantee', 'guaranteeAmounts', 'furniture', 'currency'], claim),
            ),
          },
          {
            name: t('mission.policy.characteristicContract.ofWhichPreciousObjects'),
            value: formatCurrency(
              path(['contract', 'guarantee', 'guaranteeAmounts', 'precious', 'amount'], claim),
              path(['contract', 'guarantee', 'guaranteeAmounts', 'precious', 'currency'], claim),
            ),
          },
          {
            name: t('mission.policy.characteristicContract.qualityOfTheInsured'),
            value: path(['contract', 'qualityOfInsured'], claim),
          },
          {
            name: t('mission.policy.characteristicContract.feature'),
            value: path(['contract', 'riskDescription', 'particularity'], claim),
          },
          {
            name: t('mission.policy.characteristicContract.declaredProfessionalActivity'),
            value: path(['contract', 'riskDescription', 'otherDeclaredActivity'], claim)
              ? t('common.yes')
              : t('common.no'),
          },
        ]}
      />
      <FieldsBloc
        displayNull={true}
        title={t('mission.policy.characteristicRisk.title')}
        fields={[
          {
            name: t('mission.policy.characteristicRisk.typeOfDwelling'),
            value: path(['claimInformation', 'riskType', 'value'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.buildingYear'),
            value: path(['claimInformation', 'buildingYear', 'value'], claim),
          },
          {
            name: t('mission.policy.characteristicRisk.squareArea'),
            value: path(['contract', 'riskDescription', 'squareArea'], claim),
            unit: 'm²',
          },
          {
            name: t('mission.policy.characteristicRisk.habitableArea'),
            value: path(['contract', 'riskDescription', 'primaryRiskSquareArea'], claim),
            unit: 'm²',
          },
          {
            name: t('mission.policy.characteristicRisk.nbMainRooms'),
            value: path(['contract', 'riskDescription', 'nbMainRooms'], claim),
          },
        ]}
      />

      <TableFields
        title={t('mission.policy.options.titleOptions')}
        column="amount"
        data={path(['contract', 'guarantee', 'guaranteeOptional'], claim)}
        classNames="mx-4"
      />
    </CardLayout>
  )
}

export default compose(
  inject('ManagerClaimStore'),
  withTranslation(),
  observer,
)(Policy)
