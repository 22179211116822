import React from 'react'
import { observer } from 'mobx-react'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocuments from 'pages/SupportingDocuments/SupportingDocuments'
import UserStore from 'stores/Common/domain/UserStore'

class SupportingDocumentsPage extends React.Component {
  componentDidMount() {
    SupportingDocumentsCtrl.setIsCreateMissionSelector(false)
  }

  render() {
    return UserStore.hasContract('FWSD') ? (
      <SupportingDocuments transfer={true} />
    ) : (
      <SupportingDocuments />
    )
  }
}

export default observer(SupportingDocumentsPage)
