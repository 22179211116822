import React, { useRef, useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import StickyNoteViewerStore from 'stores/Claim/StickyNoteViewerStore'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { format, parseISO } from 'date-fns'

import './StickyNoteViewer.css'

const StickyNoteViewer = ({ ClaimStore }) => {
  // CONFIG
  const MAX_LENGTH = 150
  const cfiWan = ClaimStore.id

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const editorRef = useRef(null)
  const [stickyNoteViewerStore, setStickyNoteViewerStore] = useState([])
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const currentCount = editorState.getCurrentContent().getPlainText().length

  //Editor init
  const loadStickyNoteViewerStore = async wan => {
    setLoading(true)
    await StickyNoteViewerStore.loadData(wan)
  }

  useEffect(() => {
    loadStickyNoteViewerStore(cfiWan).then(() => {
      setStickyNoteViewerStore(StickyNoteViewerStore)
      if (stickyNoteViewerStore.content !== null && stickyNoteViewerStore.content !== undefined) {
        const contentBlock = htmlToDraft(stickyNoteViewerStore.content)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
      }
      setLoading(false)
    })
  }, [cfiWan, setLoading, stickyNoteViewerStore])

  return (
    <div className="container pl-0 pr-0">
      <CardLayout className="sticky-note">
        {cfiWan === null || loading === true ? (
          <Loader />
        ) : (
          <React.Fragment>
            <h4 className="font-weight-normal">{t('mission.stickyNote.title')}</h4>
            <div className="">
              <div className="">
                <Editor
                  ref={editorRef}
                  key={'texteditor'}
                  readOnly={true}
                  placeholder={t('messaging.form.text')}
                  editorState={editorState}
                  toolbarClassName="wysiwyg-toolbar"
                  wrapperClassName="wysiwyg-wrapper"
                  editorClassName="wysiwyg-editor"
                  // handleBeforeInput={__handleBeforeInput}
                  // handlePastedText={__handlePastedText}
                  // onEditorStateChange={__handleStateChange}
                  toolbar={{
                    options: [
                      'inline',
                      // 'blockType',
                      // 'fontSize',
                      // 'list',
                      // 'textAlign',
                      // 'colorPicker',
                      // 'link',
                      // 'history',
                    ],
                    inline: {
                      options: [
                        // 'bold',
                        // 'italic',
                        // 'underline',
                        // 'strikethrough',
                        // 'superscript',
                        // 'subscript',
                      ],
                    },
                  }}
                />
              </div>
              {stickyNoteViewerStore.updatedAt !== undefined &&
                stickyNoteViewerStore.updatedAt !== null && (
                  <div className="mt-0 text-left text-sm">
                    {t('common.lastUpdatedAtWithDate')}{' '}
                    {format(parseISO(stickyNoteViewerStore.updatedAt), t('common.datetimeFormat'))}
                  </div>
                )}
              <div className="mt-0 text-right text-sm">
                {currentCount}/{MAX_LENGTH} {t('messaging.form.character')}
              </div>
            </div>
          </React.Fragment>
        )}
      </CardLayout>
    </div>
  )
}

export default compose(
  withRouter,
  withTranslation(),
  inject('ClaimStore'),
  observer,
)(StickyNoteViewer)
