import request from 'services/httpRequest'

export const getStatus = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/status',
    })
    return { status: 200, apiVersion: res.jsonapi.version }
  } catch (err) {
    return 503
  }
}
