import { observable, action, decorate, runInAction } from 'mobx'

import { fetchCausesAndCircumstancesForm } from 'services/missionForm'
import Form from 'utils/dynform/Form'

class CausesAndCircumstances {
  loading = true
  form = null

  loadData = async (wan, causesAndCircumstancesData) => {
    this.loading = true
    try {
      const form = await fetchCausesAndCircumstancesForm(wan)
      form.name = 'causesAndCircumstances'
      runInAction(() => {
        this.form = new Form({ form, data: causesAndCircumstancesData })
      })
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedCausesAndCircumstances = decorate(CausesAndCircumstances, {
  loading: observable,
  form: observable,

  loadData: action,
})
export default new DecoratedCausesAndCircumstances()
