import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import PartyInvolved from 'pages/PartyInvolved/PartyInvolved'

class PartyInvolvedDB extends React.Component {
  render() {
    const { t } = this.props

    return (
      <div className="party-invovled">
        <div className="row no-gutters">
          <h5 className="mb-4">{t('parameters.partyInvolvedDB.title')}</h5>
        </div>

        <PartyInvolved />
      </div>
    )
  }
}

export default inject('UiCtrl')(withRouter(withTranslation()(observer(PartyInvolvedDB))))
