import React from 'react'
import { observer } from 'mobx-react'
import { path } from 'ramda'

import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import MissionStore from 'stores/Mission/domain/MissionStore'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import CartStore from 'stores/Mission/domain/CartStore'

const SelectWithSearchWrapper = ({ formInputs = null, inputData }) => {
  const onChange = e => {
    // FETCH additional RC only for IRSI1 and IRSI2
    if (e.target.name === 'applicableConventionType' && e.target.value === 'IRSI') {
      const wan = MissionStore.id
      const coverage = path(['cfi', 'claimInformation', 'coverage', 'key'], MissionStore.cfa)
      if (wan && coverage) {
        AddRelatedCostCtrl.loadAdditionalRelatedCostType({ wan, coverage })
      }
    }

    inputData.setProperty('value', e.target.value)

    // NEED HOTFIX FOR EMBEDDED COLLECTION
    if (path(['changeChoicesWhenLinked'], formInputs)) {
      formInputs.changeChoicesWhenLinked(inputData.name)
    }
  }

  let options = inputData.options

  // FOR IRSI IF INVOLVED PARTY IS ALREADY VENTILATED CANT NOT SELECT "Sans objet" "01"
  // PATH is UNIQ
  if (inputData.path === 'irsi.irsiInvolvedPartys.status.key') {
    const involvedPartyId = path(['extraData', 'involvedPartyId'], inputData)
    if (involvedPartyId && CartStore.isInvolvedPartyVentilated(involvedPartyId)) {
      // REMOVE 'Sans objet' in options
      options = inputData.options.filter(option => option.value !== '01')
    }
  }

  return (
    <ResponsiveSelect
      {...inputData}
      isSearchable
      options={options}
      onChange={onChange}
      defaultMenuIsOpen={inputData.autoFocus}
    />
  )
}
export default observer(SelectWithSearchWrapper)
