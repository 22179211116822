import React, { Fragment, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import ActionModal from './ActionModal'

const MissionButtons = ({ MissionStore, UiCtrl: { showOnlyCard } }) => {
  const [showModal, setShowModal] = useState('')
  const { t } = useTranslation()

  if (MissionStore.isMissionClosed) return null

  if (showOnlyCard) return null

  return (
    <Fragment>
      {MissionStore.isCancelButtonAvailable && (
        <Fragment>
          <ActionModal showModal={showModal} closeModal={() => setShowModal(null)} />
          <button className="btn btn-danger btn-block" onClick={() => setShowModal('cancel')}>
            {t('mission.qualification.longCancel')}
          </button>
        </Fragment>
      )}

      <ButtonWithConfirmation
        classNameWrapper="btn-block bg-white"
        className="btn btn-outline-primary btn-block"
        onClick={() => MissionStore.closeMission(MissionStore.cfa.wan)}
        confirmText={t('mission.report.confirmationCloseMission')}
        text={
          <span>
            {t('mission.report.closeMission')} <i className="fa fa-unlock-alt" />
          </span>
        }
      />
    </Fragment>
  )
}

export default compose(
  inject('MissionStore', 'UiCtrl'),
  withRouter,
  withTranslation(),
  observer,
)(MissionButtons)
