import { observable, computed, action, toJS, runInAction, decorate } from 'mobx'
import { path, equals } from 'ramda'

import PrejudiceTable from 'stores/Mission/domain/PrejudiceTable'
import MissionStore from 'stores/Mission/domain/MissionStore'
import CartStore from 'stores/Mission/domain/CartStore'

const getFields = list => {
  return list.map(payload => {
    return {
      cde: payload.cde,
      prejudices:
        payload.prejudices &&
        payload.prejudices.map(o => ({
          cde: o.cde,
          totalWithoutVAT: +o.totalWithoutVAT,
          obsolescenceRate: +o.obsolescenceRate,
          obsolescenceReturn: +o.obsolescenceReturn,
          vatRate: +o.vatRate,
        })),
      annexes:
        payload.annexes &&
        payload.annexes.map(o => ({
          cde: o.cde,
          totalWithoutVAT: +o.totalWithoutVAT,
          obsolescenceRate: +o.obsolescenceRate,
          obsolescenceReturn: +o.obsolescenceReturn,
          vatRate: +o.vatRate,
        })),
    }
  })
}

class PrejudiceStore {
  loading = false
  payloads = []
  originalFields = []
  ready = false

  get changed() {
    return !equals(getFields(this.originalFields), getFields(this.payloads))
  }

  get vanWithVAT() {
    return this.payloads.reduce((acc, payload) => acc + Number(payload.totalWithVAT), 0)
  }

  get vanWithoutVAT() {
    return this.payloads.reduce((acc, payload) => acc + Number(payload.totalWithoutVAT), 0)
  }

  get realObsolescence() {
    return this.payloads.reduce(
      (acc, payload) => acc + Number(payload.totalObsolescenceWithoutVAT),
      0,
    )
  }

  get realObsolescenceWithVAT() {
    return this.payloads.reduce((acc, payload) => acc + Number(payload.totalObsolescenceWithVAT), 0)
  }

  get guaranteedObsolescence() {
    return this.payloads.reduce((acc, payload) => acc + Number(payload.totalORWithoutVAT), 0)
  }

  get guaranteedObsolescenceWithVAT() {
    return this.payloads.reduce((acc, payload) => acc + Number(payload.totalORWithVAT), 0)
  }

  get nonGuaranteedObsolescence() {
    return this.realObsolescence - this.guaranteedObsolescence
  }

  get obsolescenceDeductedWithoutVAT() {
    return this.vanWithoutVAT - this.realObsolescence
  }

  get obsolescenceDeductedWithVAT() {
    return this.vanWithVAT - this.realObsolescenceWithVAT
  }

  get finalPrice() {
    return this.obsolescenceDeductedWithVAT + this.guaranteedObsolescenceWithVAT
  }

  get finalPriceWithoutVAT() {
    return this.obsolescenceDeductedWithoutVAT + this.guaranteedObsolescence
  }

  get asJson() {
    return this.payloads.map(({ asJson }) => asJson)
  }

  get totals() {
    return {
      finalPrice: this.finalPrice,
      finalPriceWithoutVAT: this.finalPriceWithoutVAT,
      obsolescenceDeductedWithVAT: this.obsolescenceDeductedWithVAT,
      obsolescenceDeductedWithoutVAT: this.obsolescenceDeductedWithoutVAT,
      nonGuaranteedObsolescence: this.nonGuaranteedObsolescence,
      guaranteedObsolescence: this.guaranteedObsolescence,
      realObsolescence: this.realObsolescence,
      vanWithVAT: this.vanWithVAT,
      vanWithoutVAT: this.vanWithoutVAT,
    }
  }

  resetData = () => {
    this.payloads = []
    toJS(this.originalFields, { recurseEverything: true }).forEach(payload => {
      this.payloads.push(new PrejudiceTable(payload))
    })
  }

  save = async () => {
    try {
      await CartStore.save('cart', true)
      runInAction(() => {
        this.originalFields = [...toJS(this.payloads, { recurseEverything: true })]
      })
    } catch (err) {}
  }

  initData = (fields = []) => {
    // TODO check if there is new elements
    // fields = ['guaranteeCeiling'] // TODO Kepp it for tests
    this.originalFields = []
    this.payloads = []
    if (!fields.length && path(['policy', 'guarantee', 'guaranteeOptional'], MissionStore)) {
      Object.keys(MissionStore.policy.guarantee.guaranteeOptional)
        .filter(
          key =>
            MissionStore.policy.guarantee.guaranteeOptional[key] &&
            !Array.isArray(MissionStore.policy.guarantee.guaranteeOptional[key]),
        )
        .forEach(cde => {
          this.originalFields.push(new PrejudiceTable({ cde }))
          this.payloads.push(new PrejudiceTable({ cde }))
        })
    } else {
      fields.forEach(payload => {
        this.payloads.push(new PrejudiceTable(payload))
        this.originalFields = [...toJS(this.payloads, { recurseEverything: true })]
      })
    }
    this.ready = true
  }

  resetReady = () => {
    this.ready = false
  }
}

const DecoratedPrejudiceStore = decorate(PrejudiceStore, {
  loading: observable,
  payloads: observable,
  originalFields: observable,
  ready: observable,

  changed: computed,
  vanWithoutVAT: computed,
  vanWithVAT: computed,
  realObsolescence: computed,
  guaranteedObsolescence: computed,
  nonGuaranteedObsolescence: computed,
  obsolescenceDeductedWithoutVAT: computed,
  obsolescenceDeductedWithVAT: computed,
  finalPrice: computed,
  finalPriceWithoutVAT: computed,
  asJson: computed,
  totals: computed,

  initData: action,
  resetData: action,
  save: action,
  resetReady: action,
})

export default new DecoratedPrejudiceStore()
