import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import './Messaging.css'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import Form from './Form'
import List from './List'
import SDModal from 'components/SDModal'

function Messaging({
  match: {
    params: { id },
  },
  BlocNoteStore: { loading, fetchMessages, resetCurrentMessage },
}) {
  useEffect(() => {
    if (!loading) fetchMessages(id)

    return () => {
      resetCurrentMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loader />

  return (
    <CardLayout className="messaging">
      <Form />

      <List />

      <SDModal />
    </CardLayout>
  )
}

export default compose(inject('BlocNoteStore'), withRouter, observer)(Messaging)
