import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'

import './Planner.css'
import Calendar from './Calendar'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import ListExpert from './ListExpert'
import ConfirmExpert from './ConfirmExpert'
import { concatAddress, isInspectionMission } from 'utils'
import { path } from 'ramda'
import PlannerDatePicker from './PlannerDatePicker'

function Planner({
  t,
  ClaimStore: { id, claim, isIME, isMPJ, isUrgent },
  PlannerStore: { getExperts, loading, resetPlanner },
}) {
  useEffect(() => {
    resetPlanner()
    getExperts(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addressLoss = concatAddress(path(['claimInformation', 'addressOfLoss'], claim))
  const missionInsurerInfoAddress = concatAddress(
    path(['mission', 'missionInsurerInformation', 'address'], claim),
  )
  const isOutOfDARVA = isInspectionMission(claim)
  const addressValue =
    addressLoss.toUpperCase() !== missionInsurerInfoAddress.toUpperCase()
      ? missionInsurerInfoAddress
      : addressLoss

  if (loading)
    return (
      <div className="planner-page">
        <CardLayout>
          <Loader />
        </CardLayout>
      </div>
    )

  return (
    <div className="planner-page">
      <CardLayout>
        <h5>{t('claim.planner.title')}</h5>
        <div className="pb-2">
          <h6>
            <p className="mb-2">
              {t('mission.infoMission.expertiseAddress')} : {addressValue}
            </p>
            {isUrgent && isIME && (
              <p className="text-danger">
                <i className="fa fa-exclamation-triangle"></i>
                {t('claim.planner.urgentInfo')}
              </p>
            )}
            {isMPJ && (
              <p className="text-danger">
                <i className="fa fa-exclamation-triangle"></i>
                {t('claim.planner.mpjInformation')}
              </p>
            )}
          </h6>
        </div>
        <div className="row no-gutters">
          <div className="col-8 pr-3 align-self-start">
            <Calendar />
          </div>

          <div className="col-4 border-left pl-3">
            <PlannerDatePicker />
            <ListExpert />

            {!isIME && !isOutOfDARVA && (
              <Link className="btn btn-primary btn-block" to={`/claim/${id}/planner/overrun`}>
                {t('claim.planner.overrun')}
              </Link>
            )}
          </div>
        </div>
        <ConfirmExpert />
      </CardLayout>
    </div>
  )
}

export default compose(
  inject('ClaimStore', 'PlannerStore'),
  withTranslation(),
  observer,
)(Planner)
