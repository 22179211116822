import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { path } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'
import { saveFormDamagesDetails } from 'services/missionForm'

const CausesAndCircumstances = ({
  CausesAndCircumstancesCtrl: { form, loading },
  RiskConformityCtrl: { form: formRiskC },
}) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null

  useEffect(() => {
    if (formRiskC && form) {
      const propertyType = formRiskC.inputs.find(({ name }) => name === 'propertyType')
      if (propertyType) {
        const affectedArea = form.inputs.find(({ name }) => name === 'affectedArea')
        if (affectedArea) {
          Object.keys(propertyType.choices).forEach(key => {
            if (propertyType.choices[key] === propertyType.originalValue) {
              affectedArea.setProperty('value', key)
              affectedArea.setProperty('originalValue', key)
            }
          })
        }
      }
    }

    return () => form && form.resetForm()
  }, [form, formRiskC])

  const nextStep = () => history.push(`/mission/${id}/damageDescription`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="dynform-damage-details">
        <Form
          formObj={form}
          focus={focus}
          nextStep={nextStep}
          saveService={saveFormDamagesDetails.bind(this, id)}
        />
      </div>
    </CardLayout>
  )
}

export default inject(
  'CausesAndCircumstancesCtrl',
  'RiskConformityCtrl',
)(observer(CausesAndCircumstances))
