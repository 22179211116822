import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classNames from 'classnames'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CartPackage from './CartPackage'
import FurnitureItem from './FurnitureItem'
import { euro } from 'utils/currency'
import { outdoorPackagesPrice } from 'utils/cart'

const Price = observer(({ rooms, selfRepair = false }) => {
  return <React.Fragment>{euro(outdoorPackagesPrice(rooms, selfRepair))}</React.Fragment>
})

const OutdoorList = ({
  t,
  match: {
    params: { id, type },
  },
  CartStore: { outdoorPackages, outdoorPackagesWithoutRoom, outdoorPackagesByRoom },
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
}) => {
  const [open, setOpen] = useState(true)
  const showOutdoor = () => {
    if (outdoorPackages.length > 0) setOpen(!open)
  }
  const setContext = () => SearchPackageCtrl.resetSearchContext('DAT_PROP')
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className="container border-bottom border-color-primary">
      <div className="row no-gutters align-items-center mt-2 mb-2">
        <div className="col-7 clickable" onClick={showOutdoor}>
          <span className="p-1 mr-2">
            <i className={classNames('fa', { 'fa-angle-down': !open, 'fa-angle-up': open })} />
          </span>
          <span className="font-weight-bold">
            {t('mission.calculation.cart.titleOutdoorFurniture')}
          </span>
        </div>

        <div className="col-2 text-right d-none d-lg-block">
          <Price rooms={outdoorPackagesByRoom} />
        </div>
        <div className="col-2 text-right d-none d-lg-block">
          <Price rooms={outdoorPackagesByRoom} selfRepair />
        </div>

        <div className="col-4 d-inline-block d-lg-none" />

        <div className="col-1 text-right">
          {!isClosed && (
            <NavLink
              className="btn btn-outline-primary rounded-circle"
              to={`/${type}/${id}/calculation/searchOutdoorPackage`}
              onClick={setContext}
            >
              <i className="fa fa-plus" />
            </NavLink>
          )}
        </div>
      </div>

      {open &&
        outdoorPackagesWithoutRoom.map(outdoorPackage => {
          return outdoorPackage.modalityAcquisition === undefined ? (
            <CartPackage key={`cart-package-${outdoorPackage.id}`} data={outdoorPackage} />
          ) : (
            <FurnitureItem key={`furniture-item-${outdoorPackage.id}`} furniture={outdoorPackage} />
          )
        })}

      {open &&
        outdoorPackagesByRoom.map(room => {
          return (
            <div
              className="room"
              key={`outdoor-room-${room.cartRoomType.key || room.cartRoomType.cde}`}
            >
              <div className="row no-gutters align-items-center mt-2 mb-2">
                <div className="col-7 pl-4 font-weight-bold">{room.cartRoomType.label}:</div>
                <div className="col-5" />
              </div>

              {room.packages.map(outdoorPackage => {
                return outdoorPackage.modalityAcquisition === undefined ? (
                  <CartPackage key={`cart-package-${outdoorPackage.id}`} data={outdoorPackage} />
                ) : (
                  <FurnitureItem
                    key={`furniture-item-${outdoorPackage.id}`}
                    furniture={outdoorPackage}
                  />
                )
              })}
            </div>
          )
        })}
    </div>
  )
}

export default compose(
  inject('CartStore', 'MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(OutdoorList)
