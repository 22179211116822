import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertFromRaw } from 'draft-js'
import './Messaging.css'

function Message({ t, data: { isSelf, text, formatCreatedDate } }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(JSON.parse(text))),
  )

  const updateTextDescription = async () => {
    setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(text))))
  }

  return (
    <div
      className={classNames('blocNote d-inline-block text-left mb-3 border rounded p-2', {
        'bg-other': !isSelf,
        'bg-self': isSelf,
      })}
    >
      <div className="info mb-2">
        {t('messaging.message.date')} : {formatCreatedDate}
      </div>

      {
        <Editor
          key={'texteditor'}
          readOnly={true}
          editorState={editorState}
          toolbarClassName="messaging rich-text__toolbar_customised"
          onEditorStateChange={updateTextDescription}
        />
      }
    </div>
  )
}

export default compose(inject('BlocNoteStore'), withRouter, withTranslation(), observer)(Message)
