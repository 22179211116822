import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import ReportCtrl from 'stores/Mission/view/ReportCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import ImageLoader from 'react-load-image'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import FallBackThumbnail from 'components/FallBackThumbnail/FallBackThumbnail'

const DisplayImage = ({ t }) => {
  return (
    <ModalWithBackdrop
      show={ReportCtrl.imageDisplayModal}
      close={value => value}
      size="modal-lg"
      className="imageReportDisplay"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t('Image ajoutée au rapport')}</h5>
        <button
          type="button"
          className="close"
          onClick={() => ReportCtrl.toggleImageDisplayModal()}
        >
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <ImageLoader src={ReportCtrl.imageDisplayUrl} onLoad={() => {}} onError={() => {}}>
          <img alt={'rapport'} />
          <FallBackThumbnail />
          <ImageLoading />
        </ImageLoader>
      </div>
    </ModalWithBackdrop>
  )
}

export default withTranslation()(observer(DisplayImage))
