import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { path } from 'ramda'

import Loader from 'components/Loader/Loader'
import CardLayout from 'components/CardLayout/CardLayout'
import ListFirm from './ListFirm'
import Map from './Map'
import ConfirmFirm from './ConfirmFirm'
import { concatAddress } from 'utils'

const Overrun = ({
  t,
  ClaimStore: { id: cfiWan, claim, lastMissionID },
  OverrunStore: { loading, loadData, reset },
}) => {
  useEffect(() => {
    loadData(cfiWan)

    return () => {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <div className="overrun-page">
        <CardLayout>
          <Loader />
        </CardLayout>
      </div>
    )
  }

  return (
    <div className="overrun-page">
      <CardLayout>
        <h5>{t('claim.overrun.title')}</h5>
        <div className="mb-2">
          {t('mission.infoMission.expertiseAddress')} :{' '}
          {concatAddress(path(['mission', 'missionInsurerInformation', 'address'], claim))}
        </div>

        <div className="row no-gutters">
          <div
            className="col-8"
            style={{
              minHeight: '700px',
            }}
          >
            <Map />
          </div>

          <div className="col-4 border-left pl-3">
            <ListFirm />
            {!lastMissionID && (
              <Link className="btn btn-primary btn-block" to={`/claim/${cfiWan}/planner`}>
                {t('claim.overrun.redirectToPlanner')}
              </Link>
            )}
          </div>
        </div>

        <ConfirmFirm />
      </CardLayout>
    </div>
  )
}

export default compose(
  inject('ClaimStore', 'OverrunStore'),
  withTranslation(),
  observer,
)(Overrun)
