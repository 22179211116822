export const optionsAnnex = [
  'architectFees',
  'demolitionExcavation',
  'rentLoss',
  'designOfficeFees',
  'costsCompliance',
  'babysittingFees',
  'relocationExpenses',
  'travelExpensesFurniture',
  'provisionalProvisionalMeasures',
  'mandatoryDiagnostics',
  'projectManagementFees',
]

export const optionsPrejudices = {
  brokenGlass: [
    'stainedGlass',
    'windowsClosedFireplaces',
    'glassPartsOfAppliances',
    'glazedPartsOfTheFurniture',
  ],
  optionalRcChildcareAssistant: ['RCMaternalAssistance'],
  optionalRcFosterFamily: ['optionalRcFosterFamily'],
  optionalRcGuestRoomOrStudent: ['rcRentedRooms'],
  exteriorLayoutsAndTreesInGround: [
    'FencesWallsGates',
    'retainingWalls',
    'treesShrubs',
    'otherPropertyDamage',
  ],
  externalFurnitureAndTreesInGround: ['outdoorFurniture', 'treesShrubs', 'otherMovableDamages'],
  poolOrSpaAndPipe: [
    'immovable',
    'poolFurniture',
    'leakageSearch',
    'repairLeaks',
    'otherDamagePools',
  ],
  durableEquipment: [
    'photovoltaic',
    'heatPump',
    'geothermal',
    'windTurbines',
    'otherEquipmentSustainability',
    'rcElectricityProduction',
    'lossesOfElectricityGeneratingIncome',
  ],
  renewMovableEquipment: [
    'jeweleryAndValuables',
    'homeAppliance',
    'computersAndMobileGoods',
    'householdFurniture',
    'professionalEquipment',
    'goodsLentOrProvidedByThirdParties',
    'clothing',
    'otherMovableDamages',
  ],
  externalPipes: ['leaks', 'repairLeaks', 'overconsumptionOfWater'],
  householdApplianceFailure: ['éléctroménagerFailure'],
  electricalDamage: [
    'homeAppliance',
    'computersAndMobileGoods',
    'freezerContent',
    'otherMovableDamages',
  ],
  brokenGlassMovable: [
    'glassPartsOfAppliances',
    'glazedPartsOfTheFurniture',
    'windowsClosedFireplaces',
    'stainedGlass',
  ],
  theftWithAggression: ['jeweleryAndValuables', 'nomadicDevices', 'otherMovableDamages'],
  robberyMusicalInstrument: ['intrusmentsMusic'],
  robberySportAndMusicMaterial: ['otherMovableDamages', 'intrusmentsMusic', 'leisureMaterial'],
  guaranteeCeiling: [
    'propertyAmenitiesOfOutside',
    'treesShrubs',
    'miscellaneousGoods',
    'movablePropertyInOutbuildings',
    'jeweleryAndValuables',
    'externalPipe',
    'capitalFurniture',
    'excavationDemolition',
    'sustainableDevelopmentFacilities',
    'musicalInstruments',
    'professionalEquipment',
    'outdoorFurniture',
    'compliance',
    'partiesGlazedWindowsMovableProperty',
    'lossOfIncomeProducingElectricity',
    'spaPoolAndEquipment',
    'leakageSearch',
    'overconsumptionOfWater',
  ],
  judicialProtection: [],
  IMAProtection: [],
  domesticIncidents: [],
  damageUnsecured: [
    'outsideOfRealEstateDevelopments',
    'animals',
    'ofAerialLocomotionDevices',
    'treesShrubs',
    'otherDamage',
    'miscellaneousGoods',
    'externalPipelines',
    'shipsSailingInOrEngines',
    'sustainableDevelopmentFacilities',
    'expertsFees',
    'trusteeFees',
    'musicalInstruments',
    'professionalGoods',
    'outdoorFurniture',
    'miniTractorsAndTrailers',
    'paymentSecuritiesSecurities',
    'partiesGlazedWindowsMovableProperty',
    'lossOfUseOfPremises',
    'lossOfIncomeProducingElectricity',
    'indirectLosses',
    'spaPoolAndEquipment',
    'kitesurfingWindsurfing',
    'prejudicesAppendices',
    'overconsumptionOfWater',
    'grounds',
    'landVehicleEngine',
  ],
}
export const optionsTva = [
  { value: '0', label: 0 },
  { value: '2.5', label: 2.5 },
  { value: '5.5', label: 5.5 },
  { value: '7', label: 7 },
  { value: '8', label: 8 },
  { value: '10', label: 10 },
  { value: '20', label: 20 },
]

export const mockPrejudices = ['exteriorLayoutsAndTreesInGround', 'Dommages non garantis']
