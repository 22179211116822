import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import TableDescriptionFurnitures from './TableDescriptionFurnitures'

const DescriptionFurnitures = ({
  CartStore: { isVentilationIPAvailable, furnituresPerCategory },
}) => {
  if (isVentilationIPAvailable) {
    return null
  }

  return (
    <TableDescriptionFurnitures
      involvedPartyId={null}
      furnituresPerCategory={furnituresPerCategory}
    />
  )
}

export default compose(
  inject('CartStore', 'PrejudiceStore'),
  observer,
)(DescriptionFurnitures)
