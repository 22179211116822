import React from 'react'
import classNames from 'classnames'

export default ({ labelComponent, priceComponent, withoutUnit, last, packageLayout }) => {
  if (withoutUnit === undefined) withoutUnit = false
  if (last === undefined) last = false

  return (
    <div className={classNames('row row-recap no-gutters align-items-center', { 'mb-3': !last })}>
      <div className="col-6">{labelComponent}</div>

      <div
        className={classNames(
          'col-6 text-right',
          {
            'without-unit': withoutUnit && packageLayout,
          },
          { 'col-lg-4': packageLayout },
          { 'col-lg-6': !packageLayout },
        )}
      >
        {priceComponent}
      </div>

      {packageLayout ? <div className="col-2 d-none d-lg-block d-xl-block" /> : null}
    </div>
  )
}
