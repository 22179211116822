import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'

import UploadCtrl from 'stores/Common/view/UploadCtrl'
import LiteGallery from 'pages/Mission/components/LiteGallery'
import UploadInvoiceModal from 'components/UploadInvoiceModal/UploadInvoiceModal'
import UploadDocumentModal from 'components/UploadDocumentModal/UploadDocumentModal'
import { SupportingDocFileTypes } from 'stores/Common/view/SupportingDocumentsCtrl'

class SupportingDocumentLite extends React.Component {
  UNSAFE_componentWillMount() {
    UploadCtrl.setProperty('context', 'supportingDocumentPackage')
    UploadCtrl.setProperty('addSupportingDocument', this.props.addSupportingDocument)
  }

  onDrop = (acceptedFiles, rejectedFiles, type) => {
    UploadCtrl.setDefaultType(type)
    if (type === 'PDMO') {
      UploadCtrl.setAcceptedFiles(acceptedFiles)
    } else {
      UploadCtrl.setInvoiceAcceptedFiles(acceptedFiles)
    }
  }

  renderUploadBtn = (type, uploadFor) => {
    const { t } = this.props
    let entity
    switch (type) {
      case 'PDMO':
        entity = 'mission.upload.photo'
        break
      case 'IQUO':
        entity = 'mission.upload.quotation'
        break
      case 'DOTH':
        entity = 'mission.upload.other'
        break
      default:
        entity =
          uploadFor === 'furniture' ? 'mission.upload.invoiceMobilier' : 'mission.upload.invoice'
        break
    }

    return (
      <Dropzone
        key={type}
        className="btn btn-outline-primary mr-2 clickable"
        multiple={false}
        onDrop={(acceptedFiles, rejectedFiles) => {
          this.onDrop(acceptedFiles, rejectedFiles, type)
        }}
        accept={type === 'PDMO' ? SupportingDocFileTypes.PHOTO : SupportingDocFileTypes.DOCUMENT}
      >
        {t(entity)} <i className="fa fa-upload" />
      </Dropzone>
    )
  }

  render() {
    const { id } = this.props.match.params
    const { supportingDocuments, btnTypes, uploadFor } = this.props

    return (
      <div className="support-document-lite" style={{ width: '100%' }}>
        <div className="d-flex justify-content-start">
          {btnTypes.map(btnType => this.renderUploadBtn(btnType, uploadFor))}
        </div>
        <LiteGallery supportingDocuments={supportingDocuments} forPackages={true} />
        <UploadInvoiceModal wan={id} />
        <UploadDocumentModal wan={id} />
      </div>
    )
  }
}

SupportingDocumentLite.defaultProps = {
  btnTypes: ['IQUO', 'IINV', 'PDMO'],
  uploadFor: 'package',
}

export default withRouter(withTranslation()(observer(SupportingDocumentLite)))
