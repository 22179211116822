import { observable, action, decorate, runInAction, computed } from 'mobx'
import { isNil, path } from 'ramda'

import CartStore from 'stores/Mission/domain/CartStore'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import { fetchSinister, saveSinister } from 'services/claimManagerForm'
import Form from 'utils/dynform/Form'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'

class SinisterCtrl {
  loading = false
  form = null
  originalRiskTypeValue = ''

  get riskTypeChanged() {
    if (isNil(this.form)) return false

    const riskTypeInput = this.form.getInput('riskType')

    return this.originalRiskTypeValue !== path(['value'], riskTypeInput)
  }

  loadData = async (wan, sinisterData) => {
    this.loading = true
    this.originalRiskTypeValue = path(['claimInformation', 'riskType', 'key'], sinisterData)

    try {
      const form = await fetchSinister(wan)
      form.name = 'sinister'
      runInAction(() => {
        this.form = new Form({ form, data: sinisterData })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    try {
      let needSaving = false
      const res = await saveSinister(wan, formData)
      ManagerClaimStore.setProperty('claim', res)
      if (this.riskTypeChanged) {
        SearchPackageCtrl.loadMissionRooms(wan)
        runInAction(() => {
          this.originalRiskTypeValue = path(['claimInformation', 'riskType', 'key'], res)
        })
      }

      // IRSI RULE
      if (
        path(['claimInformation', 'eventType', 'id'], res) === 'EV0030' &&
        path(['damageDescription', 'applicableConventionType', 'id'], res) === 'IRSI'
      ) {
        needSaving = true
        CartStore.instructionsIRSIRule()
      }
      // PRESENTED RULE
      else if (
        (path(['damageDescription', 'hasOtherDamages'], res) === true ||
          path(['claimInformation', 'thirdPartyIsResponsible'], res) === true) &&
        (path(['causesAndCircumstances', 'insuredResponsibility', 'id'], res) ===
          'AGGRIEVED_AND_AUTHOR' ||
          path(['causesAndCircumstances', 'insuredResponsibility', 'id'], res) === 'AUTHOR')
      ) {
        needSaving = true
        CartStore.instructionsPresentedRule()
      }

      if (needSaving) {
        CartStore.save()
      }
    } catch (error) {
      console.error(error)
    }
  }
}

const DecoratedSinister = decorate(SinisterCtrl, {
  loading: observable,
  form: observable,
  originalRiskTypeValue: observable,

  riskTypeChanged: computed,

  loadData: action,
  save: action,
})

export default new DecoratedSinister()
