import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import TourConfigurationCtrl from 'stores/Parameters/TourConfigurationCtrl'
import AddressDeparture from 'pages/Parameters/Pages/TourConfiguration/AddressDeparture'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import Button from 'components/Button/Button'
import { uid } from 'utils'

class TourConfiguration extends React.Component {
  UNSAFE_componentWillMount() {
    TourConfigurationCtrl.loadData()
  }

  saveDepartures = () => TourConfigurationCtrl.save()

  render() {
    const { t } = this.props
    const { loading, saving, days } = TourConfigurationCtrl

    return (
      <div className="tour-configuration-page">
        <div className="row no-gutters">
          <h5 className="mb-4">{t('parameters.tourConfiguration.title')}</h5>
        </div>

        <CardLayout className="tour-configuration">
          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              {days.map(day => (
                <AddressDeparture key={`day-${uid(10)}`} day={day} />
              ))}

              <div className="d-flex justify-content-end mt-2">
                <Button className="btn btn-primary" onClick={this.saveDepartures} loading={saving}>
                  {t('common.save')}
                </Button>
              </div>
            </React.Fragment>
          )}
        </CardLayout>
      </div>
    )
  }
}

export default withTranslation()(observer(TourConfiguration))
