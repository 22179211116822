import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import SearchInput from 'pages/Dashboard/components/SearchInput'
import FiltersForm from 'pages/Dashboard/components/FiltersForm'
import DropdownSelectFilters from 'pages/Dashboard/components/DropdownSelectFilters'
import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import Button from 'components/Button/Button'

class Filters extends React.Component {
  showFilter = () => {
    DashboardFilterStore.setProperty('showForm', true)
  }
  closeFilter = () => {
    DashboardFilterStore.closeFilter()
  }
  newFilter = () => {
    DashboardFilterStore.setCurrentFilter()
    DashboardFilterStore.setProperty('showForm', true)
  }
  render() {
    const { showForm } = DashboardFilterStore
    const { t } = this.props

    return (
      <div className="card">
        <div className="card-body">
          <div className="row no-gutters">
            <div className="col-12 col-md-5">
              <SearchInput />
            </div>
            <div className="col-12 mt-2 mt-md-0 pl-md-2 col-md-5">
              <DropdownSelectFilters />
            </div>
            <div className="col mt-2 mt-md-0 pl-md-2 text-right">
              <div className="btn-group">
                {showForm ? (
                  <Button className="btn btn-secondary" onClick={this.closeFilter}>
                    <i className="fa fa-close" /> {t('common.close')}
                  </Button>
                ) : (
                  <Button className="btn btn-primary" onClick={this.showFilter}>
                    <i className="fa fa-filter" /> {t('common.filter')}
                  </Button>
                )}
                <Button className="btn btn-success" onClick={this.newFilter}>
                  <i className="fa fa-plus" />
                </Button>
              </div>
            </div>
          </div>
          <FiltersForm />
        </div>
      </div>
    )
  }
}

export default withTranslation()(observer(Filters))
