import request from 'services/httpRequest'

export const getMissions = url => {
  return request({
    method: 'get',
    url,
  })
    .then(res => {
      return res.data.attributes[0]
    })
    .catch(err => {
      if (err) return Promise.reject(err.data)
    })
}
