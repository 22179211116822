import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { compose } from 'recompose'
import Label from 'components/Label/Label'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import classNames from 'classnames'

const SelectInvolvedPartyModal = ({
  showModal,
  onClose,
  onChange,
  onValid,
  onChangeIP,
  optionsIP,
  selectedIP,
  email,
  phoneNumber,
  emailOptionAvailable = true,
  phoneOptionAvaiable = true,
  emailInputName = 'email',
  phoneInputName = 'phoneNumber',
  involvedPartyInputName = 'involvedParty',
  loading,
  t,
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const isModalValid = () => {
    return (
      (phoneOptionAvaiable && phoneNumber.length >= 10) ||
      (emailOptionAvailable && email.length > 0)
    )
  }

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={showModal}
      onClose={onClose}
      draggable
      close={onClose}
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="mb-0">{t('common.selectIP')}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <ResponsiveSelect
            name={involvedPartyInputName}
            onChange={onChangeIP}
            options={optionsIP}
            value={selectedIP}
            placeholder={t('mission.calculation.involvedParty.involvedParty')}
            nullable
          />
        </div>

        {emailOptionAvailable && (
          <div className="mb-3">
            <Label>{t('resetPasswordPage.email')} : </Label>

            <Input
              name={emailInputName}
              type="text"
              className="form-control"
              onChange={onChange}
              value={email}
              placeholder="email@email.com"
            />
          </div>
        )}

        {phoneOptionAvaiable && (
          <div className="mb-3">
            <Label>{t('partyInvolved.modal.mobile')} : </Label>
            <Input
              name={phoneInputName}
              value={phoneNumber}
              className="form-control"
              onChange={onChange}
              type="text"
              placeholder="+00 123 456 78"
            />
          </div>
        )}
      </div>

      <div className="modal-footer d-flex justify-content-end pb-2 pt-2">
        <Button className="btn btn-secondary" onClick={onClose} loading={loading}>
          {t('common.close')}
        </Button>

        <Button
          className="btn btn-primary"
          onClick={onValid}
          disabled={!isModalValid()}
          loading={loading}
        >
          {t('common.send')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(withTranslation(), observer)(SelectInvolvedPartyModal)
