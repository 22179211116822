import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { dropLast } from 'ramda'
import classNames from 'classnames'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import InputPrice from 'components/InputPrice/InputPrice'
import Unit from 'components/Unit/Unit'
import { euro, roundDecimal } from 'utils/currency'

const Pricing = observer(({ obj, item, onChange }) => {
  // FIX DISPLAYING OF VAT COMPUTATION
  // c'est deja calcule cote item mais l'affichage n'est pas coherent avec le total de l'item
  const vat = (obj.type === 'material' || obj.type === 'supply') && item.selfRepair ? 1.2 : 1

  if (obj.type === 'material') {
    const { qualityType } = DetailPackageCtrl.packageData
    let price = 0

    switch (qualityType) {
      case 'low':
        price = obj.calculatedPriceLWithMargin
        break
      case 'medium':
        price = obj.calculatedPriceMWithMargin
        break
      case 'high':
        price = obj.calculatedPriceHWithMargin
        break
      default:
        console.warn(`Package quality not found ${qualityType}.`)
        return null
    }
    price = price * vat

    if (obj.default) {
      return (
        <InputPrice
          name="overridePrice"
          classNamesForPrice="font-italic text-primary"
          className="text-primary"
          price={obj.overridePrice === null ? roundDecimal(price) : roundDecimal(obj.overridePrice)}
          overridable
          overrode={obj.overrode}
          onChange={onChange}
          disabled={!item.piActive}
          showUnit={item.unit !== 'F'}
          unit={item.unit}
          disableShowUnit={false}
          changed={obj.overridePrice !== null}
        />
      )
    }

    return (
      <div className="input-price-wrapper">
        <span>{euro(parseFloat(price))}</span>
        <Unit className="unit" unit={item.unit} />
      </div>
    )
  }

  return (
    <InputPrice
      name="overridePrice"
      classNamesForPrice="font-italic"
      price={
        obj.overridePrice === null
          ? roundDecimal(obj.calculatedPriceWithMargin * vat)
          : roundDecimal(obj.overridePrice)
      }
      overridable
      overrode={obj.overrode}
      onChange={onChange}
      disabled={!item.piActive}
      showUnit={item.unit !== 'F'}
      unit={item.unit}
      disableShowUnit={false}
      changed={obj.overridePrice !== null}
    />
  )
})

const Item = inject('MissionStore', 'ManagerClaimStore')(
  withRouter(
    observer(
      class Item extends React.Component {
        selectDefault = cde => this.props.itemData.selectDefaultMaterial(cde)
        onChange = e => this.props.obj.setProperty(e.target.name, e.target.value)

        render() {
          const {
            obj,
            itemData,
            match: {
              params: { type: urlType },
            },
            MissionStore: { isMissionClosed },
            ManagerClaimStore: { isClaimClosed },
          } = this.props
          const { type } = obj
          const isClosed = urlType === 'mission' ? isMissionClosed : isClaimClosed
          const name = dropLast(1, obj.label)
          const selectDefaultFunc =
            type === 'material' && !isClosed ? this.selectDefault.bind(this, obj.cde) : () => {}

          return (
            <div
              className={classNames(
                'row no-gutters pl-2',
                {
                  'clickable hover-primary': type === 'material',
                },
                { 'selected-material': type === 'material' && obj.default },
                { disabled: isClosed },
              )}
            >
              <div className="col-12 sol-sm-8 col-md-8" onClick={selectDefaultFunc}>
                {obj.default ? (
                  <i className="fa fa-check-circle text-success material-icon-check" />
                ) : null}
                <p
                  className={classNames('font-italic mb-0 item-padding', {
                    'not-default': !obj.default,
                  })}
                >
                  {name}
                </p>
              </div>
              <div
                className="d-none d-sm-none d-md-block col-md-4 col-lg-3 text-right"
                onClick={selectDefaultFunc}
              >
                <Pricing obj={obj} item={itemData} onChange={this.onChange} />
              </div>
              <div className="col-1 d-none d-lg-block d-xl-block" onClick={selectDefaultFunc} />
            </div>
          )
        }
      },
    ),
  ),
)

class CollapseData extends React.Component {
  render() {
    const { type, items, t, itemData } = this.props
    if (items.length === 0) return null

    return (
      <div>
        <div className="row no-gutters pl-2">
          <div className="col-12 font-weight-bold">
            {t(`mission.calculation.detailPackage.${type}`)}
          </div>
        </div>

        {items.map(obj => {
          return <Item key={`${obj.type}-${obj.cde}`} obj={obj} itemData={itemData} />
        })}
      </div>
    )
  }
}

export default withTranslation()(CollapseData)
