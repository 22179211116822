import { observable, action, computed, decorate, runInAction } from 'mobx'
import { isNil, path, difference } from 'ramda'

import { fetchIrsi, saveIrsi } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import MissionStore from 'stores/Mission/domain/MissionStore'
import CartStore from 'stores/Mission/domain/CartStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import PrejudiceStore from 'stores/Mission/domain/PrejudiceStore'

class IrsiCtrl {
  loading = false
  form = null
  loaded = false

  get involvedParties() {
    if (isNil(this.form)) return []

    const embeddedForm = this.form.inputs.find(input => input.name === 'irsiInvolvedPartys')

    if (!embeddedForm) return []

    const involvedPartyIDs = embeddedForm.collection.map(form => {
      const ip = form.inputs.find(input => input.name === 'involvedPartyId')
      if (ip) return ip.value
      return null
    })

    return involvedPartyIDs.filter(ip => !isNil(ip))
  }

  loadData = async (wan, irsiData) => {
    this.loading = true

    try {
      const form = await fetchIrsi(wan)
      form.name = 'irsi'
      runInAction(() => {
        this.form = new Form({ form, data: irsiData })
        this.loaded = true
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    // PROTECT IRSI PARTY INVOLVED DATA
    const irsiIPs = formData.irsiInvolvedPartys.map(embedded => embedded.involvedPartyId)
    const diff = difference(CartStore.uniqIPs, irsiIPs)
    if (diff.length > 0) {
      AlertCtrl.alert('danger', 'partyInvolved.ventilationWarning')
      throw new Error('party is ventilated')
    }

    // PROTECT INSURED OPTION / PREJUDICE
    if (
      PrejudiceStore.ready &&
      !!PrejudiceStore.payloads.length &&
      path(['totals', 'finalPrice'], PrejudiceStore) > 0
    ) {
      formData.irsiInvolvedPartys.forEach(data => {
        if (data.involvedPartyId !== MissionStore.insuredInformationId) {
          AlertCtrl.alert('danger', 'partyInvolved.prejudiceWarning')
          throw new Error('party is the insurer and have prejudice')
        }
      })
    }

    try {
      const cfa = await saveIrsi(wan, formData)
      MissionStore.updateIrsi(cfa)
    } catch (error) {
      console.error(error)
    }
  }

  resetForm = () => {
    this.form = null
    this.loaded = false
    this.loading = false
  }
}

const DecoratedIrsi = decorate(IrsiCtrl, {
  loading: observable,
  form: observable,
  loaded: observable,

  involvedParties: computed,

  loadData: action,
  save: action,
  resetForm: action,
})

export default new DecoratedIrsi()
