import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import { format } from 'utils/date'

const NavigationDate = ({
  t,
  UiCtrl: { language },
  CalendarExpertStore: { viewType, day, weekNumber },
}) => {
  let date = `${t('calendar.info.week')} ${weekNumber}`

  if (viewType === 'day') date = format(day, 'iii d MMM', { locale: language })

  return <div className="navigation-date text-center font-weight-bold mr-3">{date}</div>
}

export default compose(
  inject('UiCtrl', 'CalendarExpertStore'),
  withTranslation(),
  observer,
)(NavigationDate)
