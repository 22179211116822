import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import CanvasZone from './CanvasZone'
import ToolBar from './ToolBar'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import { isRequiredValid } from 'utils/supportingDocumentsForm'
import Button from 'components/Button/Button'

class DrawModal extends React.Component {
  toggleDrawModal = () => DrawCtrl.toggleDrawModal()
  setSchemaName = e => DrawCtrl.setSchemaName(e.target.value)
  createSchema = () => DrawCtrl.createSchema(this.props.match.params.id)

  render() {
    const { t } = this.props
    if (!DrawCtrl.drawModal) return null

    return (
      <ModalWithBackdrop
        className="drawModal modal modal-fullscreen-xl"
        show={DrawCtrl.drawModal}
        size=""
        close={this.toggleDrawModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {DrawCtrl.photoEdit
              ? t('mission.supportingDocuments.draw.modifyImage')
              : t('mission.supportingDocuments.draw.title')}
          </h5>
          <span>
            <button type="button" className="close" onClick={this.toggleDrawModal}>
              <span>&times;</span>
            </button>
          </span>
        </div>
        <div className="modal-body">
          <div className="row justify-content-between align-items-center mb-1">
            <div className="col-10">
              {!DrawCtrl.photoEdit && (
                <form>
                  <Input
                    type="text"
                    className="form-control"
                    value={DrawCtrl.schemaName}
                    onChange={this.setSchemaName}
                    required
                    placeholder={t('mission.supportingDocuments.draw.name')}
                    errors={[]}
                    maxLength={64}
                  />
                </form>
              )}
            </div>
            <div className="col-2 d-flex justify-content-end">
              <div className="btn-group mr-2 btn-add-photo-edit">
                <Button
                  className="btn btn-primary"
                  disabled={!isRequiredValid(DrawCtrl.schemaName) || DrawCtrl.elements.length === 0}
                  onClick={this.createSchema}
                  loading={DrawCtrl.loading}
                >
                  {!DrawCtrl.photoEdit ? t('common.validate') : t('common.modify')}
                </Button>
              </div>
            </div>
          </div>
          <div className="row">
            <ToolBar />
            <div className="col-11">
              <CanvasZone />
            </div>
          </div>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default withRouter(withTranslation()(observer(DrawModal)))
