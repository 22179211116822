import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import Label from 'components/Forms/Label'
import InputComponent from './InputComponent'

const EmbeddedFormFields = ({ trigger, inputs, MissionStore: { isMissionClosed } }) => {
  return (
    <div className="w-100 card pt-3 px-3 mr-2">
      {inputs.map(input => {
        if (!input.show) return null
        if (isMissionClosed) input.disabled = true

        return (
          <div key={input.id} className="form-group row no-gutters">
            {input.type !== 'label' && (
              <Label className="col-sm-4" required={input.required} label={input.label} />
            )}

            <div className="col">
              <InputComponent input={input} formObj={inputs} trigger={trigger} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default compose(inject('MissionStore'), observer)(EmbeddedFormFields)
