import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import './Toggle.css'

const Toggle = ({ disabled, onChange, checked, autoFocus }) => {
  const { t } = useTranslation()
  const [hasFocus, setHasFocus] = useState(autoFocus)

  const handleClick = () => {
    if (!disabled) {
      onChange(!checked)
      setHasFocus(true)
    }
  }

  return (
    <div
      tabIndex="0"
      onBlur={() => setHasFocus(false)}
      className={classNames(
        'toggle',
        { 'toggle--checked': checked },
        { 'toggle--focus': hasFocus },
        { 'toggle--disabled': disabled },
      )}
      onClick={handleClick}
    >
      <div className="toggle-track">
        <div className="toggle-track-check">{t('common.yes')}</div>
        <div className="toggle-track-x">{t('common.no')}</div>
      </div>
      <div className="toggle-thumb" />
    </div>
  )
}

Toggle.defaultProps = {
  checked: false,
  autoFocus: false,
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default observer(Toggle)
