import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { add } from 'ramda'

import { euro } from 'utils/currency'

const DescriptionExcess = ({
  t,
  CartStore: {
    totalsFromCart: {
      catalog: {
        overflowTotalPriceWithVAT,
        overflowTotalPriceWithoutVAT,
        noGuaranteeTotalPriceWithVAT,
        noGuaranteeTotalPriceWithoutVAT,
      },
    },
  },
  MissionStore: { isMPJ },
}) => {
  return (
    <table className="table table-bordered">
      <tbody>
        <tr>
          <th>{t('mission.conclusion.title.prejudice')}</th>
          <th className="text-center">{t('mission.conclusion.computation.vanWithoutVAT')}</th>
          <th className="text-center">{t('mission.conclusion.computation.vanWithVAT')}</th>
        </tr>

        {!isMPJ && (
          <tr>
            <th scope="row">{t('mission.conclusion.data.overflow')}</th>
            <td>{euro(overflowTotalPriceWithoutVAT)}</td>
            <td>{euro(overflowTotalPriceWithVAT)}</td>
          </tr>
        )}

        {!isMPJ && (
          <tr>
            <th scope="row">{t('mission.conclusion.data.noGuarantee')}</th>
            <td>{euro(noGuaranteeTotalPriceWithoutVAT)}</td>
            <td>{euro(noGuaranteeTotalPriceWithVAT)}</td>
          </tr>
        )}

        <tr>
          <th scope="row">{t('mission.conclusion.computation.total')}</th>
          <td>{euro(add(overflowTotalPriceWithoutVAT, noGuaranteeTotalPriceWithoutVAT))}</td>
          <td>{euro(add(overflowTotalPriceWithVAT, noGuaranteeTotalPriceWithVAT))}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default compose(
  inject('CartStore', 'MissionStore'),
  withTranslation(),
  observer,
)(DescriptionExcess)
