import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'

const ReportButton = ({ MissionStore: { isMissionClosed }, children }) => {
  if (isMissionClosed) return null

  return <Fragment>{children}</Fragment>
}

export default inject('MissionStore')(observer(ReportButton))
