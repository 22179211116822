import React from 'react'
import { withTranslation } from 'react-i18next'
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  format,
  parseISO,
} from 'date-fns'

const LastUpdatedAt = ({ t, lastUpdatedAt, saving, suffix }) => {
  if (lastUpdatedAt === null || lastUpdatedAt === undefined) return null

  const now = new Date()
  let lastUpdate = 'lastUpdatedAt'
  let showTime = null
  const minutes = differenceInMinutes(now, parseISO(lastUpdatedAt))
  const hours = differenceInHours(now, parseISO(lastUpdatedAt))
  const days = differenceInDays(now, parseISO(lastUpdatedAt))

  if (minutes === 0) showTime = t('common.leastThanMinute')
  else if (minutes < 60) showTime = t('common.minute', { count: minutes })
  else if (minutes > 60 && hours < 24) showTime = t('common.hour', { count: hours })
  else if (days < 3) showTime = t('common.day', { count: days })
  else {
    lastUpdate = 'lastUpdatedAtWithDate'
    showTime = format(parseISO(lastUpdatedAt), t('common.datetimeFormat'))
  }
  if (suffix !== undefined && suffix !== null && suffix !== '') {
    lastUpdate += suffix
  }
  return (
    <span>
      {saving ? t('common.lastUpdateAtSaving') : `${t(`common.${lastUpdate}`)} ${showTime}`}
    </span>
  )
}

export default withTranslation()(LastUpdatedAt)
