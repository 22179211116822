import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import FurnitureCtrl from 'stores/Mission/EasyEstimation/FurnitureCtrl'
import Dropdown from '../components/Dropdown'
import Tabs from '../components/Tabs'
import CategoryModal from './CategoryModal'
import CurrentCategory from './CurrentCategory'
import { furnituresPrice } from 'utils/cart'

const Furniture = ({
  className,
  CartStore: { furnitures, removeFurniture },
  ManagerClaimStore,
  MissionStore,
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const isClosed =
    type === 'mission' ? MissionStore.isMissionClosed : ManagerClaimStore.isClaimClosed

  useEffect(() => {
    return () => {
      FurnitureCtrl.setProperty('selectedCategory', '')
    }
  }, [])

  const { listCategories, selectedCategory, setProperty, removeFromListCategories } = FurnitureCtrl

  const deleteTab = cde => {
    furnitures.forEach(f => {
      if (f.category === cde) {
        removeFurniture(f.id)
      }
    })
    removeFromListCategories(cde)
  }
  return (
    <div className={className}>
      <CategoryModal selectedList={listCategories} />

      <Dropdown
        title={t('mission.calculation.cart.titleFurniture')}
        showDropdown
        priceType={`${t('mission.calculation.cart.totalWithVAT')} :`}
        total={furnituresPrice(furnitures)}
      >
        <Tabs
          addText={t('mission.calculation.furniture.addCategory')}
          list={listCategories}
          selected={selectedCategory}
          onSelect={cde => setProperty('selectedCategory', cde)}
          add={() => setProperty('showCategoryModal', true)}
          deleteTab={deleteTab}
          disabled={isClosed}
        />

        <CurrentCategory isClosed={isClosed} />
      </Dropdown>
    </div>
  )
}

export default inject(
  'CartStore',
  'MissionStore',
  'ManagerClaimStore',
  'UserStore',
)(observer(Furniture))
