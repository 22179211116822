import React from 'react'
import PropTypes from 'prop-types'

const noop = () => {}

export default class InputCheckbox extends React.Component {
  onChange = e =>
    this.props.onChange({ target: { name: this.props.name, value: e.target.checked } })

  render() {
    const { className, name, value } = this.props

    return (
      <input
        name={name}
        className={className}
        checked={value}
        onChange={this.onChange}
        type="checkbox"
      />
    )
  }
}

InputCheckbox.defaultProps = {
  className: '',
  name: 'inputCheckboxName',
  onChange: noop,
  value: false,
}

InputCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
