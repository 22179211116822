import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil } from 'ramda'

import './Forms.css'
import InputErrors from 'components/Forms/InputErrors'

const noop = () => {}

class Input extends React.Component {
  render() {
    const {
      autoComplete,
      className,
      disabled,
      minLength,
      maxLength,
      id,
      name,
      onKeyPress,
      onBlur,
      onChange,
      onFocus,
      errors,
      pattern,
      placeholder,
      readOnly,
      required,
      step,
      type,
      value,
      max,
      autoFocus,
      style,
    } = this.props
    let { min } = this.props

    return (
      <React.Fragment>
        <input
          key={`input-${name}`}
          id={id}
          autoComplete={autoComplete}
          className={classNames(
            className,
            { error: errors && !!errors.length, required },
            { 'bg-required': required && !value },
          )}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          name={name}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          pattern={pattern}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          step={step}
          type={type}
          value={isNil(value) ? '' : value}
          min={isNil(min) ? 0 : min}
          max={max}
          autoFocus={autoFocus}
          style={style}
        />
        <InputErrors errors={errors} />
      </React.Fragment>
    )
  }
}

Input.defaultProps = {
  autoComplete: 'off',
  name: 'inputName',
  type: 'text',
  onChange: noop,
  errors: [],
  style: {},
}

Input.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  label: PropTypes.string,
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'password', 'hidden']),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
}

export default withTranslation()(Input)
