import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import { uid } from 'utils'
import classNames from 'classnames'
import { fetchUsers } from 'services/userManagement'

function Impersonate({ t, UserStore }) {
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUsers({
      customerId: UserStore.customer.id,
      recursive: false,
      filterOnlyExperts: false,
    }).then(result => {
      setUsersList(result)
      setLoading(false)
    })
  }, [UserStore.customer.id])

  return (
    <div className="informations-page">
      <h5 className="title mb-4">{t('common.impersonate')}</h5>

      <CardLayout>
        {loading ? (
          <Loader />
        ) : (
          <table className="table table-bordered">
            <thead>
              <tr className="table-primary">
                <th colSpan="4" scope="col">
                  <div className="mt-2 float-left">{UserStore.customer.name}</div>
                </th>
              </tr>

              <tr>
                <th scope="col" className="d-none d-lg-table-cell">
                  {t('parameters.userManagement.firstName')}
                </th>
                <th scope="col">{t('parameters.userManagement.lastName')}</th>
                <th scope="col">{t('parameters.userManagement.login')}</th>
                <th scope="col" className="d-none d-lg-table-cell">
                  {t('parameters.userManagement.email')}
                </th>
              </tr>
            </thead>
            <tbody>
              {usersList.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    {t('parameters.userManagement.none')}
                  </td>
                </tr>
              ) : (
                usersList.map(user => (
                  <tr
                    key={uid(10)}
                    className={classNames('clickable', { 'disabled-user': !user.enabled })}
                    onClick={() => {
                      UserStore.setProperty(
                        'impersonating',
                        `${UserStore.customer.id}\\${user.email}`,
                      )
                      UserStore.loadImpersonatedUser()
                    }}
                  >
                    <td className="d-none d-lg-table-cell">{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.username}</td>
                    <td className="d-none d-lg-table-cell">{user.email}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </CardLayout>
    </div>
  )
}

export default inject('UserStore')(withTranslation()(observer(Impersonate)))
