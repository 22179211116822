import request from 'services/httpRequest'

export const fetchClaimStatus = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/status/v1/status/list',
    })

    return res.data
      .map(status => status.attributes)
      .sort((status, nextStatus) => {
        if (status.rank < nextStatus.rank) return -1
        else return 1
      })
  } catch (err) {
    return Promise.reject(err)
  }
}
