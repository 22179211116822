import React from 'react'
import { observer } from 'mobx-react'

import Toggle from 'components/Toggle'

const ToggleWrapper = ({ inputData }) => {
  const onChange = value => inputData.setProperty('value', value)

  const { value, disabled, autoFocus } = inputData

  return <Toggle checked={value} {...{ disabled, autoFocus, onChange }} />
}

export default observer(ToggleWrapper)
