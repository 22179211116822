import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getFullLinkFormShortenToken } from 'services/httpRequest'

const ShortenUrl = () => {
  const { shortenToken } = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchLink = async () => {
      const link = await getFullLinkFormShortenToken(shortenToken)

      if (link) {
        window.location.href = link
        return
      }
      setLoading(false)
    }
    fetchLink()
  })

  if (loading) return null

  return <h1 className="text-center text-primary">{t('notFound.title')}</h1>
}

export default ShortenUrl
