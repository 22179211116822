import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useHistory } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'

const Irsi = ({ IrsiCtrl: { loading, form, save } }) => {
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  const nextStep = () => history.push(`/mission/${id}/calculation/listPackage`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="coverage-details-page">
        <Form formObj={form} nextStep={nextStep} saveService={save.bind(this, id)} />
      </div>
    </CardLayout>
  )
}

export default inject('IrsiCtrl')(observer(Irsi))
