import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import classNames from 'classnames'
import CurrentJob from './CurrentJob'
import { concatAddress } from 'utils'
import { path } from 'ramda'

const LastBill = ({
  ManagerClaimStore: { claim, lastBillJobs, getLastBillJobById },
  LastBillCtrl: { currentJob, loading, resetCtrl, setCurrentJob },
  t,
}) => {
  useEffect(() => {
    return function() {
      resetCtrl()
    }
  }, [resetCtrl])

  useEffect(() => {
    if (!currentJob) {
      setCurrentJob(getLastBillJobById(lastBillJobs[0]['id']))
    }
  }, [currentJob, setCurrentJob, getLastBillJobById, lastBillJobs])

  if (!currentJob) return <Loader />

  const changeCurrentJob = id => {
    setCurrentJob(getLastBillJobById(id))
  }

  return (
    <div className="last-bill">
      <CardLayout>
        <h5>{t('lastbill.title')}</h5>
        <div className="mb-2">
          {t('lastbill.addressOfLoss')} :{' '}
          {concatAddress(path(['claimInformation', 'addressOfLoss'], claim))}
        </div>

        <ul className="nav nav-tabs mb-3">
          {lastBillJobs.map(job => (
            <li className="nav-item" key={`job-${job.id}`}>
              <span
                className={classNames('nav-link clickable py-2', {
                  active: currentJob.id === job.id,
                })}
              >
                <span onClick={() => changeCurrentJob(job.id)}>{job.forQualificationLabel}</span>
              </span>
            </li>
          ))}
        </ul>
        {loading && <Loader />}
        {!loading && <CurrentJob />}
      </CardLayout>
    </div>
  )
}

export default compose(
  inject('ManagerClaimStore', 'LastBillCtrl'),
  withTranslation(),
  observer,
)(LastBill)
