import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import classNames from 'classnames'

import './Tooltip.css'

const TooltipBox = ({ text, placement, innerClassName }) => {
  return (
    <Popper placement={placement}>
      {({ ref, style, placement, arrowProps }) => (
        <div
          ref={ref}
          style={style}
          data-placement={placement}
          className={classNames('tooltip', 'show', `bs-tooltip-${placement}`)}
        >
          <div className={classNames('tooltip-inner', innerClassName)}>{text}</div>
          <span ref={arrowProps.ref} style={arrowProps.style} className="arrow" />
        </div>
      )}
    </Popper>
  )
}

const Tooltip = ({
  setHour,
  setCurrentStep,
  meteo,
  className,
  children,
  id,
  text,
  placement,
  clickable,
  innerClassName,
}) => {
  const [show, setShow] = useState(false)

  const onMouseLeave = () => {
    if (!clickable) setShow(false)
  }
  const onMouseOver = () => {
    if (meteo) {
      setHour(id)
      setCurrentStep(id)
    }
    if (!clickable) setShow(true)
  }
  const onClick = () => {
    if (meteo) {
      setHour(id)
      setCurrentStep(id)
    }
    if (clickable) setShow(prevShow => !prevShow)
  }

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div {...{ ref, className, onMouseLeave, onMouseOver, onClick }}>{children}</div>
        )}
      </Reference>
      {show && text && <TooltipBox {...{ placement, text, innerClassName }} />}
      {show && clickable && <div className="tooltip-backdrop" onClick={() => setShow(false)} />}
    </Manager>
  )
}

export default Tooltip

Tooltip.propTypes = {
  placement: PropTypes.oneOf(['left', 'top', 'right', 'bottom', 'auto']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
  clickable: PropTypes.bool,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
}

Tooltip.defaultProps = {
  placement: 'bottom',
  clickable: false,
  innerClassName: '',
}
