import React from 'react'
import { runInAction } from 'mobx'
import { observer, inject } from 'mobx-react'
import { NavLink, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import Furniture from 'stores/Mission/domain/Furniture'

const RadioTabs = ({
  t,
  match: {
    params: { id, type },
    path,
  },
  MissionStore: { isIRSI, selectedIRSICart },
}) => {
  const endpoint = path.split('/').pop()
  const furnitureType = endpoint === 'searchOutdoorFurniture' ? 'outdoor' : 'regular'
  const setContext = () =>
    AddFurnitureCtrl.setFurniture(
      runInAction(
        () =>
          new Furniture({
            location: furnitureType,
            roomType: SearchFurnitureCtrl.selectedRoom,
            involvedParty: isIRSI ? selectedIRSICart : null,
          }),
      ),
      'new',
    )

  return (
    <div className="search-radio-tabs row no-gutters mr-xl-2">
      <div
        name="catalogType"
        className={classNames('tab col-12 col-sm-4 col-md-4 col-lg-auto', {
          selected: true,
        })}
      >
        {t('mission.calculation.searchFurniture.catalog')}
      </div>
      <NavLink
        exact
        to={`/${type}/${id}/calculation/add${furnitureType.charAt(0).toUpperCase() +
          furnitureType.slice(1)}Furniture`}
        onClick={setContext}
        className="tab col-12 col-sm-4 col-md-4 col-lg-auto"
      >
        {t('mission.calculation.furniture.breadcrumbNewFurniture')}{' '}
        {t(`mission.calculation.furniture.${furnitureType}`)}
      </NavLink>
    </div>
  )
}
export default compose(
  inject('MissionStore'),
  withRouter,
  withTranslation(),
  observer,
)(RadioTabs)
