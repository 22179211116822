import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const SelectRoom = ({ match: { path } }) => {
  const last = path.split('/').pop()
  const outdoorCondition = last === 'searchOutdoorPackage'
  const { roomType, missionRoomsOptions } = SearchPackageCtrl
  const list = outdoorCondition ? SearchFurnitureCtrl.roomsForSelect : missionRoomsOptions
  const onChange = e => SearchPackageCtrl.setProperty(e.target.name, e.target.value)

  return (
    <ResponsiveSelect
      className="select-room"
      name="roomType"
      value={roomType === null ? 'selectFilter' : roomType}
      options={list}
      onChange={onChange}
      isSearchable
    />
  )
}

export default compose(
  withRouter,
  observer,
)(SelectRoom)
