import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import InputErrors from 'components/Forms/InputErrors'

const InputNumber = ({
  name,
  value,
  disabled,
  className,
  required,
  errors,
  placeholder,
  onChange,
  autoFocus,
  minValue,
  maxValue,
  step,
}) => {
  const onChangeNumber = e => {
    if (
      (!Number.isInteger(maxValue) || e.target.value <= maxValue) &&
      (!Number.isInteger(minValue) || e.target.value >= minValue)
    ) {
      onChange(e)
    }
  }

  return (
    <Fragment>
      <input
        name={name}
        placeholder={placeholder}
        className={classNames(
          className,
          { error: errors && !!errors.length, required },
          { 'bg-required': required && !value },
        )}
        value={value}
        onChange={onChangeNumber}
        disabled={disabled}
        key={`input-number-${name}`}
        autoFocus={autoFocus}
        type="number"
        step={step}
      />
      <InputErrors errors={errors} />
    </Fragment>
  )
}

InputNumber.defaultProps = {
  name: 'input-number-name',
  onChange: () => {},
  disabled: false,
  required: false,
  autoFocus: false,
  errors: [],
  className: '',
  step: '1',
}

InputNumber.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  errors: PropTypes.array,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  step: PropTypes.string,
}

export default InputNumber
