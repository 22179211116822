import { observable, action, decorate, runInAction } from 'mobx'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { generateReport } from 'services/claimManagerForm'

class OrientationReportCtrl {
  loading = false
  reportContent = ''
  numPages = 0

  generateReport = async wan => {
    this.setProperty('reportContent', '')

    try {
      const res = await generateReport(wan)

      runInAction(() => {
        this.setProperty('reportContent', res)
      })
    } catch (error) {
      console.warn(error)
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedOrientationReport = decorate(OrientationReportCtrl, {
  loading: observable,
  reportContent: observable,
  numPages: observable,

  generateReport: action,
  setProperty: action,
})

export default new DecoratedOrientationReport()
