import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import TH from './TH'
import TD from './TD'
import { formatCurrency } from 'utils/currency'

const RecapPrejudiceTable = ({ payload, payload: { cde, isExpanded } }) => {
  const { t } = useTranslation()

  return (
    <table border="1" className="line-display table table-bordered table-sm">
      <thead>
        <tr className="table-secondary">
          <TH>
            <button
              onClick={payload.toggleAccordion}
              className="btn btn-primary-outline text-primary"
            >
              <i className={`fa fa-lg fa-angle-${isExpanded ? 'down' : 'up'}`}></i>
            </button>
          </TH>
          <TH style={{ width: '35%' }}>{t('mission.prejudice.description')}</TH>
          <TH>{t('mission.prejudice.damageAmount')}</TH>
          <TH>{t('mission.prejudice.damageAmountWithVAT')}</TH>
          <TH>{t('mission.prejudice.obsolescence')}</TH>
          <TH>{t('mission.prejudice.immediateCompensation')}</TH>
          <TH>{t('mission.prejudice.obsolescenceReturn')}</TH>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TD></TD>
          <TD className="text-left pl-3">{t(`mission.prejudice.options.${cde}`)}</TD>
          <TD>{formatCurrency(payload.totalWithoutVAT)}</TD>
          <TD>{formatCurrency(payload.totalWithVAT)}</TD>
          <TD>{formatCurrency(payload.totalObsolescenceWithoutVAT)}</TD>
          <TD>{formatCurrency(payload.immediateCompensationTotal)}</TD>
          <TD>{formatCurrency(payload.totalORWithoutVAT)}</TD>
        </tr>
      </tbody>
    </table>
  )
}

export default observer(RecapPrejudiceTable)
