import { observable, action, computed, runInAction, decorate } from 'mobx'
import { equals, type } from 'ramda'
import i18next from 'i18next'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { uid } from 'utils'
import { saveUser, fetchUserAreas, saveUserAreas } from 'services/userManagement'

class User {
  id = null
  firstName = ''
  lastName = ''
  email = ''
  username = ''
  roles = []
  technicityLevels = []
  skills = ''
  enabled = true
  areas = ''
  plainPassword = ''
  originalData = null
  saving = false
  errors = []

  constructor(data) {
    if (data) {
      this.id = data.id
      this.firstName = data.firstName
      this.lastName = data.lastName
      this.email = data.email
      this.username = data.username
      this.phone = data.phoneNumber
      this.enabled = data.enabled
      this.technicityLevels = data.technicityLevels.map(techLevel => techLevel.technicityLevel.key)
      this.roles = data.roles.filter(role => role !== 'ROLE_USER')
    }
    this.setOriginalData()
  }

  get asJson() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      username: this.username,
      phone: this.phone,
      role: this.roles,
      technicityLevels: this.technicityLevels,
      skills: this.skills,
      enabled: this.enabled,
      areas: this.areas,
      plainPassword: this.plainPassword,
    }
  }

  // TODO, il prend pas en consideration les zones !! areas
  get changed() {
    if (this.originalData === null) return true
    return !equals(this.asJson, this.originalData)
  }

  get areasValidation() {
    let error = false
    this.areas.split(',').forEach(area => {
      if (area.length === 0) return
      if (area.length !== 2 && area.length !== 3 && area.length !== 5) error = true
    })
    return error
  }

  get areasFormatted() {
    if (this.areas.length > 0) return this.areas.split(',')
    return []
  }

  get isValid() {
    return !!(this.firstName && this.lastName && this.email && this.username && this.roles.length)
  }

  formatAreas() {
    this.areas = this.areas
      .trim()
      .split(',')
      .filter(area => area.length !== 0)
      .join(',')
  }

  setProperty = (key, value) => {
    if (key === 'roles' || key === 'technicityLevels')
      this[key] = (value && value.map(item => item.value)) || []
    else this[key] = value
  }

  setOriginalData() {
    this.originalData = this.asJson
  }

  generatePassword() {
    this.plainPassword = uid(8)
  }

  async getAreas() {
    try {
      const res = await fetchUserAreas(this.id)
      runInAction(() => {
        this.areas = res.map(area => area.areaValue).join(',')
      })
    } catch (err) {
      console.log(err)
    }
  }

  async save(customerId) {
    this.saving = true
    const method = this.id ? 'patch' : 'post'
    try {
      const res = await saveUser({
        user: {
          ...this.asJson,
          customer: customerId,
        },
        method,
      })
      runInAction(() => {
        if (method === 'post') this.id = res.id
        this.setOriginalData()
        this.errors = []
      })
      await saveUserAreas({ userId: this.id, areas: this.areasFormatted })
      AlertCtrl.alert('success', i18next.t('parameters.userManagement.saveSuccess'))

      return { user: res, method }
    } catch (err) {
      console.log('err', err)
      runInAction(() => {
        if (type(err) === 'Array') this.errors = err
      })
      return Promise.reject(err)
    } finally {
      runInAction(() => (this.saving = false))
    }
  }
}

const DecoratedUser = decorate(User, {
  id: observable,
  firstName: observable,
  lastName: observable,
  email: observable,
  username: observable,
  phone: observable,
  roles: observable,
  technicityLevels: observable,
  skills: observable,
  enabled: observable,
  areas: observable,
  plainPassword: observable,
  originalData: observable,
  saving: observable,
  errors: observable,

  formatAreas: action,
  setProperty: action,
  setOriginalData: action,
  generatePassword: action,
  getAreas: action,
  save: action,

  asJson: computed,
  changed: computed,
  areasValidation: computed,
  areasFormatted: computed,
  isValid: computed,
})

export default DecoratedUser
