import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'

import { noop } from 'utils'
import SDItem from 'components/SDItem'

const Attachments = ({
  documents,
  canDelete = false,
  showInfo = true,
  onSelectItem = noop,
  deleteItem = noop,
  thumbnailSize = 'medium',
  MessagingStore: { showSDModal },
}) => {
  if (showSDModal || documents.length === 0) return null

  return (
    <div className="sd-item-list row mt-2">
      {documents.map(document => (
        <SDItem
          key={document.id ? document.id : document}
          sdItem={document}
          canDelete={canDelete}
          showInfo={showInfo}
          thumbnailSize={thumbnailSize}
          onSelectItem={onSelectItem}
          deleteItem={deleteItem.bind(this, document.id)}
        />
      ))}
    </div>
  )
}

export default compose(inject('MessagingStore'), observer)(Attachments)
