import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import InvolvedPartyName from './InvolvedPartyName'
import TableDescriptionPrejudices from './TableDescriptionPrejudices'
import TableDescriptionFurnitures from './TableDescriptionFurnitures'

const Ventilation = ({
  CartStore: { ventilationIPsData },
  MissionStore: { insuredInformationId },
}) => {
  return (
    <Fragment>
      {ventilationIPsData.map(ventilated => {
        return (
          <Fragment key={`ventilated-${ventilated.involvedPartyId}`}>
            {ventilated.involvedPartyId && (
              <InvolvedPartyName involvedPartyId={ventilated.involvedPartyId} />
            )}

            {ventilated.prejudices && (
              <TableDescriptionPrejudices
                involvedPartyId={ventilated.prejudices.involvedPartyId}
                vans={ventilated.prejudices.vans}
                obs={ventilated.prejudices.obs}
                returns={ventilated.prejudices.returns}
                noReturns={ventilated.prejudices.noReturns}
                withOBWithoutVAT={ventilated.prejudices.withOBWithoutVAT}
                withOBWithVAT={ventilated.prejudices.withOBWithVAT}
                finalPrice={ventilated.prejudices.finalPrice}
                showPrejudiceStore={ventilated.prejudices.involvedPartyId === insuredInformationId}
              />
            )}

            {ventilated.furnitures && (
              <TableDescriptionFurnitures
                involvedPartyId={ventilated.furnitures.involvedPartyId}
                furnituresPerCategory={ventilated.furnitures.computations}
              />
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default compose(
  inject('CartStore', 'MissionStore'),
  observer,
)(Ventilation)
