import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { NavLink, withRouter } from 'react-router-dom'

import './SmartLink.css'
import Tooltip from 'components/Tooltip/Tooltip'

class SmartLink extends React.Component {
  close = () => {
    this.props.UiCtrl.toggleSideNav(false)
  }

  render() {
    const { id } = this.props.match.params
    const { sideNavMobile } = this.props.UiCtrl
    const { children, page, disabled, tooltipDisabled } = this.props

    if (disabled) {
      if (tooltipDisabled.length === 0) {
        return <span className="nav-link disabled">{children}</span>
      } else {
        return (
          <Tooltip text={tooltipDisabled}>
            <span className="nav-link disabled">{children}</span>
          </Tooltip>
        )
      }
    }

    return (
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/mission/${id}/${page}`}
        onClick={sideNavMobile ? () => this.close() : noop => noop}
      >
        {children}
      </NavLink>
    )
  }
}

SmartLink.defaultProps = {
  disabled: false,
  tooltipDisabled: '',
}
SmartLink.protoTypes = {
  disabled: PropTypes.bool,
  tooltipDisabled: PropTypes.string,
}

export default inject('UiCtrl', 'MissionStore')(withRouter(observer(SmartLink)))
