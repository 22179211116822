export const setDataSession = (key, value) => {
  if (!isAvailable()) return

  try {
    const data = JSON.stringify(value)
    sessionStorage.setItem(key, data)
  } catch (err) {
    console.warn('Error stringify data ')
  }
}

export const getDataSession = key => {
  if (!isAvailable()) return

  const data = sessionStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    console.warn(err)
    return null
  }
}

export const emptyDataSession = () => {
  if (!isAvailable()) return

  sessionStorage.clear()
}

export const isAvailable = () => {
  try {
    const storage = window['sessionStorage']
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}
