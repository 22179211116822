import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { path } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'

const DamageDescriptionVehiclePage = ({
  VehicleDamageDescriptionCtrl: { form, loading, save },
}) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  const nextStep = () => history.push(`/mission/${id}/calculation/listPackage`)
  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="dynform-damage-description-vehicle">
        <Form formObj={form} focus={focus} nextStep={nextStep} saveService={save.bind(this, id)} />
      </div>
    </CardLayout>
  )
}

export default inject('VehicleDamageDescriptionCtrl')(observer(DamageDescriptionVehiclePage))
