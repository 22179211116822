import React from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import CreatePackageCtrl from 'stores/Mission/view/CreatePackageCtrl'
import SmartLink from './SmartLink'
import TotalPackages from './TotalPackages'

const PackageLinks = ({
  t,
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
  id,
  packageType,
  hideElements,
  createPackage,
}) => {
  const { type } = useParams()
  const { packageData } = DetailPackageCtrl
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className="breadcrumb m-0 p-0 d-flex align-items-center">
      <SmartLink path={`/${type}/${id}/calculation/listPackage`} selected={hideElements === 2}>
        {t('mission.calculation.cart.breadcrumb')} <TotalPackages />
      </SmartLink>

      <i
        className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
          hide: hideElements !== 0 && hideElements !== 1,
        })}
      />

      {(!isClosed || (packageData && packageData.fromList !== 'cart')) && (
        <React.Fragment>
          <SmartLink
            key="search-package-link"
            path={`/${type}/${id}/calculation/search${
              packageData && packageData.location === 'outdoor' ? 'Outdoor' : ''
            }Package`}
            selected={hideElements === 1}
            hide={hideElements !== 0 && hideElements !== 1}
          >
            {t(`mission.calculation.searchPackage.breadcrumbSearch${packageType}`)}
          </SmartLink>
          <i
            key="chevron-right-search-package-link"
            className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
              hide: hideElements !== 0,
            })}
          />
        </React.Fragment>
      )}

      <SmartLink selected={hideElements === 0} hide={hideElements !== 0}>
        {createPackage
          ? CreatePackageCtrl.context === 'new'
            ? t('mission.calculation.createPackage.breadcrumbNewPackage')
            : t('mission.calculation.createPackage.breadcrumbEditPackage')
          : packageData && packageData.fromList === 'listSearch'
          ? t('mission.calculation.detailPackage.breadcrumbAddPackage')
          : t(
              `mission.calculation.detailPackage.breadcrumb${
                isClosed ? 'ViewPackage' : 'ModifyPackage'
              }`,
            )}
      </SmartLink>
    </div>
  )
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore'),
  withTranslation(),
  observer,
)(PackageLinks)
