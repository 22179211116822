import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { format } from 'date-fns'

const dateFnsLocales = {
  en: require('date-fns/locale/en-US'),
  fr: require('date-fns/locale/fr'),
}

function DateDay({ UiCtrl: { language }, CalendarExpertStore: { day } }) {
  const lang = language[0] + language[1]

  return (
    <div className="date-day">
      <div>{format(day, 'eee', { locale: dateFnsLocales[lang] })}</div>
      <div className="custom-day">{format(day, 'd', { locale: dateFnsLocales[lang] })}</div>
    </div>
  )
}

export default compose(
  inject('UiCtrl', 'CalendarExpertStore'),
  observer,
)(DateDay)
