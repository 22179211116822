import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { euro } from 'utils/currency'
import Map from './Map'
import ListFirm from './ListFirm'
import ConfirmFirm from './ConfirmFirm'
import Events from './Events'

const CurrentJob = ({ LastBillCtrl: { currentJob } }) => {
  const { calculationData, statusId } = currentJob

  return (
    <div>
      {calculationData.map((calculation, idx) => (
        <div key={`${currentJob.id}-${idx}`} className="row no-gutters mt-1 mb-1">
          <div className="col-7">{calculation.label}</div>
          <div className="col-1">{euro(calculation.totalWithVAT)}</div>
        </div>
      ))}

      {statusId === 'draft' && (
        <div className="row no-gutters mt-4">
          <div className="col-8" style={{ minHeight: '700px' }}>
            <Map />
          </div>

          <div className="col-4 border-left pl-3">
            <ListFirm />
          </div>
        </div>
      )}

      {(statusId === 'send' || statusId === 'terminated' || statusId === 'canceled') && <Events />}

      <ConfirmFirm />
    </div>
  )
}

export default compose(
  inject('LastBillCtrl'),
  withTranslation(),
  observer,
)(CurrentJob)
