import currency from 'currency.js'

const USD = value => currency(value, { pattern: `# !` })
const EURO = value => currency(value, { pattern: `# !`, symbol: '€', decimal: ',', separator: ' ' })

export const euro = value => {
  return `${currency(value, {
    separator: ' ',
    decimal: ',',
    precision: 2,
    formatWithSymbol: false,
  }).format()} €`.replace(/ /g, '\u00a0')
}

export const percentage = value => {
  return `${currency(value, {
    separator: ' ',
    decimal: ',',
    precision: 2,
    formatWithSymbol: false,
  }).format()} %`.replace(/ /g, '\u00a0')
}

export const roundDecimal = value => {
  const factor = Math.pow(10, 2)
  return Math.round(value * factor) / factor
}

export const formatCurrency = (amount, curr) => {
  if (!isFinite(amount)) return amount
  switch (curr) {
    case 'USD':
      return USD(amount)
        .format(true)
        .replace(/ /g, '\u00a0')
    case 'PERCENTAGE':
      return percentage(amount)
    default:
      return EURO(amount)
        .format(true)
        .replace(/ /g, '\u00a0')
  }
}

export const currency_symbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
}
