import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { isNil } from 'ramda'

import Furniture from 'stores/Mission/domain/Furniture'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import Tooltip from 'components/Tooltip/Tooltip'
import { euro } from 'utils/currency'
import FurnitureRecap from 'pages/EasyEstimation/Furniture/FurnitureRecap'

const FurnitureShow = ({ isClosed, furniture, onEdit, onDelete }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="row no-gutters d-flex justify-content-between align-items-center p-2 mb-2">
        <div className="col-5">
          {`${furniture.brand} ${furniture.description}`}
          <span>&nbsp;</span>
          {!isNil(furniture.parentProduct) && (
            <Tooltip
              className="tooltip-action-bar"
              text={`${t('mission.calculation.furniture.replacementProduct')} ${
                furniture.parentProduct.description
              }`}
            >
              <i className="fa fa-recycle" aria-hidden="true"></i>
            </Tooltip>
          )}
        </div>
        <div className="col text-right">
          {furniture.dateOfPurchased
            ? format(furniture.dateOfPurchased, t('common.dateFormat'))
            : '-'}
        </div>
        <div className="col d-flex justify-content-end">
          {euro(furniture.finalPrice)}
          {furniture.correction > 0 && (
            <Tooltip
              className="tooltip-action-bar ml-2"
              text={`${t('mission.calculation.cart.obsolescence')} : ${furniture.correction} %`}
            >
              <i className="fa fa-exclamation-circle"></i>
            </Tooltip>
          )}
        </div>
        <div className="col d-flex align-items-cente justify-content-end">
          <FurnitureRecap furniture={furniture} isClosed={isClosed} />
          {!isClosed && (
            <div>
              <button className="btn btn-outline-primary rounded-circle mr-1" onClick={onEdit}>
                <i className="fa fa-edit" />
              </button>
            </div>
          )}

          {!isClosed && (
            <ButtonWithConfirmation
              className="btn btn-outline-primary rounded-circle"
              icon
              iconClassName="fa fa-trash"
              onClick={onDelete}
              confirmText={t('mission.calculation.cart.confirmDelete')}
            />
          )}
        </div>
      </div>
    </div>
  )
}

FurnitureShow.proptype = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  furniture: PropTypes.instanceOf(Furniture),
}
export default observer(FurnitureShow)
