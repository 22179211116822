import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import FiltersDropZone from 'pages/SupportingDocuments/FiltersDropZone'
import FiltersDropZoneUpdateType from 'pages/SupportingDocuments/FiltersDropZoneUpdateType'
import UploadDocumentModal from 'components/UploadDocumentModal/UploadDocumentModal'
import Filters from './Filters'
import List from './List'
import classNames from 'classnames'

const SDModal = ({
  t,
  match: {
    params: { id },
  },
  MessagingStore: { showSDModal, currentMessage, closeModal, confirmSelection },
}) => {
  const [grabbing, setGrabbing] = useState(false)

  return (
    <ModalWithBackdrop
      show={showSDModal}
      size="modal-xl"
      close={() => closeModal()}
      draggable
      setGrabbing={setGrabbing}
      scrollable
    >
      <div
        className={classNames('modal-header d-flex justify-content-between', {
          grabbable: !grabbing,
          grabbing: grabbing,
        })}
      >
        <h5 className="modal-title">{t('messaging.form.sdTitle')}</h5>

        <i className="fa fa-close clickable" onClick={() => closeModal()} />
      </div>

      <div className="modal-body supporting-documents container">
        <Filters />
        {!SupportingDocumentsCtrl.dropZoneFiltered &&
          !SupportingDocumentsCtrl.dropZoneFilteredUpdateType && (
            <List
              onSelectItem={currentMessage.selectDocument}
              selectedItems={currentMessage.selectingDocuments}
            />
          )}

        <FiltersDropZone />
        <FiltersDropZoneUpdateType />
        <UploadDocumentModal wan={id} />
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button className="btn btn-secondary" onClick={() => closeModal()}>
          {t('common.cancel')}
        </button>
        <Button
          className="btn btn-success"
          onClick={() => confirmSelection()}
          disabled={currentMessage.selectingDocuments.length === 0}
        >
          {t('common.select2')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(inject('MessagingStore'), withRouter, withTranslation(), observer)(SDModal)
