import { observable, computed, action, decorate, runInAction } from 'mobx'
import { path, type, isNil } from 'ramda'
import moment from 'moment'

import {
  fetchClaim,
  fetchMeteo,
  changeCurrentMissionAssignee,
  changeToAggravation,
  sendConfirmationBySms,
} from 'services/claim'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import CreateMissionCtrl from 'stores/Claim/view/CreateMissionCtrl'
import MessagingStore from 'stores/Messaging/MessagingStore'
import CommonStore, {
  HORS_DARVA_TAGS,
  HORS_DARVA_NO_CHIFFRAGE,
  HORS_DARVA_TAGS_DIVERS,
  HORS_DARVA_TAGS_CORPOREL,
} from 'stores/Common/domain/CommonStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

export const OVERRAN_STEPS = Object.freeze(['CIMR', 'CIMQ', 'CIMO'])

class ClaimStore {
  loading = false
  aggravating = false
  reassigning = false
  claim = null
  missionsWan = []

  confirmSmsDate = null
  sendingSms = false
  showSmsModal = false
  phoneNumber = ''
  smsSelectedIP = null

  id = null

  //to get filter for responsiblity
  horsDarvaTag = null

  //get type of horsDarvaClaim
  claimDivers = false
  claimCorporel = false

  //for horsDarva menu
  chocVTAMMenu = false
  communicationINCENDIEMenu = false
  volDeCONTENUMenu = false
  temoignageMenu = false
  configurationDesLieuxMenu = false
  autreMotifMenu = false
  missionDivers = false
  missionCorporel = false

  get isClaimClosed() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CICC'
  }

  get isClaimTerminated() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CICT'
  }

  get hasCarenceDuLese() {
    return path(['hasCarenceDulese'], this.claim)
  }

  get hasFinMission() {
    return path(['hasFinMission'], this.claim)
  }
  get isMissionCorporel() {
    return this.missionCorporel
  }
  get isCancelButtonAvailable() {
    const sd13Available = path(['sd13Available'], this.claim)
    const sd70Available = path(['sd70Available'], this.claim)
    const status = path(['status', 'key'], this.claim)
    return (
      (sd13Available || sd70Available || this.isHorsDarva) &&
      status !== 'CICT' &&
      status !== 'CICC' &&
      status !== 'CIMO'
    )
  }

  get isClaimOverran() {
    return path(['status', 'key'], this.claim) === 'CIMO'
  }

  get isToQualify() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CIMQ' || status === 'CIMR' || status === 'CIMW'
  }

  get isQualified() {
    const status = path(['status', 'key'], this.claim)
    return status === 'CIMQ' || status === 'CIMW'
  }

  get isPlanned() {
    return path(['status', 'key'], this.claim) === 'CIAF'
  }

  get isMissionSent() {
    return path(['status', 'key'], this.claim) === 'CIMS'
  }

  get updatedAt() {
    return path(['updatedAt'], this.claim)
  }

  get missions() {
    const missions = path(['missions'], this.claim)
    if (missions) return missions
    return []
  }

  get mission() {
    return this.claim && this.claim.mission
  }

  get isMissionClosed() {
    const status = path(['mission', 'status', 'key'], this.claim)
    return status === 'CACC'
  }

  get lastMission() {
    if (this.claim && this.claim.mission) return this.claim && this.claim.mission
    if (this.claim && this.claim.missions.length > 0)
      return this.claim.missions[this.claim.missions.length - 1]
    return null
  }

  get lastMissionID() {
    const missions = path(['missions'], this.claim)
    if (missions && missions.length > 0) {
      return path([missions.length - 1, 'id'], missions)
    }
    return null
  }

  get validMissions() {
    if (this.isClaimOverran) return []
    else if (this.claim) return this.claim.missions.filter(mission => mission.cfaWan !== null)
    return []
  }

  get missionDuration() {
    const mission = path(['mission'], this.claim)
    if (!mission) return 0

    const missionDuration =
      path(['missionInsurerInformation', 'coverageExecutionTime'], mission) || 0

    return type(missionDuration) === 'String' ? parseInt(missionDuration) : missionDuration
  }

  get isIME() {
    const gta = path(['gta'], this.claim)
    return gta === '1311' || gta === '418E'
  }

  get isMPJ() {
    const gta = path(['gta'], this.claim)
    return gta === 'J01' || gta === 'J02'
  }

  get isCoverageFire() {
    return path(['claimInformation', 'coverage', 'key'], this.claim) === 'FIRE'
  }

  get isUrgent() {
    return path(['mission', 'missionInsurerInformation', 'missionNatureKey'], this.claim) === 'M'
  }

  get position() {
    const position = path(
      ['mission', 'missionInsurerInformation', 'address', 'geometry'],
      this.claim,
    )
    if (position && position.lat && position.long) return { lat: position.lat, lng: position.long }

    return null
  }

  get isHorsDarva() {
    const tags = path(['tags'], this.claim)
    if (!isNil(tags) && type(tags) === 'Array') {
      return tags.some(tag => HORS_DARVA_TAGS.includes(tag))
    }

    return false
  }
  get isClaimCorporel() {
    return this.claimCorporel
  }
  get isSocle() {
    const gta = path(['gta'], this.claim)
    const isSocle = path(['contract', 'isSocle'], this.claim)
    return isSocle === true && gta === '418'
  }

  get status() {
    return path(['status', 'key'], this.claim)
  }

  get isCreatedFromCfm() {
    return path(['cfmWan'], this.claim) !== null
  }

  get isAllNewMissionCreationRequestFieldsFilled() {
    return (
      path(['newMission', 'missionNatureKey'], this.claim) &&
      (path(['newMission', 'missionUrgente'], this.claim) === false ||
        path(['newMission', 'missionUrgente'], this.claim) === true) &&
      path(['newMission', 'natureBlessure'], this.claim) &&
      path(['newMission', 'informationTiersPayeurs'], this.claim)
    )
  }

  getMission = missionId => {
    const currentMission = this.validMissions.find(mission => mission.cfaWan === missionId)

    if (isNil(currentMission)) {
      console.warn(`Current mission ${missionId} is not found in missions.`)
    }

    return currentMission
  }

  reloadClaimStatus = async wan => {
    try {
      const res = await fetchClaim(wan)
      runInAction(() => {
        this.claim = res
        MessagingStore.fetchContexts(wan)
      })
    } catch (err) {
      console.log(err)
    }
  }

  async retrieveMeteo(wan, isExpert) {
    try {
      return await fetchMeteo(wan, isExpert)
    } catch (err) {
      AlertCtrl.alert('danger', 'meteoError.errorAPI')
    }
  }

  changeAssignee = async (wan, userId) => {
    const mission = path(['mission'], this.claim)
    this.reassigning = true
    try {
      await changeCurrentMissionAssignee(wan, mission.cfaWan, userId)
      await this.loadData(wan)
      return true
    } catch (err) {
      return false
    } finally {
      runInAction(() => (this.reassigning = false))
    }
  }

  async loadData(wan, withLoading = true) {
    if (withLoading) this.loading = true

    try {
      const claim = await fetchClaim(wan)
      if (
        process.env.REACT_APP_SOLERA_ENV === 'TEST' ||
        process.env.REACT_APP_SOLERA_ENV === 'DEV'
      ) {
        console.log('claim data : ', claim)
      }

      runInAction(() => {
        this.id = wan
        const addressOfLossGeometry = path(['claimInformation', 'addressOfLoss', 'geometry'], claim)
        if (!isNil(addressOfLossGeometry)) {
          claim.claimInformation.addressOfLoss.geometry.lat = parseFloat(addressOfLossGeometry.lat)
          claim.claimInformation.addressOfLoss.geometry.long = parseFloat(
            addressOfLossGeometry.long,
          )
        }

        if (path(['tags'], claim)) {
          let tags = path(['tags'], claim)
          if (type(tags) === 'Object') {
            tags = Object.values(tags)
            claim.tags = tags
          }
          this.horsDarvaTag = tags
          //reinitialize variables
          this.claimCorporel = false
          this.claimDivers = false
          //check for divers or corporel
          if (
            !isNil(tags) &&
            type(tags) === 'Array' &&
            tags.some(tag => HORS_DARVA_TAGS_DIVERS.includes(tag))
          ) {
            this.claimDivers = true
          }
          if (
            !isNil(tags) &&
            type(tags) === 'Array' &&
            tags.some(tag => HORS_DARVA_TAGS_CORPOREL.includes(tag))
          ) {
            this.claimCorporel = true
          }
        }
        //check for misssionNatureKey
        if (path(['mission', 'missionInsurerInformation', 'missionNatureKey'], claim)) {
          const missionNaturekey = path(
            ['mission', 'missionInsurerInformation', 'missionNatureKey'],
            claim,
          )
          //reinitialize varrables
          this.chocVTAMMenu = false
          this.communicationINCENDIEMenu = false
          this.volDeCONTENUMenu = false
          this.temoignageMenu = false
          this.configurationDesLieuxMenu = false
          this.autreMotifMenu = false
          if (missionNaturekey === 'CHOCVTAM') {
            this.chocVTAMMenu = true
          }
          if (missionNaturekey === 'COMMUNICATIONINCENDIE') {
            this.communicationINCENDIEMenu = true
          }
          if (missionNaturekey === 'VOLDECONTENU') {
            this.volDeCONTENUMenu = true
          }
          if (missionNaturekey === 'TEMOIGNAGE') {
            this.temoignageMenu = true
          }
          if (missionNaturekey === 'CONFIGURATIONDESLIEUX') {
            this.configurationDesLieuxMenu = true
          }
          if (missionNaturekey === 'AUTREMOTIF') {
            this.autreMotifMenu = true
          }
        }
        if (path(['mission', 'missionInsurerInformation', 'coverageKey'], claim)) {
          const coverageKey = path(['mission', 'missionInsurerInformation', 'coverageKey'], claim)

          if (HORS_DARVA_NO_CHIFFRAGE.includes(coverageKey)) {
            SupportingDocumentsCtrl.setIsInvoiceAvailable(false)
          } else {
            SupportingDocumentsCtrl.setIsInvoiceAvailable(true)
            if (
              process.env.REACT_APP_SOLERA_ENV === 'TEST' ||
              process.env.REACT_APP_SOLERA_ENV === 'DEV'
            ) {
              console.log(
                'Please maintain HORS_DARVA_NO_CHIFFRAGE array found in CommonStore in case of new coverage not having chiffrage',
              )
            }
          }
        }

        this.claim = claim
        this.confirmSmsDate = path(['mission', 'confirmSmsDate'], claim)
        this.loading = false
        //refresh data for involve party responsiblity
        CommonStore.refreshInvolvedPartyResponsabilitiesData()
        //refresh data for involve party role
        CommonStore.refreshInvolvedPartyRoleData()
        PartyInvolvedStore.loadData(claim.involvedParties)
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
        MessagingStore.loadData(wan, wan)
        if (!this.isClaimClosed) CreateMissionCtrl.loadData(wan)
        CommonStore.loadSupportingDocumentsTypesData(wan, 'claim')
        if (!this.isClaimClosed && !this.isToQualify && !this.isClaimTerminated)
          CommonStore.loadExperts(wan)
      })
    } catch (err) {
      runInAction(() => {
        this.loading = true
      })
    }
  }

  async aggravateMission(wan, cfaWan) {
    this.aggravating = true

    try {
      await changeToAggravation(cfaWan)
      await this.loadData(wan, false)

      runInAction(() => {
        this.aggravating = false
      })

      return path(['cfaWan'], this.lastMission)
    } catch (error) {
      AlertCtrl.alert('danger', 'httpCode.500')
      throw error
    }
  }

  get canSendConfirmationBySms() {
    if (this.isClaimClosed || this.isMissionClosed) return false

    const appointmentDate = path(['mission', 'appointmentDate'], this.claim)
    if (!appointmentDate || moment(appointmentDate).diff(moment(new Date()), 'hours') < 0)
      return false

    return true
  }

  sendConfirmationBySms = async missionId => {
    const cfaWan = path(['mission', 'cfaWan'], this.claim)
    this.sendingSms = true
    try {
      const confirmSmsDate = await sendConfirmationBySms(cfaWan, this.phoneNumber)
      AlertCtrl.alert('success', 'mission.report.confirmSmsSent')
      runInAction(() => {
        this.confirmSmsDate = confirmSmsDate
        this.showSmsModal = false
        const mission = this.getMission(missionId)
        mission.smsSent = true
        this.phoneNumber = ''
        this.smsSelectedIP = null
      })
    } catch (error) {
      AlertCtrl.alert('danger', 'mission.report.unableToconfirmSms')
    } finally {
      runInAction(() => {
        this.sendingSms = false
      })
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  updateNewMission(claim) {
    this.claim.newMission = claim
  }
}

const DecoratedClaimStore = decorate(ClaimStore, {
  loading: observable,
  claim: observable,
  missionsWan: observable,
  reassigning: observable,
  aggravating: observable,
  confirmSmsDate: observable,
  sendingSms: observable,
  showSmsModal: observable,
  phoneNumber: observable,
  smsSelectedIP: observable,
  horsDarvaTag: observable,
  claimDivers: observable,
  claimCorporel: observable,
  chocVTAMMenu: observable,
  communicationINCENDIEMenu: observable,
  volDeCONTENUMenu: observable,
  temoignageMenu: observable,
  configurationDesLieuxMenu: observable,
  autreMotifMenu: observable,

  isClaimClosed: computed,
  isClaimTerminated: computed,
  isClaimOverran: computed,
  isQualified: computed,
  isToQualify: computed,
  missions: computed,
  mission: computed,
  isMissionClosed: computed,
  lastMission: computed,
  lastMissionID: computed,
  missionDuration: computed,
  isHorsDarva: computed,
  isClaimCorporel: computed,
  isCancelButtonAvailable: computed,
  isPlanned: computed,
  isIME: computed,
  isMPJ: computed,
  isMissionCorporel: computed,
  isCoverageFire: computed,
  isUrgent: computed,
  updatedAt: computed,
  position: computed,
  isSocle: computed,
  status: computed,
  canSendConfirmationBySms: computed,
  hasCarenceDuLese: computed,
  hasFinMission: computed,
  isCreatedFromCfm: computed,
  isAllNewMissionCreationRequestFieldsFilled: computed,

  reloadClaimStatus: action,
  sendConfirmationBySms: action,
  loadData: action.bound,
  loadMissions: action,
  changeAssignee: action,
  aggravateMission: action.bound,
  setProperty: action,
  updateNewMission: action,
})

export default new DecoratedClaimStore()
