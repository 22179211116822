import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { roundDecimal } from 'utils/currency'

import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import InputNumber from 'components/InputNumber/InputNumber'

const Calculator = () => {
  const {
    area,
    quantity,
    showCalculator,
    selectedPart,
    setProperty,
    changeArea,
  } = PropertyEmbellishmentCtrl
  const [length, setLength] = useState(0)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const isArea = selectedPart === 'C' || selectedPart === 'W' || selectedPart === 'F'
  const surface = isArea ? area : quantity

  useEffect(() => {
    let l = roundDecimal(surface / 2)
    let w = 2
    setLength(l)
    if (selectedPart === 'W') {
      setHeight(w)
    } else {
      setWidth(w)
    }
  }, [selectedPart, surface])

  useEffect(() => {
    if (selectedPart === 'W' && width > 0) setWidth(0)
    if (selectedPart !== 'W' && height > 0) setHeight(0)
  }, [selectedPart, width, height])

  const onSave = () => {
    if (isArea) {
      if (selectedPart === 'C' || selectedPart === 'F') {
        setProperty('area', width * length)
        changeArea(width * length)
      } else if (selectedPart === 'W') {
        const area = length > 0 ? length : height
        setProperty('area', height * area)
        changeArea(height * area)
      }
    } else {
      setProperty('quantity', width * length)
    }
    setProperty('showCalculator', false)
  }

  if (!showCalculator) return null

  return (
    <div className="calculator px-3 py-2 border">
      <div className="d-flex justify-content-end">
        <i className="fa fa-close clickable" onClick={() => setProperty('showCalculator', false)} />
      </div>
      <label>Longueur :</label>
      <InputNumber
        className="form-control mr-2"
        value={length}
        onChange={e => setLength(e.target.value)}
        disabled={selectedPart === 'W' && width > 0}
      />
      <label>Largeur :</label>
      <InputNumber
        className="form-control mr-2"
        value={width}
        onChange={e => setWidth(e.target.value)}
        disabled={selectedPart === 'W'}
      />
      {isArea && <label>Hauteur :</label>}
      {isArea && (
        <InputNumber
          className="form-control mr-2"
          value={height}
          onChange={e => setHeight(e.target.value)}
          disabled={selectedPart === 'C' || selectedPart === 'F'}
        />
      )}
      <div className="d-flex justify-content-end mt-2">
        <button className="btn btn-primary" onClick={onSave}>
          Calculer
        </button>
      </div>
    </div>
  )
}

export default observer(Calculator)
