import React from 'react'
import { observer } from 'mobx-react'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import { euro } from 'utils/currency'
import { formatArea } from 'utils'
import Tooltip from 'components/Tooltip/Tooltip'

const Text = ({ total, areaSquare, unit }) => {
  return (
    <React.Fragment>
      {euro(total)} x {formatArea(areaSquare)}
      {unit === 'M2' ? (
        <span>
          {' '}
          m<sup>2</sup>
        </span>
      ) : (
        <span>ml</span>
      )}
    </React.Fragment>
  )
}

class TooltipPrice extends React.Component {
  render() {
    const { itemData } = this.props
    const { packageData } = DetailPackageCtrl
    const overrode = itemData.overrodeQualification || itemData.overrode

    if (
      overrode ||
      (!packageData.areaPresent && (itemData.unit !== 'M2' || itemData.unit !== 'ML'))
    )
      return null

    const areaSquare = packageData.area
    const total = itemData.selfRepair
      ? itemData.totalPriceSelfRepairWithoutArea
      : itemData.totalPriceCatalogWithoutArea

    return (
      <Tooltip
        text={<Text total={total} areaSquare={areaSquare} unit={itemData.unit} />}
        className="tooltip-price mr-0 mr-sm-2"
        clickable
        placement="top"
      >
        <i className="fa fa-info-circle clickable" />
      </Tooltip>
    )
  }
}
export default observer(TooltipPrice)
