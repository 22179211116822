import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import TextArea from 'components/Forms/TextArea'
import Select from 'components/Forms/Select'

import classNames from 'classnames'

const CancelModal = ({
  t,
  LastBillCtrl: {
    showCancelModal,
    cancelReasonId,
    cancelComment,
    setProperty,
    cancelReasons,
    canceling,
    cancelJob,
  },
}) => {
  const [grabbing, setGrabbing] = useState(false)

  const closeModal = () => {
    setProperty('showCancelModal', false)
    setProperty('cancelReasonId', '')
    setProperty('cancelComment', '')
  }

  const onChange = e => {
    setProperty(e.target.name, e.target.value)
  }

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={showCancelModal}
      close={closeModal}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t('lastbill.cancelTitle')}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="form-group row mt-2">
          <Label htmlFor="dueDate" className="col-4 col-form-label" required>
            {t('lastbill.cancelreason')}
          </Label>
          <div className="col-8">
            <Select
              className="form-control"
              name="cancelReasonId"
              options={cancelReasons}
              value={cancelReasonId}
              onChange={onChange}
            />
          </div>
        </div>

        <div>
          <Label required>{t('lastbill.assignationComment')}</Label>
          <TextArea
            className="form-control"
            name="cancelComment"
            value={cancelComment}
            onChange={onChange}
          />
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <Button
          className="btn btn-secondary"
          data-cy="cancel"
          onClick={closeModal}
          disabled={canceling}
        >
          Fermer
        </Button>

        <Button
          className="btn btn-danger"
          data-cy="submit"
          onClick={cancelJob}
          disabled={canceling || cancelComment.length === 0 || !cancelComment}
          loading={canceling}
        >
          Annuler la mission
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('ManagerClaimStore', 'LastBillCtrl'),
  withRouter,
  withTranslation(),
  observer,
)(CancelModal)
