import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import classNames from 'classnames'

import './Carousel.css'
import { baseUrl } from 'services/utils'

import MissionHeaderCtrl from 'stores/Mission/view/MissionHeaderCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import SliderModal from 'pages/SupportingDocuments/SliderModal'
import Loader from 'components/Loader/Loader'

const Carousel = ({ UserStore: { token } }) => {
  const [loadingImages, setLoadingImages] = useState(true)
  const { showGallery } = MissionHeaderCtrl
  const { sdByPhotos } = SupportingDocumentStore
  const { t } = useTranslation()
  const { pathname } = useLocation()
  let imageGallery = useRef(0)
  const isSDPage = pathname.split('/').indexOf('supportingDocuments') > -1
  const images = sdByPhotos.map(sd => ({
    original: `${baseUrl()}/supportingDocuments/v1/${sd.id}/thumbnail/lg?access_token=${token}`,
    thumbnail: `${baseUrl()}/supportingDocuments/v1/${sd.id}/thumbnail/lg?access_token=${token}`,
  }))

  if (!showGallery) return null
  else if (!SupportingDocumentsCtrl.loading && images.length === 0) {
    return (
      <div className="p-3 text-center bg-white border rounded mb-3">
        {t('mission.header.noSDPhotos')}
      </div>
    )
  }

  return (
    <div className="supporting-documents p-0 border-0 rounded-0">
      {!isSDPage && <SliderModal />}

      <div className="header-carousel row ml-0 mr-0 mb-3">
        <div className={classNames('col p-2 border bg-white rounded', { loading: loadingImages })}>
          {loadingImages && <Loader />}

          <ImageGallery
            ref={i => (imageGallery = i)}
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            lazyLoad={true}
            onClick={() => {
              SupportingDocumentsCtrl.setSliderModalDisplay(true)
              SupportingDocumentsCtrl.setDropZoneFiltered(false)
              SupportingDocumentsCtrl.setFilter(SupportingDocTypes.PHOTO)
              SupportingDocumentsCtrl.setSelectedSlide(imageGallery.getCurrentIndex())
            }}
            onImageLoad={() => {
              setLoadingImages(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default inject('UserStore')(observer(Carousel))
