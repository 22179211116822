import { observable, action, runInAction, decorate, computed } from 'mobx'
import {
  setHours,
  setMinutes,
  setSeconds,
  addMinutes,
  format,
  startOfWeek,
  endOfWeek,
  subDays,
  isWithinInterval,
  isEqual,
} from 'date-fns'
import { path, reduce } from 'ramda'

import UserStore from 'stores/Common/domain/UserStore'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import PlannerExpert from 'stores/Claim/PlannerExpert'
import { fetchExperts, fetchSchedule } from 'services/planner'
import { convertUTCDateToLocalDate } from 'utils'

class PlannerStore {
  currentDate = new Date()
  min = setSeconds(setMinutes(setHours(new Date(), 8), 0), 0)
  max = setSeconds(setMinutes(setHours(new Date(), 18), 30), 0)
  experts = []
  schedule = []
  loading = false
  loadingSchedule = false
  selectedExpert = ''
  selectedTimeSlot = null
  showConfirmExpert = false

  checkAllAvailability(timeSlot) {
    const unavailabilities = this.experts.map(expert => expert.checkAvailability(timeSlot))

    return unavailabilities.indexOf(true) > -1
  }

  checkAvailability(timeSlot, expert) {
    let available = true

    for (let i = 0; i < this.schedule.length; i++) {
      const unavailability = this.schedule[i]
      const unavailable = isWithinInterval(timeSlot, {
        start: new Date(unavailability.startDate),
        end: new Date(unavailability.endDate),
      })

      if (unavailability.assignee === expert && unavailable) return false
    }

    return available
  }

  withinMissionDuration(timeSlot) {
    const { missionDuration } = ClaimStore

    if (
      this.selectedTimeSlot === null ||
      missionDuration === 0 ||
      isEqual(this.selectedTimeSlot, timeSlot)
    )
      return false

    const endMission = addMinutes(this.selectedTimeSlot, missionDuration)

    return isWithinInterval(timeSlot, {
      start: this.selectedTimeSlot,
      end: endMission,
    })
  }

  get selectedExpertData() {
    if (this.selectedExpert === '') return null
    const expert = this.experts.find(expert => expert.id === this.selectedExpert)

    return expert
  }

  async getExperts(wan) {
    this.loading = true
    const { lastMission } = ClaimStore
    this.selectedExpert = path(['suggestedExpertId'], lastMission)

    try {
      const res = await fetchExperts(wan)
      runInAction(() => {
        this.experts = res.map(
          expert =>
            new PlannerExpert({
              id: expert.id,
              firstName: expert.firstName,
              lastName: expert.lastName,
              fullName: expert.fullName,
              tours: expert.tours,
              email: expert.email,
            }),
        )
        this.getSchedule({
          cfiWan: wan,
          startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
          endDate: subDays(endOfWeek(new Date(), { weekStartsOn: 1 }), 2),
        })
      })
    } catch (err) {
      console.log(err)
      runInAction(() => {
        this.loading = true
      })
    }
  }

  async getSchedule({ cfiWan, startDate, endDate }) {
    this.loadingSchedule = true
    try {
      const res = await fetchSchedule({
        cfiWan,
        startDate: format(startDate, 'y-MM-dd'),
        endDate: format(endDate, 'y-MM-dd'),
      })

      const unavailabilities = reduce(
        (acc, user) => {
          return [...acc, ...user.appointments]
        },
        [],
        res,
      )

      runInAction(() => {
        this.schedule = unavailabilities.map(unavailability => {
          // Multiple data format for startDate wait for API to fix
          const startDate = unavailability.startDate.date
            ? convertUTCDateToLocalDate(new Date(unavailability.startDate.date))
            : convertUTCDateToLocalDate(new Date(unavailability.startDate))
          const endDate = unavailability.endDate.date
            ? convertUTCDateToLocalDate(new Date(unavailability.endDate.date))
            : convertUTCDateToLocalDate(new Date(unavailability.endDate))
          return {
            assignee: unavailability.assignee.id,
            address: unavailability.address,
            startDate,
            endDate,
          }
        })

        this.experts.forEach(expert => {
          const expertSchedule = this.schedule.filter(
            unavailability => unavailability.assignee === expert.id,
          )
          const expertData = res.find(expertData => expertData.userId === expert.id)
          expert.setProperty('schedule', expertSchedule)
          if (expertData) {
            expert.setProperty('legal', expertData.counter.JURIDIQUE)
            expert.setProperty('corporate', expertData.counter.CORPO)
            expert.setProperty('home', expertData.counter.HABITATION)
          }
        })
        this.loading = false
        this.loadingSchedule = false
      })
    } catch (err) {
      runInAction(() => {
        this.loading = true
        this.loadingSchedule = true
      })
      console.log(err)
    }
  }

  changeWeek(date) {
    this.currentDate = date
    if (UserStore.isInsurer) {
      this.getSchedule({
        cfiWan: ClaimStore.id,
        startDate: startOfWeek(date, { weekStartsOn: 1 }),
        endDate: subDays(endOfWeek(date, { weekStartsOn: 1 }), 2),
      })
    }
  }

  select(key, value) {
    if (this[key] === value) this[key] = key === 'selectedTimeSlot' ? null : ''
    else this[key] = value
  }

  setProperty(key, value) {
    this[key] = value
  }

  resetPlanner() {
    this.experts = []
    this.schedule = []
    this.selectedExpert = ''
    this.selectedTimeSlot = null
    this.currentDate = new Date()
    this.showConfirmExpert = false
  }
}

const DecoratedPlannerStore = decorate(PlannerStore, {
  experts: observable,
  schedule: observable,
  selectedExpert: observable,
  selectedTimeSlot: observable,
  currentDate: observable,
  showConfirmExpert: observable,
  loading: observable,
  loadingSchedule: observable,

  selectedExpertData: computed,

  getExperts: action.bound,
  getSchedule: action.bound,
  changeWeek: action.bound,
  select: action.bound,
  checkAvailability: action.bound,
  checkAllAvailability: action.bound,
  withinMissionDuration: action.bound,
  setProperty: action.bound,
  resetPlanner: action.bound,
})

export default new DecoratedPlannerStore()
