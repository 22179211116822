import { observable, action, decorate } from 'mobx'
import { searchPackage } from 'services/calculation'
import UiCtrl from 'stores/Common/view/UiCtrl'
import Package from 'stores/Mission/domain/Package'

const leakSearchMapping = (packageType, type) => ({
  value: type.cde,
  label: type.label,
  description: type.description || '',
  type: type.type,
  packageType: packageType,
  darvaLookupValue: type.darvaLookupValue,
  price: type.priceListPrice,
})

class SearchLeakCtrl {
  search = ''
  searching = false
  activeSearch = false
  leakPackage = null
  editionId = null
  list = []
  leakSearchType = []
  page = 1

  fetchLeakSearchPackage(wan) {
    this.list = []
    const searchData = {
      damageType: 'DAT_LEAK',
    }

    searchPackage(wan, searchData)
      .then(
        action(res => {
          this.list = res.packages
          this.searching = false
          this.totalSearchPackage = res.totalSearchPackage
          this.leakSearchType = this.list.map(leakSearchMapping.bind(null, 'DAT_LEAK'))
        }),
      )
      .catch(
        action(err => {
          console.log(err)
          this.list = []
          this.searching = false
        }),
      )
  }

  setNewLeakPackage() {
    const roomType = {
      key: 'noFilter',
      label: UiCtrl.language === 'fr-FR' ? 'Autre' : 'Other',
      areaSize: 10,
    }

    this.leakPackage = new Package({
      data: { location: 'regular', ren: false },
      fromList: 'listSearch',
      packageType: 'DAT_LEAK',
      roomType,
      priceType: 'catalog',
    })
  }

  setLeakPackage(cde, involvedParty = null) {
    const foundInList = this.list.find(lp => {
      return lp.cde === cde
    })

    if (foundInList) {
      const roomType = {
        key: 'noFilter',
        label: UiCtrl.language === 'fr-FR' ? 'Autre' : 'Other',
        areaSize: 10,
      }
      const location = 'regular'

      const newPackage = new Package({
        data: { ...foundInList, location, ren: false },
        fromList: 'listSearch',
        packageType: 'DAT_LEAK',
        roomType,
        priceType: 'catalog',
      })
      newPackage.setProperty('involvedParty', involvedParty)

      this.leakPackage = newPackage
    }
  }

  setProperty(key, value) {
    this[key] = value
  }
}
const DecoratedSearchLeakCtrl = decorate(SearchLeakCtrl, {
  search: observable,
  editionId: observable,
  searching: observable,
  activeSearch: observable,
  leakPackage: observable,
  leakSearchType: observable,
  list: observable,
  page: observable,

  setProperty: action,
  setNewLeakPackage: action,
  setLeakPackage: action.bound,
  fetchLeakSearchPackage: action,
})

export default new DecoratedSearchLeakCtrl()
