import request from 'services/httpRequest'

export const fetchCustomerConfig = async customerId => {
  try {
    const res = await request({
      method: 'get',
      url: '/customerConfig/v1/' + customerId,
    })

    //Get attributes
    let configKeys = res.data.map(element => ({ ...element.attributes }))

    const result = {
      data: configKeys,
    }
    return Promise.resolve(result)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchCustomerConfigKey = async (customerId, key) => {
  try {
    const res = await request({
      method: 'get',
      url: '/customerConfig/v1/' + customerId + '/' + key,
    })

    //Get attributes
    const result = {
      data: res.data.attributes,
    }

    return Promise.resolve(result)
  } catch (err) {
    return Promise.reject(err)
  }
}
