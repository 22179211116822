import React, { Fragment, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import useOutsideAlerter from 'hooks/useOutsideAlerter'
import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'

const DropdownFilters = observer(() => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const outSideRef = useRef(null)
  useOutsideAlerter(outSideRef, () => setShow(false))

  const openDropdown = () => setShow(prevShow => !prevShow)
  const selectFilter = filterId => {
    DashboardFilterStore.setCurrentFilter(filterId)
    setShow(prevShow => !prevShow)
  }
  const setDefaultFilter = ({ missionStatus, name }) => {
    DashboardFilterStore.setDefaultFilter({ missionStatus, name })
    DashboardFilterStore.setProperty('showForm', false)
    setShow(prevShow => !prevShow)
  }

  const { currentFilter, filters, defaultFilters } = DashboardFilterStore

  const filterName = currentFilter.name ? currentFilter.name : ''

  return (
    <div ref={outSideRef} className="flex-grow-1">
      <input
        type="text"
        name="dropdown-select-filter"
        className="form-control clickable"
        onClick={openDropdown}
        value={filterName}
        onChange={() => {}}
        autoComplete="off"
      />

      <div className={classNames('dropdown-menu w-100', { 'zindex-modal show': show })}>
        {defaultFilters.map(({ key, name }) => (
          <div
            key={key}
            className={`dropdown-item clickable mission-${key}`}
            onClick={() => setDefaultFilter({ missionStatus: key, name: t(name) })}
          >
            <div>{t(name)}</div>
          </div>
        ))}
        <div
          className="dropdown-item clickable"
          onClick={() =>
            setDefaultFilter({ missionStatus: 'allOpen', name: t('dashboard.filter.allMissions') })
          }
        >
          <div>{t('dashboard.filter.allMissions')}</div>
        </div>
        {filters.length > 0 && (
          <Fragment>
            <div className="dropdown-divider" />
            {filters.map(filter => (
              <div
                className="dropdown-item clickable"
                onClick={() => selectFilter(filter.id)}
                key={filter.id}
              >
                <div>{filter.name}</div>
              </div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  )
})

const DropdownSelectFilters = () => {
  const { t } = useTranslation()
  return (
    <div className="input-group">
      <div className="input-group-prepend d-flex">
        <span className="input-group-text bg-primary text-white">
          {t('dashboard.filter.form.filters')}
        </span>
      </div>
      <DropdownFilters t={t} />
    </div>
  )
}

export default observer(DropdownSelectFilters)
