import { values } from 'ramda'

import request from 'services/httpRequest'

export const fetchRelatedCostType = async ({
  wan,
  coverage,
  endpoint,
  type,
  relatedCostType = null,
}) => {
  const body = {}
  if (type === 'relatedCost_search') {
    body.coverage = coverage
    if (relatedCostType) body.type = relatedCostType
  }

  try {
    const res = await request({
      method: 'post',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/estimation/${endpoint}`,
      data: {
        data: {
          type,
          attributes: body,
        },
      },
    })

    return values(res[0].data)
  } catch (err) {
    return Promise.reject(err)
  }
}
