import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { add, reduce, values } from 'ramda'

import { euro } from 'utils/currency'

const TableDescriptionPrejudices = ({
  t,
  involvedPartyId,
  showPrejudiceStore = false,
  vans,
  obs,
  returns,
  noReturns,
  withOBWithoutVAT,
  withOBWithVAT,
  finalPrice,
  PrejudiceStore,
  MissionStore: { isWaterDamage, isMPJ },
  CartStore: { showComputationWithoutIP },
}) => {
  if (involvedPartyId === 'notVentilated' && !showComputationWithoutIP) return null

  return (
    <React.Fragment>
      <div className="p-4 border d-block d-lg-none">{t('mission.conclusion.tableResponsive')}</div>

      <table className="table table-bordered d-none d-lg-table">
        <tbody>
          <tr>
            <th rowSpan="2">{t('mission.conclusion.title.prejudice')}</th>
            <th rowSpan="2" className="text-center">
              {t(`mission.conclusion.computation.vanWithoutVAT${isMPJ ? 'MPJ' : ''}`)}
            </th>

            <th colSpan="3" className="text-center">
              {t('mission.conclusion.computation.obsolescenceGroup')}
            </th>

            <th rowSpan="2" className="text-center">
              {t('mission.conclusion.computation.totalHT')}
            </th>
            <th rowSpan="2" className="text-center">
              {t('mission.conclusion.computation.totalTTC')}
            </th>
            <th rowSpan="2" className="text-center">
              {t('mission.conclusion.computation.totalCompensable')}
            </th>
          </tr>

          <tr>
            <th className="text-center">{t('mission.conclusion.computation.obsolescence')}</th>
            <th className="text-center">
              {t('mission.conclusion.computation.obsolescenceCompensable')}
            </th>
            <th className="text-center">
              {t('mission.conclusion.computation.obsolescenceCompensable1')}
            </th>
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.data.property')}</th>
            <td>{euro(vans.property)}</td>
            <td>{euro(obs.property)}</td>
            <td>{euro(returns.property)}</td>
            <td>{euro(noReturns.property)}</td>
            <td>{euro(withOBWithoutVAT.property)}</td>
            <td>{euro(withOBWithVAT.property)}</td>
            <td>{euro(finalPrice.property)}</td>
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.data.embellishment')}</th>
            <td>{euro(vans.embellishment)}</td>
            <td>{euro(obs.embellishment)}</td>
            <td>{euro(returns.embellishment)}</td>
            <td>{euro(noReturns.embellishment)}</td>
            <td>{euro(withOBWithoutVAT.embellishment)}</td>
            <td>{euro(withOBWithVAT.embellishment)}</td>
            <td>{euro(finalPrice.embellishment)}</td>
          </tr>

          {isWaterDamage && (
            <tr>
              <th scope="row">{t('mission.conclusion.data.leak')}</th>
              <td>{euro(vans.leak)}</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td>{euro(finalPrice.leak)}</td>
              <td>{euro(finalPrice.leak)}</td>
            </tr>
          )}

          <tr>
            <th scope="row">{t('mission.conclusion.data.relatedCost')}</th>
            <td>{euro(vans.relatedCost)}</td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
            <td>{euro(finalPrice.relatedCost)}</td>
            <td>{euro(finalPrice.relatedCost)}</td>
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.data.outdoor')}</th>
            <td>{euro(vans.outdoor)}</td>
            <td>{euro(obs.outdoor)}</td>
            <td>{euro(returns.outdoor)}</td>
            <td>{euro(noReturns.outdoor)}</td>
            <td>{euro(withOBWithoutVAT.outdoor)}</td>
            <td>{euro(withOBWithVAT.outdoor)}</td>
            <td>{euro(finalPrice.outdoor)}</td>
          </tr>

          {showPrejudiceStore && PrejudiceStore.ready && PrejudiceStore.payloads.length > 0 && (
            <tr>
              <th scope="row">{t('mission.conclusion.data.options')}</th>
              <td>{euro(PrejudiceStore.vanWithoutVAT)}</td>
              <td>{euro(PrejudiceStore.realObsolescence)}</td>
              <td>{euro(PrejudiceStore.guaranteedObsolescence)}</td>
              <td>{euro(PrejudiceStore.nonGuaranteedObsolescence)}</td>
              <td>{euro(PrejudiceStore.obsolescenceDeductedWithoutVAT)}</td>
              <td>{euro(PrejudiceStore.obsolescenceDeductedWithVAT)}</td>
              <td>{euro(PrejudiceStore.finalPrice)}</td>
            </tr>
          )}

          <tr>
            <th scope="row">{t('mission.conclusion.computation.total')}</th>
            <td>{euro(reduce(add, 0, values(vans)))}</td>
            <td>{euro(reduce(add, 0, values(obs)))}</td>
            <td>{euro(reduce(add, 0, values(returns)))}</td>
            <td>{euro(reduce(add, 0, values(noReturns)))}</td>
            <td>{euro(reduce(add, 0, values(withOBWithoutVAT)))}</td>
            <td>{euro(reduce(add, 0, values(withOBWithVAT)))}</td>
            <td>{euro(reduce(add, 0, values(finalPrice)))}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default compose(
  inject('PrejudiceStore', 'MissionStore', 'CartStore'),
  withTranslation(),
  observer,
)(TableDescriptionPrejudices)
