import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import NavigationButtons from './NavigationButtons'
import { format } from 'utils/date'

function Navigation({
  t,
  UiCtrl: { language },
  CalendarExpertStore: { day, weekNumber, view },
  AppointmentCtrl: { showModal },
}) {
  return (
    <div className="day-navigation row no-gutters">
      <div className="current-date-title text-capitalize font-weight-bold col-8 col-md-3">
        {view === 'day' && `${format(day, 'dd/MM/yyyy', { locale: language })}`}
        {view === 'week' &&
          `${format(day, 'MMMM yyyy', { locale: language })} ${t(
            'calendar.info.week',
          )} ${weekNumber}`}
        {view === 'month' && `${format(day, 'MMMM yyyy', { locale: language })}`}
      </div>

      <div className="col-4 col-md-1 offset-md-8 d-flex align-items-center justify-content-center">
        {!showModal && <NavigationButtons />}
      </div>
    </div>
  )
}

export default compose(
  inject('UiCtrl', 'CalendarExpertStore', 'AppointmentCtrl'),
  withTranslation(),
  observer,
)(Navigation)
