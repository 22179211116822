import { observable, action, toJS, computed, decorate } from 'mobx'

import MissionStore from 'stores/Mission/domain/MissionStore'
import SupportingDocumentStore, {
  SupportingDocument,
} from 'stores/Common/domain/SupportingDocumentStore'
import { fetchSupportingDocuments, addSdToTheReport } from 'services/supportingDocuments'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

class SDExpertCtrl {
  sdAddedToReport = []
  sdAddToReportLoading = false

  showAddToReportButton = true
  postUrl = '/claimFiles/v1/claimFileAssessor'

  loadSDReport(data) {
    this.sdAddedToReport = data
  }

  fetchSD(wan) {
    return fetchSupportingDocuments({ wan, url: '/claimFiles/v1/claimFileAssessor' })
      .then(
        action(supportingDocs => {
          SupportingDocumentStore.supportingDocuments = supportingDocs.data.map(data => {
            return new SupportingDocument(data.attributes)
          })

          return Promise.resolve()
        }),
      )
      .catch(err => {
        return Promise.reject(err)
      })
  }

  addOrRemoveToReport(sdId) {
    this.sdAddToReportLoading = true
    const addOrRemove = this.isSdAddedToReport(sdId)

    const addSdToReport = () => {
      let arr = [...toJS(this.sdAddedToReport)]
      arr.push(sdId)
      return arr
    }

    const removeSdFromReport = () => {
      let arr = [...toJS(this.sdAddedToReport)]
      const index = arr.indexOf(sdId)

      arr.splice(index, 1)
      return arr
    }

    const sdToSend = addOrRemove ? removeSdFromReport() : addSdToReport()

    addSdToTheReport(MissionStore.id, sdToSend)
      .then(
        action(() => {
          this.sdAddedToReport = sdToSend
          this.sdAddToReportLoading = false
          addOrRemove
            ? AlertCtrl.alert('success', 'mission.supportingDocuments.removeToReport')
            : AlertCtrl.alert('success', 'mission.supportingDocuments.addToReport')
        }),
      )
      .catch(
        action(err => {
          AlertCtrl.alert('danger', `${err}`)
        }),
      )
  }

  removeAllToTheReport() {
    this.sdAddToReportLoading = true
    const sdToSend = []

    addSdToTheReport(MissionStore.id, sdToSend)
      .then(
        action(() => {
          this.sdAddedToReport = sdToSend
          this.sdAddToReportLoading = false
          AlertCtrl.alert('success', 'mission.supportingDocuments.addedAllToReport')
        }),
      )
      .catch(
        action(err => {
          AlertCtrl.alert('danger', `${err}`)
        }),
      )
  }

  addAllToReport(allSd) {
    this.sdAddToReportLoading = true
    const sdToSend = allSd.map(sd => sd.id)

    addSdToTheReport(MissionStore.id, sdToSend)
      .then(
        action(() => {
          this.sdAddedToReport = sdToSend
          this.sdAddToReportLoading = false
          AlertCtrl.alert('success', 'mission.supportingDocuments.removedAllFromReport')
        }),
      )
      .catch(
        action(err => {
          AlertCtrl.alert('danger', `${err}`)
        }),
      )
  }

  get isSdSelectEmpty() {
    return this.sdAddedToReport && this.sdAddedToReport.length === 0
  }

  isSdAddedToReport(sdId) {
    return this.sdAddedToReport.indexOf(sdId) !== -1
  }
}

const DecoratedSDExpertCtrl = decorate(SDExpertCtrl, {
  sdAddedToReport: observable,
  sdAddToReportLoading: observable,

  loadSDReport: action,
  fetchSD: action,
  addOrRemoveToReport: action,
  removeAllToTheReport: action,
  addAllToReport: action,

  isSdSelectEmpty: computed,
})

export default new DecoratedSDExpertCtrl()
