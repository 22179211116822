import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import InputArea from 'components/InputArea/InputArea'
import AreaCalculator from './AreaCalculator'
import InputNumber from 'components/InputNumber/InputNumber'

class Area extends React.Component {
  onChangeQuantity = e => {
    const quantity = e.target.value !== '' ? parseInt(e.target.value, 10) : ''
    DetailPackageCtrl.packageData.setProperty(e.target.name, quantity)
  }
  onChangeArea = e => DetailPackageCtrl.packageData.setProperty(e.target.name, e.target.value)

  render() {
    const {
      t,
      match: {
        params: { type },
      },
      MissionStore: { isMissionClosed },
      ManagerClaimStore: { isClaimClosed },
    } = this.props
    const { unit, quantity, areaPresent, area } = DetailPackageCtrl.packageData
    const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

    const suffix =
      unit === 'M2' ? (
        <span className="ml-1">
          m<sup>2</sup>
        </span>
      ) : (
        <span className="ml-1">ml</span>
      )

    if (!areaPresent) {
      return (
        <div className="areasquare d-flex">
          <span className="font-weight-bold">
            {t('mission.calculation.detailPackage.quantityLabel')}
          </span>
          <InputNumber
            name="quantity"
            type="number"
            className="input text-right"
            minValue={1}
            maxValue={999}
            onChange={this.onChangeQuantity}
            value={quantity}
          />
        </div>
      )
    }

    return (
      <div className="areasquare d-flex">
        <span className="font-weight-bold">{t('mission.calculation.detailPackage.areaLabel')}</span>
        <div className="area-input">
          <InputArea
            name="overrideAreaSquare"
            value={area}
            onChange={this.onChangeArea}
            disabled={isClosed}
          />
          {suffix}
          {unit === 'M2' && <AreaCalculator />}
        </div>
      </div>
    )
  }
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(Area)
