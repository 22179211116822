import { observable, computed, action, decorate } from 'mobx'

import CartStore from 'stores/Mission/domain/CartStore'

class Tool {
  overridePrice = null
  overrode = false

  calculatedPrice = 0
  cde = null
  dimensions = null
  label = ''
  material = null
  price = ''
  unit = ''
  type = 'tool'

  get calculatedPriceWithMargin() {
    const { configuration } = CartStore

    if (configuration && configuration.toolMargin)
      return this.calculatedPrice * parseFloat(configuration.toolMargin)

    return this.calculatedPrice
  }

  get asJson() {
    return {
      calculatedPrice: this.calculatedPrice,
      cde: this.cde,
      dimensions: this.dimensions,
      label: this.label,
      material: this.material,
      price: this.price,
      unit: this.unit,
      type: this.type,
      overridePrice: this.overridePrice,
      overrode: this.overrode,
    }
  }

  constructor(data) {
    Object.assign(this, data)
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedTool = decorate(Tool, {
  overridePrice: observable,
  overrode: observable,

  setProperty: action,

  calculatedPriceWithMargin: computed,
  asJson: computed,
})

export default DecoratedTool
