import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import AddToReportButton from './AddToReportButton'
import LinkToOperation from './LinkToOperation'

const ItemActions = ({ sdItem, showAddToReportButton }) => {
  const setDeleteModal = () => SupportingDocumentsCtrl.setDeleteModal()

  return (
    <div className="item-actions-wrapper">
      <AddToReportButton sdItemId={sdItem.id} showAddToReportButton={showAddToReportButton} />

      <div className="item-actions">
        <LinkToOperation sdItemId={sdItem.id} />

        <div className="item-actions-inner">
          <button
            type="button"
            className={classNames('delete-btn btn btn-circle', {
              'btn-primary':
                (SupportingDocumentsCtrl.isCreateMissionSelector === false &&
                  SupportingDocumentsCtrl.isSelected(sdItem.id)) ||
                (SupportingDocumentsCtrl.isCreateMissionSelector &&
                  SupportingDocumentsCtrl.isCreateMissionSelected(sdItem.id)),
            })}
            onClick={() => {
              SupportingDocumentsCtrl.isCreateMissionSelector
                ? SupportingDocumentsCtrl.isCreateMissionSelected(sdItem.id)
                  ? SupportingDocumentsCtrl.removeCreateMissionSelectedSd(sdItem.id)
                  : SupportingDocumentsCtrl.addCreateMissionSelectedSd(sdItem.id)
                : SupportingDocumentsCtrl.isSelected(sdItem.id)
                ? SupportingDocumentsCtrl.removeSelectedSd(sdItem.id)
                : SupportingDocumentsCtrl.addSelectedSd(sdItem.id)
              setDeleteModal()
            }}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default observer(ItemActions)
