import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import SDItem from 'components/SDItem/index'
import Loader from 'components/Loader/Loader'

const List = ({ t, onSelectItem, selectedItems }) => {
  if (SupportingDocumentsCtrl.loading) {
    return (
      <div className="list row">
        <Loader />
      </div>
    )
  }

  let text = ''
  switch (SupportingDocumentsCtrl.filter) {
    case SupportingDocTypes.PHOTO:
      text = t('mission.supportingDocuments.photos')
      break
    case SupportingDocTypes.DOCUMENT:
      text = t('mission.supportingDocuments.documents')
      break
    case SupportingDocTypes.INVOICE:
      text = t('mission.supportingDocuments.invoices')
      break
    default:
      text = t('mission.supportingDocuments.schemas')
      break
  }

  return (
    <div className="sd-item-list row">
      {SupportingDocumentStore.sdFiltered.length > 0 ? (
        SupportingDocumentStore.sdFiltered.map(sd => (
          <SDItem
            key={sd.id}
            sdItem={sd}
            showInfo={true}
            canDelete={false}
            onSelectItem={onSelectItem.bind(this, sd.id)}
            selected={selectedItems.indexOf(sd.id) > -1}
          />
        ))
      ) : (
        <div className="col-12 empty-sd-list mb-3">
          {t('mission.supportingDocuments.emptyList', { document: text })}
        </div>
      )}
    </div>
  )
}

export default compose(
  withTranslation(),
  observer,
)(List)
