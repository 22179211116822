import React from 'react'
import { useState } from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import './UserManagement.css'
import UserManagementCtrl from 'stores/Parameters/UserManagement/UserManagementCtrl'
import UserManagementStore from 'stores/Parameters/UserManagement/UserManagementStore'
import { uid } from 'utils'

const User = observer(({ data, setUser, t }) => {
  return (
    <tr className={classNames('clickable', { 'disabled-user': !data.enabled })} onClick={setUser}>
      <td className="d-none d-lg-table-cell">{data.firstName}</td>
      <td>{data.lastName}</td>
      <td>{data.username}</td>
      <td className="d-none d-lg-table-cell">{data.email}</td>
      <td>
        {data.enabled
          ? t('parameters.userManagement.modal.enabled')
          : t('parameters.userManagement.modal.notEnabled')}
      </td>
    </tr>
  )
})

const Users = observer(({ t, customerId, name, users }) => {
  const { level } = UserManagementStore
  const [usersData, setUsersSorted] = useState(users)
  const [sortField, setSortField] = useState('')
  const [order, setOrder] = useState('asc')

  const columns = [
    {
      accessor: 'firstName',
      label: 'firstName',
      displayClass: 'd-none d-lg-table-cell',
      sortable: true,
    },
    { accessor: 'lastName', label: 'lastName', displayClass: null, sortable: true },
    { accessor: 'username', label: 'login', displayClass: null, sortable: true },
    { accessor: 'email', label: 'email', displayClass: 'd-none d-lg-table-cell', sortable: true },
    { accessor: 'enabled', label: 'status', displayClass: null, sortable: true },
  ]

  function addNewUser(customerId) {
    UserManagementCtrl.resetForm()
    UserManagementCtrl.setProperty('showUserModal', true)
    UserManagementCtrl.setProperty('customerId', customerId)
  }

  function loadData(customerId) {
    UserManagementStore.loadData(customerId)
  }

  function setUser(user, customerId) {
    UserManagementCtrl.setProperty('user', user)
    UserManagementCtrl.user.getAreas()
    UserManagementCtrl.setProperty('showUserModal', true)
    UserManagementCtrl.setProperty('customerId', customerId)
  }

  const handleSorting = (sortField, sortOrder) => {
    if (sortField === 'status') {
      sortField = 'enabled'
    }
    if (sortField) {
      const sorted = [...usersData].sort((a, b) => {
        if (a[sortField] === null) return 1
        if (b[sortField] === null) return -1
        if (a[sortField] === null && b[sortField] === null) return 0
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        )
      })
      setUsersSorted(sorted)
      handleSortingIcon(sortField, sortOrder)
    }
  }

  const handleSortingChange = accessor => {
    const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc'
    setSortField(accessor)
    setOrder(sortOrder)
    handleSorting(accessor, sortOrder)
  }

  const handleSortingIcon = (sortField, sortOrder) => {
    const iconClass = sortOrder === 'asc' ? '-up' : '-down'
    document.querySelectorAll('.fa-fw').forEach((icon, index) => {
      icon.classList.contains(sortField)
        ? (icon.className = 'fa fa-fw fa-sort' + iconClass + ' ' + columns[index].accessor)
        : (icon.className = 'fa fa-fw fa-sort ' + columns[index].accessor)
    })
  }

  return (
    <table className="table table-bordered">
      <thead>
        <tr className="table-primary">
          <th colSpan="5" scope="col">
            <div className="mt-2 float-left">{name}</div>

            <div className="float-right">
              {level > 0 ? (
                <div
                  className="btn btn-success mr-3 clickable"
                  onClick={loadData.bind(this, customerId)}
                >
                  {t('parameters.userManagement.seeGroup')}
                </div>
              ) : null}

              <button className="btn btn-success" onClick={addNewUser.bind(this, customerId)}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </th>
        </tr>

        <tr>
          {columns.map(({ accessor, label, sortable, displayClass }) => {
            return (
              <th
                scope="col"
                key={accessor}
                className={classNames(displayClass, { clickable: sortable })}
                onClick={handleSortingChange.bind(this, accessor)}
              >
                {t('parameters.userManagement.' + label)}
                <i className={`fa fa-fw fa-sort ${accessor}`}></i>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {usersData.length === 0 ? (
          <tr>
            <td colSpan="4" className="text-center">
              {t('parameters.userManagement.none')}
            </td>
          </tr>
        ) : (
          usersData.map(user => (
            <User
              key={`user-${uid(10)}`}
              data={user}
              setUser={setUser.bind(this, user, customerId)}
              t={t}
            />
          ))
        )}
      </tbody>
    </table>
  )
})

export default withTranslation()(Users)
