import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import InputPrice from 'components/InputPrice/InputPrice'
import ObsolescenceCalculator from './ObsolescenceCalculator'
import WarningMinPriceTooltip from './WarningMinPriceTooltip'
import TooltipQuantityPrice from './TooltipQuantityPrice'
import Input from 'components/Forms/Input'
import SelectVAT from 'components/SelectVAT/SelectVAT'

const onChangePercentage = (onChange, e) => {
  if (e.target.value === '') e.target.value = 0
  else if (e.target.value > 100) e.target.value = 100
  else if (e.target.value <= 0) e.target.value = 0
  onChange(e)
}

export const TotalWithoutVAT = observer(({ packageData, onChange }) => {
  const {
    selfRepair,
    overridePrice,
    price,
    priceType,
    quantityOriginalTotalPriceSelfRepair,
    quantityOriginalTotalPriceCatalog,
  } = packageData
  let value = 0
  let inputName = 'price'

  // FOR PACKAGE TYPE
  if (overridePrice === null || overridePrice > 0) {
    value = overridePrice || 0
    inputName = 'overridePrice'
  }

  // FOR FURNITURE TYPE
  if (overridePrice === undefined) {
    value = price
    inputName = 'price'
  }

  if (priceType === 'custom' || overridePrice === undefined) {
    let price = value
    return <InputPrice name={inputName} price={price} onChange={onChange} />
  }

  const originalPriceWithQuantity = selfRepair
    ? quantityOriginalTotalPriceSelfRepair
    : quantityOriginalTotalPriceCatalog

  return [
    <WarningMinPriceTooltip key="warning" />,
    <TooltipQuantityPrice key="tooltip" />,
    <InputPrice
      key="input-total-price-without-vat"
      name="overridePrice"
      price={overridePrice === null ? originalPriceWithQuantity : overridePrice}
      overridable
      onChange={onChange}
      classNamesForPrice="font-weight-bold text-primary"
      disableShowUnit={false}
      changed={overridePrice !== null}
      maxLengthNumber={6}
    />,
  ]
})

export const DisabledLabelObsolescence = withTranslation()(
  observer(({ t, packageData, subLabel = '' }) => {
    const { obsolescence } = packageData

    return (
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-md-6">
          <span className="recap-label">{t('mission.calculation.cart.obsolescence')}</span>
          {subLabel}
        </div>
        <div className="col-12 col-md-6 text-md-right">{`${obsolescence} %`}</div>
      </div>
    )
  }),
)

export const LabelObsolescence = withTranslation()(
  observer(({ t, onChange, packageData, controller, subLabel }) => {
    const { obsolescence, age, materialPresent, lifetime } = packageData

    return (
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-md-6">
          <span className="recap-label">{t('mission.calculation.cart.obsolescence')}</span>
          {subLabel}
        </div>

        <div
          className={classNames('col-12 col-md-6 text-md-right', {
            'calculator-padding': lifetime === null || !materialPresent,
          })}
        >
          <Input
            className="percentage-input form-control d-inline text-left mr-1"
            name="correction"
            type="number"
            max={100}
            min={0}
            value={obsolescence}
            onChange={onChangePercentage.bind(this, onChange)}
            readOnly={age !== 0}
          />
          %
          {lifetime === null || !materialPresent ? null : (
            <ObsolescenceCalculator controller={controller} />
          )}
        </div>
      </div>
    )
  }),
)
LabelObsolescence.defaultProps = {
  subLabel: '',
}
export const LabelObsolescenceReturn = withTranslation()(
  observer(({ t, onChange, packageData }) => {
    const { obsolescenceReturn } = packageData

    return (
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-md-6">
          <span className="recap-label">{t('mission.calculation.cart.totalVRShort')}</span>
        </div>
        <div className={classNames('col-12 col-md-6 text-md-right calculator-padding')}>
          <Input
            className="percentage-input form-control d-inline text-left mr-1"
            name="obsolescenceReturn"
            type="number"
            max={100}
            min={0}
            key="input-correction"
            value={obsolescenceReturn}
            onChange={onChangePercentage.bind(this, onChange)}
          />
          %
        </div>
      </div>
    )
  }),
)

export const LabelVAT = withTranslation()(
  observer(({ t, onChange, packageData }) => {
    const { vat, selfRepair } = packageData

    return (
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-md-4">
          <span className="recap-label">{t('mission.calculation.common.vat')}</span>
        </div>
        <div className={classNames('col-12 col-md-8 text-md-right calculator-padding')}>
          <SelectVAT vat={selfRepair ? 0 : vat} disabled={selfRepair} onChange={onChange} />
        </div>
      </div>
    )
  }),
)

export const DisabledLabelVAT = withTranslation()(
  observer(({ t, packageData }) => {
    const { vat } = packageData

    return (
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-md-4">
          <span className="recap-label">{t('mission.calculation.common.vat')}</span>
        </div>
        <div className={classNames('col-12 col-md-8 text-md-right calculator-padding')}>
          {`${vat} %`}
        </div>
      </div>
    )
  }),
)

// JUST FOR FURNITURE
export const TotalWithVAT = observer(({ onChange, packageData }) => {
  const { priceWithVat, totalPriceVAT } = packageData

  return (
    <InputPrice
      key="input-total-price-with-vat"
      name="priceWithVat"
      price={priceWithVat === null ? totalPriceVAT : priceWithVat}
      onChange={onChange}
      classNamesForPrice="font-weight-bold text-primary"
      disableShowUnit
      changed={false}
      maxLengthNumber={6}
    />
  )
})
