import { observable, decorate, computed } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class Table extends AbstractField {
  show = true
  type = 'table'

  constructor(data) {
    super(data)
    this.type = data.type || 'table'
    this.show = data.show
  }

  get isValid() {
    return true
  }
}

const DecoratedTable = decorate(Table, {
  show: observable,
  isValid: computed,
})

export default DecoratedTable
