import { observable, action, decorate, runInAction, computed } from 'mobx'
import { isNil, mergeDeepRight, path, subtract, sum } from 'ramda'

import { fetchWorkRequest, saveWorkRequest } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import MissionStore from 'stores/Mission/domain/MissionStore'
import CartStore from 'stores/Mission/domain/CartStore'
import ConclusionCtrl from 'stores/Mission/ConclusionCtrl'
import PrejudiceStore from 'stores/Mission/domain/PrejudiceStore'
import { roundDecimal } from 'utils/currency'

class WorkRequest {
  loading = false
  form = null
  attachments = []

  addSupportingDocumentAttachment = id => {
    this.attachments.push(id)
  }

  removeSupportingDocumentAttachment = deleteId => {
    this.attachments = this.attachments.filter(attachmentId => attachmentId !== deleteId)
  }

  get obsPrice() {
    const { catalog } = CartStore.totalsFromCart

    const obs = {
      property: catalog.totalPropertyObsolescence,
      embellishment: catalog.totalEmbellishmentObsolescence,
      outdoor: catalog.totalOutdoorObsolescence,
      options: !!PrejudiceStore.payloads.length ? PrejudiceStore.realObsolescenceWithVAT : 0,
    }

    const returns = {
      property: catalog.totalPropertyOR,
      embellishment: catalog.totalEmbellishmentOR,
      outdoor: catalog.totalOutdoorOR,
      options: !!PrejudiceStore.payloads.length ? PrejudiceStore.guaranteedObsolescenceWithVAT : 0,
    }

    const noReturns = {
      property: subtract(obs.property, returns.property),
      embellishment: subtract(obs.embellishment, returns.embellishment),
      outdoor: subtract(obs.outdoor, returns.outdoor),
      options: !!PrejudiceStore.payloads.length ? PrejudiceStore.obsolescenceDeductedWithoutVAT : 0,
    }

    return roundDecimal(
      sum([noReturns.property, noReturns.embellishment, noReturns.outdoor, noReturns.options]),
    )
  }

  loadData = async (wan, data) => {
    this.loading = true

    let workPriceTTC = path(['workRequest', 'workPriceTTC'], data) || 0
    let obsPrice = path(['workRequest', 'obsPrice'], data) || 0
    let franchise = path(['workRequest', 'franchise'], data) || 0

    if (workPriceTTC === 0 && obsPrice === 0 && franchise === 0) {
      if (ConclusionCtrl.isFranchiseMustBeDeducted) {
        franchise = isNil(CartStore.deductibleOverride.immediateSettlement)
          ? CartStore.deductible.immediateSettlement
          : CartStore.deductibleOverride.immediateSettlement
      }
      let initWorkRequestAmount = {
        workRequest: {
          workPriceTTC: roundDecimal(CartStore.workAmount),
          obsPrice: this.obsPrice,
          franchise: roundDecimal(franchise),
        },
      }

      data = mergeDeepRight(data, initWorkRequestAmount)
    }

    try {
      const form = await fetchWorkRequest(wan)
      form.name = 'workRequest'
      runInAction(() => {
        this.form = new Form({ form, data })
      })
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    const res = await saveWorkRequest(wan, formData)
    MissionStore.updateWorkRequest(res)
  }
}

const DecoratedWorkRequest = decorate(WorkRequest, {
  loading: observable,
  form: observable,
  attachments: observable,

  loadData: action,
  addSupportingDocumentAttachment: action,
  removeSupportingDocumentattachment: action,
  save: action,

  obsPrice: computed,
})

export default new DecoratedWorkRequest()
