import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import Field from 'components/DynForm/Field'

const FormFields = ({
  trigger,
  groupId,
  formObj,
  focus,
  formObj: { inputs, withAccordion, saving, saved },
  MissionStore: { isMissionClosed, cfa },
}) => {
  return inputs
    .filter(input => input.groupId === groupId || !withAccordion)
    .map((input, index) => {
      if (!input.show) return null

      if (
        focus === input.name ||
        (index === 0 && saving === false && saved === false && !withAccordion)
      ) {
        input.autoFocus = true
      } else {
        input.autoFocus = false
      }

      if (isMissionClosed) input.disabled = true

      return <Field key={index} input={input} formObj={formObj} cfa={cfa} trigger={trigger} />
    })
}

export default compose(inject('MissionStore'), observer)(FormFields)
