import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import CardLayout from 'components/CardLayout/CardLayout'

class VersionInfo extends React.Component {
  render() {
    const {
      t,
      UiCtrl: { apiVersion },
    } = this.props

    return (
      <div className="informations-page">
        <h5 className="mb-4">{t('common.applicationVersion')}</h5>

        <CardLayout className="parameters-informations">
          <div className="mb-2">
            {t('common.spsVersion')} : {process.env.REACT_APP_TAG ? process.env.REACT_APP_TAG : '-'}
          </div>

          <div className="mb-2">
            {t('common.apiVersion')} : {apiVersion ? apiVersion : '-'}
          </div>
        </CardLayout>
      </div>
    )
  }
}

export default inject('UiCtrl')(withTranslation()(observer(VersionInfo)))
