import React from 'react'
import PropTypes from 'prop-types'

export default class Radio extends React.Component {
  render() {
    const { options, autoFocus } = this.props

    return options.map((option, index) => {
      return (
        <div className="d-inline">
          <input
            type="checkbox"
            className="form-control"
            value={option.value}
            autoFocus={index === 0 && autoFocus}
          />
          <label>{option.label}</label>
        </div>
      )
    })
  }
}

Radio.defaultProps = {
  value: '',
  autoFocus: false,
}

Radio.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  errors: PropTypes.array,
  autoFocus: PropTypes.bool,
}
