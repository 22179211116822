import React from 'react'
import { observer, inject } from 'mobx-react'
import { splitEvery } from 'ramda'
import { withTranslation } from 'react-i18next'

import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import PartyInvolvedDBStore from 'stores/Parameters/PartyInvolvedDBStore'
import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import PartyCard from 'pages/PartyInvolved/PartyCard'
import PartyLine from 'pages/PartyInvolved/PartyLine'

const List = ({ t, forceUseList }) => {
  const { displayList, pageContext } = PartyInvolvedCtrl
  const partyInvolved =
    pageContext === 'common'
      ? PartyInvolvedStore.partyInvolved
      : PartyInvolvedDBStore.partyInvolvedDB
  const rowPartyInvolved = splitEvery(2, partyInvolved)

  if (rowPartyInvolved.length === 0) {
    return <div className="row d-flex justify-content-center mb-3">{t('partyInvolved.empty')}</div>
  }

  if (displayList || forceUseList)
    return (
      <table border="1" className="line-display table table-bordered table-sm">
        <thead>
          <tr className="table-primary">
            <th>
              {t('mission.contact.lastName')} {t('mission.contact.firstName')}
            </th>
            <th>{t('mission.contact.quality')}</th>
            <th>{t('mission.contactPro.company')}</th>
          </tr>
        </thead>
        <tbody>
          {partyInvolved.map((partyData, index) => {
            return <PartyLine data={partyData} key={`party-${partyData.id}`} index={index} />
          })}
        </tbody>
      </table>
    )

  return rowPartyInvolved.map((row, index) => {
    return (
      <div className="row" key={`party-row-${index}`}>
        {row.map((partyData, index) => {
          return <PartyCard data={partyData} key={`party-${partyData.id}`} index={index} />
        })}
      </div>
    )
  })
}

export default inject('UserStore')(withTranslation()(observer(List)))
