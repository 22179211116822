import React from 'react'
import { inject, observer } from 'mobx-react'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const SelectResponsability = ({ CommonStore: { responsabilitiesForSelect } }) => {
  const onChange = e => PartyInvolvedCtrl.party.setResponsability(e.target.value)
  const { involvedPartyResponsabilityKey } = PartyInvolvedCtrl.party

  let involvedPartyRoleFilter
  if (PartyInvolvedCtrl.role) {
    involvedPartyRoleFilter = Object.values(PartyInvolvedCtrl.role)
  } else {
    involvedPartyRoleFilter = null
  }

  return (
    <ResponsiveSelect
      name="involvedPartyResponsability"
      value={involvedPartyResponsabilityKey}
      defaultValue={involvedPartyRoleFilter}
      onChange={onChange}
      options={responsabilitiesForSelect}
      isSearchable
    />
  )
}

export default inject('CommonStore')(observer(SelectResponsability))
