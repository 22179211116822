import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import './PartyInvolved.css'
import CardLayout from 'components/CardLayout/CardLayout'
import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import PartyModal from 'pages/PartyInvolved/PartyModal'
import List from 'pages/PartyInvolved/List'
import DisplayView from 'pages/PartyInvolved/DisplayView'

class PartyInvolved extends React.Component {
  componentDidMount() {
    if (PartyInvolvedCtrl.ctrl.canSelectCreateMission !== undefined)
      PartyInvolvedCtrl.ctrl.setSelectCreateMission(false)

    if (this.props.match.url === '/parameters/partyInvolvedDB') {
      PartyInvolvedCtrl.setProperty('pageContext', 'parameters')
      PartyInvolvedCtrl.setParametersCtrl()
    }
  }
  componentWillUnmount() {
    if (this.props.match.url === '/parameters/partyInvolvedDB') {
      PartyInvolvedCtrl.setProperty('pageContext', 'common')
      PartyInvolvedCtrl.setDefaultCtrl()
    }
  }

  showForm = () => PartyInvolvedCtrl.showForm(null, false)

  render() {
    const { t, useCardLayout, forceUseList } = this.props
    const {
      ctrl: { showActions },
    } = PartyInvolvedCtrl
    const { isMobile } = this.props.UiCtrl

    const content = (
      <Fragment>
        <div className="row no-gutters pb-3">
          {!forceUseList && (
            <div className="col-3">
              <div className="font-weight-bold">
                <DisplayView />
              </div>
            </div>
          )}

          <div
            className={classNames('d-flex justify-content-end', {
              'col-12': forceUseList,
              'col-9': !forceUseList,
            })}
          >
            {showActions ? (
              <button
                className="btn btn-primary"
                onClick={this.showForm}
                data-cy="create-party-involved"
              >
                {isMobile ? <i className="fa fa-plus" /> : t('partyInvolved.addParty')}
              </button>
            ) : null}
          </div>
        </div>

        <div className="list-party">
          <List forceUseList={forceUseList} />
        </div>
        <PartyModal />
      </Fragment>
    )

    if (useCardLayout) return <CardLayout className="party-involved">{content}</CardLayout>

    return <div className="party-involved">{content}</div>
  }
}

PartyInvolved.defaultProps = { useCardLayout: true, forceUseList: false }

PartyInvolved.propTypes = {
  useCardLayout: PropTypes.bool,
  forceUseList: PropTypes.bool,
}

export default compose(
  inject('UiCtrl'),
  withRouter,
  withTranslation(),
  observer,
)(PartyInvolved)
