import React from 'react'
import { inject, observer } from 'mobx-react'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const SelectTypeOfVictim = ({ CommonStore: { typeOfVictimeForSelect } }) => {
  const { typeOfVictimKey } = PartyInvolvedCtrl.party
  const onChange = e => PartyInvolvedCtrl.party.setTypeOfVictime(e.target.value)

  return (
    <ResponsiveSelect
      name="typeOfVictimKey"
      value={typeOfVictimKey}
      onChange={onChange}
      options={typeOfVictimeForSelect}
      disabled
    />
  )
}

export default inject('CommonStore')(observer(SelectTypeOfVictim))
