import request, { requestQlik } from 'services/httpRequest'

export const fetchQlikToken = async () => {
  try {
    const res = await request({
      method: 'get',
      url: `/qlik/v1/get-token`,
    })
    return res.data ? res.data.attributes : null
  } catch (err) {
    return Promise.reject(err)
  }
}

export const sendTokenToQlik = async token => {
  try {
    return await requestQlik({
      method: 'GET',
      url: `https://analytics.sidexa.fr/sh_jwt/hub/`,
      headers: { Authorization: 'Bearer ' + token },
      credentials: 'include',
    })
  } catch (err) {
    return err
  }
}
