import { observable, computed, action, decorate } from 'mobx'
import { isNil } from 'ramda'

import CartStore from 'stores/Mission/domain/CartStore'
import { uid } from 'utils'

class Material {
  default = false
  overridePrice = null
  overrode = false

  calculatedPriceL = 0
  calculatedPriceM = 0
  calculatedPriceH = 0
  cde = null
  conditioning = null
  consumption = ''
  dimensions = ''
  label = ''
  lifetime = 0
  material = ''
  priceH = null
  priceL = null
  priceM = null
  unit = ''
  type = 'material'

  get calculatedPriceLWithMargin() {
    const { configuration } = CartStore
    let total = this.calculatedPriceL
    if (configuration && configuration.materialMargin)
      total = this.calculatedPriceL * parseFloat(configuration.materialMargin)

    return total
  }

  get calculatedPriceMWithMargin() {
    const { configuration } = CartStore
    let total = this.calculatedPriceM
    if (configuration && configuration.materialMargin)
      total = this.calculatedPriceM * parseFloat(configuration.materialMargin)

    return total
  }

  get calculatedPriceHWithMargin() {
    const { configuration } = CartStore
    let total = this.calculatedPriceH

    if (configuration && configuration.materialMargin)
      total = this.calculatedPriceH * parseFloat(configuration.materialMargin)

    return total
  }

  get asJson() {
    return {
      id: this.id,
      calculatedPriceH: this.calculatedPriceH,
      calculatedPriceL: this.calculatedPriceL,
      calculatedPriceM: this.calculatedPriceM,
      cde: this.cde,
      conditioning: this.conditioning,
      consumption: this.consumption,
      default: this.default,
      dimensions: this.dimensions,
      label: this.label,
      lifetime: this.lifetime,
      material: this.material,
      priceH: this.priceH,
      priceL: this.priceL,
      priceM: this.priceM,
      unit: this.unit,
      overridePrice: this.overridePrice,
      overrode: this.overrode,
      type: this.type,
    }
  }

  constructor(data) {
    Object.assign(this, data)
    if (isNil(this.id)) this.id = uid(10)
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedMaterial = decorate(Material, {
  default: observable,
  overridePrice: observable,
  overrode: observable,

  setProperty: action,

  calculatedPriceLWithMargin: computed,
  calculatedPriceMWithMargin: computed,
  calculatedPriceHWithMargin: computed,
  asJson: computed,
})

export default DecoratedMaterial
