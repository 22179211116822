import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import UiCtrl from 'stores/Common/view/UiCtrl'
import ActionBar from './ActionBar'

class Filters extends React.Component {
  findNumberByEntity = () => {
    switch (SupportingDocumentsCtrl.sdCategory) {
      case SupportingDocTypes.SCHEMA:
        return SupportingDocumentStore.sdBySchemas.length

      case SupportingDocTypes.DOCUMENT:
        return SupportingDocumentStore.sdByDocuments.length

      case SupportingDocTypes.INVOICE:
        return SupportingDocumentStore.sdByInvoices.length

      default:
        return SupportingDocumentStore.sdByPhotos.length
    }
  }
  onClick = filter => {
    SupportingDocumentsCtrl.setDropZoneFiltered(false)
    SupportingDocumentsCtrl.setFilter(filter)
  }

  render() {
    const {
      t,
      withFilters,
      MissionStore: { missionStatus },
    } = this.props
    const allCounter = this.findNumberByEntity()

    return [
      <div
        key="main-filters-wrapper"
        className="main-filters-wrapper justify-content-between d-flex align-items-center"
      >
        <ul key={'main-filter'} className="nav nav-bar filters">
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.PHOTO,
              })}
              onClick={this.onClick.bind(this, SupportingDocTypes.PHOTO)}
            >
              {UiCtrl.deviceType === 'mobile' ? (
                <i className="fa fa-image" />
              ) : (
                t('mission.supportingDocuments.type.photos', {
                  counter: SupportingDocumentStore.sdByPhotos.length,
                })
              )}
            </span>
          </li>
          <span className="divider" />
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.DOCUMENT,
              })}
              onClick={this.onClick.bind(this, SupportingDocTypes.DOCUMENT)}
            >
              {UiCtrl.deviceType === 'mobile' ? (
                <i className="fa fa-file" />
              ) : (
                t('mission.supportingDocuments.type.documents', {
                  counter: SupportingDocumentStore.sdByDocuments.length,
                })
              )}
            </span>
          </li>
          <span className="divider" />
          <li className="nav-item">
            <span
              className={classNames('nav-link', {
                active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.SCHEMA,
              })}
              onClick={this.onClick.bind(this, SupportingDocTypes.SCHEMA)}
            >
              {UiCtrl.deviceType === 'mobile' ? (
                <i className="fa fa-pencil" />
              ) : (
                t('mission.supportingDocuments.type.schemas', {
                  counter: SupportingDocumentStore.sdBySchemas.length,
                })
              )}
            </span>
          </li>
          {SupportingDocumentsCtrl.isInvoiceAvailable && (
            <Fragment>
              <span className="divider" />
              <li className="nav-item">
                <span
                  className={classNames('nav-link', {
                    active: SupportingDocumentsCtrl.sdCategory === SupportingDocTypes.INVOICE,
                  })}
                  onClick={this.onClick.bind(this, SupportingDocTypes.INVOICE)}
                >
                  {UiCtrl.deviceType === 'mobile' ? (
                    <i className="fa fa-file-archive-o" />
                  ) : (
                    t('mission.supportingDocuments.type.invoices', {
                      counter: SupportingDocumentStore.sdByInvoices.length,
                    })
                  )}
                </span>
              </li>
            </Fragment>
          )}
        </ul>
        <ul className="nav nav-bar">{missionStatus !== 'closed' && <ActionBar />}</ul>
      </div>,
      !SupportingDocumentsCtrl.dropZoneFiltered && withFilters && (
        <div key="filter-types" className="row no-gutters">
          <div className="filter-types col-sm-12">
            {allCounter > 0 && (
              <button
                className={classNames('btn btn-outline-secondary btn-sm')}
                onClick={this.onClick.bind(this, SupportingDocumentsCtrl.sdCategory)}
              >
                {t('mission.supportingDocuments.type.all')}
                &nbsp;(
                {allCounter})
              </button>
            )}
            {Object.keys(SupportingDocumentStore.typeKeysFilterList).map((typeKey, index) => {
              return (
                <button
                  key={index}
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => SupportingDocumentsCtrl.setFilter(typeKey)}
                >
                  <span
                    className="badge"
                    style={{
                      background: SupportingDocumentStore.typeKeysFilterList[typeKey].color,
                    }}
                  >
                    &nbsp;
                  </span>
                  &nbsp;
                  {SupportingDocumentStore.typeKeysFilterList[typeKey]['name']}
                  &nbsp; ({SupportingDocumentStore.typeKeysFilterList[typeKey].count.length})
                </button>
              )
            })}
          </div>
        </div>
      ),
    ]
  }
}

Filters.defaultProps = { withFilters: true }

export default inject('MissionStore')(withTranslation()(observer(Filters)))
