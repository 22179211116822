import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const InvolvedPartyName = ({
  involvedPartyId,
  CartStore: { showComputationWithoutIP, furnituresWithoutIP },
  PartyInvolvedStore: { findName },
}) => {
  const { t } = useTranslation()
  const label =
    involvedPartyId === 'notVentilated'
      ? t('mission.conclusion.data.notVentilated')
      : findName(involvedPartyId)

  if (
    involvedPartyId === 'notVentilated' &&
    !showComputationWithoutIP &&
    furnituresWithoutIP.length === 0
  )
    return null

  return <div className="text-underline font-weight-bold mb-1">{label} :</div>
}

export default inject('CartStore', 'PartyInvolvedStore')(observer(InvolvedPartyName))
