import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'
import { path } from 'ramda'

import { formatDateHour } from 'utils'

function Event({
  t,
  data: {
    user,
    type: { display_level, category },
    createdAt,
    label,
    status,
  },
  UiCtrl: { isMobile },
}) {
  if (status === false) {
    label = t('common.error') + ' : ' + label
  }
  return (
    <tr className={classNames({ 'table-danger': status === false })}>
      {!isMobile && (
        <td>
          {path(['firstName'], user)} {path(['lastName'], user)}
        </td>
      )}
      {!isMobile && <td>{display_level}</td>}
      {!isMobile && <td>{category.value}</td>}
      <td>
        {status === false && <i className="fa fa-exclamation-triangle">&nbsp;</i>}
        {label}
      </td>
      <td>{formatDateHour({ date: createdAt })}</td>
    </tr>
  )
}

export default compose(
  inject('UiCtrl'),
  withTranslation(),
  observer,
)(Event)
