import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import LoginCtrl from 'stores/Common/view/LoginCtrl'
import ChangePasswordCtrl from 'stores/Parameters/ChangePasswordCtrl'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

const ChangePasswordModal = () => {
  const { t } = useTranslation()
  const { saving, errors } = ChangePasswordCtrl

  const saveNewPassword = () => {
    ChangePasswordCtrl.saveNewPassword(true)
  }
  const onChange = e => {
    ChangePasswordCtrl.setChangePasswordProperty(e.target.name, e.target.value)
  }
  const setTranslatedError = (errors, field) => {
    const fieldErrors = errors.filter(err => err.key === field)
    return !!fieldErrors.length ? fieldErrors.map(error => t(error.value)) : []
  }
  const closeModal = () => LoginCtrl.setProperty('modalChangePassword', false)

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={LoginCtrl.modalChangePassword}
      centered
      close={closeModal}
    >
      <div className="modal-header">
        <h5 className="modal-title">{t('loginPage.modal.changePassword.title')}</h5>
      </div>
      <div
        className="modal-body"
        onKeyPress={e => {
          if (e.charCode === 13 && !saving) {
            ChangePasswordCtrl.saveNewPassword(true)
          }
        }}
      >
        <div className="row form-group">
          <div className="col-12 mb-3">{t('loginPage.modal.changePassword.content')}</div>
        </div>
        <div className="row form-group">
          <div className="col-4">{t('loginPage.modal.changePassword.newPassword')}</div>
          <div className="col-8">
            <Input
              type="password"
              name="first"
              onChange={onChange}
              className="form-control"
              value={ChangePasswordCtrl.changePassword.first}
              errors={setTranslatedError(errors, 'first')}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-4">{t('loginPage.modal.changePassword.repeatPassword')}</div>
          <div className="col-8">
            <Input
              type="password"
              name="second"
              onChange={onChange}
              className="form-control"
              value={ChangePasswordCtrl.changePassword.second}
              errors={setTranslatedError(errors, 'second')}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-4" />
          <div className="col-8">
            <Button
              className="form-control btn btn-primary"
              loading={saving}
              disabled={saving}
              type="submit"
              onClick={saveNewPassword}
            >
              {t('loginPage.modal.changePassword.validate')}
            </Button>
          </div>
        </div>
      </div>
    </ModalWithBackdrop>
  )
}

export default observer(ChangePasswordModal)
