import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
// import { test } from 'ramda'

import UserManagementCtrl from 'stores/Parameters/UserManagement/UserManagementCtrl'
import Input from 'components/Forms/Input'

class AreasInput extends React.Component {
  formatAreas = () => {
    UserManagementCtrl.user.formatAreas()
  }
  onKeyPress = e => {
    if (e.charCode === 44 || e.charCode === 97 || e.charCode === 98) return
    else if (e.charCode >= 48 && e.charCode <= 57) return
    else e.preventDefault()
  }

  render() {
    const { t, onChange, findError } = this.props
    const { areas, areasValidation } = UserManagementCtrl.user

    let inputErrors = findError('areas')
    if (areasValidation) inputErrors.push(t('parameters.userManagement.modal.areasFormatError'))

    return (
      <Input
        className="form-control"
        name="areas"
        value={areas}
        onChange={onChange}
        onKeyPress={this.onKeyPress}
        onBlur={this.formatAreas}
        placeholder={t('parameters.userManagement.modal.areasPlaceholder')}
        errors={inputErrors}
      />
    )
  }
}

export default withTranslation()(observer(AreasInput))
