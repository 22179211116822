import React from 'react'
import { Image } from 'react-konva'
import { withTranslation } from 'react-i18next'

class ImageBackground extends React.Component {
  componentDidMount() {
    this.imgBackground.cache()
  }

  render() {
    const { image, width } = this.props
    return (
      <Image
        image={image}
        x={0}
        y={0}
        width={width}
        ref={node => {
          this.imgBackground = node
        }}
      />
    )
  }
}
export default withTranslation()(ImageBackground)
