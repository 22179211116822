import React from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const IRSITabs = ({
  MissionStore: { isIRSI, IRSIInvolvedPartyWithName, selectedIRSICart, setProperty },
  CartStore: { showComputationWithoutIP },
}) => {
  const { t } = useTranslation()
  if (!isIRSI || (IRSIInvolvedPartyWithName.length === 0 && !showComputationWithoutIP)) return null

  return (
    <ul className="irsi-tabs nav nav-tabs">
      {IRSIInvolvedPartyWithName.map(irsiIP => (
        <li key={`irsi-tab-${irsiIP.id}`} className="nav-item">
          <span
            className={classNames('nav-link clickable', {
              active: irsiIP.id === selectedIRSICart,
            })}
            onClick={() => setProperty('selectedIRSICart', irsiIP.id)}
          >
            {irsiIP.name}
          </span>
        </li>
      ))}

      <li className="nav-item">
        <span
          className={classNames('nav-link clickable', {
            active: selectedIRSICart === null,
          })}
          onClick={() => setProperty('selectedIRSICart', null)}
        >
          {t('mission.conclusion.data.notVentilated')}
        </span>
      </li>
    </ul>
  )
}

export default inject('MissionStore', 'CartStore')(observer(IRSITabs))
