import React from 'react'
import { observer } from 'mobx-react'
import { Switch, useLocation, useParams, Redirect, Route } from 'react-router-dom'

import NotFound from 'pages/NotFound/NotFound'
import Cart from 'pages/Mission/pages/Calculation/Cart/Cart'
import SearchPackage from 'pages/Mission/pages/Calculation/SearchPackage/SearchPackage'
import DetailPackage from 'pages/Mission/pages/Calculation/DetailPackage/DetailPackage'
import SearchFurniture from 'pages/Mission/pages/Calculation/SearchFurniture/SearchFurniture'
import AddFurniture from 'pages/Mission/pages/Calculation/AddFurniture/AddFurniture'
import AddRelatedCost from 'pages/Mission/pages/Calculation/AddRelatedCost/AddRelatedCost'
import CreatePackage from 'pages/Mission/pages/Calculation/CreatePackage/CreatePackage'

const Calculation = () => {
  const { pathname } = useLocation()
  const { id, type } = useParams()

  if (pathname === `/${type}/${id}/Calculation`) {
    return <Redirect to={`/${type}/${id}/Calculation/listPackage`} />
  }

  return (
    <Switch>
      <Route exact path={`/:type/:id/Calculation/listPackage`} component={Cart} />
      <Route exact path={`/:type/:id/Calculation/searchPackage`} component={SearchPackage} />
      <Route exact path={`/:type/:id/Calculation/searchOutdoorPackage`} component={SearchPackage} />

      <Route exact path={`/:type/:id/Calculation/createPackage`} component={CreatePackage} />
      <Route exact path={`/:type/:id/Calculation/createOutdoorPackage`} component={CreatePackage} />

      <Route exact path={`/:type/:id/Calculation/detailPackage`} component={DetailPackage} />
      <Route exact path={`/:type/:id/Calculation/detailOutdoorPackage`} component={DetailPackage} />

      <Route
        exact
        path={`/:type/:id/Calculation/searchRegularFurniture`}
        component={SearchFurniture}
      />
      <Route
        exact
        path={`/:type/:id/Calculation/searchOutdoorFurniture`}
        component={SearchFurniture}
      />

      <Route exact path={`/:type/:id/Calculation/addRegularFurniture`} component={AddFurniture} />
      <Route exact path={`/:type/:id/Calculation/addOutdoorFurniture`} component={AddFurniture} />

      <Route exact path={`/:type/:id/Calculation/addRelatedCost`} component={AddRelatedCost} />
      <Route exact path={`/:type/:id/Calculation/addOverflow`} component={AddRelatedCost} />
      <Route exact path={`/:type/:id/Calculation/addNoGuarantee`} component={AddRelatedCost} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default observer(Calculation)
