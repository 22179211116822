import { path } from 'ramda'

import request from './httpRequest'

export const fetchEmbeddedCollectionModel = async ({ wan, entryType }) => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/static-form/${entryType}`,
    })
    return path(['data', 0, 'attributes', 'fields'], res)
  } catch (error) {
    throw error
  }
}
