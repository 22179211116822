import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Label from 'components/Label/Label'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const Simplified = ({
  options,
  MissionStore: { isMissionClosed },
  CartStore: { simplifiedRen, simplifiedCompanyName, changeAllWithoutSelfRepairPackages },
}) => {
  const { t } = useTranslation()
  const onChange = e => {
    if (e.target.name === 'ren') e.target.value = e.target.value === 'true'
    changeAllWithoutSelfRepairPackages(e.target.name, e.target.value)

    // RESET company name when changing to REN
    if (e.target.name === 'ren' && e.target.value) {
      changeAllWithoutSelfRepairPackages('companyName', '')
    }
  }

  return (
    <Fragment>
      <div className="row no-gutters align-items-center">
        <div className="col-12 col-sm-4 form-group">
          <Label>{t('mission.conclusion.ren.type')}</Label>
        </div>
        <div className="col-12 col-sm-8 form-group">
          <ResponsiveSelect
            name="ren"
            disabled={isMissionClosed}
            onChange={onChange}
            value={simplifiedRen.toString()}
            options={options}
          />
        </div>
      </div>

      {!simplifiedRen && (
        <div className="row no-gutters align-items-center">
          <div className="col-12 col-sm-4 form-group">
            <Label>{t('mission.conclusion.ren.companyName')}</Label>
          </div>
          <div className="col-12 col-sm-8 form-group">
            <Input
              className="form-control"
              name="companyName"
              required
              onChange={onChange}
              value={simplifiedCompanyName}
              disabled={isMissionClosed}
              placeholder={t('mission.conclusion.ren.companyName')}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default inject('MissionStore', 'CartStore')(observer(Simplified))
