import request from 'services/httpRequest'

export const fetchInsurers = customerId => {
  return request({
    method: 'get',
    url: `/networks/v1/membership/${customerId}`,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
