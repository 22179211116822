import React from 'react'

import './FallBackThumbnail.css'
import ItemIcon from './ItemIcon'

const FallBackToDownload = ({ url, mimeType }) => {
  return (
    <a href={url} className="fallback-thumbnail download">
      <ItemIcon mimeType={mimeType} />
    </a>
  )
}

export default FallBackToDownload
