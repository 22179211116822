import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { noop } from 'utils'
import TextArea from 'components/Forms/TextArea'
import Button from 'components/Button/Button'
import Attachments from './Attachments'

function Form({
  MessagingStore: {
    setProperty,
    currentMessage: { text, setProperty: setMessage, attachments, deleteDocument },
  },
  transfer,
  UserStore: { hasContract },
}) {
  const { t } = useTranslation()

  return (
    <div>
      <div>
        <div>
          {transfer && (
            <TextArea
              name="text"
              className="form-control text-area"
              placeholder={t('messaging.message.contentPV')}
              value={text}
              onChange={e => setMessage(e.target.name, e.target.value)}
              maxLength="770"
            />
          )}
          {!transfer && (
            <TextArea
              name="text"
              className="form-control text-area"
              placeholder={t('messaging.message.content')}
              value={text}
              onChange={e => setMessage(e.target.name, e.target.value)}
              maxLength="770"
            />
          )}
        </div>
        {hasContract('FWSD') && (
          <div>
            <Button
              className="btn btn-secondary mb-0 mb-lg-3 mr-2 mr-lg-0"
              onClick={() => setProperty('showSDModal', true)}
            >
              <i className="fa fa-paperclip" />
            </Button>
          </div>
        )}
      </div>

      <Attachments
        documents={attachments}
        onSelectItem={noop}
        deleteItem={deleteDocument}
        canDelete={true}
        showInfo={true}
      />
    </div>
  )
}

export default inject('MessagingStore', 'UserStore')(observer(Form))
