import React from 'react'
import { test } from 'ramda'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const formatPrice = priceString => {
  if (priceString === '') return '0'

  const uniformString = priceString.replace(/,|\./g, ',')
  const arrayString = uniformString.split(',')
  const joinString = arrayString.join('')

  if (joinString === '' || joinString === '0') return '0'

  if (arrayString.length === 1) return joinString
  else if (arrayString[arrayString.length] === '') return joinString

  const decimal = arrayString.pop()

  return arrayString.join('') + ',' + decimal
}

export default class InputArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      areaString: formatPrice(this.props.value.toString()),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ areaString: formatPrice(nextProps.value.toString()) })
    }
  }

  onChange = e => {
    // Block if there is maxLengthDecimal number after the decimal
    if (test(/,/g, e.target.value) && e.target.value.split(',').pop().length > 2) return
    else if (test(/\./g, e.target.value) && e.target.value.split('.').pop().length > 2) return

    // Block if there is more than maxLengthNumber number
    if (!test(/,|\./g, e.target.value) && e.target.value.length > 4) return

    // Block if there is more than maxLengthNumber number before the decimal
    if (test(/,/g, e.target.value) && e.target.value.split(',').shift().length > 4) return
    else if (test(/\./g, e.target.value) && e.target.value.split('.').shift().length > 4) return

    this.setState({ areaString: e.target.value })
  }
  onBlur = () => {
    if (this.state.areaString === '') {
      this.setState({ areaString: formatPrice(this.props.value.toString()) })
      return
    }
    const formattedAreaString = formatPrice(this.state.areaString)

    if (formattedAreaString === '') {
      this.props.onChange({ target: { name: this.props.name, value: 0 } })
    } else {
      this.props.onChange({
        target: { name: this.props.name, value: parseFloat(formattedAreaString.replace(',', '.')) },
      })
    }
  }
  onFocus = () => {
    this.setState({ areaString: '' })
  }
  onKeyPress = e => {
    // Allow modification if input value is selected and the max length is reached
    if (e.target.selectionStart !== e.target.selectionEnd) return

    // Block if it is at maximum length
    // 4 number + 2 decimal + 1 coma
    const totalMaxLength = 4 + 2 + 1
    if (e.target.value.length > totalMaxLength) {
      e.preventDefault()
      return
    }

    if (e.target.value.length === totalMaxLength) {
      e.preventDefault()
      return
    }
    // Block , or . if there is already a , or .
    if (test(/,|\./g, e.target.value) && (e.charCode === 44 || e.charCode === 46)) {
      e.preventDefault()
      return
    }

    if (e.charCode === 44 || e.charCode === 46) return
    else if (e.charCode >= 48 && e.charCode <= 57) return
    else e.preventDefault()
  }

  render() {
    const { areaString } = this.state
    const { name, disabled, className } = this.props

    return (
      <input
        name={name}
        className={classNames(className, 'input text-right')}
        value={areaString}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyPress={this.onKeyPress}
        disabled={disabled}
      />
    )
  }
}

InputArea.defaultProps = {
  onChange: () => {},
  disabled: false,
}

InputArea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}
