import { observable, action, decorate } from 'mobx'
import { path } from 'ramda'

import MissionStore from 'stores/Mission/domain/MissionStore'
import Package from 'stores/Mission/domain/Package'

class CreatePackageCtrl {
  packageData = null
  context = 'new'
  showAreaCalculator = false
  showObsolescenceCalculator = false

  createPackage(roomType, packageType, overrideLabel, location) {
    roomType = path(['key'], roomType) === 'noFilter' ? {} : roomType
    const data = {
      cde: null,
      description: '',
      label: '',
      lifetime: 50,
      unit: null,
      location,
      involvedParty: MissionStore.isIRSI ? MissionStore.selectedIRSICart : null,
    }

    this.packageData = new Package({
      data,
      fromList: 'listSearch',
      packageType,
      roomType,
      priceType: 'custom',
    })

    if (overrideLabel) {
      this.packageData.setRoomTypeLabel(overrideLabel)
    }

    this.context = 'new'
  }

  setPackage(packageData) {
    this.packageData = packageData
    this.context = 'edit'
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedCreatePackageCtrl = decorate(CreatePackageCtrl, {
  packageData: observable,
  context: observable,
  showAreaCalculator: observable,
  showObsolescenceCalculator: observable,

  createPackage: action,
  setPackage: action,
  setProperty: action,
})

export default new DecoratedCreatePackageCtrl()
