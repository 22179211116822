import React from 'react'
import { inject, observer } from 'mobx-react'

import SettlementAspTable from './SettlementAspTable'
import SettlementStandardTable from './SettlementStandardTable'

const Settlements = ({ CartStore: { asp } }) => {
  if (asp) return <SettlementAspTable />

  return <SettlementStandardTable />
}

export default inject('CartStore')(observer(Settlements))
