import React from 'react'
import { roundDecimal } from 'utils/currency'
import Tooltip from 'components/Tooltip/Tooltip'
import { getWall, computeWallArea } from 'utils/easyEstimation'

const toolTipTextForWall = (percentageDammaged, area, t) => {
  const numberWall = getWall(percentageDammaged)
  const finalArea = computeWallArea(percentageDammaged, area)

  return numberWall > 1
    ? `${numberWall} ${t('mission.easyEstimation.unitDetail.walls')} - ${roundDecimal(
        finalArea,
      )} m2`
    : `${numberWall} ${t('mission.easyEstimation.unitDetail.wall')} - ${roundDecimal(finalArea)} m2`
}

export default ({ part, unitByDefault, area, quantity, percentageDammaged, label, t }) => {
  if (part === 'C' || part === 'F') {
    return (
      <span>
        {t('mission.easyEstimation.unitDetail.room')} {roundDecimal(area)} m<sup>2</sup> -{' '}
        {percentageDammaged}% {t('mission.easyEstimation.unitDetail.damaged')} - {label}
      </span>
    )
  } else if (part === 'W') {
    return (
      <Tooltip text={toolTipTextForWall(percentageDammaged, area, t)} placement="top">
        <span style={{ cursor: 'help' }}>
          {t('mission.easyEstimation.unitDetail.room')} {roundDecimal(area)} m<sup>2</sup> -{' '}
          {percentageDammaged}% {t('mission.easyEstimation.unitDetail.damaged')} - {label}
        </span>
      </Tooltip>
    )
  } else if (unitByDefault.length > 0) {
    return (
      <span>
        {quantity} {unitByDefault} - {percentageDammaged}%{' '}
        {t('mission.easyEstimation.unitDetail.damaged')} - {label}
      </span>
    )
  }

  return (
    <span>
      {quantity} {t('mission.easyEstimation.unitDetail.unit')}
    </span>
  )
}
