import React from 'react'
import classNames from 'classnames'

class Label extends React.Component {
  preventQuestionMarkBreaking() {
    let { label } = this.props
    if (label.lastIndexOf('?') === label.length - 1) {
      return label.replace(/\s\?/, `${String.fromCharCode(160)}?`)
    }
    return label
  }

  render() {
    const { className, required, htmlFor, children } = this.props

    return (
      <label className={classNames(className, { required })} htmlFor={htmlFor}>
        {this.preventQuestionMarkBreaking()}{' '}
        {required && <span className="text-danger font-weight-bold">*</span>}
        {children}
      </label>
    )
  }
}

export default Label
