import { observable, computed, action, decorate } from 'mobx'

import CartStore from 'stores/Mission/domain/CartStore'

class Supply {
  overridePrice = null
  overrode = false

  calculatedPrice = 0
  cde = null
  consumption = ''
  dimensions = ''
  label = ''
  material = ''
  price = ''
  unit = ''
  type = 'supply'

  get calculatedPriceWithMargin() {
    const { configuration } = CartStore
    let total = this.calculatedPrice

    if (configuration && configuration.supplyMargin)
      total = this.calculatedPrice * parseFloat(configuration.supplyMargin)

    return total
  }

  get asJson() {
    return {
      calculatedPrice: this.calculatedPrice,
      cde: this.cde,
      dimensions: this.dimensions,
      label: this.label,
      material: this.material,
      price: this.price,
      unit: this.unit,
      type: this.type,
      overridePrice: this.overridePrice,
      overrode: this.overrode,
    }
  }

  constructor(data) {
    Object.assign(this, data)
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedSupply = decorate(Supply, {
  overridePrice: observable,
  overrode: observable,

  setProperty: action,

  calculatedPriceWithMargin: computed,
  asJson: computed,
})

export default DecoratedSupply
