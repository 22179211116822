import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'

class SearchInput extends React.Component {
  onChange = e => DashboardFilterStore.setProperty('search', e.target.value)
  // Keep old behavior in case - REMOVE AFTER VALIDATION
  // onSubmit = () => DashboardFilterStore.submitSearch()
  // onInputKeyPress = e => {
  //   if (e.key === 'Enter') DashboardFilterStore.submitSearch()
  // }

  render() {
    const { t } = this.props
    const { search } = DashboardFilterStore

    return (
      <div className="input-group">
        <input
          type="text"
          name="search-dashboard"
          className="form-control"
          placeholder={t('common.search')}
          value={search}
          onChange={this.onChange}
          maxLength="64"
          autoComplete="off"
        />
        {/* <div className="input-group-append">
          <button className="btn btn-success" name="search-filter" onClick={this.onSubmit}>
            <i className="fa fa-search" />
          </button>
        </div> */}
      </div>
    )
  }
}
// onKeyPress={this.onInputKeyPress}

export default withTranslation()(observer(SearchInput))
