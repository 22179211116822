import React from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'

const DisplayView = () => {
  const changeDisplay = () => PartyInvolvedCtrl.setDisplayList(!PartyInvolvedCtrl.displayList)

  return (
    <div className="display-type d-inline-block">
      <i
        className={classNames('fa fa-th', { active: !PartyInvolvedCtrl.displayList })}
        onClick={changeDisplay}
      />

      <i
        className={classNames('fa fa-bars', { active: PartyInvolvedCtrl.displayList })}
        onClick={changeDisplay}
      />
    </div>
  )
}

export default inject('UiCtrl')(observer(DisplayView))
