import React from 'react'

export default function({ col1, col2, col3 }) {
  return (
    <div className="row mb-2 align-items-center">
      <div className="col-md-12 col-lg-6">
        <div className="row align-items-center">
          <div className="col-6">
            <span className="font-weight-bold">{col1}</span>
          </div>

          <div className="col-6">{col2}</div>
        </div>
      </div>

      <div className="col-md-12 col-lg-6 mt-2 mt-lg-0">{col3}</div>
    </div>
  )
}
