import React from 'react'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import CardLayout from 'components/CardLayout/CardLayout'
import Event from './Event'
import useKonamiCode from 'services/useKonamiCode'
import Button from 'components/Button/Button'
import CancelModal from './CancelModal'

function Events({
  UiCtrl: { isMobile },
  LastBillCtrl: {
    events,
    loadingLastBillLink,
    goToLastBill,
    loadJobEvents,
    currentJob,
    setProperty,
  },
  t,
}) {
  const isKonamicode = useKonamiCode()
  const { statusId } = currentJob

  return (
    <CardLayout className="events">
      <div className="row mb-3 font-weight-bold">
        <div className="col-12">
          {t('lastbill.eventTitle')}{' '}
          <button className="btn btn-primary" onClick={loadJobEvents}>
            <i className="fa fa-refresh"></i>
          </button>
          {statusId !== 'terminated' && statusId !== 'canceled' && (
            <Button
              className="btn btn-danger ml-4"
              onClick={() => setProperty('showCancelModal', true)}
            >
              {t('lastbill.cancelTitle')}
            </Button>
          )}
          {isKonamicode && (
            <Button
              className="btn btn-danger ml-4"
              onClick={() => goToLastBill()}
              loading={loadingLastBillLink}
            >
              {t('lastbill.link')}
            </Button>
          )}
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead>
            <tr className="bg-primary text-light">
              {!isMobile && <th>{t('events.user')}</th>}
              {!isMobile && <th>{t('events.level')}</th>}
              {!isMobile && <th>{t('events.category')}</th>}
              <th>{t('events.action')}</th>
              <th>{t('events.date')}</th>
            </tr>
          </thead>
          <tbody>
            {events.length > 0 && events.map(event => <Event key={event.id} data={event} />)}
            {events.length === 0 && (
              <tr>
                <td colSpan="6" className="text-center">
                  {t('events.none')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <CancelModal />
    </CardLayout>
  )
}

export default compose(
  inject('LastBillCtrl', 'UiCtrl'),
  withTranslation(),
  observer,
)(Events)
