import React from 'react'
import classNames from 'classnames'

import './FallBackThumbnail.css'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import ItemIcon from './ItemIcon'

const FallBackThumbnail = ({ className, sliderIndex, mimeType }) => {
  if (sliderIndex >= 0) {
    return (
      <div
        className={classNames('fallback-thumbnail slider-launch', className)}
        onClick={() => {
          SupportingDocumentsCtrl.setSliderModalDisplay(true)
          SupportingDocumentsCtrl.setSelectedSlide(sliderIndex)
        }}
      >
        <ItemIcon mimeType={mimeType} />
      </div>
    )
  }

  return (
    <div className={classNames('fallback-thumbnail', className)}>
      <ItemIcon mimeType={mimeType} />
    </div>
  )
}

export default FallBackThumbnail
