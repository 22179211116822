import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { path } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'

const WorkRequest = ({
  MissionStore: { cfa },
  WorkRequestCtrl: { loading, form, save, loadData },
}) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null

  useEffect(() => {
    loadData(id, cfa)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  const nextStep = () => history.push(`/mission/${id}/report`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="dynform-work-request">
        <Form formObj={form} nextStep={nextStep} focus={focus} saveService={save.bind(this, id)} />
      </div>
    </CardLayout>
  )
}

export default inject('WorkRequestCtrl', 'MissionStore')(observer(WorkRequest))
