import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import InputPrice from 'components/InputPrice/InputPrice'
import TotalContractSettlement from './TotalContractSettlement'
import { showInput } from 'utils/settlement'

const SettlementRow = ({
  t,
  CartStore: { setProperty },
  settlement: { name, immediateSettlement, defferedSettlement, totalContractSettlement, override },
}) => {
  const onChange = e => {
    setProperty(e.target.name, e.target.value === '' ? 0 : parseFloat(e.target.value))
  }

  return (
    <tr>
      <th scope="row">{t(`mission.conclusion.data.${name}`)}</th>
      <td className={classNames({ 'text-center': !showInput(name, 'immediateSettlement') })}>
        {showInput(name, 'immediateSettlement') ? (
          <InputPrice
            name={`${name}Override.immediateSettlement`}
            price={
              override.immediateSettlement === null
                ? immediateSettlement
                : override.immediateSettlement
            }
            onChange={onChange}
            width={80}
          />
        ) : (
          '-'
        )}
      </td>

      <td className={classNames({ 'text-center': !showInput(name, 'defferedSettlement') })}>
        {showInput(name, 'defferedSettlement') ? (
          <InputPrice
            name={`${name}Override.defferedSettlement`}
            price={
              override.defferedSettlement === null
                ? defferedSettlement
                : override.defferedSettlement
            }
            onChange={onChange}
            width={80}
          />
        ) : (
          '-'
        )}
      </td>

      <TotalContractSettlement rowName={name} total={totalContractSettlement} />

      <td>
        <InputPrice
          name={`${name}Override.settlementPresented`}
          price={override.settlementPresented || 0}
          onChange={onChange}
          width={80}
        />
      </td>

      <td>
        <InputPrice
          name={`${name}Override.totalPresented`}
          price={override.totalPresented || 0}
          onChange={onChange}
          width={80}
        />
      </td>
    </tr>
  )
}

export default compose(
  inject('CartStore'),
  withTranslation(),
  observer,
)(SettlementRow)
