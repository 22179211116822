import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import Loader from 'components/Loader/Loader'

function ExpertSummary({
  t,
  history,
  closeModal,
  ClaimStore: {
    claim: { wan },
  },
  CreateMissionCtrl: { confirmId, resetConfirmationModal },
}) {
  if (confirmId === null)
    return (
      <div className="modal-body">
        <Loader />
      </div>
    )

  return (
    <React.Fragment>
      <div className="modal-body">
        <div className="text-center">
          <p className="text-primary font-weight-bold">
            {t('claim.planner.confirmExpert.confirmed')}
          </p>
          <p className="text-primary font-weight-bold">
            {t('claim.planner.confirmExpert.claimCreated', { id: confirmId })}
          </p>
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button
          className="btn btn-secondary"
          onClick={() => {
            resetConfirmationModal()
            closeModal()
            history.push(`/claim/${wan}/currentMission/${confirmId}`)
          }}
        >
          {t('common.close')}
        </button>
      </div>
    </React.Fragment>
  )
}

export default compose(
  inject('ClaimStore', 'CreateMissionCtrl'),
  withTranslation(),
  withRouter,
  observer,
)(ExpertSummary)
