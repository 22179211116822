import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import InputPrice from 'components/InputPrice/InputPrice'
import { listDeposit } from 'utils/deposit'
import { euro } from 'utils/currency'

const Total = ({ total, maxTotal }) => {
  const notEqual = total !== maxTotal

  return (
    <td>
      <div className={classNames('text-nowrap', { 'text-danger font-weight-bold': notEqual })}>
        {notEqual && <i className="fa fa-warning mr-1" />} {euro(total)}
      </div>

      <small>({euro(maxTotal)})</small>
    </td>
  )
}

const RepairPrecisions = ({
  t,
  CartStore,
  CartStore: {
    setProperty,
    totalPrecisionsRow,
    totalPrecisionsColumn,
    precisionsMaxTotals,
    workAmount,
  },
  MissionStore: { suffixVATLabel, deductibleGuarantee },
}) => {
  const onChange = e => {
    setProperty(e.target.name, e.target.value === '' ? 0 : parseFloat(e.target.value))
  }

  return (
    <React.Fragment>
      <div className="p-4 border d-block d-md-none">{t('mission.conclusion.tableResponsive')}</div>

      <table className="table table-bordered d-none d-md-table">
        <tbody>
          <tr>
            <th>{t('mission.conclusion.title.repairPrecisions')}</th>
            <th className="text-center">
              {t(`mission.conclusion.computation.deposit${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.defferedInvoice${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.defferedResort${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.total${suffixVATLabel}`)}
            </th>
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.data.works1')}</th>
            <td className="text-center">
              {workAmount > 0 ? t('mission.conclusion.data.requestIMH') : '-'}
            </td>
            <td>
              <InputPrice
                name="works1.defferedInvoice"
                price={CartStore.works1.defferedInvoice}
                onChange={onChange}
                width={90}
              />
            </td>
            <td>
              <InputPrice
                name="works1.defferedResort"
                price={CartStore.works1.defferedResort}
                onChange={onChange}
                width={90}
              />
            </td>
            <Total total={totalPrecisionsRow.works1} maxTotal={precisionsMaxTotals.works1} />
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.data.works2')}</th>
            <td>
              <InputPrice
                name="works2.deposit"
                price={CartStore.works2.deposit}
                onChange={onChange}
                width={90}
              />
            </td>
            <td className="text-center">-</td>
            <td>
              <InputPrice
                name="works2.defferedResort"
                price={CartStore.works2.defferedResort}
                onChange={onChange}
                width={90}
              />
            </td>
            <Total total={totalPrecisionsRow.works2} maxTotal={precisionsMaxTotals.works2} />
          </tr>

          {listDeposit.map(type => (
            <tr key={`deposit-type-${type}`}>
              <th scope="row">{t(`mission.conclusion.data.${type}`)}</th>
              <td>
                <InputPrice
                  name={`${type}.deposit`}
                  price={CartStore[type].deposit}
                  onChange={onChange}
                  width={90}
                />
              </td>
              <td>
                <InputPrice
                  name={`${type}.defferedInvoice`}
                  price={CartStore[type].defferedInvoice}
                  onChange={onChange}
                  width={90}
                />
              </td>
              <td>
                <InputPrice
                  name={`${type}.defferedResort`}
                  price={CartStore[type].defferedResort}
                  onChange={onChange}
                  width={90}
                />
              </td>
              <Total total={totalPrecisionsRow[type]} maxTotal={precisionsMaxTotals[type]} />
            </tr>
          ))}

          <tr>
            <th scope="row">{t('mission.conclusion.data.deductible')}</th>
            <td>
              <InputPrice
                name="precisionsDeductible.deposit"
                className={classNames({
                  'text-danger': CartStore.precisionsDeductible.deposit !== deductibleGuarantee,
                })}
                price={CartStore.precisionsDeductible.deposit}
                onChange={onChange}
                width={90}
              />
            </td>
            <td className="text-center">-</td>
            <td>
              <InputPrice
                name="precisionsDeductible.defferedResort"
                className={classNames({
                  'text-danger':
                    CartStore.precisionsDeductible.defferedResort !== deductibleGuarantee,
                })}
                price={CartStore.precisionsDeductible.defferedResort}
                onChange={onChange}
                width={90}
              />
            </td>
            <td className="text-center">-</td>
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.computation.total')}</th>
            <td>{euro(totalPrecisionsColumn.deposit)}</td>
            <td>{euro(totalPrecisionsColumn.defferedInvoice)}</td>
            <td>{euro(totalPrecisionsColumn.defferedResort)}</td>
            <td>{euro(totalPrecisionsColumn.totals)}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default compose(
  inject('CartStore', 'MissionStore'),
  withTranslation(),
  observer,
)(RepairPrecisions)
