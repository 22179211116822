import { observable, computed, action, decorate } from 'mobx'
import { path } from 'ramda'
import { format } from 'date-fns'
import i18next from 'i18next'

import UserStore from 'stores/Common/domain/UserStore'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'

class Message {
  id = null
  wanSource = null
  wanDest = null
  text = ''
  senderUser = null
  receiverUser = null
  customerIdDest = null
  readAt = null
  documents = []
  selectingDocuments = []
  createdAt = null
  markingAsRead = false
  type = null
  savingAttachments = false
  wanType = null
  isUrgent = false
  context = ''
  senderSecondName = null
  trackingType = null

  constructor(data) {
    if (data) {
      Object.assign(this, data)

      const senderUserId = path(['senderUser', 'id'], data)
      if (senderUserId) {
        this.senderUser = {
          id: senderUserId,
          firstName: path(['senderUser', 'firstName'], data) || '',
          lastName: path(['senderUser', 'lastName'], data) || '',
        }
      } else this.senderUser = null

      const receiverUserId = path(['receiverUser', 'id'], data)
      if (receiverUserId) {
        this.receiverUser = {
          id: receiverUserId,
          firstName: path(['receiverUser', 'firstName'], data) || '',
          lastName: path(['receiverUser', 'lastName'], data) || '',
        }
      } else this.receiverUser = null
    }
  }

  get asJson() {
    return {
      text: this.text,
      isUrgent: this.isUrgent,
      documents: this.documents,
    }
  }

  get isSelf() {
    let isSelf = path(['id'], this.senderUser) === UserStore.id

    if (
      path(['id'], this.senderUser) === UserStore.id &&
      UserStore.hasDoubleRoleExpertAndInsurer &&
      UserStore.isExpert &&
      this.context === 'sendToExpert'
    ) {
      isSelf = false
    }
    if (
      path(['id'], this.senderUser) === UserStore.id &&
      UserStore.hasDoubleRoleExpertAndInsurer &&
      UserStore.isManager &&
      this.context === 'sendToPole'
    ) {
      isSelf = false
    }
    return isSelf
  }

  get canMarkAsRead() {
    if (
      this.senderUser === null &&
      this.receiverUser !== null &&
      this.receiverUser.id === UserStore.id
    ) {
      return true
    } else if (this.senderUser !== null && this.receiverUser === null) {
      return true
    } else if (this.senderUser && this.receiverUser && this.receiverUser.id === UserStore.id) {
      return true
    } else if (this.senderUser === null && this.type === 'DARVA') {
      return true
    }
    return false
  }

  get senderFullName() {
    if (!this.senderUser) return null
    return `${this.senderUser.firstName} ${this.senderUser.lastName}`
  }

  get receiverFullName() {
    if (!this.receiverUser) return null
    return `${this.receiverUser.firstName} ${this.receiverUser.lastName}`
  }

  get from() {
    let sender = i18next.t('messaging.message.darva')
    if (this.senderFullName) sender = this.senderFullName
    if (this.senderSecondName) sender = this.senderSecondName

    return `${i18next.t('messaging.message.sender', { sender })}`
  }

  get at() {
    if (this.type === 'DARVA' && this.senderUser !== null) {
      return `${i18next.t('messaging.message.receiver', {
        receiver: i18next.t('messaging.message.darva'),
      })}`
    } else if (this.receiverFullName === null && this.type === 'DARVA') {
      return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.pole')}`
    } else if (this.receiverFullName === null) {
      if (this.context === 'sendToManager') {
        return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.manager')}`
      }
      if (this.context === 'sendToPole') {
        return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.pole')}`
      } else {
        return `${i18next.t('messaging.message.at')} ${i18next.t('messaging.secretary')}`
      }
    } else if (this.receiverFullName === null) {
      return null
    }
    return `${i18next.t('messaging.message.receiver', { receiver: this.receiverFullName })}`
  }

  get formatCreatedDate() {
    const date = new Date(this.createdAt)
    return `${format(date, 'dd/MM/y')} ${i18next.t('common.at')} ${format(date, 'k:mm')}`
  }

  get formatReadAt() {
    if (this.readAt) {
      const date = new Date(this.readAt)
      return `${format(date, 'dd/MM/y')} ${i18next.t('common.at')} ${format(date, 'k:mm')}`
    }
    return null
  }

  get attachments() {
    return SupportingDocumentStore.supportingDocuments.filter(
      sd => this.documents.indexOf(sd.id) > -1,
    )
  }

  setMessage(data) {
    Object.assign(this, data)
  }

  selectDocument(documentId) {
    if (this.selectingDocuments.indexOf(documentId) === -1) this.selectingDocuments.push(documentId)
    else
      this.selectingDocuments = this.selectingDocuments.filter(document => document !== documentId)
  }

  deleteDocument(documentId) {
    this.documents = this.documents.filter(document => document !== documentId)
  }

  setProperty(key, value) {
    this[key] = value
  }
}

export default decorate(Message, {
  text: observable,
  readAt: observable,
  documents: observable,
  selectingDocuments: observable,
  markingAsRead: observable,
  savingAttachments: observable,
  isUrgent: observable,
  senderSecondName: observable,
  trackingType: observable,

  isSelf: computed,
  senderFullName: computed,
  asJson: computed,
  receiverFullName: computed,
  attachments: computed,
  from: computed,
  at: computed,
  canMarkAsRead: computed,

  setProperty: action.bound,
  setMessage: action.bound,
})
