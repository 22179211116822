import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import Button from 'components/Button/Button'

function ReadAt({
  t,
  isSelf,
  formatReadAt,
  markAsRead,
  markingAsRead,
  type,
  from,
  canMarkAsRead,
  senderUser,
}) {
  const badinterTypes = ['BDNT_PRV', 'BDNT_EXP', 'BDNT_PRV_DIS', 'BDNT_EXP_DIS']
  let content = null

  if (
    formatReadAt &&
    (type !== 'DARVA' ||
      (type === 'DARVA' && senderUser == null) ||
      badinterTypes.includes(type))
  ) {
    // Message Read
    content = `${t('messaging.message.readAt')} ${formatReadAt}`
  } else if (
    !isSelf &&
    !formatReadAt &&
    canMarkAsRead &&
    !badinterTypes.includes(type)
  ) {
    // Message Unread, The user can mark the message as Read
    content = (
      <Button className="btn btn-link p-0" loading={markingAsRead} onClick={() => markAsRead()}>
        {t('messaging.message.markAsRead')}
      </Button>
    )
  } 
  else if (type === 'SH') {
    // Message Unread, the user can't mark the message as Read
    content = `${t('messaging.message.notRead')}`
  }

  return <div className="info">{content}</div>
}

export default compose(withTranslation())(ReadAt)
