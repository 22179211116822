import { observable, action, decorate, runInAction } from 'mobx'

import { fetchMissionVehicleDamageDescForm } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import { saveFormVehicleDamageDescription } from 'services/missionForm'
import MissionStore from 'stores/Mission/domain/MissionStore'

class VehicleDamageDescriptionCtrl {
  loading = true
  form = null

  loadData = async (wan, VehicleDamageDescriptionData) => {
    this.loading = true
    try {
      const form = await fetchMissionVehicleDamageDescForm(wan)
      form.name = 'VehicleDamageDescription'
      runInAction(() => {
        this.form = new Form({ form, data: VehicleDamageDescriptionData })
      })
    } catch (err) {
      console.log('err', err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    try {
      const res = await saveFormVehicleDamageDescription(wan, formData)
      MissionStore.updateVehicleDamageDescription(res.data.attributes.vehicleDamageDescription)
    } catch (error) {
      console.error(error)
    }
  }
}

const DecoratedVehicleDamageDescriptionCtrl = decorate(VehicleDamageDescriptionCtrl, {
  loading: observable,
  form: observable,

  loadData: action,
})
export default new DecoratedVehicleDamageDescriptionCtrl()
