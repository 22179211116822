import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import { isImage } from 'stores/Common/view/SupportingDocumentsCtrl'

const AlertImage = ({ t, sdItem }) => {
  if (!isImage(sdItem.mimeType)) return null

  return (
    <div>
      {sdItem.visionResults && sdItem.visionResults.pagesWithMatchingImages ? (
        <p className="sd-alert">
          <i className="fa fa-exclamation-triangle" />{' '}
          {t('mission.supportingDocuments.ai.fraudAlertTitle')}
        </p>
      ) : null}
      {sdItem.visionAlerts && sdItem.visionAlerts.includes('DISTANCE ALERT') ? (
        <p className="sd-alert">
          <i className="fa fa-exclamation-triangle" />{' '}
          {t('mission.supportingDocuments.ai.distanceAlertTitle')}
        </p>
      ) : null}
      {sdItem.visionResults &&
      sdItem.visionResults.safeSearchAnnotation &&
      (sdItem.visionResults.safeSearchAnnotation.adult === 'VERY_LIKELY' ||
        sdItem.visionResults.safeSearchAnnotation.adult === 'LIKELY') ? (
        <p className="sd-alert">
          <i className="fa fa-thermometer-empty" />{' '}
          {t('mission.supportingDocuments.ai.adultAlertTitle')}
        </p>
      ) : null}
      {sdItem.visionResults && sdItem.visionResults.faceAnnotations ? (
        <p className="sd-alert">
          <i className="fa fa-info-circle " />{' '}
          {t('mission.supportingDocuments.ai.faceCount', {
            count: sdItem.visionResults.faceAnnotations.nb,
          })}
        </p>
      ) : null}
    </div>
  )
}

export default compose(
  withTranslation(),
  observer,
)(AlertImage)
