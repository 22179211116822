import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader/Loader'
import IncomingMessage from './IncomingMessage'
import Pagination from 'components/Pagination'

const List = ({
  InboxStore: { loading, messages, totalPages, currentPage, goToPage },
  UiCtrl: { isMobile },
}) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column align-items-center table-responsive">
      <table className="table table-bordered table-hover">
        <thead>
          <tr className="bg-primary text-light">
            <th>{t('messaging.inbox.insurerClaimId')}</th>
            {!isMobile && <th>{t('messaging.inbox.insuredName')}</th>}
            {!isMobile && <th>{t('messaging.inbox.assigneeUserName')}</th>}
            {!isMobile && <th>{t('messaging.inbox.sender')}</th>}
            {!isMobile && <th>{t('messaging.inbox.sendAt')}</th>}
            {!isMobile && <th>{t('messaging.inbox.wan')}</th>}
            <th>{t('messaging.inbox.message')}</th>
            {!isMobile && <th>{t('messaging.inbox.attachment')}</th>}
          </tr>
        </thead>
        <tbody>
          {!messages.length || loading ? (
            <LoadOrEmpty {...{ loading, t }} />
          ) : (
            messages.map(message => <IncomingMessage key={message.id} {...message} />)
          )}
        </tbody>
      </table>

      <Pagination {...{ totalPages, goToPage, page: currentPage }} />
    </div>
  )
}

const LoadOrEmpty = ({ loading, t }) => {
  return (
    <tr>
      <td colSpan="8" className="text-center">
        {loading ? <Loader /> : t('messaging.inbox.empty')}
      </td>
    </tr>
  )
}
export default inject('InboxStore', 'UiCtrl')(observer(List))
