import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import Item from './Item'
import Loader from 'components/Loader/Loader'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'

const List = ({
  t,
  UserStore: { isExpert },
  MissionStore: { retrieveReportButtonAvailable, id },
  ReportCtrl: { retrieveDarva, retrievingDarva },
}) => {
  if (SupportingDocumentsCtrl.loading) {
    return (
      <div className="list row">
        <Loader />
      </div>
    )
  }

  let emptyListText = ''
  switch (SupportingDocumentsCtrl.filter) {
    case SupportingDocTypes.PHOTO:
      emptyListText = `${t('mission.supportingDocuments.emptyList', {
        document: t('mission.supportingDocuments.photos'),
      })} ${t('mission.supportingDocuments.addToDropZone')}`

      break
    case SupportingDocTypes.DOCUMENT:
      emptyListText = `${t('mission.supportingDocuments.emptyList', {
        document: t('mission.supportingDocuments.documents'),
      })} ${t('mission.supportingDocuments.addToDropZone')}`
      break
    case SupportingDocTypes.INVOICE:
      emptyListText = t('mission.supportingDocuments.emptyList', {
        document: t('mission.supportingDocuments.invoices'),
      })
      break
    case SupportingDocTypes.SCHEMA:
      emptyListText = t('mission.supportingDocuments.emptyList', {
        document: t('mission.supportingDocuments.schemas'),
      })
      break
    default:
      emptyListText = t('mission.supportingDocuments.emptyList', {
        document: t('mission.supportingDocuments.documents'),
      })
      break
  }

  return (
    <div className="list row">
      {SupportingDocumentStore.sdFiltered.length > 0 ? (
        SupportingDocumentStore.sdFiltered.map((sd, index) => (
          <Item key={sd.id} itemIndex={index} sdItem={sd} />
        ))
      ) : (
        <div className="col-12 empty-sd-list mb-3">{emptyListText}</div>
      )}

      {/* RETRIEVE DARVA REPORT */}
      {SupportingDocumentsCtrl.filter === SupportingDocTypes.DOCUMENT &&
        isExpert &&
        retrieveReportButtonAvailable && (
          <div className="col-12 d-flex justify-content-center">
            <ButtonWithConfirmation
              className="btn btn-primary mr-2"
              onClick={() => retrieveDarva(id)}
              text={t('mission.report.retrieveDarva')}
              confirmText={t('mission.report.confirmRetrieveDarva')}
              disabled={retrievingDarva}
              loading={retrievingDarva}
            />
          </div>
        )}
    </div>
  )
}

export default compose(
  inject('UserStore', 'MissionStore', 'ReportCtrl'),
  withTranslation(),
  observer,
)(List)
