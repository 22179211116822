import { observable, action, decorate, runInAction } from 'mobx'
import { fetchCustomerConfig, fetchCustomerConfigKey } from 'services/customerConfig'
import { toJS } from 'mobx'

class CustomerConfigStore {
  id = null
  customerId = ''
  config = []

  //Single configuration
  key = ''
  value = ''
  type = ''

  loading = true

  constructor(data) {
    if (data) {
      this.id = data.id
      this.customerId = data.customerId
      this.config = data.config
    } else {
      this.loading = false
    }
  }

  /**
   * Calls the api to fetch all the configuration data from the given customer id
   * @param {string} customerId
   */
  async loadCustomerConfigData(customerId) {
    this.loading = true

    try {
      const res = await fetchCustomerConfig(customerId)
      runInAction(() => {
        this.loading = false
        this.customerId = customerId
        this.config = res.data
        this.loading = false
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.loading = false
      })
    }
  }

  /**
   * Calls the api endpoint to fetch a single configuration data from the given customer id
   * @param {string} customerId
   * @param {string} key
   */
  async loadCustomerConfigKey(customerId, key) {
    this.loading = true
    try {
      const res = await fetchCustomerConfigKey(customerId, key)
      runInAction(() => {
        this.loading = false
        this.customerId = customerId

        this.key = key
        this.value = res.data.value
        this.type = res.data.type
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getConfig = key => {
    //Single previous load call was made
    if (this.key === key) {
      return {
        key: this.key,
        type: this.type,
        value: this.value,
      }
    }

    //Customer config load call was made
    const configItems = toJS(this.config).filter(item => item.key === key)
    if (configItems.length >= 0) {
      return configItems[0]
    }
    return configItems
  }

  getValue = key => {
    const config = this.getConfig(key)

    if (config.type === 'array') {
      return JSON.parse(config.value.replace(/'/g, '"'))
    }

    if (config.type === 'boolean') {
      return config.value === 'true'
    }

    if (config.type === 'string') {
      return config.value
    }

    if (config.type === 'json') {
      return JSON.parse(config.value)
    }
  }
}

const DecoratedCustomerConfigStore = decorate(CustomerConfigStore, {
  id: observable,
  customerId: observable,
  config: observable,

  getConfig: action.bound,
  getValue: action.bound,
  loadCustomerConfigData: action.bound,
})

export default new DecoratedCustomerConfigStore()
