import { observable, computed, action, decorate } from 'mobx'
import { equals, isNil } from 'ramda'
import { format } from 'date-fns'

import { uid, concatNames } from 'utils'
import { roundDecimal } from 'utils/currency'
import CartStore from 'stores/Mission/domain/CartStore'

const fixPrice = (value, defaultValue = null) => {
  value = parseFloat(value)
  if (isNaN(value)) {
    return defaultValue
  }

  return value
}

class Furniture {
  brand = ''
  model = ''
  dateOfPurchased = null
  modalityAcquisition = 'new'
  description = ''
  characteristics = ''
  price = 0
  priceWithVat = null
  vat = 20
  correction = 0
  obsolescenceReturn = 25
  originalData = null
  roomType = null
  category = 'MC001'
  compensation = 'default'
  supportingDocuments = []
  hasNoSupportingDocuments = false
  obsRateFoundInDatabase = true
  initPrice

  lifetime = 50
  id = uid()
  type = 'manual'
  location = 'regular'
  involvedParty = null

  get toString() {
    return concatNames(this.brand, this.description || this.model)
  }

  set correction(correction) {
    correction = parseInt(correction, 10)
    if (isNaN(correction)) correction = 0
    this.correction = correction
  }

  get validFurniture() {
    return (
      this.description && this.category !== null && (this.price > 0 || this.priceWithVat !== null)
    )
  }

  get changed() {
    return !equals(this.originalData, this.asJson)
  }

  get totalVAT() {
    if (this.priceWithVat !== null)
      return roundDecimal(this.priceWithVat - this.priceWithVat / (1 + this.vat / 100))

    return roundDecimal(this.price * (this.vat / 100))
  }

  get totalPriceVAT() {
    if (this.priceWithVat !== null) return this.priceWithVat
    return roundDecimal(this.price + this.totalVAT)
  }

  get age() {
    return 0
  }

  get obsolescence() {
    if (this.correction !== 0) return this.correction
    if (this.age === 0) return 0
    const total = (this.age / this.lifetime) * 80
    if (total >= 80) return 80
    return roundDecimal(total)
  }

  get totalObsolescence() {
    return roundDecimal((this.totalPriceVAT * this.obsolescence) / 100)
  }

  get totalObsolescenceReturn() {
    return 0
  }

  get finalPrice() {
    return roundDecimal(this.totalPriceVAT - this.totalObsolescence + this.totalObsolescenceReturn)
  }

  // new computations
  get obsWithoutVAT() {
    return roundDecimal((this.price * this.obsolescence) / 100)
  }

  get ORWithoutVAT() {
    return 0
  }

  get finalPriceWithoutVAT() {
    return roundDecimal(this.price - this.obsWithoutVAT + this.ORWithoutVAT)
  }

  get canCheckPrice() {
    return this.type === 'catalog'
  }

  toJsonHelper() {
    return {
      id: this.id,
      type: this.type,
      brand: this.brand,
      model: this.model,
      dateOfPurchased: this.dateOfPurchased ? format(this.dateOfPurchased, 'y-MM-dd') : null,
      modalityAcquisition: this.modalityAcquisition,
      description: this.description,
      characteristics: this.characteristics,
      price: this.price,
      initPrice: this.initPrice,
      vat: this.vat,
      correction: this.correction,
      obsolescenceReturn: this.obsolescenceReturn,
      location: this.location,
      roomType: this.roomType,
      category: this.category,
      compensation: this.compensation,
      hasNoSupportingDocuments: this.hasNoSupportingDocuments,
      obsRateFoundInDatabase: this.obsRateFoundInDatabase,
      involvedParty: this.involvedParty,
      totals: {
        totalObsolescence: this.totalObsolescence,
        totalWithoutVAT: this.price,
        totalVAT: this.totalVAT,
        totalWithVAT: this.totalPriceVAT,
        totalOR: this.totalObsolescenceReturn,
        finalPrice: this.finalPrice,
        totalObsWithoutVAT: this.obsWithoutVAT,
        totalORWithoutVAT: this.ORWithoutVAT,
        finalPriceWithoutVAT: this.finalPriceWithoutVAT,
      },
      supportingDocuments: this.supportingDocuments,
    }
  }

  get asJson() {
    return this.toJsonHelper()
  }

  constructor(furniture = null) {
    if (!furniture) return
    this.id = furniture.id || uid(15)
    this.description = furniture.description || ''
    this.model = furniture.model || ''
    this.brand = furniture.brand || ''
    this.characteristics = furniture.characteristics || ''
    this.modalityAcquisition = furniture.modalityAcquisition || 'new'
    this.dateOfPurchased = furniture.dateOfPurchased ? new Date(furniture.dateOfPurchased) : null
    this.price =
      furniture.price !== null && furniture.price !== undefined ? parseFloat(furniture.price) : 0
    this.initPrice = isNil(furniture.initPrice) ? furniture.price : furniture.initPrice
    this.supportingDocuments = furniture.supportingDocuments || []

    this.vat = isNil(furniture.vat) ? (CartStore.isTaxSystemHT ? 0 : 20) : furniture.vat
    this.correction = isNil(furniture.correction) ? 0 : furniture.correction
    this.obsolescenceReturn = isNil(furniture.obsolescenceReturn)
      ? 25
      : furniture.totalObsolescenceReturn
    this.location = furniture.location || 'regular'
    this.roomType = furniture.roomType || null
    this.category = furniture.category || 'MC001'
    this.compensation = furniture.compensation || 'default'
    this.involvedParty =
      furniture.involvedParty === 'notVentilated' || isNil(furniture.involvedParty)
        ? null
        : furniture.involvedParty
    this.hasNoSupportingDocuments = furniture.hasNoSupportingDocuments || false
    this.obsRateFoundInDatabase = furniture.obsRateFoundInDatabase || true
    this.originalData = this.asJson
    if (furniture.priceWithVat !== null && furniture.priceWithVat !== undefined) {
      this.price = roundDecimal(furniture.priceWithVat / (1 + this.vat / 100))
      this.priceWithVat = furniture.priceWithVat
    }
  }

  setProperty(key, value) {
    if (key === 'price') {
      value = fixPrice(value, 0)
      this.priceWithVat = roundDecimal(value * (1 + this.vat / 100))
    } else if (key === 'priceWithVat') {
      value = fixPrice(value, null)
      console.log({ value, vat: this.vat, price: roundDecimal(value / (1 + this.vat / 100)) })
      this.price = roundDecimal(value / (1 + this.vat / 100))
    } else if (key === 'vat') {
      this.priceWithVat = roundDecimal(this.price * (1 + value / 100))
    }

    if (key === 'involvedParty' && value === '') {
      value = null
    }

    if (key === 'compensation' && value === 'used') {
      this.setProperty('vat', 0)
    }

    if (key === 'modalityAcquisition' && value === 'used') {
      this.setProperty('compensation', 'used')
    } else if (key === 'modalityAcquisition' && value === 'new') {
      this.setProperty('compensation', 'default')
      this.setProperty('vat', 20)
    }

    this[key] = value
  }

  addSupportingDocument(id) {
    this.supportingDocuments.push(id)
  }

  setOriginalData() {
    this.originalData = this.asJson
  }
}

const DecoratedFurniture = decorate(Furniture, {
  brand: observable,
  model: observable,
  dateOfPurchased: observable,
  modalityAcquisition: observable,
  description: observable,
  characteristics: observable,
  price: observable,
  initPrice: observable,
  supportingDocuments: observable,
  priceWithVat: observable,
  vat: observable,
  correction: observable,
  obsolescenceReturn: observable,
  originalData: observable,
  roomType: observable,
  category: observable,
  compensation: observable,
  involvedParty: observable,
  hasNoSupportingDocuments: observable,
  obsRateFoundInDatabase: observable,

  setProperty: action,
  addSupportingDocument: action,
  setOriginalData: action,

  toString: computed,
  validFurniture: computed,
  changed: computed,
  totalVAT: computed,
  totalPriceVAT: computed,
  age: computed,
  obsolescence: computed,
  totalObsolescence: computed,
  totalObsolescenceReturn: computed,
  finalPrice: computed,
  asJson: computed,
  obsWithoutVAT: computed,
  ORWithoutVAT: computed,
  finalPriceWithoutVAT: computed,
  canCheckPrice: computed,
})

export default DecoratedFurniture
