import { observable, computed, action, decorate } from 'mobx'
import { isNil } from 'ramda'
import Furniture from 'stores/Mission/domain/Furniture'
import CommonStore from 'stores/Common/domain/CommonStore'

class FurnitureCtrl {
  listCategories = []
  selectedCategory = ''
  showCategoryModal = false
  furniture = null
  editionId = null

  get currentCategory() {
    return null
  }

  removeFromListCategories = cde => {
    this.listCategories = this.listCategories.filter(f => f.cde !== cde)
    this.selectedCategory = this.listCategories.length > 0 ? this.listCategories[0].cde : ''
  }
  createCategoryFromCart = furnitures => {
    const categories = []

    furnitures.forEach(f => {
      const cde = f.category
      const existing = categories.find(category => cde === category.cde)

      if (isNil(existing)) {
        const data = CommonStore.furnitureCategoriesOptions.find(option => option.value === cde)
        if (data)
          categories.push({
            cde: data.value,
            label: data.label,
          })
      }
    })
    this.listCategories = categories
    if (this.listCategories.length > 0) this.selectedCategory = this.listCategories[0].cde
  }

  setNewFurniture() {
    this.editionId = this.furniture = new Furniture()
    this.furniture.setProperty('category', this.selectedCategory)
    this.furniture.setProperty('vat', 0)
    this.furniture.setProperty('priceWithVat', 0)
  }

  addNewCategory = category => {
    const categoryExist = this.listCategories.find(data => data.cde === category.cde)

    if (!categoryExist) this.listCategories.push(category)

    this.selectedCategory = category.cde
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedFurnitureCtrl = decorate(FurnitureCtrl, {
  listCategories: observable,
  selectedCategory: observable,
  editionId: observable,
  showCategoryModal: observable,
  furniture: observable,

  currentCategory: computed,

  setNewFurniture: action.bound,
  removeFromListCategories: action.bound,
  createCategoryFromCart: action,
  addNewCategory: action,
  setProperty: action,
})

export default new DecoratedFurnitureCtrl()
