import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import './AlertCenter.css'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

const Alert = ({ t, type, message, directMessage = false, count = 0 }) => {
  return (
    <div className={classNames(`alert alert-${type} fade show`)} role="alert">
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => AlertCtrl.removeAlert()}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {directMessage ? message : t(message, { count })}
    </div>
  )
}

class AlertCenter extends React.Component {
  render() {
    return (
      <div className="alert-center">
        {AlertCtrl.alerts.map((alert, key) => {
          return <Alert key={`alert-${alert.type}-${key}`} {...alert} t={this.props.t} />
        })}
      </div>
    )
  }
}

export default withTranslation()(observer(AlertCenter))
