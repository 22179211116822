import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import Label from 'components/Label/Label'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'
import DatePicker from 'components/Forms/DatePicker'
import Toggle from 'components/Toggle/index'

function AppointmentRecurrence({ t, AppointmentCtrl: { currentAppointment } }) {
  const {
    isRecurrent,
    every,
    repeatType,
    setProperty,
    toggleOnDayOfWeek,
    onDaysOfWeek,
    monthRepeatOptions,
    monthSelectedOption,
    endRecurrentDate,
    setRepeatType,
    setIsRecurrent,
  } = currentAppointment
  const onChange = e => setProperty(e.target.name, e.target.value)
  const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const REPEAT_MODES = {
    D: t('appointmentReccurence.days'),
    W: t('appointmentReccurence.weeks'),
    M: t('appointmentReccurence.months'),
    Y: t('appointmentReccurence.years'),
  }

  const monthRepeatOptionsSelect = {}
  monthRepeatOptions.forEach(option => {
    let value = ''
    if (option.type === 'day') {
      value = `${t('appointmentReccurence.monthlyOnDay')} ${option.dayOfMonth}`
    } else {
      value =
        t('appointmentReccurence.monthlyOnOrdinal') +
        ' ' +
        t(`appointmentReccurence.${option.ordinal}`) +
        ' ' +
        t(`appointmentReccurence.${option.dayName}`)
    }
    monthRepeatOptionsSelect[option.key] = value
  })

  return (
    <Fragment>
      <div className="row form-group">
        <div className="col-sm-3 d-flex align-items-center">
          <Label>{t('appointmentReccurence.recurrence')}</Label>
        </div>
        <div className="col-sm-9">
          <Toggle onChange={value => setIsRecurrent(value)} checked={isRecurrent} />
        </div>
      </div>

      {isRecurrent && (
        <div className="border border-primary rounded-sm p-2 mt-2 mb-2">
          <div className="row form-group">
            <div className="col-sm-3 d-flex align-items-center">
              <Label required>{t('appointmentReccurence.repeatEvery')}</Label>
            </div>
            <div className="col-sm-3">
              <Input
                className="form-control"
                name="every"
                type="number"
                min="1"
                max="12"
                onChange={onChange}
                value={every}
              />
            </div>
            <div className="col-sm-6">
              <Select
                className="form-control"
                name="repeatType"
                options={REPEAT_MODES}
                value={repeatType}
                onChange={e => setRepeatType(e.target.value)}
              />
            </div>
          </div>

          {repeatType === 'W' && (
            <div className="row form-group">
              <div className="col-sm-3 d-flex align-items-center">
                <Label required>{t('appointmentReccurence.repeatOn')}</Label>
              </div>
              <div className="col-sm-9">
                <div className="btn btn-group pl-0">
                  {dayNames.map(dayName => (
                    <button
                      key={`button-part-${dayName}`}
                      className={classNames('btn', {
                        'btn-primary': onDaysOfWeek.indexOf(dayName) !== -1,
                        'btn-outline-primary': onDaysOfWeek.indexOf(dayName) === -1,
                      })}
                      onClick={() => toggleOnDayOfWeek(dayName)}
                    >
                      {t(`appointmentReccurence.${dayName}`)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {repeatType === 'M' && (
            <div className="row form-group">
              <div className="col-sm-3 d-flex align-items-center">
                <Label required>{t('appointmentReccurence.repeat')}</Label>
              </div>
              <div className="col-sm-9">
                <Select
                  className="form-control"
                  name="monthSelectedOption"
                  options={monthRepeatOptionsSelect}
                  value={monthSelectedOption}
                  onChange={onChange}
                />
              </div>
            </div>
          )}

          <div className="row form-group">
            <div className="col-sm-3 d-flex align-items-center">
              <Label required>{t('appointmentReccurence.endReccurrentDate')}</Label>
            </div>
            <div className="col">
              <DatePicker
                className="form-control"
                name="endRecurrentDate"
                dateFormat="dd/MM/y"
                onChange={value => setProperty('endRecurrentDate', value)}
                selected={endRecurrentDate}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default compose(
  inject('AppointmentCtrl'),
  withTranslation(),
  observer,
)(AppointmentRecurrence)
