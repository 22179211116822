import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classNames from 'classnames'
import './Expert.css'

function Expert({
  t,
  history,
  data,
  PlannerStore: { selectedTimeSlot, selectedExpert, select },
  CalendarExpertStore: { changeView, getExpert, setProperty },
  UserStore: {
    customer: { id },
  },
}) {
  const available = data.checkAvailability(selectedTimeSlot)

  return (
    <div
      className={classNames('row no-gutters p-2 mb-3 invisible-border rounded', {
        'selected-expert': selectedExpert === data.id,
      })}
      data-cy="expert"
    >
      <div
        className={classNames('col-11', { clickable: available, 'disabled-expert': !available })}
        onClick={() => {
          if (available) select('selectedExpert', data.id)
        }}
      >
        <div className="title">{data.fullName}</div>
        <span className="counter">
          <i className="fa fa-legal" />
          &nbsp;
          {t('claim.planner.expert.legal')}&nbsp;{data.legal}
        </span>
        <br />
        <span className="counter">
          <i className="fa fa-medkit" />
          &nbsp;
          {t('claim.planner.expert.corporate')}&nbsp;{data.corporate}
        </span>
        <br />
        <span className="counter">
          <i className="fa fa-home" />
          &nbsp;
          {t('claim.planner.expert.home')}&nbsp;{data.home}
        </span>
        {selectedExpert === data.id && selectedTimeSlot !== null && (
          <div>{data.lastLocation(selectedTimeSlot)}</div>
        )}
      </div>

      <div className="col-1">
        <i
          className="text-primary fa fa-calendar title clickable"
          onClick={() => {
            changeView('week')
            setProperty('fromPlanner', true)
            getExpert({ ...data, customerId: id })
            history.push('/agenda')
          }}
        />
      </div>
    </div>
  )
}

export default compose(
  inject('PlannerStore', 'CalendarExpertStore', 'UserStore'),
  withRouter,
  withTranslation(),
  observer,
)(Expert)
