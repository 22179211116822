import React from 'react'
import PropTypes from 'prop-types'

export default class Unit extends React.PureComponent {
  render() {
    const { unit, className } = this.props

    switch (unit) {
      case 'M2':
        return (
          <span className={className}>
            {' / '}m<sup>2</sup>
          </span>
        )
      case 'M':
        return <span className={className}>{' / '}m</span>
      default:
        return (
          <span className={className}>
            {' / '}
            {unit}
          </span>
        )
    }
  }
}

Unit.defaultProps = {
  className: '',
}

Unit.propTypes = {
  unit: PropTypes.string.isRequired,
  className: PropTypes.string,
}
