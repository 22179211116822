import React from 'react'
import { observer } from 'mobx-react'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import { euro } from 'utils/currency'
import Tooltip from 'components/Tooltip/Tooltip'

class TooltipQuantityPrice extends React.Component {
  render() {
    const {
      areaPresent,
      quantity,
      selfRepair,
      originalTotalPriceSelfRepair,
      originalTotalPriceCatalog,
      overridePrice,
    } = DetailPackageCtrl.packageData
    const totalPrice = selfRepair ? originalTotalPriceSelfRepair : originalTotalPriceCatalog
    if (overridePrice || areaPresent || quantity === 1 || quantity === '') return null

    return (
      <Tooltip
        text={`${euro(totalPrice)} x ${quantity}`}
        className="tooltip-price mr-0 mr-sm-2"
        clickable
        placement="top"
      >
        <i className="fa fa-info-circle clickable" />
      </Tooltip>
    )
  }
}

export default observer(TooltipQuantityPrice)
