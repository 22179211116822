import React, { Component, Fragment } from 'react'
import InputPrice from 'components/InputPrice/InputPrice'

class DropDownMessage extends Component {
  state = {
    showLabel: false,
    quantityTTC: 0,
    quantityHT: 0,
  }
  //On the change event for the select box pass the selected value back to the parent
  handleChange = event => {
    let selectedValue = event.target.value
    this.props.onSelectChange(selectedValue)

    if (selectedValue === '02') {
      this.setState({ showLabel: true })
    } else {
      this.setState({ showLabel: false })
    }
  }

  onChangeQuantityTTC = e => {
    const quantityTTC = e.target.value !== '' ? parseFloat(e.target.value, 10) : ''
    this.setState({ quantityTTC: quantityTTC })
  }

  onChangeQuantityHT = e => {
    const quantityHT = e.target.value !== '' ? parseFloat(e.target.value, 10) : ''
    this.setState({ quantityHT: quantityHT })
  }

  render() {
    const { showLabel, quantityHT, quantityTTC } = this.state

    let t = this.props.t
    let arrayOfData = this.props.arrayOfData
    let options = arrayOfData.map(data => (
      <option key={data.id} value={data.id}>
        {data.name}
      </option>
    ))

    return (
      <Fragment>
        <div className="form-group row no-gutters">
          <label className="col-sm-4 required">{t('messaging.SD63.reason')}</label>
          <div className="col">
            <select name="form-control" className="form-control" onChange={this.handleChange}>
              {options}
            </select>
          </div>
        </div>
        <Fragment>
          <div className="form-group row no-gutters">
            {showLabel && (
              <label className="col-sm-4 required">
                {t('messaging.SD63.TTC')}
                <span className="text-danger font-weight-bold">*</span>
              </label>
            )}
            {showLabel && (
              <InputPrice
                name="priceWithVAT"
                price={quantityTTC}
                disableShowUnit
                maxLengthNumber={6}
                onChange={this.onChangeQuantityTTC}
              />
            )}
          </div>
          <div className="form-group row no-gutters">
            {showLabel && (
              <label className="col-sm-4 required">
                {t('messaging.SD63.HT')}
                <span className="text-danger font-weight-bold">*</span>
              </label>
            )}
            {showLabel && (
              <InputPrice
                name="priceWithoutVAT"
                price={quantityHT}
                disableShowUnit
                maxLengthNumber={6}
                onChange={this.onChangeQuantityHT}
              />
            )}
          </div>
        </Fragment>
      </Fragment>
    )
  }
}

export default DropDownMessage
