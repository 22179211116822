import React, { Fragment } from 'react'
import { uid } from 'utils'

import Field from 'components/Field'

const FieldsBloc = ({ title, fields = [], noTopDivider = false, displayNull = false }) => {
  if (displayNull === false) {
    if (fields.every(({ value }) => !value)) return null
  }

  return (
    <Fragment>
      {!noTopDivider && <div className="divider" />}
      {title && (
        <div className="row">
          <div className="font-weight-bold">{title}</div>
        </div>
      )}
      {fields.map(field => (
        <Field key={uid()} {...field} displayNull={displayNull} />
      ))}
    </Fragment>
  )
}

export default FieldsBloc
