import React from 'react'
import { inject, observer } from 'mobx-react'
import { path } from 'ramda'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

class Coverage extends React.Component {
  onChange = e => SearchPackageCtrl.setProperty(e.target.name, e.target.value)

  componentDidMount() {
    const {
      UserStore: { isExpert },
      MissionStore: { claim: missionClaim },
      ManagerClaimStore: { claim: cfmClaim },
    } = this.props
    const claim = isExpert ? missionClaim : cfmClaim

    const coverageKey = isExpert ? claim.coverage.key : claim.claimInformation.coverage.key

    SearchPackageCtrl.setProperty('coverage', coverageKey)
  }

  render() {
    const {
      UserStore: { isExpert },
      MissionStore: { claim: missionClaim },
      ManagerClaimStore: { claim: cfmClaim },
    } = this.props
    const { coverage } = SearchPackageCtrl
    const coverageKey = path(
      ['coverage', 'key'],
      isExpert ? missionClaim : cfmClaim.claimInformation,
    )
    const coverageValue = path(
      ['coverage', 'value'],
      isExpert ? missionClaim : cfmClaim.claimInformation,
    )
    const { t } = this.props

    const coverageTypeOptions = [
      { label: coverageValue, value: coverageKey },
      { label: t('mission.calculation.searchPackage.noFilter'), value: 'noFilter' },
    ]

    return (
      <ResponsiveSelect
        className="coverage-type"
        name="coverage"
        value={coverage}
        options={coverageTypeOptions}
        onChange={this.onChange}
      />
    )
  }
}

export default inject('UserStore', 'MissionStore', 'ManagerClaimStore')(observer(Coverage))
