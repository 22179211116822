import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

function Filters({
  t,
  UiCtrl: { isMobile },
  EventCtrl: { filterLevel, filterUser, filterCategory, filters, onChangeFilters },
}) {
  if (isMobile) return null

  const onChange = e => onChangeFilters({ name: e.target.name, value: e.target.value })

  return (
    <div className="row mb-3">
      <div className="col-3">
        <ResponsiveSelect
          name="level"
          value={filters.level}
          placeholder={t('events.level')}
          options={[
            { label: `${t('events.filterBy')} ${t('events.level')}`, value: null },
            ...filterLevel,
          ]}
          onChange={onChange}
        />
      </div>

      <div className="col-3">
        <ResponsiveSelect
          name="user"
          value={filters.user}
          placeholder={t('events.user')}
          options={[
            { label: `${t('events.filterBy')} ${t('events.user')}`, value: null },
            ...filterUser,
          ]}
          onChange={onChange}
        />
      </div>

      <div className="col-3">
        <ResponsiveSelect
          name="category"
          value={filters.category}
          placeholder={t('events.category')}
          options={[
            { label: `${t('events.filterBy')} ${t('events.category')}`, value: null },
            ...filterCategory,
          ]}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default compose(
  inject('UiCtrl', 'EventCtrl'),
  withTranslation(),
  observer,
)(Filters)
