import React from 'react'
import { observer } from 'mobx-react'

import TextArea from 'components/Forms/TextArea'
import TextAreaEditor from 'components/Forms/TextAreaEditor'

const TextAreaWrapper = ({ inputData }) => {
  const onChange = e => inputData.setProperty('value', e.target.value)
  const onChangeEditor = editorState => inputData.setProperty('editorState', editorState)
  const onBlurEditor = () => inputData.convertEditorContentToText()

  if (inputData.wysiwyg) {
    return (
      <TextAreaEditor
        className="form-control"
        {...inputData}
        onChangeEditor={onChangeEditor}
        onBlurEditor={onBlurEditor}
      />
    )
  }

  return <TextArea className="form-control" {...inputData} onChange={onChange} />
}

export default observer(TextAreaWrapper)
