import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import classNames from 'classnames'

import { euro } from 'utils/currency'
import { showInput } from 'utils/settlement'

const Data = ({ rowName, value, exceed }) => {
  if (!showInput(rowName, 'totalContractSettlement')) return '-'

  return (
    <React.Fragment>
      {exceed && <i className="fa fa-warning" />} {euro(value)}
    </React.Fragment>
  )
}

const TotalContractSettlement = ({ CartStore: { finalPriceHelper }, rowName, total }) => {
  let value = 0
  let exceed = false
  if (total === null) {
    value = finalPriceHelper.finalPrice[rowName]
  } else {
    exceed = total > finalPriceHelper.finalPrice[rowName]
    value = total
  }

  return (
    <td
      className={classNames({
        'text-success': !exceed,
        'text-danger': exceed,
        'text-center': !showInput(rowName, 'totalContractSettlement'),
      })}
    >
      <Data rowName={rowName} exceed={exceed} value={value} />
    </td>
  )
}

export default compose(
  inject('CartStore'),
  observer,
)(TotalContractSettlement)
