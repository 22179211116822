import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import './Help.css'
import LogoTeamViewer from 'assets/logo-teamviewer.svg'
import CardLayout from 'components/CardLayout/CardLayout'

const Help = () => {
  const { t } = useTranslation()

  return (
    <div className="informations-page">
      <h5 className="mb-4">{t('common.teamViewerDownload')}</h5>

      <CardLayout className="parameters-informations">
        <div className="mb-2">{t('common.teamViewerMsg')}</div>

        <a href="https://www.teamviewer.com/fr/" target="_blanck">
          <img src={LogoTeamViewer} className="logo-teamviewer" alt="logo team viewer" />
        </a>
      </CardLayout>
    </div>
  )
}

export default observer(Help)
