import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import './Breadcrumb.css'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import PackageLinks from './PackageLinks'
import FurnitureLinks from './FurnitureLinks'
import RelatedCostLinks from './RelatedCostLinks'
import OutdoorFurnitureLinks from './OutdoorFurnitureLinks'

const Breadcrumb = ({
  match: {
    path,
    params: { id },
  },
}) => {
  const { packageType } = SearchPackageCtrl
  const last = path.split('/').pop()

  switch (last) {
    case 'listPackage':
      return <PackageLinks id={id} packageType={packageType} hideElements={2} />
    case 'searchPackage':
    case 'searchOutdoorPackage':
      return <PackageLinks id={id} packageType={packageType} hideElements={1} />
    case 'createPackage':
    case 'createOutdoorPackage':
    case 'detailPackage':
    case 'detailOutdoorPackage':
      return (
        <PackageLinks
          id={id}
          packageType={packageType}
          hideElements={0}
          createPackage={last === 'createPackage' || last === 'createOutdoorPackage'}
        />
      )

    case 'searchRegularFurniture':
      return <FurnitureLinks id={id} hideElements={1} last={last} />
    case 'addRegularFurniture':
      return <FurnitureLinks id={id} hideElements={0} last={last} />

    case 'addOutdoorFurniture':
    case 'searchOutdoorFurniture':
      return <OutdoorFurnitureLinks />

    case 'addRelatedCost':
    case 'addOverflow':
    case 'addNoGuarantee':
      return <RelatedCostLinks id={id} hideElements={0} last={last} />
    default:
      console.warn(`Something is wrong, your path ${last} do not exist.`)
      return null
  }
}

export default compose(
  withRouter,
  observer,
)(Breadcrumb)
