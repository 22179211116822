import { observable, action, computed, runInAction, decorate } from 'mobx'
import { filter } from 'ramda'

import UiCtrl from 'stores/Common/view/UiCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import CommonStore from 'stores/Common/domain/CommonStore'
import { getMissions } from 'services/missions'
import { getDataLocal, setDataLocal } from 'stores/localStorage'
import {
  DYNAMIC_SELECT_FILTER_MANAGER,
  DYNAMIC_SELECT_FILTER_EXPERT,
  dynSelectFilterName,
  reduceKey,
} from 'utils/dashboard'

export const BreakPoints = Object.freeze({
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
})

export const BreakPointsWidth = Object.freeze({
  xs: {
    min: 0,
    max: 575.98,
  },
  sm: {
    min: 576,
    max: 768,
  },
  md: {
    min: 768,
    max: 991.98,
  },
  lg: {
    min: 992,
    max: 4000,
  },
})

const hiddenHeaders = Object.freeze(['status.value'])

class DashboardCtrl {
  missionsData = []
  headers = []
  pageSize = 10
  pageNumber = 1
  missionsView = 'listCard'
  loading = true
  missionsToPrint = []

  constructor() {
    const missionsView = getDataLocal('missionsView')
    if (missionsView) this.missionsView = missionsView
    else setDataLocal('missionsView', 'listCard')

    DYNAMIC_SELECT_FILTER_MANAGER.forEach(selectFilterPath => {
      this[`select${dynSelectFilterName(selectFilterPath)}Manager`] = computed(() => {
        return reduceKey(selectFilterPath, this.missionsData)
      })
    })

    DYNAMIC_SELECT_FILTER_EXPERT.forEach(selectFilterPath => {
      this[`select${dynSelectFilterName(selectFilterPath)}Expert`] = computed(() => {
        return reduceKey(selectFilterPath, this.missionsData)
      })
    })
  }

  get filters() {
    const { claimStatus, claimCFI, claimCFA, claimCFM } = CommonStore
    if (claimStatus.length === 0) return []

    const claimTypes = UserStore.isExpert
      ? claimCFA
      : UserStore.isClaimManager
      ? claimCFM
      : claimCFI

    let index = 0

    return claimTypes.map(status => {
      index += 1

      return {
        key: status.key,
        name: status.value,
        categories: [status.key],
        defaultFilterPosition: status.rank,
        defaultFilterSelected: index === 1,
      }
    })
  }

  getFilter(key) {
    return this.filters.find(filter => {
      return filter.key === key
    })
  }

  get defaultFilters() {
    return this.filters
      .filter(filter => {
        return filter.hasOwnProperty('defaultFilterPosition') && filter.defaultFilterPosition
      })
      .sort((a, b) => {
        return a.defaultFilterPosition - b.defaultFilterPosition
      })
  }

  get defaultFilterSelected() {
    return this.filters.find(filter => filter.defaultFilterSelected)
  }

  get missionsByPage() {
    let pageNumber = this.pageNumber
    --pageNumber
    return this.missions.slice(pageNumber * this.pageSize, (pageNumber + 1) * this.pageSize)
  }

  get pageNumbers() {
    return Math.ceil(this.missions.length / this.pageSize)
  }

  get visibleHeaders() {
    return this.headers.filter(
      header =>
        (header.hidden === undefined || header.hidden === false) && header.field !== 'priority',
    )
  }

  get headersField() {
    return this.visibleHeaders.map(header => {
      return header.field
    })
  }

  get headersInfo() {
    const headers = {}
    this.visibleHeaders.forEach(header => {
      headers[header.field] = header
    })
    return headers
  }

  getStatusesByFilters(filterKeys) {
    let statuses = []
    filterKeys.forEach(key => {
      let filter = this.getFilter(key)
      if (filter) statuses = [...statuses, ...filter.categories]
    })

    return statuses
  }

  getMissionsByFilters(filterKeys) {
    const statuses = this.getStatusesByFilters(filterKeys)

    return this.missionsData.filter(mission => {
      return statuses.includes(mission['status.key'])
    })
  }

  get missions() {
    let missionsFiltered = []

    missionsFiltered = DashboardFilterStore.filterByAssigneeUserId(this.missionsData)
    // console.log('filteredByAssigneeUserId : ', missionsFiltered.map(mission => toJS(mission)))

    missionsFiltered = DashboardFilterStore.filterByMissionType(missionsFiltered)
    // console.log('missionType : ', missionsFiltered.map(mission => toJS(mission)))

    missionsFiltered = DashboardFilterStore.filterByMissionStatus(missionsFiltered)
    // console.log('missionStatus : ', missionsFiltered.map(mission => toJS(mission)))

    missionsFiltered = DashboardFilterStore.filterByDynamicFilters(missionsFiltered)
    // console.log('df : ', missionsFiltered.map(mission => toJS(mission)))

    missionsFiltered = DashboardFilterStore.filterBySearch(missionsFiltered)

    return missionsFiltered
      .slice()
      .sort(DashboardFilterStore.sortMissions.bind(DashboardFilterStore))
  }

  get newMissions() {
    const totalMissions = this.missionsData.filter(
      mission => mission['tags'] && mission['tags'].includes('NEW'),
    )
    return totalMissions.length
  }

  get getMissionsUrl() {
    if (UserStore.isExpert) return '/claimFiles/v1/claimFileAssessor/dashboard'
    else if (UserStore.isClaimManager) return '/claimFiles/v1/claimFileManager/dashboard'
    else if (UserStore.isInsurer) return '/claimFiles/v1/dashboard'
    else throw new Error(`Something is wrong. There is no role for ${UserStore.role}`)
  }

  get getMissionsParams() {
    if (UserStore.isExpert)
      return this.missionsView === 'listCard' ? '?service=listCard' : '?service=listTable'
    else if (UserStore.isClaimManager)
      return this.missionsView === 'listCard' ? '?service=MGRListCard' : '?service=MGRListTable'
    else if (UserStore.isInsurer)
      return this.missionsView === 'listCard' ? '?service=SHListCard' : '?service=SHListTable'
    else throw new Error(`Something is wrong. There is no role for ${UserStore.role}`)
  }

  get headersForSortList() {
    // Remove mission type because it's a select box and not a input string anymorea
    const isSortable = header =>
      header.sortable && header.field !== 'mission.missionInsurerInformations.missionType.value'
    const headers = filter(isSortable, this.headers)
    const isColumnVisible = header => {
      if (header['disable']) {
        const headerFiltered = header.disable.filter(breakpoint => {
          return (
            UiCtrl.windowWidth > BreakPointsWidth[breakpoint]['min'] &&
            UiCtrl.windowWidth < BreakPointsWidth[breakpoint]['max']
          )
        })
        return headerFiltered.length <= 0
      }
      return true
    }

    const headersFiltered = headers.filter(header => hiddenHeaders.indexOf(header.field) === -1)

    return filter(isColumnVisible, headersFiltered)
  }

  get formatMissionsPrint() {
    return this.missionsToPrint.map(wan => `wans[]=${wan}`).join('&')
  }

  submitSearch() {
    this.searchNow = this.searchText
  }

  setPageNumber = pageNumber => {
    this.pageNumber = pageNumber
  }

  setProperty = (key, value) => {
    if (key === 'missionsView') setDataLocal('missionsView', value)
    this[key] = value
    if (key === 'missionsView') this.fetchMissions()
  }

  fetchMissions = async () => {
    try {
      const url = `${this.getMissionsUrl}${this.getMissionsParams}`
      const res = await getMissions(url)

      runInAction(() => {
        const datas = Object.keys(res.data)
        if (!!datas.length) {
          this.headers = res.headers.filter(header => !header.hidden)
          DashboardFilterStore.loadFilters(res.filters)
          this.missionsData = datas.reduce((arr, current, index) => {
            arr[index] = res.data[current]
            return arr
          }, [])
        }
      })
    } catch (err) {
      if (err)
        if (err.error !== 'access_denied') AlertCtrl.alert('danger', `${err.error_description}`)
      console.error(err)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  checkIfOnline = () => {
    if (!UiCtrl.isOffLine && UserStore.token) {
      this.fetchMissions()
    }
  }

  resetMissionsToPrint = () => {
    this.missionsToPrint = []
  }
  addRemoveMissionPrint = (wan, isAdd = true) => {
    if (isAdd) this.missionsToPrint.push(wan)
    else this.missionsToPrint = this.missionsToPrint.filter(id => id !== wan)
  }
}

const DecoratedDashboardCtrl = decorate(DashboardCtrl, {
  missionsData: observable,
  headers: observable,
  pageSize: observable,
  pageNumber: observable,
  missionsView: observable,
  loading: observable,
  missionsToPrint: observable,

  filters: computed,
  defaultFilters: computed,
  defaultFilterSelected: computed,
  missionsByPage: computed,
  pageNumbers: computed,
  visibleHeaders: computed,
  headersField: computed,
  headersInfo: computed,
  missions: computed,
  newMissions: computed,
  getMissionsUrl: computed,
  getMissionsParams: computed,
  headersForSortList: computed,
  formatMissionsPrint: computed,

  fetchMissions: action,
  setProperty: action,
  setPageNumber: action,
  submitSearch: action,
  addRemoveMissionPrint: action,
  resetMissionsToPrint: action,
})

export default new DecoratedDashboardCtrl()
