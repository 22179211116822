import React from 'react'

import Tooltip from 'components/Tooltip/Tooltip'
import { euro } from 'utils/currency'

const TotalPriceWarning = ({ total, trueTotal }) => {
  if (total > trueTotal || total < trueTotal)
    return (
      <Tooltip placement="top" text={`Le total devrait être ${euro(trueTotal)}.`}>
        <span className="text-danger">
          <i className="fa fa-warning" /> {euro(total)}
        </span>
      </Tooltip>
    )

  return euro(total)
}

export default TotalPriceWarning
