import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'
import { concatAddress } from 'utils'

const Firm = ({
  data: { id, firmName, address, phoneNumber, allowedClaimIdentifierTerminations },
  OverrunStore: { selectedFirm, selectFirm },
}) => {
  return (
    <div
      className={classNames('clickable rounded mb-3 p-2', {
        'border border-primary': selectedFirm === id,
        'border border-white': selectedFirm !== id,
      })}
      onClick={() => {
        selectFirm(id)
      }}
    >
      <span className="font-weight-bold mb-2">{firmName}</span>
      <br />
      {concatAddress(address)}
      <br />
      {phoneNumber}
      <br />
      Informations : {allowedClaimIdentifierTerminations}
    </div>
  )
}

export default compose(inject('OverrunStore'), withTranslation(), observer)(Firm)
