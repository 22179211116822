import React from 'react'
import { observer, inject } from 'mobx-react'
import ImageLoader from 'react-load-image'
import classNames from 'classnames'

import { isImage } from 'stores/Common/view/SupportingDocumentsCtrl'
import { baseUrl } from 'services/utils'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import FallBackThumbnail from 'components/FallBackThumbnail/FallBackThumbnail'
import FallBackToDownload from 'components/FallBackThumbnail/FallBackToDownload'
import PhotoLocationMap from 'components/SDItem/PhotoLocationMap'
import { path } from 'ramda'

const SliderItem = ({ slide, UserStore: { token } }) => {
  return (
    <div className="slide-image-wrapper">
      {slide.showLocationPhoto && (
        <PhotoLocationMap
          siniterPosition={path(['extraData', 'geometrySinister'], slide)}
          photoLocation={path(['extraData', 'geometry'], slide)}
        />
      )}

      {!slide.showLocationPhoto && (
        <ImageLoader
          src={`${baseUrl()}/supportingDocuments/v1/${slide.id}/thumbnail/lg?access_token=${token}`}
        >
          <img
            className={classNames({
              'slider-pdf': slide.mimeType === 'application/pdf',
              'slider-img': slide.mimeType !== 'application/pdf',
            })}
            alt="Supporting document"
          />
          {isImage(slide.mimeType) ? (
            <FallBackThumbnail mimeType={slide.mimeType} />
          ) : (
            <FallBackToDownload
              url={`${baseUrl()}/supportingDocuments/v1/${
                slide.id
              }/attachment?access_token=${token}`}
              mimeType={slide.mimeType}
            />
          )}
          <ImageLoading />
        </ImageLoader>
      )}
    </div>
  )
}

export default inject('UserStore')(observer(SliderItem))
