import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'
import Toggle from 'components/Toggle/Toggle'
import DropDownMessage from 'pages/Messaging/DropDownMessage'
import WorkRequestAttachment from 'pages/Mission/pages/WorkRequest/WorkRequestAttachment'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

function ConfirmBoxMessage({ setOpenModal, wan, sendSDR63, WorkRequestCtrl: { attachments } }) {
  const { t } = useTranslation()

  const arrayOfData = [
    {
      id: '01',
      name: t('messaging.SD63.notValid'),
    },
    {
      id: '02',
      name: t('messaging.SD63.rectification'),
    },
  ]

  const [modalDropDown, setModalDropDown] = useState(false)
  const [selectedValue, setSelectedValue] = useState('01')
  const [EnableAttachement, setEnableAttachement] = useState(false)
  const [grabbing, setGrabbing] = useState(false)

  const Dropdowndata = useRef()

  const handleSelectChange = selectedValue => {
    setSelectedValue(selectedValue)
    if (selectedValue === '02') {
      setEnableAttachement(true)
    } else {
      setEnableAttachement(false)
    }
  }

  const setAction = e => {
    if (e.target.value === false) {
      setModalDropDown(true)
    } else {
      setModalDropDown(false)
      setEnableAttachement(false)
    }
  }

  const handleSendSDR63 = () => {
    if (Dropdowndata.current === undefined) {
      sendSDR63(
        { wan, answer: true, motif: '', montantTTC: 0, montantHT: 0, documentId: '' },
        setOpenModal,
      )
    }
    if (modalDropDown && !EnableAttachement) {
      sendSDR63(
        {
          wan,
          answer: false,
          motif: selectedValue,
          montantTTC: Dropdowndata.current.state.quantityTTC,
          montantHT: Dropdowndata.current.state.quantityHT,
          documentId: '',
        },
        setOpenModal,
      )
    }
    if (modalDropDown && EnableAttachement) {
      //for rectification du devis
      if (
        (Dropdowndata.current.state.quantityTTC > 0) &
        (Dropdowndata.current.state.quantityHT > 0) &
        (attachments.length !== 0)
      ) {
        sendSDR63(
          {
            wan,
            answer: false,
            motif: selectedValue,
            montantTTC: Dropdowndata.current.state.quantityTTC,
            montantHT: Dropdowndata.current.state.quantityHT,
            documentId: attachments[0],
          },
          setOpenModal,
        )
      } else {
        if (Dropdowndata.current.state.quantityTTC === 0) {
          AlertCtrl.alert('danger', t('messaging.SD63.TTCRequired'))
        }
        if (Dropdowndata.current.state.quantityHT === 0) {
          AlertCtrl.alert('danger', t('messaging.SD63.HTRequired'))
        }
        if (!attachments[0]) {
          AlertCtrl.alert('danger', t('messaging.SD63.AttachementRequired'))
        }
      }
    }
  }

  return (
    <ModalWithBackdrop size="modal-lg" show={true} draggable scrollable setGrabbing={setGrabbing}>
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t('messaging.SD63.approval')}</h5>
        <button type="button" className="close" onClick={() => setOpenModal(false)}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body overflow-x-hidden">
        <div className="form-group row no-gutters">
          <label className="col-sm-4 required">
            {t('messaging.SD63.approval')}
            <span className="text-danger font-weight-bold">*</span>
          </label>
          <div className="col">
            <Toggle name="CheckAccordChiffrage" checked={true} onChange={setAction} />
          </div>
        </div>
        {modalDropDown && (
          <DropDownMessage
            ref={Dropdowndata}
            arrayOfData={arrayOfData}
            onSelectChange={handleSelectChange}
            t={t}
          />
        )}
        {EnableAttachement && <WorkRequestAttachment />}
      </div>
      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-primary" onClick={() => handleSendSDR63()}>
          {t('messaging.form.send')}
        </button>
        <div></div>
      </div>
    </ModalWithBackdrop>
  )
}
export default compose(inject('WorkRequestCtrl'), withTranslation(), observer)(ConfirmBoxMessage)
