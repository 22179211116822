import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

import TotalTableDescriptionPrejudices from './TotalTableDescriptionPrejudices'
import Ventilation from './Ventilation'

const DescriptionPrejudices = ({ CartStore: { isVentilationIPAvailable } }) => {
  if (isVentilationIPAvailable) {
    return <Ventilation />
  }

  return <TotalTableDescriptionPrejudices />
}

export default compose(
  inject('CartStore', 'PrejudiceStore'),
  observer,
)(DescriptionPrejudices)
