import { useState, useEffect } from 'react'

const useKonamicode = () => {
  let lastTypeKeyCodes = []
  let lastTimeKeyDown = new Date()

  const [isKonamicode, setIsKonamicode] = useState(false)

  const handleKonamiCode = e => {
    const now = new Date()
    if (now - lastTimeKeyDown > 1000) {
      lastTypeKeyCodes = []
    }

    lastTimeKeyDown = now
    const keyCode = e.keyCode
    lastTypeKeyCodes.push(keyCode)

    if (lastTypeKeyCodes.length >= 10) {
      // konami cheat code Up, Up, Down, Down, Left, Right, Left, Right, B, A
      const konami = lastTypeKeyCodes.slice(-10).join('-')
      if (konami === '38-38-40-40-37-39-37-39-66-65') {
        setIsKonamicode(true)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKonamiCode)
    return () => {
      document.removeEventListener('keydown', handleKonamiCode)
    }
    // eslint-disable-next-line
  }, [])

  return isKonamicode
}

export default useKonamicode
