import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import ChangePasswordCtrl from 'stores/Parameters/ChangePasswordCtrl'
import Input from 'components/Forms/Input'
import Label from 'components/Label/Label'

class ChangePassword extends React.Component {
  onChange = e => ChangePasswordCtrl.setChangePasswordProperty(e.target.name, e.target.value)
  setTranslatedError = (field, t) => {
    const fieldErrors = ChangePasswordCtrl.errors.filter(err => err.key === field)
    if (fieldErrors.length > 0) {
      const array = fieldErrors.map(error => {
        return t(error.value)
      })
      return array
    }
    return []
  }

  render() {
    const { t } = this.props
    const { changePassword } = ChangePasswordCtrl

    return (
      <React.Fragment>
        <h6 className="mb-4 font-weight-bold">
          {t('parameters.informations.changePassword.title')}
        </h6>

        <div className="row no-gutters">
          <div className="form-group col-12 row no-gutters">
            <div className="col-12 col-md-5">
              <Label required>{t('parameters.informations.changePassword.oldPassword')}</Label>
            </div>
            <div className="col-12 col-md-7">
              <Input
                required
                type="password"
                name="current_password"
                onChange={this.onChange}
                className="form-control"
                value={changePassword.current_password}
                errors={this.setTranslatedError('current_password', t)}
              />
            </div>
          </div>

          <div className="form-group col-12 row no-gutters">
            <div className="col-12 col-md-5">
              <Label required>{t('parameters.informations.changePassword.newPassword')}</Label>
            </div>
            <div className="col-12 col-md-7">
              <Input
                required
                type="password"
                name="first"
                onChange={this.onChange}
                className="form-control"
                value={changePassword.first}
                errors={this.setTranslatedError('first', t)}
              />
            </div>
          </div>

          <div className="form-group col-12 row no-gutters">
            <div className="col-12 col-md-5">
              <Label required>{t('parameters.informations.changePassword.repeatPassword')}</Label>
            </div>
            <div className="col-12 col-md-7">
              <Input
                required
                type="password"
                name="second"
                onChange={this.onChange}
                className="form-control"
                value={changePassword.second}
                errors={this.setTranslatedError('second', t)}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default withTranslation()(observer(ChangePassword))
