import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { GOOGLE_MAP_API_KEY } from 'utils/constants'
import { getPositionFromAddress } from 'utils'
import { path } from 'ramda'

const GoogleMapWrapper = withGoogleMap(({ children, defaultCenter, markers }) => {
  const bounds = new window.google.maps.LatLngBounds()
  bounds.extend(new window.google.maps.LatLng(defaultCenter.lat, defaultCenter.lng))
  markers.forEach(marker =>
    bounds.extend(new window.google.maps.LatLng(marker.position.lat, marker.position.lng)),
  )

  return (
    <GoogleMap
      ref={ref => {
        ref && ref.fitBounds(bounds)
      }}
      defaultZoom={14}
      defaultCenter={defaultCenter}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY.key}&v=3.exp&libraries=geometry,drawing,places`}
      isMarkerShown={true}
    >
      {children}
    </GoogleMap>
  )
})

const Map = ({ t, ManagerClaimStore: { claim }, LastBillCtrl: { markers, selectFirm } }) => {
  const position = getPositionFromAddress(path(['claimInformation', 'addressOfLoss'], claim))

  if (!position) {
    return <div className="border p-5 text-center mr-3">{t('lastbill.errorPosition')}</div>
  }

  return (
    <GoogleMapWrapper
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      defaultCenter={position}
      markers={markers}
    >
      <Marker
        title="Sinistre"
        position={position}
        label={t('claim.overrun.assignmentPosition')}
        draggable={false}
        cursor="not-allowed"
      />

      {markers.map(marker => (
        <Marker
          key={`maker-${marker.id}`}
          label={marker.name}
          title={marker.name}
          labelStyle={{
            color: 'red',
          }}
          position={marker.position}
          draggable={false}
          onClick={() => {
            selectFirm(marker.id)
          }}
        />
      ))}
    </GoogleMapWrapper>
  )
}

export default compose(
  inject('ManagerClaimStore', 'LastBillCtrl'),
  withTranslation(),
  observer,
)(Map)
