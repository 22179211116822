import React from 'react'
import classNames from 'classnames'

export default ({ className, children }) => {
  return (
    <div className={classNames('container pl-0 pr-0', className)}>
      <div className="card">
        <div className="card-body">{children}</div>
      </div>
    </div>
  )
}
