import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

const OfflineBar = () => {
  const { t } = useTranslation()

  return (
    <div className="progress w-100 p-0 rounded-0">
      <div className="progress-bar bg-danger w-100 p-0">{t('header.noInternet')}</div>
    </div>
  )
}

export default memo(OfflineBar)
