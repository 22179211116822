import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import Button from 'components/Button/Button'

function BadinterDisableAlert({
  t,
  type,
  disableBadinterAlert,
  disablingBadinterAlert,
  canDisableBadinterAlert,
}) {
  let content = null
  if (type === 'BDNT_PRV' && canDisableBadinterAlert === true) {
    content = (
      <Button
        className="btn btn-link p-0"
        loading={disablingBadinterAlert}
        onClick={() => disableBadinterAlert()}
      >
        {t('messaging.message.disableBadinterDelayProvisionAlert')}
      </Button>
    )
  }

  if (type === 'BDNT_EXP' && canDisableBadinterAlert === true) {
    content = (
      <Button
        className="btn btn-link p-0"
        loading={disablingBadinterAlert}
        onClick={() => disableBadinterAlert()}
      >
        {t('messaging.message.disableBadinterDelayExpirationAlert')}
      </Button>
    )
  }
  return <div className="info">{content}</div>
}

export default compose(withTranslation())(BadinterDisableAlert)
