import React from 'react'
import { observer } from 'mobx-react'

import InputNumber from 'components/InputNumber/InputNumber'

const InputNumberWrapper = ({ inputData }) => {
  const onChange = e => inputData.setProperty('value', e.target.value)

  return <InputNumber {...inputData} className="form-control" onChange={onChange} />
}

export default observer(InputNumberWrapper)
