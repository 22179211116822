import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'

const Pagination = ({
  DashboardFilterStore: {
    search,
    currentFilter: { dynamicFilters, name },
  },
  DashboardCtrl: { setPageNumber, pageNumber: currentPage, pageNumbers, missions },
}) => {
  const dynFiltersDeps = dynamicFilters.map(({ value }) => value).join()
  useEffect(() => setPageNumber(1), [search, dynFiltersDeps, setPageNumber, name])

  const handlePageChange = pageNumber => {
    setPageNumber(pageNumber + 1)
  }

  const handleUpNavigation = currentPage => {
    setPageNumber(currentPage + 1)
  }

  const handleDownNavigation = currentPage => {
    setPageNumber(currentPage - 1)
  }

  const renderPageItems = totalPage => {
    return [...Array(totalPage)].map((a, index) => {
      return [
        index < 5 && (
          <li key={index} className="page-item">
            <button
              className={classNames('page-link', {
                'text-dark': currentPage === index + 1,
              })}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </button>
          </li>
        ),
        totalPage > 5 && index === 6 && (
          <li key={index} className="page-item">
            <button
              className={classNames('page-link disabled', {
                'text-dark': currentPage > 5,
              })}
            >
              {currentPage > 5 ? currentPage : '...'}
            </button>
          </li>
        ),
      ]
    })
  }

  return (
    !!missions.length && (
      <div className="row">
        <div className="col-12">
          <nav aria-label="Dashboard pagination" className="pagination-navbar">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className={classNames('page-link', { disabled: currentPage === 1 })}
                  aria-label="Previous"
                  onClick={handleDownNavigation.bind(this, currentPage)}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              {renderPageItems(pageNumbers)}
              <li className="page-item">
                <button
                  className={classNames('page-link', { disabled: currentPage === pageNumbers })}
                  aria-label="Next"
                  onClick={handleUpNavigation.bind(this, currentPage)}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    )
  )
}
export default inject('DashboardCtrl', 'DashboardFilterStore')(observer(Pagination))
