import request from 'services/httpRequest'

export const fetchManagerClaim = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileManager/${wan}`,
    })

    return res.data.attributes
  } catch (error) {
    throw error
  }
}

export const fetchMeteoManager = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileManager/${wan}/meteo`,
    })
    return res
  } catch (error) {
    throw error
  }
}
