import { observable, action, decorate } from 'mobx'

import Item from 'stores/Mission/domain/Item'
import { isNil } from 'ramda'

class CustomItemCtrl {
  customItem = null

  setNewCustomItem = packageData => {
    this.customItem = new Item({
      data: {
        cde: null,
        isCustom: true,
        order: packageData.maxItemOrder + 1,
        desc: '',
        itemCraft: 'Autre',
        pi_active: true,
        qtUnitMinute: 0,
        prices: null,
        qualification: { cde: 99999 },
        serviceGroup: '',
        type: 'M',
        unit: 'U',
        materials: [],
        tools: [],
        supplies: [],
        overrideTotalPrice: [],
      },
      qualityType: 'medium',
      packageUnit: packageData.unit,
      packageDifficulty: packageData.difficulty,
      areaSquare: isNil(packageData.overrideAreaSquare)
        ? packageData.areaSquare
        : packageData.overrideAreaSquare,
      packageIsSelfRepair: packageData.selfRepair,
    })
  }

  get customItemValid() {
    if (!this.customItem) return false

    const { desc, unit, quantityForCustomItem, priceUnitForCustomItem } = this.customItem

    return desc && unit && quantityForCustomItem && priceUnitForCustomItem
  }

  confirmCustomItem = packageData => {
    if (!this.customItemValid) return

    packageData.addOrReplaceItem(this.customItem)

    this.customItem = null
  }

  cancelCustomItem = () => {
    this.customItem = null
  }

  setProperty(key, value) {
    this[key] = value
  }

  unitsByPriceType = priceType => {
    if (priceType === 'priceList') return ['U', 'F', 'M2', 'ML']

    return ['F', 'H', 'HR', 'KG', 'L', 'M2', 'M3', 'ML', 'U']
  }
}

const DecoratedCustomItemCtrl = decorate(CustomItemCtrl, {
  customItem: observable,

  setNewCustomItem: action,
  setProperty: action,
  confirmCustomItem: action,
  cancelCustomItem: action,
})

export default new DecoratedCustomItemCtrl()
