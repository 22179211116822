import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import UserManagementStore from 'stores/Parameters/UserManagement/UserManagementStore'
import UserManagementCtrl from 'stores/Parameters/UserManagement/UserManagementCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import Toggle from 'components/Toggle/Toggle'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import AreasInput from './AreasInput'
import PhoneNumber from 'components/Forms/PhoneNumber'

const UserModal = ({
  t,
  CommonStore: { technicityLevelsForSelectInput },
  UserStore: { id: myId, updateUser },
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const closeModal = () => UserManagementCtrl.setProperty('showUserModal', false)
  const onSave = async () => {
    try {
      const res = await UserManagementCtrl.user.save(UserManagementCtrl.customerId)
      if (res.method === 'post') UserManagementStore.addNewUser(res.user, UserManagementCtrl.user)
      if (res.user.id === myId) updateUser(res.user)
      UserManagementCtrl.setProperty('showUserModal', false)
    } catch (err) {
      console.log(err)
    }
  }
  const onChangePhone = value => UserManagementCtrl.user.setProperty('phone', value ? value : '')
  const onChange = e => UserManagementCtrl.user.setProperty(e.target.name, e.target.value)
  const generatePassword = () => UserManagementCtrl.user.generatePassword()
  const findError = key => {
    const error = UserManagementCtrl.user.errors.find(error => error.source === key)
    if (error) return [error.detail]
    return []
  }

  const {
    showUserModal,
    inputSelectRoles,
    valueSelectRoles,
    valueSelectTechnicityLevels,
    user: {
      id,
      firstName,
      lastName,
      email,
      username,
      phone,
      skills,
      enabled,
      plainPassword,
      saving,
      roles,
      isValid,
    },
  } = UserManagementCtrl

  return (
    <ModalWithBackdrop
      show={showUserModal}
      close={closeModal}
      draggable
      scrollable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">
          {id
            ? t('parameters.userManagement.modal.modify')
            : t('parameters.userManagement.modal.new')}
        </h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <form>
          <div className="form-group row">
            <div className="col-md-3 col-sm-12" required>
              <Label required>{t('parameters.userManagement.modal.firstName')}</Label>
            </div>
            <div className="col-md-9 col-sm-12">
              <Input
                required
                className="form-control"
                name="firstName"
                value={firstName}
                onChange={onChange}
                placeholder={t('parameters.userManagement.modal.firstName')}
                errors={findError('firstName')}
                maxLength={64}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">
              <Label required>{t('parameters.userManagement.modal.lastName')}</Label>
            </div>
            <div className="col-md-9 col-sm-12">
              <Input
                required
                className="form-control"
                name="lastName"
                value={lastName}
                onChange={onChange}
                placeholder={t('parameters.userManagement.modal.lastName')}
                errors={findError('lastName')}
                maxLength={64}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">
              <Label required>{t('parameters.userManagement.modal.email')}</Label>
            </div>
            <div className="col-md-9 col-sm-12">
              <Input
                required
                className="form-control"
                name="email"
                value={email}
                onChange={onChange}
                placeholder={t('parameters.userManagement.modal.email')}
                errors={findError('email')}
                maxLength={64}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3">
              <Label required>{t('parameters.userManagement.modal.username')}</Label>
            </div>
            <div className="col-md-9">
              <Input
                required
                className="form-control"
                name="username"
                value={username}
                onChange={onChange}
                placeholder={t('parameters.userManagement.modal.username')}
                errors={findError('username')}
                maxLength={64}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3">
              <Label>{t('parameters.userManagement.modal.phone')}</Label>
            </div>
            <div className="col-md-9">
              <PhoneNumber
                className="form-control"
                name="phone"
                value={phone}
                onChange={value => onChangePhone(value)}
                placeholder={t('parameters.userManagement.modal.phone')}
                errors={findError('phone')}
                country="FR"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">
              <Label required>{t('parameters.userManagement.modal.roles')}</Label>
            </div>
            <div className="col-md-9 col-sm-12">
              <ResponsiveSelect
                required
                name="roles"
                value={valueSelectRoles}
                options={inputSelectRoles.filter(roleOption => {
                  if (roles.indexOf('ROLE_ADMIN') === -1 && roleOption.value === 'ROLE_ADMIN')
                    return false
                  return true
                })}
                placeholder={t('parameters.userManagement.modal.roles')}
                onChange={onChange}
                isMulti
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">
              {t('parameters.userManagement.modal.technicity')}
            </div>
            <div className="col-md-9 col-sm-12">
              <ResponsiveSelect
                name="technicityLevels"
                value={valueSelectTechnicityLevels}
                options={technicityLevelsForSelectInput}
                placeholder={t('parameters.userManagement.modal.technicity')}
                onChange={onChange}
                isMulti
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">{t('parameters.userManagement.modal.skills')}</div>
            <div className="col-md-9 col-sm-12">
              <Input
                className="form-control"
                name="skills"
                value={skills}
                onChange={onChange}
                placeholder={t('parameters.userManagement.modal.skills')}
                errors={[]}
                maxLength={64}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">{t('parameters.userManagement.modal.enabled')}</div>
            <div className="col-md-9 col-sm-12">
              <Toggle name="enabled" checked={enabled} onChange={onChange} />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">
              {t('parameters.userManagement.modal.areasLabel')}
            </div>
            <div className="col-md-9 col-sm-12">
              <AreasInput onChange={onChange} findError={findError} />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-3 col-sm-12">
              {t('parameters.userManagement.modal.plainPassword')}
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="input-group">
                <div className="input-group-prepend clickable" onClick={generatePassword}>
                  <div className="input-group-text">
                    <i className="fa fa-gears" />
                  </div>
                </div>

                <Input
                  className="form-control"
                  type="text"
                  name="plainPassword"
                  value={plainPassword}
                  onChange={onChange}
                  placeholder={t('parameters.userManagement.modal.plainPassword')}
                  errors={findError('plainPassword')}
                  maxLength={64}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-secondary" onClick={closeModal}>
          {t('partyInvolved.modal.close')}
        </button>
        <Button
          className="btn btn-primary"
          onClick={onSave}
          // TODO, !changed prend pas en consideration les zones !! areas
          disabled={saving || !isValid}
        >
          {t('partyInvolved.modal.save')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('CommonStore', 'UserStore'),
  withTranslation(),
  observer,
)(UserModal)
