import Compressor from 'compressorjs'
import EXIF from 'exif-js'

export const isImage = type => {
  return (
    type === 'image/gif' ||
    type === 'image/png' ||
    type === 'image/jpeg' ||
    type === 'image/bmp' ||
    type === 'image/webp' ||
    type === 'image/heif' ||
    type === 'image/heic' ||
    type === 'image/heif-sequence' ||
    type === 'image/heic-sequence'
  )
}

export const compressImage = async file => {
  try {
    const compressedImg = await new Promise((resolve, reject) => {
      new Compressor(file, { quality: 0.6, success: resolve, error: reject })
    })
    return compressedImg
  } catch (error) {
    throw error
  }
}

export const getExifData = file => {
  return new Promise(resolve => {
    EXIF.getData(file, function() {
      const tags = EXIF.getAllTags(this)
      resolve(tags)
    })
  })
}
