import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import InputArea from 'components/InputArea/InputArea'

class AreaCalculator extends React.Component {
  showAreaCalculator = () => {
    if (!DetailPackageCtrl.showAreaCalculator) DetailPackageCtrl.setReaction()
    else DetailPackageCtrl.disposeReaction()
    DetailPackageCtrl.setProperty('showAreaCalculator', !DetailPackageCtrl.showAreaCalculator)
    DetailPackageCtrl.setProperty('width', 0)
    DetailPackageCtrl.setProperty('length', 0)
    DetailPackageCtrl.setProperty('height', 0)
  }

  onChange = e => DetailPackageCtrl.setProperty(e.target.name, e.target.value)

  render() {
    const { t } = this.props
    const { showAreaCalculator, width, length, height, packageData } = DetailPackageCtrl

    return (
      <div className="area-calculator d-inline">
        <i className="fa fa-calculator ml-3 clickable" onClick={this.showAreaCalculator} />

        {showAreaCalculator
          ? [
              <div key="calculator" className="calculator p-3">
                <div className="font-weight-bold mb-2">
                  {t('mission.calculation.detailPackage.areaCalculatorTitle')}
                </div>

                <div
                  className={classNames('row no-gutters d-flex align-items-center mb-2', {
                    disabled: DetailPackageCtrl.inputDisabled('length'),
                  })}
                >
                  <span className="col-5">{t('mission.calculation.detailPackage.length')} :</span>
                  <div className="col-7 text-right">
                    <InputArea
                      name="length"
                      value={length}
                      onChange={this.onChange}
                      disabled={DetailPackageCtrl.inputDisabled('length')}
                    />
                    &nbsp;m
                  </div>
                </div>

                {packageData.localisation !== 'WALL' && (
                  <div
                    className={classNames('row no-gutters d-flex align-items-center mb-2', {
                      disabled: DetailPackageCtrl.inputDisabled('width'),
                    })}
                  >
                    <span className="col-5">{t('mission.calculation.detailPackage.width')} :</span>
                    <div className="col-7 text-right">
                      <InputArea
                        name="width"
                        value={width}
                        onChange={this.onChange}
                        disabled={DetailPackageCtrl.inputDisabled('width')}
                      />
                      &nbsp;m
                    </div>
                  </div>
                )}

                {packageData.localisation === 'WALL' && (
                  <div
                    className={classNames('row no-gutters d-flex align-items-center', {
                      disabled: DetailPackageCtrl.inputDisabled('height'),
                    })}
                  >
                    <span className="col-5">{t('mission.calculation.detailPackage.height')} :</span>
                    <div className="col-7 text-right">
                      <InputArea
                        name="height"
                        value={height}
                        onChange={this.onChange}
                        disabled={DetailPackageCtrl.inputDisabled('height')}
                      />
                      &nbsp;m
                    </div>
                  </div>
                )}
              </div>,
              <div key="overlay" className="overlay" onClick={this.showAreaCalculator} />,
            ]
          : null}
      </div>
    )
  }
}

export default withTranslation()(observer(AreaCalculator))
