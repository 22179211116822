import React from 'react'

const LabelWrapper = ({ inputData }) => {
  return (
    <div style={{ textAlign: inputData.textAlign }}>
      <label
        style={{
          fontSize: inputData.fontSize,
          fontWeight: inputData.fontWeight,
        }}
      >
        {inputData.text}
      </label>
    </div>
  )
}

export default LabelWrapper
