import { observable, action, computed, decorate } from 'mobx'
import i18next from 'i18next'

import User from 'stores/Parameters/UserManagement/User'
import UserStore from 'stores/Common/domain/UserStore'
import CommonStore from 'stores/Common/domain/CommonStore'

class UserManagementCtrl {
  showUserModal = false
  user = null
  customerId = null

  constructor() {
    this.user = new User()
  }

  get inputSelectRoles() {
    return UserStore.rolesAvailable.map(role => ({
      label: i18next.t(`userRoles.${role}`),
      value: role,
    }))
  }

  get valueSelectRoles() {
    return this.user.roles.map(role => {
      const inputValue = this.inputSelectRoles.find(inputRole => inputRole.value === role)
      if (inputValue)
        return {
          label: inputValue.label,
          value: inputValue.value,
        }

      return null
    })
  }

  get valueSelectTechnicityLevels() {
    return this.user.technicityLevels.map(technicityLevel => {
      const inputValue = CommonStore.technicityLevelsForSelectInput.find(
        input => input.value === technicityLevel,
      )
      return inputValue
        ? {
            label: inputValue.label,
            value: inputValue.value,
          }
        : null
    })
  }

  resetForm() {
    this.user = new User()
    this.customerId = null
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedUserManagementCtrl = decorate(UserManagementCtrl, {
  showUserModal: observable,
  user: observable,
  customerId: observable,

  resetForm: action,
  setProperty: action,

  inputSelectRoles: computed,
  valueSelectRoles: computed,
})

export default new DecoratedUserManagementCtrl()
