import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import Tooltip from 'components/Tooltip/Tooltip'

const AddRCLink = ({
  id,
  type,
  packageType,
  endpoint,
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
  CartStore: { relatedCostPackages, overflowPackages, noGuaranteePackages },
}) => {
  const { t } = useTranslation()
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  if (isClosed) return null

  const setContext = () => {
    AddRelatedCostCtrl.setProperty('context', 'new')
    AddRelatedCostCtrl.setNewRelatedCost(packageType)
  }

  let packagesMax = false
  let translation = 'addRelatedCost'
  switch (packageType) {
    case 'relatedCost':
      packagesMax = relatedCostPackages.length === 5
      break
    case 'overflow':
      packagesMax = overflowPackages.length === 5
      translation = 'addOverflow'
      break
    case 'noGuarantee':
      packagesMax = noGuaranteePackages.length === 5
      translation = 'addNoGuarantee'
      break
    default:
      console.warn(`Related cost list packageType is undefined`)
      break
  }

  if (packagesMax) {
    return (
      <Tooltip
        placement="right"
        text={`${t('mission.calculation.relatedCost.limit')} ${t(
          `mission.calculation.relatedCost.${translation}`,
        )}`}
      >
        <span className="btn btn-outline-primary rounded-circle disabled not-allowed">
          <i className="fa fa-plus" />
        </span>
      </Tooltip>
    )
  }

  return (
    <NavLink
      className="btn btn-outline-primary rounded-circle"
      to={`/${type}/${id}/calculation/${endpoint}`}
      onClick={setContext}
    >
      <i className="fa fa-plus" />
    </NavLink>
  )
}

export default inject('MissionStore', 'CartStore', 'ManagerClaimStore')(observer(AddRCLink))
