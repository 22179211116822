import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'

const NavigationButtons = ({
  children,
  CalendarExpertStore: { loadingCalendar, calendarNavigation, viewType },
}) => {
  if (loadingCalendar) return <i className="text-primary fa fa-spinner fa-spin mr-2" />

  return (
    <React.Fragment>
      <i
        className="text-primary mr-4 fa fa-caret-left clickable"
        onClick={() => calendarNavigation({ direction: 'prev', type: viewType })}
      />
      {children}
      <i
        className="text-primary fa fa-caret-right clickable"
        onClick={() => calendarNavigation({ direction: 'next', type: viewType })}
      />
    </React.Fragment>
  )
}

export default compose(
  inject('CalendarExpertStore'),
  observer,
)(NavigationButtons)
