import React from 'react'
import { observer } from 'mobx-react'
import { NavLink, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CreatePackageCtrl from 'stores/Mission/view/CreatePackageCtrl'
import SelectPriceList from './SelectPriceList'
import CartStore from 'stores/Mission/domain/CartStore'

class RadioTabs extends React.Component {
  setContext = outdoorCondition => {
    const { missionRooms } = SearchPackageCtrl
    let overrideLabel = null
    const roomType = {}
    if (SearchPackageCtrl.roomType === 'default' || SearchPackageCtrl.roomType === null) {
      roomType.key = SearchPackageCtrl.missionRooms[0].cde
      roomType.label = SearchPackageCtrl.missionRooms[0].label
    } else if (SearchPackageCtrl.roomType.split('_')[0] === 'noFilter') {
      roomType.key = 'noFilter'
      roomType.label = 'Autre'
    } else {
      const roomTypeStore = missionRooms.find(
        roomType => roomType.cde === SearchPackageCtrl.roomType.split('_')[0],
      )
      if (roomTypeStore) {
        roomType.key = roomTypeStore.cde
        roomType.label = roomTypeStore.label
        if (SearchPackageCtrl.roomType.split('_').length > 1) {
          overrideLabel = SearchPackageCtrl.roomType.split(/_(.+)/)[1]
        }
      } else console.warn(`Room id ${SearchPackageCtrl.roomType} do not exist`)
    }
    CreatePackageCtrl.createPackage(
      roomType,
      SearchPackageCtrl.packageType === 'noFilter' ? 'DAT_EMB' : SearchPackageCtrl.packageType,
      overrideLabel,
      outdoorCondition ? 'outdoor' : 'regular',
    )
  }

  render() {
    const {
      t,
      match: {
        params: { id, type },
        path,
      },
    } = this.props

    const last = path.split('/').pop()
    const outdoorCondition = last === 'searchOutdoorPackage'
    const urlCreatePackage = outdoorCondition ? 'createOutdoorPackage' : 'createPackage'
    const { configuration } = CartStore
    const multiPriceList = configuration && configuration.priceListOptions.length > 1

    return (
      <div className="search-radio-tabs row no-gutters mr-xl-2">
        <SelectPriceList />
        <NavLink
          exact
          to={`/${type}/${id}/calculation/${urlCreatePackage}`}
          onClick={this.setContext.bind(this, outdoorCondition)}
          className={classNames('tab col-12 col-sm-4 col-md-4 col-lg-auto', {
            'last-tab': multiPriceList,
          })}
        >
          {t('mission.calculation.createPackage.breadcrumbNewPackage')}
        </NavLink>

        {outdoorCondition && (
          <NavLink
            exact
            to={`/${type}/${id}/calculation/searchOutdoorFurniture`}
            className={classNames('tab col-12 col-sm-4 col-md-4 col-lg-auto', {
              'last-tab': multiPriceList,
            })}
          >
            {t('mission.calculation.furniture.breadcrumbNewFurniture')}{' '}
            {t('mission.calculation.furniture.outdoor')}
          </NavLink>
        )}
      </div>
    )
  }
}

export default compose(
  withRouter,
  withTranslation(),
  observer,
)(RadioTabs)
