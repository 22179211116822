import React from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import SmartLink from './SmartLink'
import TotalPackages from './TotalPackages'

const FurnitureLinks = ({
  t,
  UserStore: { customer },
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
  id,
  hideElements,
  last,
}) => {
  const { type } = useParams()
  const newState = AddFurnitureCtrl.context === 'new'
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className="breadcrumb m-0 p-0 d-flex align-items-center">
      <SmartLink path={`/${type}/${id}/calculation/listPackage`} selected={hideElements === 2}>
        {t('mission.calculation.cart.breadcrumb')} <TotalPackages />
      </SmartLink>

      <i
        className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
          hide: hideElements !== 0 && hideElements !== 1,
        })}
      />

      {!isClosed && customer.hasContract('RP') && (
        <React.Fragment>
          <SmartLink
            path={`/${type}/${id}/calculation/searchRegularFurniture`}
            selected={last === 'searchRegularFurniture'}
            hide={hideElements !== 0 && hideElements !== 1}
          >
            {t('mission.calculation.searchFurniture.breadcrumbSearch')}{' '}
            {t(`mission.calculation.furniture.regular`)}
          </SmartLink>
          <i
            className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
              hide: hideElements !== 0,
            })}
          />
        </React.Fragment>
      )}

      <SmartLink
        path={`/${type}/${id}/calculation/addRegularFurniture`}
        selected={hideElements === 0}
        hide={hideElements !== 0}
      >
        {t(`mission.calculation.furniture.breadcrumb${newState ? 'NewFurniture' : 'Modify'}`)}{' '}
        {t('mission.calculation.furniture.regular')}
      </SmartLink>
    </div>
  )
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore', 'UserStore'),
  withTranslation(),
  observer,
)(FurnitureLinks)
