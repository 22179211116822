import { observable, action, runInAction, computed, decorate } from 'mobx'
import i18n from 'i18next'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import { fetchCreateMissionForm, fetchSuggestedExperts } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import { path } from 'ramda'

class CreateMissionFormCtrl {
  loading = false
  form = null
  suggestedExpertId = null
  suggestedExperts = []

  currentAddressInput = null
  currentTechnicalLevelInput = null

  loadData = async wan => {
    try {
      this.loading = true
      const form = await fetchCreateMissionForm(wan)
      runInAction(() => {
        const { lastMission } = ClaimStore
        this.suggestedExpertId = path(['suggestedExpertId'], lastMission)
        this.form = new Form({ form, data: lastMission })
        this.loading = false
        this.getCurrentAddressAndTechnicalLevel()
      })
    } catch (err) {
      runInAction(() => (this.loading = false))
    }
  }

  getCurrentAddressAndTechnicalLevel = () => {
    if (this.form) {
      this.form.inputs.forEach(att => {
        if (att.name === 'address') {
          this.currentAddressInput = att
        }

        if (att.name === 'technicalLevel') {
          this.currentTechnicalLevelInput = att
        }
      })
    }
  }

  loadSuggestedExperts = async () => {
    if (this.currentAddressInput && this.currentTechnicalLevelInput) {
      const suggestedExperts = []
      const requestData = {
        address: this.currentAddressInput.value,
        technicalLevel: this.currentTechnicalLevelInput.value,
      }
      const data = await fetchSuggestedExperts(ClaimStore.id, requestData)
      let suggestedExpertIsStillPresent = false
      data.forEach(expert => {
        if (expert.id === this.suggestedExpertId) {
          suggestedExpertIsStillPresent = true
        }
        suggestedExperts.push({
          label: expert.attributes.fullName,
          value: expert.id,
        })
      })
      runInAction(() => {
        this.suggestedExperts = suggestedExperts
        if (!suggestedExpertIsStillPresent) {
          this.suggestedExpertId = null
        }
      })
    }
  }

  checkValidation = () => {
    let isValid = true
    this.form.inputs.forEach(input => {
      if (input.required && !input.value) {
        isValid = false
        input.setProperty('errors', [i18n.t('form.requiredField')])
      }
    })
    return isValid
  }

  get getPostBody() {
    let obj = {}
    if (this.form && this.form.inputs) {
      this.form.inputs.forEach(att => (obj[att.name] = att.value))
    }
    obj.suggestedExpertId = this.suggestedExpertId
    return obj
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}
const DecoratedCreateMissionFormCtrl = decorate(CreateMissionFormCtrl, {
  loading: observable,
  form: observable,
  suggestedExpertId: observable,
  suggestedExperts: observable,
  currentAddressInput: observable,
  currentTechnicalLevelInput: observable,
  setProperty: action,

  loadData: action,
  checkValidation: action,
  loadSuggestedExperts: action,
  getCurrentAddressAndTechnicalLevel: action,

  getPostBody: computed,
})

export default new DecoratedCreateMissionFormCtrl()
