import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import ImageLoader from 'react-load-image'
import { useTranslation } from 'react-i18next'

import './LiteGallery.css'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import { baseUrl } from 'services/utils'
import SupportingDocumentLiteCtrl from 'stores/Mission/view/SupportingDocumentsLiteCtrl'
import ItemIcon from 'components/FallBackThumbnail/ItemIcon'

const ModalPreview = observer(
  ({ token, showImage, imageData, closeImage, hasDeleteBtn = false, onDelete }) => {
    const { t } = useTranslation()
    if (!showImage) return null

    const setImagesLoaded = () => SupportingDocumentsCtrl.setImagesLoaded(imageData.id)

    return (
      <ModalWithBackdrop
        show={showImage}
        close={closeImage}
        size="modal-lg"
        className="package-sd-preview-modal"
      >
        <div className="modal-header">
          <h5 className="modal-title">{imageData.filename}</h5>
          <button type="button" className="close" onClick={closeImage}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="supporting-documents">
            <div className="slide-wrapper">
              <div className="slide-image-wrapper">
                <ImageLoader
                  src={`${baseUrl()}/supportingDocuments/v1/${
                    imageData.id
                  }/thumbnail/lg?access_token=${token}`}
                  onLoad={setImagesLoaded}
                  onError={setImagesLoaded}
                  className="float-left mr-2"
                >
                  <img alt={`sd-detail-${imageData.id}`} />
                  <a
                    className="fallback-thumbnail"
                    href={`${baseUrl()}/supportingDocuments/v1/${
                      imageData.id
                    }/attachment?access_token=${token}`}
                  >
                    <ItemIcon mimeType={imageData.mimeType} />
                  </a>
                  <ImageLoading />
                </ImageLoader>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          {hasDeleteBtn && (
            <button
              className="btn btn-danger"
              onClick={() => {
                onDelete(imageData.id)
                closeImage()
              }}
            >
              <i className="fa fa-trash" /> {t('common.delete')}
            </button>
          )}
          <a
            className="btn btn-primary"
            href={`${baseUrl()}/supportingDocuments/v1/${
              imageData.id
            }/attachment?access_token=${token}`}
          >
            <i className="fa fa-download" /> {t('common.download')}
          </a>
        </div>
      </ModalWithBackdrop>
    )
  },
)

const Thumbnail = inject('UserStore')(
  observer(
    class Thumbnail extends React.Component {
      showImage = ({ id, filename, mimeType }) => {
        SupportingDocumentLiteCtrl.setProperty('imageData', { id, filename, mimeType })
        SupportingDocumentLiteCtrl.setProperty('showImage', !SupportingDocumentLiteCtrl.showImage)
      }
      setImagesLoaded = id => SupportingDocumentsCtrl.setImagesLoaded(id)

      render() {
        const {
          data: { id, filename, mimeType },
          UserStore: { token },
          thumbnailWidth,
          thumbnailHeight,
        } = this.props

        return (
          <div
            className="supporting-document mr-3 mb-2"
            style={{ width: thumbnailWidth, height: thumbnailHeight }}
          >
            <ImageLoader
              className="clickable thumbnail-package"
              src={`${baseUrl()}/supportingDocuments/v1/${id}/thumbnail/lg?access_token=${token}`}
              onLoad={this.setImagesLoaded.bind(this, id)}
              onError={this.setImagesLoaded.bind(this, id)}
            >
              <img
                onClick={this.showImage.bind(this, { id, filename, mimeType })}
                alt={`sd-${id}`}
                style={{ width: thumbnailWidth, height: thumbnailHeight }}
              />
              <div
                className="fallback-thumbnail"
                onClick={this.showImage.bind(this, { id, filename, mimeType })}
                style={{ width: thumbnailWidth, height: thumbnailHeight }}
              >
                <ItemIcon mimeType={mimeType} />
              </div>
              <ImageLoading />
            </ImageLoader>

            <div className="sd-infos">
              <p className="sd-filename px-1">{filename}</p>
            </div>
          </div>
        )
      }
    },
  ),
)

class LiteGallery extends React.Component {
  closeImage = () => SupportingDocumentLiteCtrl.setProperty('showImage', false)

  render() {
    const { sdForPackage } = SupportingDocumentStore
    const { showImage, imageData } = SupportingDocumentLiteCtrl
    const {
      supportingDocuments,
      forPackages,
      thumbnailWidth,
      thumbnailHeight,
      emptyMessage,
      hasDeleteBtn = false,
      onDelete = () => {},
      UserStore: { token },
    } = this.props
    let packageInvoices = []
    let images = []

    if (forPackages) {
      if (sdForPackage.length > 0) {
        packageInvoices = supportingDocuments.map(invoiceId => {
          return sdForPackage.find(sdInvoice => invoiceId === sdInvoice.id)
        })
      }
      packageInvoices = packageInvoices.filter(packageInvoice => packageInvoice !== undefined)
    } else {
      images = supportingDocuments
    }

    return (
      <div className="lite-gallery mt-4" style={{ flexWrap: 'wrap' }}>
        {images.length === 0 && packageInvoices.length === 0 && emptyMessage.length > 0 ? (
          <p className="p-4 text-center">{emptyMessage}</p>
        ) : (
          <div className="d-flex">
            {forPackages &&
              packageInvoices.map(image => (
                <Thumbnail
                  key={`thumbnail-${image.id}`}
                  data={image}
                  thumbnailWidth={thumbnailWidth}
                  thumbnailHeight={thumbnailHeight}
                />
              ))}

            {!forPackages &&
              images.map(image => (
                <Thumbnail
                  key={`thumbnail-${image.id}`}
                  data={image}
                  thumbnailWidth={thumbnailWidth}
                  thumbnailHeight={thumbnailHeight}
                />
              ))}
          </div>
        )}

        <ModalPreview
          token={token}
          showImage={showImage}
          imageData={imageData}
          closeImage={this.closeImage}
          hasDeleteBtn={hasDeleteBtn}
          onDelete={onDelete}
        />
      </div>
    )
  }
}

LiteGallery.defaultProps = {
  supportingDocuments: [],
  forPackages: false,
  thumbnailWidth: '100px',
  thumbnailHeight: '70px',
  emptyMessage: '',
}

LiteGallery.propTypes = {
  supportingDocuments: PropTypes.array,
  forPackages: PropTypes.bool,
  thumbnailWidth: PropTypes.string,
  thumbnailHeight: PropTypes.string,
  emptyMessage: PropTypes.string,
}

export default inject('UserStore')(observer(LiteGallery))
