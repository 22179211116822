import { observable, computed, action, decorate, runInAction, toJS } from 'mobx'
import { path, isNil, keys } from 'ramda'

import {
  sendEmail,
  sendStandardDocument,
  sendStandardDocumentManager,
  sendStandardDocumentClaimManager,
  sendEmailCfm,
  generateReport,
  downloadReportPdf,
  retreiveIrsiReportFromDarva,
  syncDarva,
  sendDarva,
  sendRectifReportReason,
  getReportType,
  getReportValidator,
  getReportValidatorClaimFileInsurer,
  fetchSDDetail,
  retrieveDarva,
  saveReportToSD,
} from 'services/report'
import { baseUrl } from 'services/utils'
import UiCtrl from 'stores/Common/view/UiCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import MissionStore from 'stores/Mission/domain/MissionStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import { emailRegex, PromiseAllSkipErrors } from 'utils'
import { handleSendToDarvaErrors } from 'utils/report'
import MessagingStore from 'stores/Messaging/MessagingStore'

class ReportCtrl {
  email = ''
  subject = ''
  emailModal = false
  fieldErrors = []
  loading = false
  sendReportPending = false
  sendReportRectifPending = false
  reportContent = ''
  reportIrsiContent = ''
  imageDisplayModal = false
  imageDisplayUrl = ''
  numPages = 0
  availableReports = []
  reportDetails = []
  currentReport = null
  SDAttachedToReport = []
  loadingSDDetails = false
  antiSpamActivated = false
  showSyncDarvaBtn = false
  retrievingDarva = false
  lastTimeKeyDown = new Date()
  lastTypeKeyCodes = []

  // VALIDATION
  loadingValidator = false
  showValidatorModal = false
  validatorErrors = []

  savingReport = false

  get availableReportsForSelect() {
    return this.availableReports.map(report => ({
      value: report.key,
      label: report.value,
    }))
  }

  get isEmailValid() {
    return emailRegex.test(toJS(this.email))
  }

  get currentReportObj() {
    const currentReportObj = path([this.currentReport], this.reportDetails)
    if (isNil(currentReportObj)) return null

    return toJS(currentReportObj)
  }

  get currentReportType() {
    return path(['reportType'], this.currentReportObj)
  }

  toggleModal = () => {
    if (this.emailModal) this.email = ''
    this.emailModal = !this.emailModal
    if (this.emailModal) this.subject = ''
    if (this.emailModal) MessagingStore.currentMessage.setProperty('documents', [])
    if (this.emailModal) MessagingStore.currentMessage.setProperty('text', '')
  }

  closeModal = () => {
    if (this.emailModal) this.email = ''
    this.emailModal = false
  }

  setEmail = value => {
    this.email = value
  }

  setSubject = value => {
    this.subject = value
  }

  setError = errorMsg => {
    this.fieldErrors.push(errorMsg)
  }

  resetErrors = () => {
    this.fieldErrors = []
  }

  validateEmail = (wan, claimType = 'cfa', subject, content, attachments) => {
    this.resetErrors()
    if (this.isEmailValid) {
      if (claimType === 'cfm') {
        this.sendEmailCfm(wan)
      } else {
        this.sendEmail(wan, subject, content, attachments)
      }
      this.emailModal = false
    } else {
      UiCtrl.language === 'fr-FR'
        ? this.setError("Format d'email invalide")
        : this.setError('Invalid Email format')
    }
  }

  validateEmailStandard = (wan, claimType = 'cfa', subject, content, attachments) => {
    this.resetErrors()
    if (this.isEmailValid) {
      if (claimType === 'cfm') {
        this.sendEmailCfm(wan)
      } else {
        this.sendEmailStandard(wan, subject, content, attachments)
      }
      this.emailModal = false
    } else {
      UiCtrl.language === 'fr-FR'
        ? this.setError("Format d'email invalide")
        : this.setError('Invalid Email format')
    }
  }

  validateEmailStandardManager = (wan, claimType = 'cfa', subject, content, attachments) => {
    this.resetErrors()
    if (this.isEmailValid) {
      if (claimType === 'cfm') {
        this.sendEmailCfm(wan)
      } else {
        this.sendEmailStandardManager(wan, subject, content, attachments)
      }
      this.emailModal = false
    } else {
      UiCtrl.language === 'fr-FR'
        ? this.setError("Format d'email invalide")
        : this.setError('Invalid Email format')
    }
  }

  validateEmailStandardClaimManager = (wan, claimType = 'cfa', subject, content, attachments) => {
    this.resetErrors()
    if (this.isEmailValid) {
      if (claimType === 'cfm') {
        this.sendEmailCfm(wan)
      } else {
        this.sendEmailStandardClaimManager(wan, subject, content, attachments)
      }
      this.emailModal = false
    } else {
      UiCtrl.language === 'fr-FR'
        ? this.setError("Format d'email invalide")
        : this.setError('Invalid Email format')
    }
  }

  sendEmail = (wan, subject, content, attachments) => {
    this.loading = true
    sendEmail(
      wan,
      toJS(this.email),
      subject,
      content,
      attachments,
      path(['SHreportType'], this.currentReportObj),
    )
      .then(
        action(() => {
          AlertCtrl.alert('success', 'mission.report.emailSent.success')
          this.loading = false
          this.email = ''
        }),
      )
      .catch(
        action(() => {
          AlertCtrl.alert('danger', 'mission.report.emailSent.failure')
          this.loading = false
        }),
      )
  }

  sendEmailStandard = (wan, subject, content, attachments) => {
    this.loading = true
    sendStandardDocument(wan, toJS(this.email), subject, content, attachments)
      .then(
        action(() => {
          AlertCtrl.alert('success', 'mission.report.emailSent.success')
          this.loading = false
          this.email = ''
        }),
      )
      .catch(
        action(() => {
          AlertCtrl.alert('danger', 'mission.report.emailSent.failure')
          this.loading = false
        }),
      )
  }

  sendEmailStandardManager = (wan, subject, content, attachments) => {
    this.loading = true
    sendStandardDocumentManager(wan, toJS(this.email), subject, content, attachments)
      .then(
        action(() => {
          AlertCtrl.alert('success', 'mission.report.emailSent.success')
          this.loading = false
          this.email = ''
        }),
      )
      .catch(
        action(() => {
          AlertCtrl.alert('danger', 'mission.report.emailSent.failure')
          this.loading = false
        }),
      )
  }

  sendEmailStandardClaimManager = (wan, subject, content, attachments) => {
    this.loading = true
    sendStandardDocumentClaimManager(wan, toJS(this.email), subject, content, attachments)
      .then(
        action(() => {
          AlertCtrl.alert('success', 'mission.report.emailSent.success')
          this.loading = false
          this.email = ''
        }),
      )
      .catch(
        action(() => {
          AlertCtrl.alert('danger', 'mission.report.emailSent.failure')
          this.loading = false
        }),
      )
  }

  sendEmailCfm = wan => {
    this.loading = true
    sendEmailCfm(wan, toJS(this.email))
      .then(
        action(() => {
          AlertCtrl.alert('success', 'mission.report.emailSent.success')
          this.loading = false
          this.email = ''
        }),
      )
      .catch(
        action(() => {
          AlertCtrl.alert('danger', 'mission.report.emailSent.failure')
          this.loading = false
        }),
      )
  }

  sendToDarva = async wan => {
    this.loading = true
    this.sendReportPending = true

    try {
      await sendDarva(wan, this.currentReportType)

      runInAction(() => {
        MissionStore.updateStatus('CARS')
        MissionStore.updateSd13Sd70()
        if (['WR', 'WR_REC', 'WR_COM'].includes(this.currentReportType)) {
          MissionStore.reloadAndUpdateWorkRequest()
        }
        this.getReportType(wan, false)
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
        this.email = ''
      })

      return { isSent: true }
    } catch (err) {
      return handleSendToDarvaErrors(err)
    } finally {
      runInAction(() => {
        this.loading = false
        this.sendReportPending = false
      })
    }
  }

  saveReportToSD = async wan => {
    this.savingReport = true

    try {
      await saveReportToSD(wan, path(['SHreportType'], this.currentReportObj))
      SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      AlertCtrl.alert('success', 'mission.report.save')
    } catch (err) {
      return handleSendToDarvaErrors(err)
    } finally {
      runInAction(() => {
        this.savingReport = false
      })
    }
  }

  setRectifReportReason = async (wan, payload) => {
    this.loading = true
    this.sendReportRectifPending = true

    try {
      await sendRectifReportReason(wan, payload, this.currentReportType)

      runInAction(() => {
        MissionStore.updateStatus('CARS')
        MissionStore.updateSd13Sd70()
        if (['WR', 'WR_REC', 'WR_COM'].includes(this.currentReportType)) {
          MissionStore.reloadAndUpdateWorkRequest()
        }
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      })

      return { isSent: true }
    } catch (err) {
      return handleSendToDarvaErrors(err)
    } finally {
      runInAction(() => {
        this.loading = false
        this.sendReportRectifPending = false
      })
    }
  }

  setReportContent = content => {
    this.reportContent = content
  }

  generateReport = async (wan, missionStatus, missionId) => {
    // this.loading = true
    this.loadingSDDetails = true
    this.setReportContent('')

    try {
      console.log('this.currentReportObj  ', this.currentReportObj)
      const res = await generateReport(
        wan,
        missionStatus,
        path(['SHreportType'], this.currentReportObj),
      )

      runInAction(() => {
        if (res.body) {
          this.setReportContent(res.body)
          this.SDAttachedToReport = res.supportingDocumentIds || []
          if (!UserStore.isExpert && this.SDAttachedToReport.length > 0) {
            this.fetchSDAttachedToReport(missionId)
          }
          if (this.SDAttachedToReport.length === 0) {
            this.loadingSDDetails = false
          }
        } else {
          this.setReportContent(res)
        }
        // this.loading = false
      })
    } catch (error) {
      console.warn(error)
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  generateIRSIReport = async wan => {
    this.loading = true
    this.setProperty('reportIrsiContent', '')

    try {
      let reportDetails = this.reportDetails
      if (!reportDetails) {
        const reportType = await getReportType(wan)
        reportDetails = reportType.reportDetails
      }

      if (keys(reportDetails).indexOf('IRSI_REPORT') !== -1) {
        const res = await generateReport(wan, false, 'IRSI_REPORT')
        runInAction(() => {
          if (res.body) {
            this.setProperty('reportIrsiContent', res.body)
          }
          this.loading = false
        })
      }
    } catch (error) {
      console.warn(error)
    }
  }

  fetchSDAttachedToReport = async missionId => {
    try {
      const res = await PromiseAllSkipErrors(
        this.SDAttachedToReport.map(id => fetchSDDetail({ wan: missionId, sdId: id })),
      )

      runInAction(() => {
        this.SDAttachedToReport = res
          .filter(sd => !isNil(sd))
          .map(sd => ({
            id: sd.id,
            filename: sd.filename,
            mimeType: sd.mimeType,
          }))
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingSDDetails = false
      })
    }
  }

  resetReportType = () => {
    this.availableReports = []
    this.reportDetails = []
    this.currentReport = null
    this.reportContent = ''
  }

  getReportType = async (wan, changeLoadingStatus = true) => {
    try {
      if (changeLoadingStatus) this.loading = true
      const { availableReports, reportDetails } = await getReportType(wan)
      runInAction(() => {
        if (availableReports.length === 0) throw new Error(`No available report for ${wan}`)

        this.availableReports = availableReports
        this.currentReport = path([0, 'key'], availableReports)
        this.reportDetails = reportDetails
        if (changeLoadingStatus) this.loading = false
      })
    } catch (err) {
      console.warn('getReportType : ', err)
    }
  }

  downloadReportPdf = async wan => {
    try {
      const res = await downloadReportPdf(wan, path(['SHreportType'], this.currentReportObj))
      //We create a blob pdf to create a fake link to download the pdf
      const blob = new Blob([res], { type: 'application/pdf' })
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.href = window.URL.createObjectURL(blob)
      link.download = wan + '.pdf'
      link.target = '_self'
      link.click()
      link.remove()
    } catch (err) {
      console.warn(err)
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  retreiveIrsiReportFromDarva = async wan => {
    try {
      await retreiveIrsiReportFromDarva(wan)
      SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      await this.getReportType(wan)
      AlertCtrl.alert('success', 'mission.report.retreiveIrsiSuccess')
    } catch (err) {
      console.warn(err)
      AlertCtrl.alert('danger', err.errors)
    }
  }

  syncDarva = async wan => {
    try {
      await syncDarva(wan)
      runInAction(() => {
        this.showSyncDarvaBtn = false
      })
      AlertCtrl.alert('success', 'mission.report.syncDarvaSuccess')
      window.location.reload()
    } catch (err) {
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  handleKeyDown = e => {
    const now = new Date()
    if (now - this.lastTimeKeyDown > 1000) {
      this.lastTypeKeyCodes = []
    }

    this.lastTimeKeyDown = now
    const keyCode = e.keyCode
    this.lastTypeKeyCodes.push(keyCode)

    if (this.lastTypeKeyCodes.length >= 10) {
      // konami cheat code Up, Up, Down, Down, Left, Right, Left, Right, B, A
      const konami = this.lastTypeKeyCodes.slice(-10).join('-')
      if (konami === '38-38-40-40-37-39-37-39-66-65') {
        this.showSyncDarvaBtn = true
      }
    }
  }

  toggleImageDisplayModal = () => {
    this.imageDisplayModal = !this.imageDisplayModal
    if (!this.imageDisplayModal) this.imageDisplayUrl = ''
  }

  setImageDisplayUrl = sdId => {
    this.imageDisplayUrl = `${baseUrl()}/supportingDocuments/v1/${sdId}/attachment?access_token=${
      UserStore.token
    }`
  }

  reportValidator = async wan => {
    return await this.validate(wan, path(['SHreportType'], this.currentReportObj))
  }

  pvValidator = async wan => {
    return await this.validate(wan, 'PV_REPORT')
  }

  newMissionValidator = async wan => {
    return await this.validate(wan, 'MISSION_CREATION_REPORT')
  }

  newMissionValidatorClaimFileInsurer = async wan => {
    return await this.validateClaimFileInsurer(wan, 'MISSION_CREATION_REPORT')
  }

  validate = async (wan, reportType) => {
    this.loadingValidator = true
    this.validatorErrors = []
    this.loading = true

    try {
      const data = await getReportValidator(wan, reportType)
      runInAction(() => {
        if (data === 204) {
          this.showValidatorModal = false
        } else {
          this.loading = false
          this.showValidatorModal = true
          this.validatorErrors = data
        }
      })
      return data
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingValidator = false
        this.loading = false
      })
    }
  }

  validateClaimFileInsurer = async (wan, reportType) => {
    this.loadingValidator = true
    this.validatorErrors = []
    this.loading = true
    try {
      const data = await getReportValidatorClaimFileInsurer(wan, reportType)
      runInAction(() => {
        if (data === 204) {
          this.showValidatorModal = false
        } else {
          this.loading = false
          this.showValidatorModal = true
          this.validatorErrors = data
        }
      })
      return data
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loadingValidator = false
        this.loading = false
      })
    }
  }

  activateAntiSpam = () => {
    this.antiSpamActivated = true

    setTimeout(() => {
      runInAction(() => {
        this.antiSpamActivated = false
      })
    }, 3000)
  }

  retrieveDarva = async wan => {
    this.retrievingDarva = true

    try {
      await retrieveDarva(wan)
      MissionStore.setProperty('retrieveReportButtonAvailable', false)
      SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
      AlertCtrl.alert('success', 'mission.report.successRetrieveDarva')
    } catch (error) {
      console.warn(error)
    } finally {
      runInAction(() => {
        this.retrievingDarva = false
      })
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }
}

const DecoratedReportCtrl = decorate(ReportCtrl, {
  SDAttachedToReport: observable,
  email: observable,
  subject: observable,
  emailModal: observable,
  fieldErrors: observable,
  loading: observable,
  sendReportPending: observable,
  sendReportRectifPending: observable,
  reportContent: observable,
  reportIrsiContent: observable,
  imageDisplayUrl: observable,
  numPages: observable,
  showSyncDarvaBtn: observable,
  loadingValidator: observable,
  showValidatorModal: observable,
  validatorErrors: observable,
  availableReports: observable,
  reportDetails: observable,
  currentReport: observable,
  loadingSDDetails: observable,
  antiSpamActivated: observable,
  retrievingDarva: observable,
  savingReport: observable,

  availableReportsForSelect: computed,
  currentReportObj: computed,
  currentReportType: computed,
  isEmailValid: computed,

  toggleModal: action,
  closeModal: action,
  setEmail: action,
  setSubject: action,
  setError: action,
  resetErrors: action,
  validateEmail: action,
  sendEmail: action,
  validateEmailStandard: action,
  validateEmailStandardManager: action,
  validateEmailStandardClaimManager: action,
  sendEmailStandard: action,
  sendEmailStandardManager: action,
  sendEmailStandardClaimManager: action,
  setReportContent: action,
  generateReport: action,
  resetReportType: action,
  generateIRSIReport: action,
  downloadReportPdf: action,
  retreiveIrsiReportFromDarva: action,
  toggleImageDisplayModal: action,
  setImageDisplayUrl: action,
  sendToDarva: action,
  setRectifReportReason: action,
  getReportType: action,
  handleKeyDown: action,
  syncDarva: action,
  reportValidator: action,
  activateAntiSpam: action,
  setProperty: action,
  pvValidator: action,
  newMissionValidator: action,
  newMissionValidatorClaimFileInsurer: action,
  validate: action,
  validateClaimFileInsurer: action,
  retrieveDarva: action,
  saveReportToSD: action,
})

export default new DecoratedReportCtrl()
