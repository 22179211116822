import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import DrawCtrl, { DrawElements } from 'stores/Mission/view/DrawCtrl'
import DrawColorPicker from './DrawColorPicker'
import StrokeWidth from './StrokeWidth'
import StrokeWidthSelect from './StrokeWidthSelect'

class ToolBar extends React.Component {
  componentDidMount() {
    document.addEventListener('click', e => this.onClickOutside(e), true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', e => this.onClickOutside(e), true)
  }
  onClickOutside = e => {
    if (DrawCtrl.strokeWidthOpen && !this.strokeMenu.contains(e.target)) {
      DrawCtrl.toggleStrokeWidthSelect()
      e.preventDefault()
    } else if (DrawCtrl.colorPickerOpen && !this.colorPickerMenu.contains(e.target)) {
      DrawCtrl.toggleColorPicker()
      e.preventDefault()
    }
  }
  toggleColorPicker = () => {
    if (!DrawCtrl.colorPickerOpen) DrawCtrl.toggleColorPicker()
  }
  toggleStrokeWidthSelect = () => {
    if (!DrawCtrl.strokeWidthOpen) DrawCtrl.toggleStrokeWidthSelect()
  }
  setType = type => DrawCtrl.setType(type)
  undo = () => DrawCtrl.undo()

  render() {
    const colorPicker = {
      color: DrawCtrl.color,
    }

    return (
      <div className="col-1 toolbar">
        <div className="btn-group-vertical" ref="toolBar">
          <div className="btn-group mb-2">
            <button
              className={classNames('btn btn-outline-primary', {
                disabled: DrawCtrl.elements.length === 0,
              })}
              onClick={this.undo}
            >
              <i className="fa fa-undo" />
            </button>
          </div>
          <div className={classNames('btn-group mb-2', { show: DrawCtrl.strokeWidthOpen })}>
            <button
              className="btn btn-outline-primary stroke-width-btn"
              onClick={this.toggleStrokeWidthSelect}
            >
              <StrokeWidth strokeWidth={DrawCtrl.strokeWidth} />
            </button>
            <div ref={strokeMenu => (this.strokeMenu = strokeMenu)}>
              <StrokeWidthSelect />
            </div>
          </div>
          <div className="btn-group mb-4">
            <button
              className="btn btn-outline-primary stroke-width-btn"
              onClick={this.toggleColorPicker}
            >
              <i className="fa fa-tint" style={colorPicker} />
            </button>
          </div>
          <div className="btn-group mb-2">
            <button
              className={classNames('btn btn-outline-primary', {
                active: DrawCtrl.type === DrawElements.ARROW,
              })}
              onClick={this.setType.bind(this, DrawElements.ARROW)}
            >
              <i className="fa fa-arrow-circle-right" />
            </button>
          </div>
          <div className="btn-group mb-2">
            <button
              className={classNames('btn btn-outline-primary', {
                active: DrawCtrl.type === DrawElements.LINE,
              })}
              onClick={this.setType.bind(this, DrawElements.LINE)}
            >
              <i className="fa fa-minus" />
            </button>
          </div>
          <div className="btn-group mb-2">
            <button
              className={classNames('btn btn-outline-primary', {
                active: DrawCtrl.type === DrawElements.CIRCLE,
              })}
              onClick={this.setType.bind(this, DrawElements.CIRCLE)}
            >
              <i className="fa fa-circle" />
            </button>
          </div>
          <div className="btn-group mb-2">
            <button
              className={classNames('btn btn-outline-primary', {
                active: DrawCtrl.type === DrawElements.RECTANGLE,
              })}
              onClick={this.setType.bind(this, DrawElements.RECTANGLE)}
            >
              <i className="fa fa-square" />
            </button>
          </div>
          <div className="btn-group mb-2">
            <button
              className={classNames('btn btn-outline-primary', {
                active: DrawCtrl.type === DrawElements.TEXT_AREA,
              })}
              onClick={this.setType.bind(this, DrawElements.TEXT_AREA)}
            >
              <i className="fa fa-font" />
            </button>
          </div>
          <div className="btn-group">
            <button
              type="button"
              className={classNames('btn btn-outline-primary', {
                active: DrawCtrl.type === DrawElements.FREE_DRAWING,
              })}
              onClick={this.setType.bind(this, DrawElements.FREE_DRAWING)}
            >
              <i className="fa fa-pencil" />
            </button>
          </div>
        </div>
        <div ref={colorPickerMenu => (this.colorPickerMenu = colorPickerMenu)}>
          <DrawColorPicker />
        </div>
      </div>
    )
  }
}

export default withTranslation()(observer(ToolBar))
