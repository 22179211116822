import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import ImageLoader from 'react-load-image'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import { baseUrl } from 'services/utils'
import FallBackThumbnail from 'components/FallBackThumbnail/FallBackThumbnail'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import ImageLoading from 'components/ImageLoading/ImageLoading'

const DeleteModal = ({
  t,
  UserStore: { token, isInsurer, isClaimManager },
  match: { params },
  MissionStore,
  ManagerClaimStore,
}) => {
  const renderDeleteContent = () => {
    return (
      <div className="modal-body">
        {!!SupportingDocumentsCtrl.deletableSelectedSd.length
          ? [
              <h6 key="title-to-delete">
                {t('mission.supportingDocuments.readyToDelete', {
                  count: SupportingDocumentsCtrl.deletableSelectedSd.length,
                })}
              </h6>,
              <div key="list-to-delete" className="can-be-delete row">
                {SupportingDocumentsCtrl.deletableSelectedSd.map((id, index) => {
                  return (
                    <div key={index} className="">
                      <ImageLoader
                        src={`${baseUrl()}/supportingDocuments/v1/${id}/attachment?access_token=${token}`}
                        onLoad={() => {
                          SupportingDocumentsCtrl.setImagesLoaded(id)
                        }}
                        onError={() => {
                          SupportingDocumentsCtrl.setImagesLoaded(id)
                        }}
                      >
                        <img alt={'supporting document'} />
                        <FallBackThumbnail />
                        <ImageLoading />
                      </ImageLoader>
                      <span>{SupportingDocumentsCtrl.getSdById(id)['name']}</span>
                    </div>
                  )
                })}
              </div>,
              !!SupportingDocumentsCtrl.notDeletableSelectedSd.length && (
                <h6 key="title-to-not-delete" className="title-to-not-delete">
                  {t('mission.supportingDocuments.canNotBeDelete', {
                    count: SupportingDocumentsCtrl.notDeletableSelectedSd.length,
                  })}
                </h6>
              ),
              !!SupportingDocumentsCtrl.notDeletableSelectedSd.length && (
                <div key="list-to-not-delete" className="row">
                  {SupportingDocumentsCtrl.notDeletableSelectedSd.map((id, index) => {
                    return (
                      <div key={index} className="item-to-not-delete">
                        <ImageLoader
                          src={`${baseUrl()}/supportingDocuments/v1/${id}/attachment?access_token=${token}`}
                          onLoad={() => {
                            SupportingDocumentsCtrl.setImagesLoaded(id)
                          }}
                          onError={() => {
                            SupportingDocumentsCtrl.setImagesLoaded(id)
                          }}
                        >
                          <img alt={'supporting document'} />
                          <FallBackThumbnail />
                          <ImageLoading />
                        </ImageLoader>
                        <span>{SupportingDocumentsCtrl.getSdById(id)['name']}</span>
                      </div>
                    )
                  })}
                </div>
              ),
            ]
          : [
              <h6 key="title-to-not-delete" className="title-to-not-delete">
                {t('mission.supportingDocuments.canNotBeDelete')}
              </h6>,
              <div key="list-to-not-delete" className="row">
                {SupportingDocumentsCtrl.notDeletableSelectedSd.map((id, index) => {
                  return (
                    <div key={index} className="item-to-not-delete">
                      <ImageLoader
                        src={`${baseUrl()}/supportingDocuments/v1/${id}/attachment?access_token=${token}`}
                        onLoad={() => {
                          SupportingDocumentsCtrl.setImagesLoaded(id)
                        }}
                        onError={() => {
                          SupportingDocumentsCtrl.setImagesLoaded(id)
                        }}
                      >
                        <img alt={'supporting document'} />
                        <FallBackThumbnail />
                        <ImageLoading />
                      </ImageLoader>
                      <span>{SupportingDocumentsCtrl.getSdById(id)['name']}</span>
                    </div>
                  )
                })}
              </div>,
            ]}
      </div>
    )
  }

  if (!SupportingDocumentsCtrl.modalDeleteDisplay) return null

  return (
    <ModalWithBackdrop
      show={SupportingDocumentsCtrl.modalDeleteDisplay}
      close={value => value}
      size="modal-lg"
      className="deleteModal"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t('mission.supportingDocuments.actions.delete')}</h5>
        <button
          type="button"
          className="close"
          onClick={() => SupportingDocumentsCtrl.removeDeleteModal()}
        >
          <span>&times;</span>
        </button>
      </div>
      {renderDeleteContent()}
      <div className="modal-footer pb-2 pt-2">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => SupportingDocumentsCtrl.removeDeleteModal()}
        >
          {t('common.cancel')}
        </button>
        {!!SupportingDocumentsCtrl.deletableSelectedSd.length && (
          <Button
            className="btn btn-danger"
            onClick={() =>
              SupportingDocumentsCtrl.deleteSupportingDocument(
                isInsurer
                  ? params.id
                  : isClaimManager
                  ? ManagerClaimStore.claim.wan
                  : MissionStore.id,
              )
            }
            loading={SupportingDocumentsCtrl.loading}
          >
            {t('common.confirm')}
          </Button>
        )}
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('UserStore', 'MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(DeleteModal)
