import React from 'react'
import './ImageLoading.css'

const ImageLoading = () => {
  return (
    <div className="image-loading mx-auto">
      <i className="fa fa-spinner fa-spin" />
    </div>
  )
}

export default ImageLoading
