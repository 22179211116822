import { observable, action, runInAction, decorate } from 'mobx'
import i18next from 'i18next'
import { path } from 'ramda'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import User from 'stores/Parameters/UserManagement/User'
import { multipleRequests } from 'services/utils'
import { fetchUsers, fetchChildren, fetchCustomer } from 'services/userManagement'

class UserManagementStore {
  parentId = null
  users = []
  level = null
  loadingData = false

  addNewUser(userRawData, userData) {
    const user = this.users.find(user => user.id === userRawData.customer.id)

    if (user) user.users.push(userData)
    else console.warn('Something went wrong no customer id found')
  }

  async loadData(customerId) {
    this.loadingData = true
    try {
      const resChildren = await fetchChildren(customerId)
      const customersId = resChildren.map(customer => customer.id)

      if (customersId.length === 0) {
        const resCustomer = await fetchCustomer(customerId)
        const res = await fetchUsers({ customerId, recursive: true })
        runInAction(() => {
          this.users = [
            {
              id: customerId,
              name: resCustomer.name,
              users: res.map(user => new User(user)),
            },
          ]
          this.level = 0
          this.parentId = path(['parent', 'id'], resCustomer) || null
          this.loadingData = false
        })
      } else {
        const resMultiUsers = await multipleRequests(
          customersId.map(customerId => fetchUsers({ customerId, recursive: true })),
        )
        runInAction(() => {
          this.users = resChildren.map((child, index) => ({
            ...child,
            users: resMultiUsers[index].map(user => new User(user)),
          }))
          this.level = 1
          this.parentId = null
          this.loadingData = false
        })
      }
    } catch (err) {
      if (err.status === 403) {
        AlertCtrl.alert('danger', i18next.t('parameters.userManagement.loadData403'))
      }

      runInAction(() => {
        this.loadingData = false
      })
      console.log(err)
    }
  }
}

const DecoratedUserManagementStore = decorate(UserManagementStore, {
  parentId: observable,
  users: observable,
  level: observable,
  loadingData: observable,

  addNewUser: action,
  loadData: action,
})
export default new DecoratedUserManagementStore()
