import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'

import './Toggle.css'

const noop = () => {}

class Toggle extends React.Component {
  state = {
    checked: this.props.checked,
    hasFocus: false,
  }
  handleClick = () => {
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onChange({ target: { name: this.props.name, value: this.state.checked } })
    })
  }
  handleFocus = () => {
    this.setState({ hasFocus: true })
  }
  handleBlur = () => {
    this.setState({ hasFocus: false })
  }

  componentDidMount() {
    if (this.props.autoFocus === true) {
      this.handleFocus()
      this.input.focus()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  render() {
    const { checked, hasFocus } = this.state
    const { t, disabled, autoFocus, name, onChange } = this.props

    return (
      <div
        className={classNames(
          'toggle',
          { 'toggle--checked': checked },
          { 'toggle--focus': hasFocus },
          { 'toggle--disabled': disabled },
        )}
        onClick={disabled ? () => {} : this.handleClick}
      >
        <div className="toggle-track text-nowrap">
          <div className="toggle-track-check">{t('common.yes')}</div>
          <div className="toggle-track-x">{t('common.no')}</div>
        </div>
        <div className="toggle-thumb" />

        <input
          ref={ref => {
            this.input = ref
          }}
          name={name}
          onChange={onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className="toggle-screenreader-only"
          type="checkbox"
          disabled={disabled}
          autoFocus={autoFocus}
        />
      </div>
    )
  }
}

Toggle.defaultProps = {
  name: 'toggleName',
  checked: false,
  autoFocus: false,
  onChange: noop,
}

Toggle.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
}

export default withTranslation()(Toggle)
