import React from 'react'
import { observer } from 'mobx-react'

import DescriptionPrejudices from 'pages/Mission/pages/Conclusion/DescriptionPrejudices'
import DescriptionExcess from 'pages/Mission/pages/Conclusion/DescriptionExcess'
import DescriptionFurnitures from 'pages/Mission/pages/Conclusion/DescriptionFurnitures'
import RepairPrecisions from 'pages/Mission/pages/Conclusion/RepairPrecisions'
import RepairSettlements from 'pages/Mission/pages/Conclusion/RepairSettlements'
import InstructionPackages from 'pages/ManagerClaim/pages/Instruction/Tables/Packages'
import InstructionFurnitures from 'pages/ManagerClaim/pages/Instruction/Tables/Furnitures'
import InstructionSettlements from 'pages/ManagerClaim/pages/Instruction/Tables/Settlements'
import SuggestedExpert from 'pages/Claim/Pages/Qualification/components/SuggestedExpert'
import InputString from './InputStringWrapper'
import InputNumber from './InputNumberWrapper'
import TextArea from './TextAreaWrapper'
import SelectWithSearch from './SelectWithSearchWrapper'
import MultiSelect from './MultiSelectWrapper'
import Radio from './RadioWrapper'
import Toggle from './ToggleWrapper'
import Address from './AddressWrapper'
import Date from './DateWrapper'
import InvolvedParty from './InvolvedPartyWrapper'
import InputPrice from './InputPriceWrapper'
import SupportingDocuments from './SupportingDocuments'
import EmbeddedCollection from './EmbeddedCollection'
import Label from './LabelWrapper'
import PvEstimation from 'pages/Mission/pages/Pv/PvEstimation'
import Signature from 'components/DynForm/Signature'
import PhoneNumber from 'components/DynForm/PhoneNumberWrapper'

const InputComponent = ({ trigger, input, formObj = null }) => {
  switch (input.type) {
    case 'address':
      return <Address inputData={input} />
    case 'tel':
      return <PhoneNumber inputData={input} />
    case 'datetime':
      return <Date inputData={input} />
    case 'text':
      return <InputString inputData={input} />
    case 'textarea':
      return <TextArea inputData={input} />
    case 'number':
      return <InputNumber inputData={input} />
    case 'inputPrice':
      return <InputPrice inputData={input} />
    case 'involvedPartySelectType':
      return <InvolvedParty inputData={input} formInputs={formObj} /> //trigger undefined
    case 'signature':
      return <Signature inputData={input} formInputs={formObj} trigger={trigger} />
    case 'supportingDocumentsType':
      return <SupportingDocuments inputData={input} />
    case 'table':
      if (input.name === 'descriptionPrejudices') return <DescriptionPrejudices />
      else if (input.name === 'descriptionExcess') return <DescriptionExcess />
      else if (input.name === 'descriptionFurnitures') return <DescriptionFurnitures />
      else if (input.name === 'repairPrecisions') return <RepairPrecisions />
      else if (input.name === 'repairSettlements') return <RepairSettlements />
      else if (input.name === 'instructionPackages') return <InstructionPackages />
      else if (input.name === 'instructionFurnitures') return <InstructionFurnitures />
      else if (input.name === 'instructionSettlements') return <InstructionSettlements />
      else if (input.name === 'suggestedExpertField') return <SuggestedExpert />
      else if (input.name === 'pvEstimationTable') return <PvEstimation />
      return null
    case 'choice':
    case 'entity':
      if (input.componentType === 'toggle') return <Toggle inputData={input} />
      else if (input.componentType === 'radio') return <Radio inputData={input} />
      else if (input.componentType === 'checkbox') return <MultiSelect inputData={input} />
      return <SelectWithSearch inputData={input} formInputs={formObj} />
    case 'embeddedCollection':
      return <EmbeddedCollection collectionData={input} trigger={trigger} />
    case 'label':
      return <Label inputData={input} />
    default:
      console.warn('unknow input type : ', input.type)
      return null
  }
}

export default observer(InputComponent)
