import React from 'react'
import styled from 'styled-components'

import {
  isPDFMimeType,
  isMSWordMimeType,
  isMSExcelMimeType,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import WordUrl from 'assets/word.svg'
import PdfUrl from 'assets/pdf.svg'
import ExcelUrl from 'assets/excel.svg'

const ImgIcon = styled.img`
  width: 60px !important;
  height: 60px !important;
  background: transparent !important;
  border: none !important;
`

const ItemIcon = ({ mimeType }) => {
  if (isPDFMimeType(mimeType)) return <ImgIcon src={PdfUrl} alt="pdf icon" />
  else if (isMSWordMimeType(mimeType)) return <ImgIcon src={WordUrl} alt="word icon" />
  else if (isMSExcelMimeType(mimeType)) return <ImgIcon src={ExcelUrl} alt="excel icon" />

  return <i className="fa fa-file" />
}

export default ItemIcon
