import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { path } from 'ramda'

import { formatCurrency } from 'utils/currency'

const TableFields = ({ classNames, tableName, column, data, addDivider, title, headersPath }) => {
  const { t } = useTranslation()
  if (!data) return null

  return (
    <Fragment>
      {addDivider && <div className="divider" />}
      {title && (
        <div className="row">
          <div className="font-weight-bold">{title}</div>
        </div>
      )}
      <div className={`d-flex flex-column align-items-center ${classNames}`}>
        <table border="1" className="line-display table table-bordered table-sm">
          <thead>
            <tr className="table-primary d-flex">
              <th className="col-8">{tableName}</th>
              <th className="col-4">{t(`${headersPath}.${column}`)}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(value => (
              <tr key={value} className="d-flex">
                <td className="col-8">{t(`${headersPath}.${value}`)}</td>
                <td className="col-4">
                  {path(['currency'], data[value])
                    ? formatCurrency(data[value][column], path(['currency'], data[value]))
                    : data[value][column] || 'Oui'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}
TableFields.defaultProps = {
  classNames: '',
  tableName: 'Type',
  column: 'amount',
  addDivider: false,
  headersPath: 'headers',
}

TableFields.propTypes = {
  classNames: PropTypes.string,
  tableName: PropTypes.string,
  column: PropTypes.string,
  data: PropTypes.object,
  addDivider: PropTypes.bool,
}

export default TableFields
