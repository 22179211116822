import React from 'react'

function CalendarHeader({ label }) {
  const day = label.split(' ')

  return (
    <React.Fragment>
      <div className="custom-day-number">{day[1]}</div>
      <div className="custom-day">{day[0]}</div>
    </React.Fragment>
  )
}

export default CalendarHeader
