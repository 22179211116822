import { observable, computed, action, decorate } from 'mobx'
import { forEachObjIndexed, isNil } from 'ramda'

import AbstractField from 'utils/dynform/AbstractField'
import { formatEntityPath } from 'utils/dynform/formHelper'

const _objToArray = choices => {
  const options = []
  forEachObjIndexed((value, key) => {
    options.push({
      label: key,
      value,
    })
  }, choices)
  return options
}

class Select extends AbstractField {
  value = null
  originalValue = null
  errors = []
  show = true
  choices = null

  allChoices = null

  type = 'choice'
  maxLength = null
  path = null
  multiple = false
  expanded = false
  nullable = false
  linked = null
  hidden = false
  extraData = null

  children = null

  componentType = 'select'

  constructor(data) {
    super(data)

    this.type = data.type
    this.choices = data.choices || null
    this.allChoices = data.allChoices || null
    this.maxLength = data.maxLength || null
    this.multiple = data.multiple
    this.expanded = data.expanded
    this.nullable = data.nullable || false
    this.linked = data.linked || null
    this.hidden = data.hidden || false

    if (data.type === 'entity') this.path = formatEntityPath(data.path)
    else this.path = data.path || null

    this.componentType = data.componentType || 'select'

    // array of values for multiple
    if (this.multiple) {
      this.value = data.value || []
    } // in select default first option of choices
    else if (!this.nullable && data.value === null) {
      this.value = this.firstOption
    } else {
      if (data.value !== null) this.value = data.value
      else if (data.value === null && data.default !== null) this.value = data.default
      else this.value = ''
    }

    this.extraData = isNil(data.extraData) ? null : data.extraData

    this.show = data.show
    this.originalValue = this.value
  }

  get options() {
    return _objToArray(this.choices)
  }

  get firstOption() {
    return this.options[0].value
  }

  get changed() {
    return this.originalValue !== this.value
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedSelect = decorate(Select, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,
  choices: observable,
  extraData: observable,

  setOriginalData: action,
  setChoicesWhenLinked: action,

  options: computed,
  firstOption: computed,
  changed: computed,
})

export default DecoratedSelect
