import React, { useState, useEffect } from 'react'
import packageJson from '../package.json'
import moment from 'moment'

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true
  } else {
    return false
  }
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

    useEffect(() => {
      fetch('/meta.json')
        .then(response => response.json())
        .then(meta => {
          const latestVersionDate = meta.buildDate
          const currentVersionDate = packageJson.buildDate

          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate)
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false)
            console.log('Force refresh applied')
            refreshCacheAndReload()
          } else {
            console.log('Force refresh not applied')
            setIsLatestBuildDate(true)
          }
        })
    }, [])

    const refreshCacheAndReload = () => {
      if (caches) {
        caches.keys().then(names => {
          // eslint-disable-next-line
          for (const name of names) {
            caches.delete(name)
          }
        })
      }
      window.location.reload(true)
    }

    return <React.Fragment>{isLatestBuildDate ? <Component {...props} /> : null}</React.Fragment>
  }

  return ClearCacheComponent
}

export default withClearCache
