import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { trim } from 'ramda'

import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import Button from 'components/Button/Button'
import FilterMissionStatus from './FilterMissionStatus'
import Input from 'components/Forms/Input'
import Label from 'components/Label/Label'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import { uid } from 'utils'
import Filter from './Filter'

class FiltersForm extends React.Component {
  onChange = e => DashboardFilterStore.currentFilter.setProperty(e.target.name, e.target.value)
  onChangeFilter = e =>
    DashboardFilterStore.currentFilter.setProperty(e.target.name, e.target.value)
  saveFilter = () => DashboardFilterStore.saveFilters()
  deleteFilter = () => DashboardFilterStore.deleteFilter()
  onBlurInputName = e =>
    DashboardFilterStore.currentFilter.setProperty(e.target.name, trim(e.target.value))

  generateSortSelectOptions = () => {
    const { t } = this.props
    const result = []
    DashboardCtrl.headersForSortList.forEach(current => {
      result.push({
        value: `ascending_${current.type}_${current.field}`,
        label: `${current.label} ${t('dashboard.sorting.ascending')}`,
      })

      result.push({
        value: `descending_${current.type}_${current.field}`,
        label: `${current.label} ${t('dashboard.sorting.descending')}`,
      })
    })
    return result
  }

  render() {
    const {
      t,
      UserStore: {
        customer: { id },
        isExpert,
        hasRole,
      },
      CommonStore: { missionTypesForSelectInput },
    } = this.props
    const { showForm, currentFilter, isNewFilter } = DashboardFilterStore
    const customersOptions = [
      {
        value: 'all',
        label: t('dashboard.filter.form.allAssignments'),
      },
      {
        value: id,
        label: t('dashboard.filter.form.onlyMyAssignments'),
      },
    ]

    if (showForm === false) return null

    return (
      <div className="row no-gutters mt-2">
        <div className="col-12">
          <div className="row small-gutters">
            {!hasRole('ROLE_INTERNAL_EXPERT') && (
              <div className="form-group col-12 col-md-6 col-lg-3">
                <label>{t('dashboard.filter.form.missionsAffectedToMe')} : </label>
                <ResponsiveSelect
                  options={customersOptions}
                  name="assigneeUserId"
                  onChange={this.onChangeFilter}
                  value={currentFilter.assigneeUserId}
                />
              </div>
            )}
            {isExpert && (
              <div className="form-group col-12 col-md-6 col-lg-3">
                <label>{t('dashboard.filter.form.missionType')}</label>
                <ResponsiveSelect
                  name="missionType"
                  value={currentFilter.missionType}
                  onChange={this.onChangeFilter}
                  options={missionTypesForSelectInput}
                />
              </div>
            )}

            <div className="form-group col-12 col-md-6 col-lg-3">
              <label>{t('dashboard.filter.form.missionStatus')}</label>
              <FilterMissionStatus />
            </div>

            <div className="form-group col-12 col-md-6 col-lg-3">
              <label>{t('dashboard.filter.form.sortedBy')}</label>
              <ResponsiveSelect
                name="sortedBy"
                value={currentFilter.sortedBy}
                onChange={this.onChangeFilter}
                options={this.generateSortSelectOptions()}
              />
            </div>
          </div>

          <div className="row small-gutters" key={uid(6)}>
            {DashboardCtrl.headersForSortList.map(filter => (
              <Filter filter={filter} key={uid(6)} />
            ))}
          </div>

          <div className="card-footer card-footer-inside-body">
            <div className="row small-gutters align-items-end">
              <div className="form-group col-12 col-md-6">
                <Label required>{t('dashboard.filter.form.save')}</Label>
                <Input
                  className="form-control mb-2"
                  name="name"
                  value={currentFilter.name}
                  placeholder={t('dashboard.filter.form.filterName')}
                  onChange={this.onChange}
                  onBlur={this.onBlurInputName}
                  maxLength={55}
                />
              </div>
              <div className="form-group col-12 col-md-6 text-right">
                {!isNewFilter && (
                  <Button className="btn btn-danger mr-2 mb-2" onClick={this.deleteFilter}>
                    <i className="fa fa-trash-o" /> {t('common.delete')}
                  </Button>
                )}

                <Button
                  className="btn btn-primary mb-2"
                  onClick={this.saveFilter}
                  disabled={currentFilter.name.length === 0}
                >
                  <i className="fa fa-save" /> {t('common.submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('UserStore', 'CommonStore')(withTranslation()(observer(FiltersForm)))
