import React from 'react'

import { euro } from 'utils/currency'

const Formula = ({ quantity, areaPresent, price }) => {
  if (quantity === 1 || areaPresent) return null

  return <span>{`${quantity} x ${euro(price)}`}</span>
}

export default Formula
