import React from 'react'

import Li from './Li'

const Pagination = ({ totalPages, page, goToPage }) => {
  if (totalPages <= 1) return null

  return (
    <nav className="pagination-navbar">
      <ul className="pagination">
        <Li
          faIcon="fast-backward"
          srOnly="First"
          disabled={page === 1}
          onClick={() => goToPage(1)}
        />
        <Li
          faIcon="angle-double-left"
          srOnly="Previous"
          disabled={page === 1}
          onClick={() => goToPage(page - 1)}
        />

        {[...Array(totalPages).keys()].map(i => {
          if ((page > 5 || i >= 5) && (page <= 5 || page <= i || page > i + 5)) return null

          return (
            <Li key={i} onClick={() => goToPage(i + 1)} active={page === i + 1}>
              {i + 1}
            </Li>
          )
        })}

        <Li
          faIcon="angle-double-right"
          srOnly="Next"
          disabled={page === totalPages}
          onClick={() => goToPage(page + 1)}
        />
        <Li
          faIcon="fast-forward"
          srOnly="Last"
          disabled={page === totalPages}
          onClick={() => goToPage(totalPages)}
        />
      </ul>
    </nav>
  )
}

export default Pagination
