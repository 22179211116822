import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Label from 'components/Label/Label'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'
import InputPrice from 'components/InputPrice/InputPrice'
import InputNumber from 'components/InputNumber/InputNumber'
import Button from 'components/Button/Button'
import CustomItemCtrl from 'stores/Mission/view/CustomItemCtrl'

const CustomItemForm = ({ t, packageData, priceType }) => {
  const { customItem, confirmCustomItem, cancelCustomItem, unitsByPriceType } = CustomItemCtrl

  const unitChoices = {}
  unitsByPriceType(priceType).reduce((choices, unit) => {
    choices[unit] = t(`mission.calculation.createCustomItem.${unit}`)
    return choices
  }, unitChoices)

  const setDescription = e => {
    customItem.setProperty('desc', e.target.value)
  }

  const setUnit = e => {
    customItem.setProperty('unit', e.target.value)
  }

  const setQuantity = e => {
    customItem.setProperty('quantityForCustomItem', e.target.value)
    customItem.setProperty(
      'overrideTotalPrice',
      customItem.quantityForCustomItem * customItem.priceUnitForCustomItem,
    )
  }

  const setUnitPrice = e => {
    customItem.setProperty('priceUnitForCustomItem', e.target.value)
    customItem.setProperty(
      'overrideTotalPrice',
      customItem.quantityForCustomItem * customItem.priceUnitForCustomItem,
    )
  }

  if (!customItem) return null

  return (
    <div className="border mb-2 p-2">
      <div className="row no-gutters">
        <div className="form-group col-12">
          <Label required> {t('mission.calculation.createCustomItem.description')}</Label>
          <Input
            name="description"
            className="form-control"
            value={customItem.desc}
            required={true}
            onChange={setDescription}
          />
        </div>
      </div>
      <div className="row no-gutters d-flex justify-content-between">
        <div className="form-group">
          <Label required> {t('mission.calculation.createCustomItem.unit')}</Label>
          <Select
            className="form-control"
            name="unit"
            value={customItem.unit}
            onChange={setUnit}
            options={unitChoices}
          />
        </div>
        <div className="form-group">
          <Label required> {t('mission.calculation.createCustomItem.quantity')}</Label>
          <InputNumber
            name="quantityForCustomItem"
            className="form-control"
            value={customItem.quantityForCustomItem}
            required={true}
            onChange={setQuantity}
            minValue={0}
          />
        </div>
        <div className="form-group">
          <Label required> {t('mission.calculation.createCustomItem.priceUnitHT')}</Label>
          <InputPrice
            className="form-control"
            name="priceUnitForCustomItem"
            price={customItem.priceUnitForCustomItem}
            classNamesForPrice="font-weight-bold text-primary form-control"
            disableShowUnit
            maxLengthNumber={6}
            required={true}
            inputPriceWrapper={false}
            autoComplete="off"
            onChange={setUnitPrice}
          />
        </div>
        <div className="form-group">
          <Label required> {t('mission.calculation.createCustomItem.totalHT')}</Label>
          <InputPrice
            className="form-control"
            name="totalHT"
            price={customItem.overrideTotalPrice || 0}
            classNamesForPrice="font-weight-bold text-primary form-control"
            disableShowUnit
            maxLengthNumber={6}
            disabled={true}
            inputPriceWrapper={false}
          />
        </div>
      </div>
      <div className="row no-gutters">
        <div className="form-group col-12 d-flex justify-content-end">
          <Button className="btn btn-secondary" onClick={cancelCustomItem}>
            {t('common.close')}
          </Button>
          <Button className="ml-2 btn btn-primary" onClick={() => confirmCustomItem(packageData)}>
            {customItem.cde === null ? t('common.add') : t('common.save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(CustomItemForm)
