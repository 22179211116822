import React from 'react'
import './Loader.css'

export default ({ loadingMsg }) => {
  return (
    <div className="loader">
      <div className="loader__inner">
        <i />
        <i />
        <i />
        <i />
      </div>
      <div className="d-flex flex-column align-items-center text-secondary">
        {loadingMsg &&
          loadingMsg
            .split('\n')
            .reduce((acc, cur, i) => (acc ? [...acc, <div key={i}>{cur}</div>] : [cur]), null)}
      </div>
    </div>
  )
}
