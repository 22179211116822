import { observable, action, computed, decorate } from 'mobx'
import moment from 'moment'

import { uid } from 'utils'

class Filter {
  id = uid(10)
  name = ''
  assigneeUserId = 'all'
  missionType = 'all'
  missionStatus = 'allOpen'
  sortedBy = 'descending_date_mission.createdAt'
  dynamicFilters = []
  default = false
  saved = false

  constructor(data = null) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.assigneeUserId = data.assigneeUserId
      this.missionType = data.missionType
      this.missionStatus = data.missionStatus
      this.sortedBy = data.sortedBy
      this.setDynamicFilters(data.dynamicFilters)
      if (data.default) this.default = data.default
      if (data.name.length > 0) this.saved = true
    }
  }

  upsertDynamicFilter(filter) {
    if (
      filter.value === '' ||
      filter.value === null ||
      (filter.type === 'date' && filter.value.key === '')
    )
      this.dynamicFilters = this.dynamicFilters.filter(df => df.key !== filter.key)
    else {
      const df = this.dynamicFilters.find(df => df.key === filter.key)

      if (df) df.value = filter.value
      else this.dynamicFilters.push(filter)
    }
  }

  setProperty(key, value) {
    this[key] = value
  }

  setDynamicFilters(dynamicFilters) {
    dynamicFilters.map(filter => {
      if (filter.type === 'date') {
        if (filter.value.startDate && typeof filter.value.startDate === 'string')
          filter.value.startDate = moment(filter.value.startDate)
        if (filter.value.endDate && typeof filter.value.endDate === 'string')
          filter.value.endDate = moment(filter.value.endDate)
      }

      return filter
    })

    this.dynamicFilters = dynamicFilters
  }

  get sortedByInformations() {
    const split = this.sortedBy.split('_')

    return {
      direction: split[0],
      type: split[1],
      field: split[2],
    }
  }
}

const DecoratedFilter = decorate(Filter, {
  name: observable,
  assigneeUserId: observable,
  missionType: observable,
  missionStatus: observable,
  sortedBy: observable,
  dynamicFilters: observable,
  default: observable,
  saved: observable,

  upsertDynamicFilter: action,
  setProperty: action,

  sortedByInformations: computed,
})

export default DecoratedFilter
