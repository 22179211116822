import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import UserManagementStore from 'stores/Parameters/UserManagement/UserManagementStore'
import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import Users from './Users'
import UserModal from './UserModal'
import { uid } from 'utils'

class UserManagement extends React.Component {
  UNSAFE_componentWillMount() {
    const { id } = this.props.UserStore.customer

    UserManagementStore.loadData(id)
    // UserManagementStore.loadData('FR00000008G')
  }

  loadData = customerId => {
    UserManagementStore.loadData(customerId)
  }

  render() {
    const {
      t,
      UserStore: { loading },
    } = this.props
    const { users, parentId, loadingData } = UserManagementStore

    return (
      <div className="user-management-page">
        <div className="row no-gutters">
          <h5 className="mb-4">{t('parameters.userManagement.title')}</h5>
        </div>

        <CardLayout className="user-management">
          {loading || loadingData ? (
            <Loader />
          ) : (
            <div>
              {parentId !== null ? (
                <div
                  className="btn btn-success clickable float-right mb-2"
                  onClick={this.loadData.bind(this, parentId)}
                >
                  {t('parameters.userManagement.parent')}
                </div>
              ) : null}

              {users.map(user => (
                <Users key={uid(10)} customerId={user.id} name={user.name} users={user.users} />
              ))}
            </div>
          )}
        </CardLayout>

        <UserModal />
      </div>
    )
  }
}

export default inject('UserStore')(withTranslation()(observer(UserManagement)))
