import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import RadioTabs from 'components/Forms/RadioTabs'

const options = [
  { key: 'low', value: <i className="fa fa-star" /> },
  {
    key: 'medium',
    value: (
      <span>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </span>
    ),
  },
  {
    key: 'high',
    value: (
      <span>
        <i className="fa fa-star" />
        <i className="fa fa-star" />
        <i className="fa fa-star" />
      </span>
    ),
  },
]

const QualityMaterials = ({
  qualityType,
  materialPresent,
  changeQuality,
  showLabel = true,
  MissionStore: { isMissionStore },
  ManagerClaimStore: { isClaimClosed },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const isClosed = type === 'mission' ? isMissionStore : isClaimClosed

  if (!materialPresent) return <div className="col-5" />

  return (
    <div className="quality-materials d-flex">
      {showLabel && (
        <span className="font-weight-bold">
          {t('mission.calculation.detailPackage.qualityMaterials')}
        </span>
      )}
      <RadioTabs
        name="qualityType"
        value={qualityType}
        options={options}
        onChange={changeQuality}
        disabled={isClosed}
      />
    </div>
  )
}

export default inject('MissionStore', 'ManagerClaimStore')(observer(QualityMaterials))
