import React from 'react'
import { inject, observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

const TotalPackages = ({
  MissionStore: { isIRSI, selectedIRSICart, selectedIRSICartName },
  CartStore: { totalPackages, packages, furnitures, relatedCosts },
}) => {
  const { pathname } = useLocation()

  if (!isIRSI) return <span>({totalPackages})</span>

  const filterByIP = data => data.involvedParty === selectedIRSICart

  const packagesIP = packages.filter(filterByIP)
  const furnituresIP = furnitures.filter(filterByIP)
  const relatedCostsIP = relatedCosts.filter(filterByIP)
  const totalPackagesIP = packagesIP.length + furnituresIP.length + relatedCostsIP.length

  return (
    <span>
      {pathname.split('/').pop() === 'listPackage' ? null : selectedIRSICartName} ({totalPackagesIP}
      )
    </span>
  )
}

export default inject('MissionStore', 'CartStore')(observer(TotalPackages))
