import { observable, action, runInAction, decorate } from 'mobx'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { resetPassword } from 'services/authentification'

class ResetPasswordCtrl {
  saving = false
  passwordChanged = false
  token = ''
  email = ''
  password = ''
  repeatPassword = ''
  errors = []

  setProperty(key, value) {
    this[key] = value
  }

  async resetPassword() {
    this.saving = true
    try {
      await resetPassword({
        token: this.token,
        email: this.email,
        password: this.password,
        repeatPassword: this.repeatPassword,
      })
      runInAction(() => {
        this.passwordChanged = true
      })
      AlertCtrl.alert('success', 'resetPasswordPage.success')
    } catch (err) {
      err.globals.forEach(error => {
        AlertCtrl.alert('danger', `${error}`)
      })

      runInAction(() => {
        this.errors = err.fields
      })
    }

    runInAction(() => {
      this.saving = false
    })
  }
}

const DecoratedResetPasswordCtrl = decorate(ResetPasswordCtrl, {
  saving: observable,
  passwordChanged: observable,
  token: observable,
  email: observable,
  password: observable,
  repeatPassword: observable,
  errors: observable,

  setProperty: action,
  resetPassword: action,
})

export default new DecoratedResetPasswordCtrl()
