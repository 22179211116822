import React from 'react'
import Dropzone from 'react-dropzone'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil, path } from 'ramda'

import SupportingDocumentsCtrl, {
  SupportingDocTypes,
  SupportingDocFileTypes,
  SupportingDocHorsDarvaFileTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import Upload from 'stores/Common/view/UploadCtrl'
import CommonStore from 'stores/Common/domain/CommonStore'

class FiltersDropZone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readyForDrops: {},
      filesAccepted: '',
    }
  }

  addEffectStyle = (e, index) => {
    if (!this.state.readyForDrops[index]) {
      this.setState({ ...this.state, readyForDrops: { [index]: true } })
    }
  }

  removeEffectStyle = (e, index) => {
    this.setState({ ...this.state, readyForDrops: { [index]: false } })
  }

  handleFilesAccepted = () => {
    switch (SupportingDocumentsCtrl.sdCategory) {
      case SupportingDocTypes.SCHEMA:
        return SupportingDocFileTypes.SCHEMA

      case SupportingDocTypes.DOCUMENT:
        if (CommonStore.isHorsDarva) {
          return SupportingDocHorsDarvaFileTypes.DOCUMENT
        }
        return SupportingDocFileTypes.DOCUMENT

      case SupportingDocTypes.INVOICE:
        return SupportingDocFileTypes.INVOICE

      default:
        if (CommonStore.isHorsDarva) {
          return SupportingDocHorsDarvaFileTypes.PHOTO
        }
        return SupportingDocFileTypes.PHOTO
    }
  }

  render() {
    const categories = path(
      [SupportingDocumentsCtrl.sdCategory],
      CommonStore.supportingDocumentsTypes,
    )

    // Add protection if categories is undefined
    if (!SupportingDocumentsCtrl.dropZoneFiltered || isNil(categories)) {
      return null
    }

    return (
      <div className="list row filter-drop-zone">
        {SupportingDocumentsCtrl.sdCategory !== 'CSCH' &&
          SupportingDocumentsCtrl.sdCategory !== 'CINV' &&
          Object.keys(CommonStore.supportingDocumentsTypes[SupportingDocumentsCtrl.sdCategory]).map(
            (type, index) => {
              return (
                <div
                  key={`dropzone-filter-${index}`}
                  className="sd-drop-item col-lg-3 col-md-4 col-xs-6"
                >
                  <div
                    className={classNames('sd-drop-item-inner filter-drop-zone-item ', {
                      readyForDrops: this.state.readyForDrops[index],
                    })}
                  >
                    <Dropzone
                      name={'sd-dropzone'}
                      onDrop={(acceptedFiles, rejectedFiles) => {
                        Upload.setAcceptedFiles(acceptedFiles, Upload.setDefaultType(type))
                        Upload.setRejectedFiles(rejectedFiles)
                        this.removeEffectStyle(null, index)
                      }}
                      multiple={true}
                      className="dropzone"
                      accept={this.handleFilesAccepted()}
                      onDragOver={e => this.addEffectStyle(e, index)}
                      onDragLeave={e => this.removeEffectStyle(e, index)}
                    >
                      <i className="fa fa-cloud-upload" />
                      <p>
                        {
                          CommonStore.supportingDocumentsTypes[SupportingDocumentsCtrl.sdCategory][
                            type
                          ]
                        }
                      </p>
                    </Dropzone>
                  </div>
                </div>
              )
            },
          )}
      </div>
    )
  }
}

export default withTranslation()(observer(FiltersDropZone))
