import request from 'services/httpRequest'

export const fetchDepartures = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/tours/v1/',
    })

    return res.data.map(tour => ({
      id: tour.attributes.id,
      dayOfWeek: tour.attributes.day_of_week,
      address: {
        addressLine1: tour.attributes.address.address_line1,
        addressLine2: tour.attributes.address.address_line2,
        addressLine3: tour.attributes.address.address_line3,
        city: tour.attributes.address.city,
        country: tour.attributes.address.country,
        geometry: {
          lat: tour.attributes.address.latitude,
          long: tour.attributes.address.longitude,
        },
        streetNumber: tour.attributes.address.street_number,
        zipCode: tour.attributes.address.zip_code,
      },
    }))
  } catch (err) {
    return Promise.reject(err)
  }
}

export const updateDepartures = async data => {
  try {
    const res = await request({
      method: 'post',
      url: '/tours/v1/',
      data: {
        data: {
          type: 'tours',
          attributes: {
            tours: data,
          },
        },
      },
    })

    return res
  } catch (err) {
    return Promise.reject(err)
  }
}
