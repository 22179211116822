import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { noop } from 'utils'

const DeleteIcon = ({ showConfirmButton, showDeleteAction, deleteTab, cde }) => {
  const { t } = useTranslation()

  if (!showDeleteAction) return null
  else if (showConfirmButton)
    return (
      <ButtonWithConfirmation
        classNameWrapper="ml-2 d-inline"
        icon
        iconClassName="fa fa-trash"
        onClick={() => deleteTab(cde)}
        confirmText={t('mission.calculation.cart.confirmDelete')}
        withoutButton
        centered
      />
    )

  return <i className="clickable fa fa-trash ml-2" onClick={() => deleteTab(cde)} />
}

const Tabs = ({
  list,
  selected,
  onSelect,
  add,
  addText,
  deleteTab,
  showConfirmButton,
  showDeleteAction,
  listKey,
  disabled,
}) => {
  return (
    <ul className="nav nav-tabs mb-3">
      {list.map(data => (
        <li className="nav-item" key={`room-${data[listKey]}`}>
          <span
            className={classNames('nav-link clickable py-2', {
              active: selected === data[listKey],
            })}
          >
            <span onClick={() => onSelect(data[listKey])}>{data.label}</span>

            {!disabled && (
              <DeleteIcon
                showDeleteAction={showDeleteAction}
                showConfirmButton={showConfirmButton}
                deleteTab={deleteTab}
                cde={data[listKey]}
              />
            )}
          </span>
        </li>
      ))}

      {!disabled && (
        <li className="nav-item" onClick={add}>
          <span
            className={classNames(
              'nav-link py-2',
              { active: selected === '' },
              { disabled, clickable: !disabled, 'not-allowed': disabled },
            )}
          >
            <i className="fa fa-plus" /> {addText}
          </span>
        </li>
      )}
    </ul>
  )
}

Tabs.defaultProps = {
  list: [],
  selected: '',
  addText: '',
  showConfirmButton: true,
  showDeleteAction: true,
  add: noop,
  onSelect: noop,
  deleteTab: noop,
  listKey: 'cde',
  disabled: false,
}

Tabs.propTypes = {
  list: PropTypes.array,
  selected: PropTypes.string,
  addText: PropTypes.string,
  add: PropTypes.func,
  onSelect: PropTypes.func,
  deleteTab: PropTypes.func,
  showConfirmButton: PropTypes.bool,
  showDeleteAction: PropTypes.bool,
  listKey: PropTypes.string,
  disabled: PropTypes.bool,
}

export default observer(Tabs)
