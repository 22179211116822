import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import MissionStore from 'stores/Mission/domain/MissionStore'
import CommonStore from 'stores/Common/domain/CommonStore'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import CausesAndCircumstancesCtrl from 'stores/Mission/view/CausesAndCircumstancesCtrl'
import SupportingDocuments from 'stores/Common/view/SupportingDocumentsCtrl'
import Header from 'components/Header/Header'
import Loader from 'components/Loader/Loader'

const PageLayout = WrappedComponent => {
  class ComponentWrapper extends React.Component {
    render() {
      const {
        i18n,
        t,
        match: {
          path,
          params: { id },
        },
        UiCtrl: { loading, showOnlyCard },
        UserStore: { token },
      } = this.props
      const userStoreLoading = this.props.UserStore.loading

      const changeLanguage = lng => {
        this.props.UiCtrl.changeLanguage(lng)
        i18n.changeLanguage(lng)
        const mappingServices = {
          '/': () => {
            DashboardCtrl.fetchMissions()
            CommonStore.loadCoverages(true)
          },
          '/mission/:id': () => {
            CausesAndCircumstancesCtrl.loadData(id)
            SupportingDocuments.fetchSupportingDocuments(id)
            MissionStore.loadData(id)
          },
        }
        if (token && mappingServices[path]) {
          mappingServices[path]()
        }
        if (token) CommonStore.loadData()
      }

      const page =
        loading || userStoreLoading ? (
          <Loader loading={t('common.loading')} />
        ) : (
          <WrappedComponent {...this.props} />
        )

      return (
        <div className={classNames({ showOnlyCard: showOnlyCard }, 'container pl-0 pr-0')}>
          <Header changeLanguage={changeLanguage} />
          <div
            className={classNames({
              'inner-container': !showOnlyCard,
              'inner-container-showOnlyCard': showOnlyCard,
            })}
          >
            {page}
          </div>
        </div>
      )
    }
  }

  return inject('UiCtrl', 'UserStore')(withRouter(withTranslation()(observer(ComponentWrapper))))
}

export default PageLayout
