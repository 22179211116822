import moment from 'moment'

export default locale => {
  const isoMapping = { 'fr-FR': 'fr_FR', 'en-US': 'en_US', 'de-DE': 'de_DE', 'nl-NL': 'nl_NL' }
  return isoMapping[locale]
}

export const baseUrl = () => {
  let baseURL = ''
  switch (process.env.NODE_ENV) {
    case 'production':
      baseURL = process.env.REACT_APP_API_URL_PRODUCTION
      break
    case 'development':
      baseURL = process.env.REACT_APP_API_URL_DEVELOPMENT
      break
    case 'test':
      baseURL = process.env.REACT_APP_API_URL_TEST
      break
    default:
      console.err('Error with process env')
      return
  }
  return baseURL
}

export const baseURLQlik = () => process.env.QLIL_URL

export const multipleRequests = async arrayRequests => {
  try {
    const res = await Promise.all(arrayRequests)
    return res
  } catch (err) {
    return Promise.reject(err)
  }
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format('DD/MM/YYYY HH:MM')
  return buildDate
}

export const delay = time => {
  return new Promise(function(resolve) {
    setTimeout(resolve, time)
  })
}
