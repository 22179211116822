import React from 'react'
import { observer } from 'mobx-react'

import Checkbox from 'components/Checkbox/Checkbox'

const MultiSelectWrapper = ({ inputData }) => {
  const onChange = e => inputData.setProperty('value', e.target.value)

  return <Checkbox {...inputData} onChange={onChange} />
}

export default observer(MultiSelectWrapper)
