import React from 'react'
import { roundDecimal } from 'utils/currency'

export default ({ part, unitByDefault, area, quantity }) => {
  if (part === 'C' || part === 'F' || part === 'W') {
    return (
      <span>
        {roundDecimal(area)} m<sup>2</sup>
      </span>
    )
  } else if (unitByDefault.length > 0) {
    return (
      <span>
        {quantity} {unitByDefault}
      </span>
    )
  }

  return <span>{quantity} unité</span>
}
