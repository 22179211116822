import { observable, action, computed, runInAction, decorate } from 'mobx'
import i18next from 'i18next'

import AlertCtrl from 'stores/Common/view/AlertCtrl'
import UserStore from 'stores/Common/domain/UserStore'
import { changePassword } from 'services/parameter'

class ChangePasswordCtrl {
  saving = false
  changePassword = {
    current_password: '',
    first: '',
    second: '',
  }
  errors = []

  get valid() {
    return (
      this.changePassword.current_password.length > 0 &&
      this.changePassword.first.length > 0 &&
      this.changePassword.second.length > 0
    )
  }

  async saveNewPassword(firstPassword = false) {
    this.saving = true
    this.errors = []

    try {
      const newPassword = {
        plainPassword: {
          first: this.changePassword.first,
          second: this.changePassword.second,
        },
      }
      if (firstPassword === false) {
        newPassword['current_password'] = this.changePassword.current_password
      }

      await changePassword(newPassword)

      runInAction(() => {
        this.changePassword = {
          current_password: '',
          first: '',
          second: '',
        }

        AlertCtrl.alert('success', i18next.t('parameters.informations.changePassword.success'))
        if (firstPassword === true) {
          UserStore.setPasswordSetAt(new Date())
        }
      })
    } catch (errors) {
      runInAction(() => {
        this.errors = errors
        AlertCtrl.alert('danger', i18next.t('parameters.informations.changePassword.errors'))
      })
    } finally {
      runInAction(() => {
        this.saving = false
      })
    }
  }

  setChangePasswordProperty(key, value) {
    this.changePassword[key] = value
  }
}

const DecoratedChangePasswordCtrl = decorate(ChangePasswordCtrl, {
  saving: observable,
  changePassword: observable,
  errors: observable,

  valid: computed,

  saveNewPassword: action.bound,
  setChangePasswordProperty: action.bound,
})

export default new DecoratedChangePasswordCtrl()
