import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import CreateClaimCtrl from 'stores/Mission/view/CreateClaimCtrl'
import CreateManagerClaimCtrl from 'stores/Mission/view/CreateManagerClaimCtrl'

class CreateClaimButton extends React.Component {
  showExpertModal = () => CreateClaimCtrl.setProperty('showModal', true)
  showManagerModal = () => CreateManagerClaimCtrl.setProperty('showModal', true)

  render() {
    const {
      t,
      UserStore: { isExpert, isClaimManager },
    } = this.props

    if (isExpert) {
      return (
        <h6>
          <span className="nav-button-claim text-right mr-2" onClick={this.showExpertModal}>
            <span className="fa fa-plus-circle mr-1" />
            {t('dashboard.createClaim.buttonTitle')}
          </span>
          <span className="nav-divider-title d-none d-md-inline">-</span>
        </h6>
      )
    } else if (isClaimManager) {
      return (
        <h6>
          <span className="nav-button-claim text-right mr-2" onClick={this.showManagerModal}>
            <span className="fa fa-plus-circle mr-1" />
            {t('dashboard.createManagerClaim.buttonTitle')}
          </span>
          <span className="nav-divider-title d-none d-md-inline">-</span>
        </h6>
      )
    } else {
      return null
    }
  }
}

export default inject('UserStore')(withTranslation()(observer(CreateClaimButton)))
