import { action, computed, observable, runInAction, decorate } from 'mobx'

import CommonStore from 'stores/Common/domain/CommonStore'
import UserStore from 'stores/Common/domain/UserStore'
import CoverageExecutionTime from 'stores/Parameters/Coverage/CoverageExecutionTime'
import { listCoverageExecutionTime, postCoverageExecutionTime } from 'services/coverage'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

class CoverageExecutionTimeStore {
  loading = false
  saving = false
  coverageExecutionTimes = []

  async save() {
    this.saving = true
    try {
      await postCoverageExecutionTime(this.asJson)
      AlertCtrl.alert('success', 'common.saveSuccess')
    } catch (err) {
      AlertCtrl.alert('danger', 'common.error500')
    }

    runInAction(() => {
      this.saving = false
    })
  }

  async loadData() {
    this.loading = true

    try {
      await CommonStore.loadCoverages()
      let coverageExecutionTimesData = await listCoverageExecutionTime()

      runInAction(() => {
        this.coverageExecutionTimes = CommonStore.coverages.map(coverage => {
          return new CoverageExecutionTime({
            key: coverage.attributes.key,
            name: coverage.attributes.value,
            time: this._getTimeOfCoverageExecutionData(
              coverage.attributes.key,
              coverageExecutionTimesData,
            ),
          })
        })
        this.loading = false
      })
    } catch (err) {
      AlertCtrl.alert('danger', 'common.error500')
    }
  }

  _getTimeOfCoverageExecutionData(coverageKey, coverageExecutionTimesData) {
    let coverageExecutionTime = coverageExecutionTimesData.find(coverageExecutionTime => {
      return coverageExecutionTime.attributes.coverage.key === coverageKey
    })

    if (coverageExecutionTime === undefined) return null

    return coverageExecutionTime.attributes.time
  }

  get asJson() {
    return this.coverageExecutionTimes.map(coverageExecutionTime => {
      return {
        ...coverageExecutionTime.asJson,
        customer: UserStore.customer.id,
      }
    })
  }
}
const DecoratedCoverageExecutionTimeStore = decorate(CoverageExecutionTimeStore, {
  loading: observable,
  saving: observable,
  coverageExecutionTimes: observable,

  save: action,
  loadData: action,

  asJson: computed,
})

export default new DecoratedCoverageExecutionTimeStore()
