import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { isNil } from 'ramda'

import { uid } from 'utils'
import Button from 'components/Button/Button'
import Expert from './Expert'

function ListExpert({
  t,
  ClaimStore: { isUrgent, isIME, isMPJ },
  PlannerStore: { experts, selectedExpert, setProperty, selectedTimeSlot },
  AlertCtrl: { alert },
}) {
  if (experts.length === 0) return <div className="mb-3">{t('claim.planner.expert.noExpert')}</div>

  return (
    <React.Fragment>
      {experts.map(expert => (
        <Expert key={uid(10)} data={expert} />
      ))}

      <Button
        className="btn btn-success btn-block mt-3 mb-3"
        disabled={selectedExpert === ''}
        onClick={() => {
          if (((isUrgent && isIME) || isMPJ) && isNil(selectedTimeSlot)) {
            alert(
              'danger',
              isIME ? t('claim.planner.urgentAlert') : t('claim.planner.mpjInformation'),
              true,
            )
          } else {
            setProperty('showConfirmExpert', true)
          }
        }}
      >
        {t('claim.planner.assign')}
      </Button>
    </React.Fragment>
  )
}

export default compose(
  inject('PlannerStore', 'ClaimStore', 'AlertCtrl'),
  withTranslation(),
  observer,
)(ListExpert)
