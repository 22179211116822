import { observable, action, decorate } from 'mobx'

const alertType = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']

class Alert {
  type = 'info'
  message = ''
  directMessage = false
  count = 0

  constructor(type, message, directMessage = false, count = 0) {
    this.type = type
    this.message = message
    this.directMessage = directMessage
    this.count = count
  }
}

class AlertCtrl {
  alerts = []

  alert(type, message, directMessage = false, count = 0) {
    if (alertType.indexOf(type) === -1) {
      console.warn(`Alert type : ${type} do not exist.`)
      return
    }
    this.alerts.push(new Alert(type, message, directMessage, count))
    setTimeout(() => {
      this.removeAlert()
    }, 4000)
  }

  removeAlert() {
    this.alerts.pop()
  }
}

const DecoratedAlertCtrl = decorate(AlertCtrl, {
  alerts: observable,

  removeAlert: action.bound,
  alert: action.bound,
})

export default new DecoratedAlertCtrl()
