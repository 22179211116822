import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import './RadioTabs.css'

const Tab = ({ selected, children, tabKey, tabSize, onClick, className }) => {
  if (!tabSize) {
    return (
      <div
        className={classNames('tab', { selected }, `tab-${tabKey}`, className)}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }
  const TabItem = styled.div`
    width: ${tabSize};
  `
  return (
    <TabItem
      className={classNames('tab', { selected }, `tab-${tabKey}`, className)}
      onClick={onClick}
    >
      {children}
    </TabItem>
  )
}

export default class RadioTabs extends React.Component {
  clickTab = value => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: value,
      },
    })
  }

  render() {
    const { className, value, options, tabSize, vertical, disabled } = this.props

    return (
      <div className={classNames('radio-tabs', className, { vertical })}>
        {options.map(data => {
          return (
            <Tab
              key={`tab-${data.key}`}
              tabKey={data.key}
              selected={value === data.key}
              onClick={disabled ? () => {} : this.clickTab.bind(this, data.key)}
              tabSize={tabSize}
              disabled={disabled}
              className={classNames({ disabled })}
            >
              {data.value}
            </Tab>
          )
        })}
      </div>
    )
  }
}

RadioTabs.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  tabSize: PropTypes.string,
  disabled: PropTypes.bool,
}

RadioTabs.defaultProps = {
  vertical: false,
  disabled: false,
  onChange: () => {},
}
