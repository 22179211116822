import React, { Fragment } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default function TextAreaEditor({
  errors,
  name,
  onChangeEditor,
  editorState,
  placeholder,
  readOnly,
  t,
  onBlurEditor,
}) {
  return (
    <Fragment>
      <Editor
        key={`textarea-${name}`}
        readOnly={readOnly}
        placeholder={placeholder}
        editorState={editorState}
        toolbarClassName="wysiwyg-toolbar"
        wrapperClassName="wysiwyg-wrapper"
        editorClassName="wysiwyg-editor"
        onEditorStateChange={onChangeEditor}
        onBlur={onBlurEditor}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
          },
        }}
      />
      <div key={`textarea-additionnal-infos-${name}`} className="row no-gutters">
        {!!errors.length && (
          <div className="error-message col-sm-6">
            {errors.map(errorDescription => (
              <div key={`${errorDescription}`}>{t(errorDescription)}</div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  )
}
