import httpRequest from 'services/httpRequest'

export const fetchCalendar = async (userId, ranges) => {
  // TODO , same arg's name for both start and end range (range[]), and object dont respect order
  let url = `/appointments/v1/user/${userId}`
  if (ranges) url += `?range[]=${ranges[0]}&range[]=${ranges[1]}`
  const res = await httpRequest({
    method: 'GET',
    url,
    params: {
      'page[size]': 'all',
    },
  })
  return res.data.map(({ attributes }) => attributes)
}

export const fetchAppointmentType = async () => {
  try {
    const res = await httpRequest({
      method: 'GET',
      url: '/appointmentType/v1/',
    })
    return res.data.map(data => data.attributes)
  } catch (err) {
    console.log(err)
    return []
  }
}

export const upsertAppointment = async appointment => {
  const method = appointment.id ? 'PUT' : 'POST'
  const url = appointment.id ? `/appointments/v1/${appointment.id}` : `/appointments/v1/`

  try {
    const res = await httpRequest({
      method,
      url,
      data: {
        data: {
          type: 'appointment',
          attributes: appointment,
        },
      },
    })
    return res
  } catch (err) {
    throw err
  }
}

export const deleteAppointment = async (appointmentId, deleteRecurrenceOption = 'one') => {
  try {
    const res = await httpRequest({
      method: 'DELETE',
      url: `/appointments/v1/${appointmentId}/${deleteRecurrenceOption}`,
    })
    return res
  } catch (err) {
    throw err
  }
}
