import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom'
import { compose } from 'recompose'
import { path as ramdaPath, isNil } from 'ramda'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import './ManagerClaim.css'
import Loader from 'components/Loader/Loader'
import PageLayout from 'components/PageLayout/PageLayout'
import SideNav from 'components/SideNav/SideNav'
import PartyInvolved from 'pages/PartyInvolved/PartyInvolved'
import SupportingDocumentsPage from 'pages/SupportingDocuments/SupportingDocumentsPage'
import NotFound from 'pages/NotFound/NotFound'
import StatusSteps from 'components/StatusSteps/StatusSteps'
import Events from 'pages/Events/Events'
import EasyEstimation from 'pages/EasyEstimation'
import Messaging from 'pages/Messaging'
import CardLayout from 'components/CardLayout/CardLayout'
import Sinister from 'pages/ManagerClaim/pages/Sinister'
import OrientationReport from 'pages/ManagerClaim/pages/OrientationReport'
import Orientation from 'pages/ManagerClaim/pages/Orientation'
import LastBill from 'pages/ManagerClaim/pages/LastBill'
import Instruction from 'pages/ManagerClaim/pages/Instruction'
import PolicyInformation from 'pages/ManagerClaim/pages/PolicyInformation'
import Calculation from 'pages/Mission/pages/Calculation/Calculation'
import VentilationREN from 'pages/Mission/pages/VentilationREN/Ren'
import ErrorBoundary from 'components/ErrorBoundary'
import Field from '../../components/Field'
import { formatDate } from 'utils'
import Meteo from 'components/Meteo/Meteo'

const ManagerClaim = ({
  ManagerClaimStore,
  ManagerClaimStore: { loadData, loading, claim, isPending, isIRSI, retrieveMeteoManager },
  UserStore: { customer, hasContractEE, isExpert, isManager, hasContract },
  CartStore: { easyEstimation },
  UiCtrl: { showOnlyCard },
  match: {
    params: { id: wan },
  },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { path } = useRouteMatch('/:type/:id')
  const [headerInfo, setHeaderInfo] = useState(false)

  const openModalInfo = () => {
    // MissionHeaderCtrl.setProperty('headerInfo', !MissionHeaderCtrl.headerInfo)
    setHeaderInfo(!headerInfo)
  }

  useEffect(() => {
    loadData(id)
  }, [loadData, id])

  if (loading || isNil(claim)) return <Loader loading={t('common.loading')} />

  return (
    <div className="claim-page container pl-sm-0 pr-sm-0 pb-5 mb-5">
      {isPending && (
        <div className="alert alert-warning text-center">{t('managerClaim.sideNav.pending')}</div>
      )}

      <div
        className={classNames(
          { 'd-none': showOnlyCard, 'd-lg-flex': !showOnlyCard },
          'row align-items-center mb-3',
        )}
      >
        <div className="d-none d-lg-block col-lg-3">
          <h5 className="title-side-nav mb-0">{t('claim.sideNav.title')}</h5>
        </div>

        <h5 className="col-sm-6 col-lg-3 sinister-title pr-0 mb-0">
          {t('mission.titleSinister')}
          &nbsp;
          {ramdaPath(['claimInformation', 'claimNumber'], claim) || '-'}
          &nbsp;
          <i
            className={classNames('fa fa-file-text-o ml-2 clickable', {
              'text-primary': !headerInfo,
            })}
            onClick={openModalInfo}
          />
        </h5>

        <div className="d-none d-lg-block col-lg-3">
          <StatusSteps current={claim.status.key} />
        </div>
      </div>

      <div className="row align-items-center align-items-lg-start">
        <div className={classNames({ 'd-none': showOnlyCard }, 'col-3 mb-3 mb-lg-0')}>
          <SideNav />
        </div>
        <div className="col-7 offset-2 mb-3 d-block d-lg-none">
          <StatusSteps current={claim.status.key} />
        </div>

        <div
          className={classNames(
            { 'col-lg-9': !showOnlyCard, showOnlyCard: showOnlyCard },
            'col-12',
          )}
        >
          {headerInfo && (
            <CardLayout className="policy mb-3">
              <Field
                name={t('mission.claim.claimNature')}
                value={ramdaPath(['claimInformation', 'coverage', 'value'], claim)}
              ></Field>
              <Field
                name={t('mission.claim.lossDate')}
                value={formatDate(ramdaPath(['claimInformation', 'dateOfLoss'], claim))}
              ></Field>
              <Meteo hasContract={hasContract} retrieveMeteo={() => retrieveMeteoManager(wan)} />
            </CardLayout>
          )}
          <ErrorBoundary>
            <Switch key="claim-pages">
              <Route exact path={`${path}/policy`} component={PolicyInformation} />
              <Route exact path={`${path}/partyInvolved`} component={PartyInvolved} />
              <Route
                exact
                path={`${path}/SupportingDocuments`}
                component={SupportingDocumentsPage}
              />
              <Route
                path={`${path}/calculation`}
                render={() => {
                  if (!hasContractEE || !easyEstimation) return <Calculation />

                  return <EasyEstimation store={ManagerClaimStore} />
                }}
              />
              <Route
                exact
                path={`${path}/ventilation`}
                render={() => {
                  if (!easyEstimation) return <VentilationREN />

                  return <Redirect to="calculation" />
                }}
              />
              <Route exact path={`${path}/declaration`} component={Sinister} />
              <Route exact path={`${path}/instruction`} component={Instruction} />
              <Route exact path={`${path}/report`} component={OrientationReport} />
              <Route exact path={`${path}/orientation`} component={Orientation} />
              <Route exact path={`${path}/last-bill`} component={LastBill} />
              <Route exact path={`${path}/events`} component={Events} />
              <Route exact path={`${path}/events-expert-mission`}>
                <Events isCfmExpertMission={true} />
              </Route>
              <Route exact path={`${path}/messaging`} component={Messaging} />
              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default compose(
  PageLayout,
  inject('ManagerClaimStore', 'UserStore', 'CartStore', 'UiCtrl'),
  observer,
)(ManagerClaim)
