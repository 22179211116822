import React from 'react'
import { observer, inject } from 'mobx-react'

import DateFilter from './DateFilter'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import {
  DYNAMIC_SELECT_FILTER_MANAGER,
  DYNAMIC_SELECT_FILTER_EXPERT,
  dynSelectFilterName,
} from 'utils/dashboard'

const FilterType = inject('DashboardCtrl', 'DashboardFilterStore', 'UserStore')(
  observer(
    ({
      DashboardCtrl,
      DashboardFilterStore: { getOptionsOfSelectField },
      UserStore: { isExpert },
      filter,
      dynFilter,
      onChangeFilter,
    }) => {
      const dynamicSelectFilter = isExpert
        ? DYNAMIC_SELECT_FILTER_EXPERT
        : DYNAMIC_SELECT_FILTER_MANAGER

      if (dynamicSelectFilter.indexOf(filter.field) > -1) {
        return (
          <ResponsiveSelect
            name={filter.field}
            value={dynFilter ? dynFilter.value : ''}
            onChange={onChangeFilter.bind(this, filter.type)}
            options={DashboardCtrl[
              `select${dynSelectFilterName(filter.field)}${isExpert ? 'Expert' : 'Manager'}`
            ].get()}
            nullable={true}
          />
        )
      }

      if (filter.type === 'date') {
        return <DateFilter name={filter.field} value={dynFilter ? dynFilter.value : null} />
      }

      if (filter.type === 'select') {
        return (
          <ResponsiveSelect
            name={filter.field}
            value={dynFilter ? dynFilter.value : ''}
            onChange={onChangeFilter.bind(this, filter.type)}
            options={getOptionsOfSelectField(filter.field)}
            nullable={true}
          />
        )
      }

      return (
        <Input
          className="form-control"
          style={{ height: '38px' }}
          name={filter.field}
          value={dynFilter ? dynFilter.value : ''}
          onChange={onChangeFilter.bind(this, filter.type)}
        />
      )
    },
  ),
)

const Filter = ({
  DashboardFilterStore: {
    currentFilter: { dynamicFilters },
    currentFilter,
  },
  filter,
}) => {
  const dynFilter = dynamicFilters.find(dynamicFilter => dynamicFilter.key === filter.field)
  const onChangeFilter = (type, e) => {
    currentFilter.upsertDynamicFilter({
      key: e.target.name,
      value: e.target.value,
      type,
    })
  }

  return (
    <div className="form-group col-12 col-md-6 col-lg-3 align-self-end">
      <label>{filter.label}</label>

      <FilterType filter={filter} dynFilter={dynFilter} onChangeFilter={onChangeFilter} />
    </div>
  )
}

export default inject('DashboardFilterStore')(observer(Filter))
