import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classNames from 'classnames'

import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import FurnitureItem from './FurnitureItem'
import { euro } from 'utils/currency'
import { furnituresPrice } from 'utils/cart'

const Price = observer(({ furnitures }) => {
  return <React.Fragment>{euro(furnituresPrice(furnitures))}</React.Fragment>
})

const FurnitureList = ({
  t,
  match: {
    params: { id, type },
  },
  MissionStore: { isMissionClosed, isIRSI, selectedIRSICart },
  CartStore: { furnitures, regularFurnitures },
  ManagerClaimStore: { isClaimClosed },
}) => {
  const [open, setOpen] = useState(true)
  const showFurniture = () => {
    if (furnitures.length > 0) setOpen(!open)
  }
  const setContext = () => {
    SearchFurnitureCtrl.setProperty('search', '')
    SearchFurnitureCtrl.setProperty('searching', false)
    SearchFurnitureCtrl.setProperty('activeSearch', false)
  }
  const listFurnitures = isIRSI
    ? regularFurnitures.filter(furniture => furniture.involvedParty === selectedIRSICart)
    : regularFurnitures
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className="container border-bottom border-color-primary">
      <div className="row no-gutters align-items-center mt-2 mb-2">
        <div className="col-7 clickable" onClick={showFurniture}>
          <span className="p-1 mr-2">
            <i className={classNames('fa', { 'fa-angle-down': !open, 'fa-angle-up': open })} />
          </span>
          <span className="font-weight-bold">{t('mission.calculation.cart.titleFurniture')}</span>
        </div>

        <div className="col-2 text-right d-none d-lg-block">
          <Price furnitures={listFurnitures} />
        </div>
        <div className="col-2 d-inline-block d-lg-none" />
        <div className="col-2" />

        <div className="col-1 text-right">
          {isClosed ? null : (
            <NavLink
              className="btn btn-outline-primary rounded-circle"
              to={`/${type}/${id}/calculation/searchRegularFurniture`}
              onClick={setContext}
            >
              <i className="fa fa-plus" />
            </NavLink>
          )}
        </div>
      </div>

      {open
        ? listFurnitures.map((furniture, index) => {
            return (
              <FurnitureItem
                key={`furniture-item-${furniture.id}`}
                furniture={furniture}
                index={index + 1}
              />
            )
          })
        : null}
    </div>
  )
}

export default compose(
  withRouter,
  withTranslation(),
  inject('MissionStore', 'CartStore', 'ManagerClaimStore'),
  observer,
)(FurnitureList)
