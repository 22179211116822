import { observable, action, reaction, decorate } from 'mobx'

import { roundDecimal } from 'utils/currency'

class DetailPackageCtrl {
  packageData = null
  showImage = false
  imageData = null
  showAreaCalculator = false
  width = 0
  length = 0
  height = 0
  showObsolescenceCalculator = false

  calculatorHandler = null

  setReaction() {
    this.calculatorHandler = reaction(
      () => [this.width, this.length, this.height].filter(val => val > 0),
      values => {
        if (values.length === 2)
          this.packageData.setProperty('overrideAreaSquare', roundDecimal(values[0] * values[1]))
      },
    )
  }

  disposeReaction() {
    this.calculatorHandler()
  }

  setPackage(packageData) {
    this.packageData = packageData
  }

  inputDisabled(input) {
    if (input === 'width' && this.length > 0 && this.height > 0) {
      return true
    } else if (input === 'length' && this.width > 0 && this.height > 0) {
      return true
    } else if (input === 'height' && this.length > 0 && this.width > 0) {
      return true
    }
    return false
  }

  setProperty(key, value) {
    if (key === 'showImage' && !value) this.imageData = null
    this[key] = value
  }
}

const DecoratedDetailPackageCtrl = decorate(DetailPackageCtrl, {
  packageData: observable,
  showImage: observable,
  imageData: observable,
  showAreaCalculator: observable,
  width: observable,
  length: observable,
  height: observable,
  showObsolescenceCalculator: observable,

  setPackage: action,
  inputDisabled: action,
  setProperty: action,
})

export default new DecoratedDetailPackageCtrl()
