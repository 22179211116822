import React from 'react'
import { observer } from 'mobx-react'
import { isNil, path, type } from 'ramda'

// CONDITION TO DISPLAY WARNING FOR IRSI INVOLVED PARTIES
// Copropriétaire non occupant: "68"
// Copropriétaire occupant: "69"
// Locataire: "17"
// Locataire d'un saisonnier: "84"
// Locataire meuble total: "23"
// Propriétaire non occupant: "67"
// Propriétaire occupant: "11"
// Sans objet: "01"
// Syndicat de copropriétaires: "58"
const warningsIP = Object.freeze({
  'irsi.recourseTotalPO': ['11'],
  'irsi.recourseTotalOcccupant': ['17', '84', '23', '11', '69'],
  'irsi.recourseTotalPNOCNO': ['67', '68'],
  'irsi.recourseTotalBuilding': ['58'],
})

// CONDITION TO DISPLAY INPUT PRICE VALUE
const warnings = Object.freeze({
  'irsi.basisTax': 5000,
})

const WarningIP = observer(({ input, cfa }) => {
  const condition = warningsIP[input.path]
  if (isNil(condition) || isNil(cfa)) return null

  const irsiInvolvedParties = path(['irsi', 'irsiInvolvedPartys'], cfa)

  if (isNil(irsiInvolvedParties) || type(irsiInvolvedParties) !== 'Array') return null

  const ip = irsiInvolvedParties.find(ip => {
    const status = path(['status', 'key'], ip)
    return condition.indexOf(status) > -1
  })

  if (isNil(ip) && input.value > 0) {
    return (
      <div className="col col-lg-5 text-danger">
        <i className="fa fa-warning" /> {input.alertMessage}
      </div>
    )
  }

  return null
})

const Warning = observer(({ input }) => {
  const condition = warnings[input.path]
  if (isNil(condition) || input.value <= condition) return null

  return (
    <div className="col col-lg-5 text-danger">
      <i className="fa fa-warning" /> {input.alertMessage}
    </div>
  )
})

const AlertMessage = ({ input, cfa }) => {
  if (isNil(input.alertMessage)) return null

  return (
    <React.Fragment>
      <WarningIP input={input} cfa={cfa} />

      <Warning input={input} />
    </React.Fragment>
  )
}

export default observer(AlertMessage)
