import { observable, action, decorate, runInAction } from 'mobx'

import { fetchMissionDamageDescForm } from 'services/missionForm'
import Form from 'utils/dynform/Form'
import { saveFormDamageDescription } from 'services/missionForm'
import MissionStore from 'stores/Mission/domain/MissionStore'

class DamageDescriptionCtrl {
  loading = true
  form = null

  loadData = async (wan, damageDescriptionData) => {
    this.loading = true
    try {
      const form = await fetchMissionDamageDescForm(wan)
      form.name = 'damageDescription'
      runInAction(() => {
        this.form = new Form({ form, data: damageDescriptionData })
      })
    } catch (err) {
      console.log('err', err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    try {
      const res = await saveFormDamageDescription(wan, formData)
      MissionStore.updateDamageDescription(res.data.attributes.damageDescription)
    } catch (error) {
      console.error(error)
    }
  }
}

const DecoratedDamageDescriptionCtrl = decorate(DamageDescriptionCtrl, {
  loading: observable,
  form: observable,

  loadData: action,
})
export default new DecoratedDamageDescriptionCtrl()
