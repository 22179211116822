import React from 'react'
import PropTypes from 'prop-types'

import { uid } from 'utils'

const Option = ({ value, label, checked, onChange, autoFocus }) => {
  return (
    <span className="mr-3">
      <input
        className="mr-1"
        checked={checked}
        type="checkbox"
        value={value}
        autoFocus={autoFocus}
        onChange={onChange}
      />
      <label onClick={onChange.bind(this, { target: { value } })}>{label}</label>
    </span>
  )
}

export default class Checkbox extends React.Component {
  onChange = e => {
    let newValue = []

    if (this.props.value.indexOf(e.target.value) > -1)
      newValue = this.props.value.filter(value => value !== e.target.value)
    else newValue = [...this.props.value, e.target.value]

    this.props.onChange({ target: { name: this.props.name, value: newValue } })
  }

  render() {
    const { className, options, value, autoFocus } = this.props

    return (
      <div className={className}>
        {options.map((option, index) => (
          <Option
            key={uid(7)}
            {...option}
            checked={value.indexOf(option.value) > -1}
            onChange={this.onChange}
            autoFocus={index === 0 && autoFocus}
          />
        ))}
      </div>
    )
  }
}

Checkbox.defaultProps = {
  name: 'checkbox',
  className: '',
  options: [],
  values: [],
  autoFocus: false,
  onChange: () => {},
}

Checkbox.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
}
