import request from 'services/httpRequest'

export const sendRemoteAssessmentInvitation = async (wan, phoneNumber, email, locale) => {
  const data = {
    data: {
      type: 'remoteAssessment',
      attributes: {
        phoneNumber: phoneNumber,
        email: email,
        locale: locale,
      },
    },
  }

  try {
    return await request({
      method: 'post',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/remote-assessment/send-invitation`,
      data,
    })
  } catch (error) {
    console.log(error)
  }
}

export const remoteAssessment = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/remote-assessment/`,
    })
    console.log(res)
    return res
  } catch (error) {
    console.log(error)
  }
}
