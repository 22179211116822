import React from 'react'
import { NavLink } from 'react-router-dom'

const ProtectedLink = ({ to, children, condition }) => {
  if (condition) return <div className="nav-link protected">{children}</div>

  return (
    <NavLink className="nav-link" activeClassName="active" to={to}>
      {children}
    </NavLink>
  )
}

export default ProtectedLink
