import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { path } from 'ramda'

import { concatAddress } from 'utils'

const dateFnsLocales = {
  en: require('date-fns/locale/en-US'),
  fr: require('date-fns/locale/fr'),
}

function ExpertSummary({
  t,
  closeModal,
  UiCtrl: { language },
  PlannerStore: { selectedTimeSlot, selectedExpertData, setProperty },
  ClaimStore: { claim },
  CreateMissionCtrl: { saveMissionForm },
}) {
  const lang = language[0] + language[1]

  const confirmOnClick = async () => {
    try {
      await saveMissionForm({ wan: claim.wan, send: true })
    } catch (error) {
      setProperty('showConfirmExpert', false)
    }
  }

  const expertiseTypeKey = path(
    ['mission', 'missionInsurerInformation', 'expertiseType', 'key'],
    claim,
  )

  return (
    <React.Fragment>
      <div className="modal-body">
        <div className="d-flex justify-content-between">
          <p className="text-primary font-weight-bold" data-cy="fullName">
            {selectedExpertData.fullName}
          </p>

          <p className="text-primary font-weight-bold" data-cy="selectedTimeSlot">
            {selectedTimeSlot
              ? format(selectedTimeSlot, 'eeee d MMMM y k:mm', { locale: dateFnsLocales[lang] })
              : t('claim.planner.confirmExpert.noTimeSlotSelected')}
          </p>
        </div>

        <p className="text-primary">
          <i className="fa fa-legal" />
          &nbsp;
          {t('claim.planner.expert.legal')}&nbsp;{selectedExpertData.legal}
          <br />
          <i className="fa fa-medkit" />
          &nbsp;
          {t('claim.planner.expert.corporate')}&nbsp;{selectedExpertData.corporate}
          <br />
          <i className="fa fa-home" />
          &nbsp;
          {t('claim.planner.expert.home')}&nbsp;{selectedExpertData.home}
        </p>

        <p className="font-weight-bold" data-cy="coverage">
          {t('claim.planner.confirmExpert.missionType')} :{' '}
          {path(['mission', 'missionInsurerInformation', 'coverage', 'value'], claim)}
        </p>

        {expertiseTypeKey && (
          <div className="font-weight-bold" data-cy="address">
            {t('claim.planner.confirmExpert.expertiseType')} :{' '}
            {path(['mission', 'missionInsurerInformation', 'expertiseType', 'value'], claim)}{' '}
            {expertiseTypeKey === 'GROUND_EXPERTISE_TYPE' && (
              <i className="fa fa-home" aria-hidden="true"></i>
            )}
            {expertiseTypeKey === 'TELE_EXPERTISE_TYPE' && (
              <i className="fa fa-mobile" aria-hidden="true"></i>
            )}
            {expertiseTypeKey === 'VISIO_EXPERTISE_TYPE' && (
              <i className="fa fa-video-camera" aria-hidden="true"></i>
            )}
          </div>
        )}

        <div className="font-weight-bold" data-cy="address">
          {t('claim.planner.confirmExpert.address')} :{' '}
          {concatAddress(path(['mission', 'missionInsurerInformation', 'address'], claim) || null)}
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button className="btn btn-secondary" data-cy="cancel" onClick={closeModal}>
          {t('common.cancel')}
        </button>
        <button className="btn btn-primary" data-cy="submit" onClick={confirmOnClick}>
          {t('claim.planner.confirmExpert.confirm')}
        </button>
      </div>
    </React.Fragment>
  )
}

export default compose(
  inject('UiCtrl', 'PlannerStore', 'ClaimStore', 'CreateMissionCtrl'),
  withTranslation(),
  observer,
)(ExpertSummary)
