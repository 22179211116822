import React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

class ModeSwitcher extends React.Component {
  switchRole = mode => {
    this.props.history.push('/')
    this.props.UserStore.switchMode(mode)
  }

  get translatedModes() {
    const {
      t,
      UserStore: { modes },
    } = this.props
    const translatedModes = modes.map(oneMode => {
      return {
        key: oneMode,
        value: t(`modes.${oneMode}`),
        icon: oneMode === 'insurer' ? 'fa-desktop' : 'fa-briefcase',
      }
    })

    translatedModes.sort((a, b) => {
      return a.value.localeCompare(b.value)
    })

    return translatedModes
  }

  render() {
    const {
      UserStore: { mode, modes },
    } = this.props

    if (modes.length <= 1) return null

    return (
      <React.Fragment>
        <div className="dropdown-divider" />
        {this.translatedModes.map(oneMode => {
          return (
            <div
              key={`mode-${oneMode.key}`}
              onClick={this.switchRole.bind(this, oneMode.key)}
              className={classNames('dropdown-item', 'mode', { disabled: oneMode.key === mode })}
            >
              <div>
                {oneMode.value}
                <i className={`fa ${oneMode.icon}`} />
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}

export default compose(
  inject('UserStore'),
  withRouter,
  withTranslation(),
  observer,
)(ModeSwitcher)
