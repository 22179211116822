import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './CollapseRow.css'

const CollapseRow = ({ rowLabel, rowValue, iconOpen, iconClose, labelShow, labelHide, rows }) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  const showContent = () => {
    setShow(prevSt => !prevSt)
  }

  const renderRow = rowData => {
    return (
      <div className="row" key={rowData.key}>
        <div className="col-4 col-sm-3 col-md-3 col-lg-3 pr-0">{rowData.key}</div>
        <div className="col-8 col-sm-9 col-md-9 col-lg-9 pr-0">{rowData.val}</div>
      </div>
    )
  }

  const label = show
    ? labelHide || t('mission.hideInfo')
    : labelShow || t('mission.contactInfo.showDetailContact')

  return [
    <div className="row button-collapse" key="collapse-row">
      <div className="col-4 col-sm-3 col-md-3 col-lg-3">{rowLabel}</div>
      <div className="col-6 col-sm-3 col-md-4 col-lg-6">{rowValue}</div>
      <div className="col-6 col-sm-6 col-md-5 col-lg-3" style={{ textAlign: 'right' }}>
        <button className="btn btn-outline-primary btn-sm" onClick={showContent}>
          <span className="d-none d-sm-inline">
            {label}
            &nbsp;
          </span>
          <i className={classNames('fa', { [iconOpen]: show }, { [iconClose]: !show })} />
        </button>
      </div>
    </div>,
    <div className={classNames('collapse', { show: show })} key="test2">
      {rows.map(renderRow)}
    </div>,
  ]
}

CollapseRow.propTypes = {
  rowLabel: PropTypes.string.isRequired,
  rowValue: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  labelShow: PropTypes.string,
  labelHide: PropTypes.string,
  iconOpen: PropTypes.string,
  iconClose: PropTypes.string,
}

CollapseRow.defaultProps = {
  iconOpen: 'fa-vcard-o',
  iconClose: 'fa-vcard-o',
}

export default CollapseRow
