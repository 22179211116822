import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { euro } from 'utils/currency'

const SelfRepairButtons = ({
  relatedCost: { selfRepair, setProperty, priceWithVAT },
  isClosed,
}) => {
  return (
    <Fragment>
      <div className="col text-right">
        <button
          className={classNames('btn btn-outline-primary', {
            'selected-price': !selfRepair,
            'disabled-price': selfRepair,
          })}
          disabled={isClosed}
          onClick={() => {
            if (selfRepair) setProperty('selfRepair', false)
          }}
        >
          {euro(priceWithVAT)} TTC
        </button>
      </div>

      <div className="col text-right">
        <button
          className={classNames('btn btn-outline-primary', {
            'selected-price': selfRepair,
            'disabled-price': !selfRepair,
          })}
          disabled={isClosed}
          onClick={() => {
            if (!selfRepair) setProperty('selfRepair', true)
          }}
        >
          {euro(priceWithVAT)} TTC
        </button>
      </div>
    </Fragment>
  )
}

export default observer(SelfRepairButtons)
