import request from 'services/httpRequest'
import UserStore from 'stores/Common/domain/UserStore'

export const fetchEvents = async (wan, lastBillEvent = false, lastBillId = '', isCfmExpertMission = false) => {
  let suffix = ''
  if (UserStore.isExpert) {
    suffix = 'claimFileAssessor/'
  } else if (UserStore.isClaimManager) {
    suffix = 'claimFileManager/'
  } else {
    suffix = ''
  }
  const url = `/claimFiles/v1/${suffix}${wan}/events`
  const params = { 'page[size]': 'all', lastBillEvent, lastBillId, isCfmExpertMission }
  const { data: events } = await request({ method: 'GET', url, params })
  return events
}
