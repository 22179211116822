import React from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import Tooltip from 'components/Tooltip/Tooltip'

const DeleteButton = ({ involvedParty: { id, delatable, isInIRSI } }) => {
  const { t } = useTranslation()
  const params = useParams()
  const { showDelete } = PartyInvolvedCtrl.ctrl

  if (!showDelete && !delatable) return null
  else if (isInIRSI) {
    return (
      <div className="d-flex justify-content-end">
        <div className="delete-tooltip">
          <Tooltip text={t('partyInvolved.isInIRSI')} placement="bottom">
            <i className="text-muted fa fa-trash not-allowed" />
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <ButtonWithConfirmation
      icon
      withoutButton
      iconClassName="fa fa-trash"
      onClick={() => {
        PartyInvolvedCtrl.deletePI({ wan: params.id, partyInvolvedId: id })
      }}
      confirmText={t('partyInvolved.confirmDelete')}
    />
  )
}

export default observer(DeleteButton)
