import React from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import MessagingStore from 'stores/Messaging/MessagingStore'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import ClaimButtons from 'pages/Claim/components/ClaimButtons'
import { formatWan } from 'utils'

class ClaimMobile extends React.Component {
  onClick = () => this.props.UiCtrl.toggleSideNav(false)

  render() {
    const {
      t,
      match: {
        params: { id },
      },
      ClaimStore: { isClaimOverran, validMissions },
    } = this.props

    return (
      <div className="side-nav">
        <h5 className="pt-3 nav-border-bottom">{t('claim.sideNav.title')}</h5>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/information`}
          onClick={this.onClick}
        >
          {t('claim.sideNav.information')}
        </NavLink>
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/claim`}>
          {t('claim.sideNav.information')}
        </NavLink>
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/policy`}>
          {t('mission.sideNav.ContractInformation')}
        </NavLink>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleAnnexes')}</h5>

        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/partyInvolved`}
          onClick={this.onClick}
        >
          {t('mission.sideNav.PartiesInvolved')}
        </NavLink>

        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/supportingDocuments`}
          onClick={this.onClick}
        >
          {t('mission.sideNav.SupportingDocuments')}
        </NavLink>

        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleCommunication')}</h5>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/messaging`}
          onClick={this.onClick}
        >
          {t('messaging.title')}
          {MessagingStore.notifications > 0 && (
            <span className="badge badge-danger ml-1">{MessagingStore.notifications}</span>
          )}
        </NavLink>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/events`}
          onClick={this.onClick}
        >
          {t('events.title')}
        </NavLink>

        <h5 className="pt-3 nav-border-bottom">{t('claim.sideNav.missions.title')}</h5>
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/claim/${id}/qualification`}
          onClick={this.onClick}
        >
          {t('claim.sideNav.missions.qualification')}
        </NavLink>
        <ProtectedLink to={`/claim/${id}/planner`} condition={!ClaimStore.isQualified}>
          {t('claim.sideNav.missions.planner')}
        </ProtectedLink>
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/blocNote`}>
          {t('mission.sideNav.Notepad')}
        </NavLink>
        <NavLink className="nav-link" activeClassName="active" to={`/claim/${id}/stickyNote`}>
          {t('mission.sideNav.StickyNote')}
        </NavLink>
        {/* for corporel mission creation */}
        {ClaimStore.claimCorporel && (
          <ProtectedLink to={`/claim/${id}/requestMissionCreationClaim`}>
            {t('claim.sideNav.missions.missionCreation')}
          </ProtectedLink>
        )}

        {isClaimOverran && (
          <div className="nav-link protected">{t('claim.sideNav.missions.overran')}</div>
        )}

        {validMissions.map(mission => (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/claim/${id}/currentMission/${mission.cfaWan}`}
            key={mission.cfaWan}
          >
            {formatWan(mission.cfaWan)}
          </NavLink>
        ))}

        <div className="mt-4 justify-content-between">
          <ClaimButtons />
        </div>
      </div>
    )
  }
}

export default compose(
  inject('UiCtrl', 'MessagingStore', 'ClaimStore'),
  withRouter,
  withTranslation(),
  observer,
)(ClaimMobile)
