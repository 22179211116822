import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'

const AddToReportButton = ({ sdItemId, showAddToReportButton }) => {
  const addOrRemoveSD = () => SupportingDocumentsCtrl.ctrl.addOrRemoveToReport(sdItemId)

  if (SupportingDocumentsCtrl.ctrl.showAddToReportButton && showAddToReportButton)
    return (
      <div className="item-added-to-report">
        <button
          type="button"
          className={classNames('btn btn-default btn-circle', {
            'btn-primary': SupportingDocumentsCtrl.ctrl.isSdAddedToReport(sdItemId),
          })}
          onClick={addOrRemoveSD}
        >
          {SupportingDocumentsCtrl.ctrl.sdAddToReportLoading ? (
            <i className="fa fa-spinner fa-spin" />
          ) : (
            <i className="fa fa-file-text-o" />
          )}
        </button>
      </div>
    )
  return <div className="item-added-to-report" />
}

export default observer(AddToReportButton)
