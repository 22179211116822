import { path } from 'ramda'
import { format as formatDateFNS } from 'date-fns'

const _dateFnsLocales = {
  en: require('date-fns/locale/en-US'),
  fr: require('date-fns/locale/fr'),
}

export const format = (date, format, options) => {
  try {
    let locale = 'fr'
    const optionsLocale = path('locale', options)
    if (optionsLocale) {
      locale = optionsLocale[0] + optionsLocale[1]
    }

    return formatDateFNS(date, format, { ...options, locale: _dateFnsLocales[locale] })
  } catch (error) {
    throw error
  }
}
