import request from 'services/httpRequest'

export const fetchContractNatures = async () => {
  try {
    const res = await request({
      method: 'GET',
      url: '/businessData/v1/contractNature',
    })

    return res.data.map(data => data.attributes)
  } catch (err) {
    throw err
  }
}
