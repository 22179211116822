import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'

const InfoModal = ({ header = '', messages = [], success = true, openModal = true, onClose }) => {
  const { t } = useTranslation()

  return (
    <ModalWithBackdrop size="modal-md" show={openModal} close={onClose}>
      {header && (
        <div className="modal-header">
          <h5 className={`modal-title text-${success ? 'primary' : 'danger'}`}>{header}</h5>
        </div>
      )}

      <div className="modal-body">
        {!!messages.length &&
          messages.map(msg => (
            <div key={msg} className={`row no-gutters text-${success ? 'primary' : 'danger'}`}>
              {msg}
            </div>
          ))}
      </div>

      <div className="modal-footer d-flex justify-content-center pb-2 pt-2">
        <Button className="btn btn-primary" onClick={onClose}>
          {t('common.close')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default InfoModal
