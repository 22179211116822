import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { getLocale } from 'stores/localStorage'
import localeISO from 'services/utils'

import Loader from 'components/Loader/Loader'
import CardLayout from 'components/CardLayout/CardLayout'
import { baseUrl } from 'services/utils'
import Button from 'components/Button/Button'
import EmailModal from 'pages/Mission/pages/Report/EmailModal'
import { Document, Page } from 'react-pdf'

const OrientationReport = ({
  OrientationReportCtrl: { loading, reportContent, generateReport, setProperty, numPages },
  UserStore: { token },
  ReportCtrl: { toggleModal },
  t,
}) => {
  const { id } = useParams()

  const locale = getLocale() ? localeISO(getLocale()) : 'fr_FR'

  const onDocumentLoadSuccess = ({ numPages }) => setProperty('numPages', numPages)

  useEffect(() => {
    generateReport(id)
  }, [generateReport, id])

  if (loading) return <Loader />

  return (
    <CardLayout>
      <EmailModal claclaimType="cfm" />

      <div className="d-flex flex-row align-items-center pull-right">
        {/* DOWNLOAD BUTTON */}
        <a
          className="btn btn-primary mr-2"
          href={`${baseUrl()}/claimFiles/v1/claimFileManager/${id}/download-pdf?access_token=${token}&locale=${locale}`}
        >
          {t('mission.report.download')}
        </a>

        {/* SEND BY EMAIL BUTTON */}
        <Button className="btn btn-light" onClick={toggleModal}>
          {t('mission.report.sendEmail')}
        </Button>
      </div>
      <div className="clearfix"></div>
      <div className="report-content-wrapper">
        {reportContent && (
          <Document
            file={reportContent}
            loading={<Loader />}
            onLoadSuccess={onDocumentLoadSuccess}
            noData={t('mission.report.contentFailure')}
          >
            {Array.from({ length: numPages || 1 }).map((_, i) => (
              <Page key={i} pageNumber={i + 1} />
            ))}
          </Document>
        )}
        {!reportContent && <Loader />}
      </div>
    </CardLayout>
  )
}

export default compose(
  inject('ManagerClaimStore', 'UserStore', 'ReportCtrl', 'OrientationReportCtrl'),
  withTranslation(),
  observer,
)(OrientationReport)
