import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { euro } from 'utils/currency'

const RelatedCostItem = ({
  relatedCost,
  editRelatedCost,
  packageType,
  CartStore: { removeRelatedCost },
  MissionStore: { isMissionClosed, isIRSI },
  ManagerClaimStore: { isClaimClosed },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const feesAndNonMaterials = relatedCost.type === 'FEES' || relatedCost.type === 'NONMATERIALS'
  const specialRelatedCost = feesAndNonMaterials && isIRSI
  const warningRelatedCost = feesAndNonMaterials && !isIRSI
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className="row no-gutters align-items-center mt-2 mb-2">
      <div
        className={classNames('package-title col-12 col-lg-7 pl-4', {
          'special-related-cost': specialRelatedCost,
          'warning-related-cost': warningRelatedCost,
        })}
        onClick={editRelatedCost.bind(this, relatedCost)}
      >
        {feesAndNonMaterials && (
          <Fragment>
            <strong>[IRSI]</strong> -{' '}
          </Fragment>
        )}{' '}
        {relatedCost.label}
      </div>
      <div
        className={classNames(
          'col-6 col-sm-5 col-lg-2 pl-4 pl-lg-0 text-left text-lg-right text-primary',
          {
            'special-related-cost': specialRelatedCost,
            'warning-related-cost': warningRelatedCost,
          },
        )}
      >
        <button
          className={classNames('btn', {
            'btn-outline-primary': !specialRelatedCost,
            'btn-outline-special-related-cost': specialRelatedCost,
            'btn-outline-warning-related-cost': warningRelatedCost,
          })}
        >
          <i className="fa fa-book d-inline-block d-lg-none" aria-hidden="true" />{' '}
          {euro(relatedCost.priceWithVAT)}
        </button>
      </div>
      <div className="col-6 col-sm-5 col-lg-2" />
      <div className="col-sm-2 col-lg-1 d-none d-sm-inline-block text-right">
        {!isClosed && (
          <ButtonWithConfirmation
            className="btn btn-outline-primary rounded-circle"
            icon
            iconClassName="fa fa-trash"
            onClick={() => removeRelatedCost(relatedCost.id, packageType)}
            confirmText={t('mission.calculation.cart.confirmDelete')}
          />
        )}
      </div>
    </div>
  )
}

export default inject('CartStore', 'MissionStore', 'ManagerClaimStore')(observer(RelatedCostItem))
