import React from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

const SmartLink = ({ selected, path, children, hide }) => {
  if (selected) return <span className="link-selected">{children}</span>
  if (hide) return null

  return (
    <NavLink exact to={path || '/'}>
      {children}
    </NavLink>
  )
}

export default observer(SmartLink)
