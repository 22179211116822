import React, { Fragment, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'

import './Inbox.css'
import PageLayout from 'components/PageLayout/PageLayout'
import CardLayout from 'components/CardLayout/CardLayout'
import Button from 'components/Button/Button'
import List from './List'
import ErrorBoundary from 'components/ErrorBoundary'
import Filters from './Filters'

const Inbox = ({ InboxStore: { loading, fetchInbox, notifications, currentPage } }) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchInbox(currentPage)
  }, [currentPage, fetchInbox])

  const unReadMessage =
    notifications > 0 ? `: ${t('messaging.inbox.unRead', { count: notifications })}` : ''

  return (
    <Fragment>
      <CardLayout>
        <Filters />
      </CardLayout>
      <CardLayout className="inbox-page mt-2">
        <ErrorBoundary>
          <div className="d-flex align-items-center mb-3">
            <h6 className="text-secondary mb-0 mr-3">
              {t('messaging.inbox.title')} {unReadMessage}
            </h6>
            <div className="btn-group">
              <Button
                className="btn btn-primary"
                onClick={() => {
                  fetchInbox(currentPage)
                }}
                disabled={loading}
                loading={loading}
              >
                <i className="fa fa-refresh" /> {t('messaging.inbox.refresh')}
              </Button>
            </div>
          </div>

          <List />
        </ErrorBoundary>
      </CardLayout>
    </Fragment>
  )
}

export default compose(
  PageLayout,
  inject('InboxStore'),
  observer,
)(Inbox)
