import { action, decorate } from 'mobx'

import SupportingDocumentStore, {
  SupportingDocument,
} from 'stores/Common/domain/SupportingDocumentStore'
import { fetchSupportingDocuments } from 'services/supportingDocuments'

class SDInsurerCtrl {
  showAddToReportButton = false
  postUrl = '/claimFiles/v1'

  loadSDReport = () => {}

  fetchSD(wan) {
    return fetchSupportingDocuments({ wan, url: '/claimFiles/v1' })
      .then(
        action(supportingDocs => {
          SupportingDocumentStore.supportingDocuments = supportingDocs.data.map(data => {
            return new SupportingDocument(data.attributes)
          })
          return Promise.resolve()
        }),
      )
      .catch(err => {
        return Promise.reject(err)
      })
  }
}

const DecoratedSDInsurerCtrl = decorate(SDInsurerCtrl, {
  fetchSD: action,
})

export default new DecoratedSDInsurerCtrl()
