import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { path } from 'ramda'

import './RiskConformity.css'
import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'

const RiskConformity = ({ RiskConformityCtrl: { loading, form, save } }) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  const nextStep = () => history.push(`/mission/${id}/causesAndCircumstances`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <div className="dynform-risk-conformity">
        <Form formObj={form} nextStep={nextStep} focus={focus} saveService={save.bind(this, id)} />
      </div>
    </CardLayout>
  )
}

export default inject('RiskConformityCtrl')(observer(RiskConformity))
