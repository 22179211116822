import React, { useState, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import RecapRowLayout from 'pages/Mission/pages/Calculation/DetailPackage/RecapRowLayout'
import { LabelVAT } from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'
import Toggle from 'components/Toggle/Toggle'
import { euro } from 'utils/currency'

//to handle click outside
let useClickOutside = handler => {
  let domNodeLeak = useRef()

  useEffect(() => {
    let maybeHandler = event => {
      if (!domNodeLeak.current.contains(event.target)) {
        handler()
      }
    }

    document.addEventListener('mousedown', maybeHandler)

    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  })

  return domNodeLeak
}

const LeakSearchRecap = ({ packageData }) => {
  const [showRecap, setShowRecap] = useState(false)
  const { t } = useTranslation()

  //to handle click outside
  let domNodeLeak = useClickOutside(() => {
    setShowRecap(false)
  })

  return (
    <div ref={domNodeLeak} className="covering-recap-wrapper">
      <button
        className={classNames('btn rounded-circle mr-1', {
          'btn-primary': showRecap,
          'btn-outline-primary': !showRecap,
        })}
        onClick={() => setShowRecap(!showRecap)}
      >
        <i
          className={classNames('fa', {
            'fa-search-minus': showRecap,
            'fa-search-plus': !showRecap,
          })}
        />
      </button>

      {showRecap && (
        <div className="covering-recap border p-3">
          <RecapRowLayout
            labelComponent={
              <div className="d-flex align-items-center">
                <span className="mr-2">REN :</span>
                <Toggle
                  name="ren"
                  onChange={e => packageData.setProperty(e.target.name, e.target.value)}
                  checked={packageData.ren}
                />
              </div>
            }
            priceComponent={
              <div className="row">
                <div className="col-6 text-right"></div>
                <div className="col-6 text-right"></div>
              </div>
            }
            packageLayout={false}
          />

          <hr />

          <RecapRowLayout
            labelComponent={
              <span className="recap-label mr-3">
                {t('mission.calculation.detailPackage.recapTitle')}
              </span>
            }
            priceComponent={euro(packageData.finalPriceCatalogWithoutVAT)}
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={
              <LabelVAT
                packageData={packageData}
                onChange={e => packageData.setProperty(e.target.name, e.target.value)}
              />
            }
            priceComponent={euro(packageData.totalVAT)}
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={
              <span className="recap-label">
                {t('mission.calculation.detailPackage.totalWithVAT')}
              </span>
            }
            priceComponent={euro(packageData.totalPriceVAT)}
            packageLayout={false}
          />
        </div>
      )}
    </div>
  )
}

export default inject('CartStore')(observer(LeakSearchRecap))
