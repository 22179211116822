import React from 'react'
import { inject, observer } from 'mobx-react'
import { getCompensatory } from 'utils/cart'
import { useTranslation } from 'react-i18next'

const AlertCompensatory = ({
  CartStore: {
    configuration,
    hasRen,
    hasPecunary,
    hasGag,
    compensationMinPrice,
    minPrice,
    franchiseTotal,
    totalInstructionsWithVAT,
    asp,
  },
  InstructionCtrl: { isFranchiseMustBeDeducted },
  small = false,
}) => {
  const { t } = useTranslation()
  const compensatoryConfigGag = getCompensatory('selfRepair', configuration)
  const compensatoryConfigRen = getCompensatory('ren', configuration)
  const compensatoryConfigPecunary = getCompensatory('propertyAndEmbellishment', configuration)
  const quoteCeiling = configuration.quoteCeiling
    ? parseFloat(configuration.quoteCeiling)
    : Infinity
  const invoiceCeiling = configuration.invoiceCeiling
    ? parseFloat(configuration.invoiceCeiling)
    : Infinity
  const maxAsp = Math.min(quoteCeiling, invoiceCeiling)

  const configFloor = Math.min(
    compensatoryConfigGag.min,
    compensatoryConfigRen.min,
    compensatoryConfigPecunary.min,
  )
  const configCeilling = Math.min(
    compensatoryConfigGag.max,
    compensatoryConfigRen.max,
    compensatoryConfigPecunary.max,
  )
  const hasMixte = hasRen + hasPecunary + hasGag > 1
  let totalWithVATWithDeductible = totalInstructionsWithVAT
  if (isFranchiseMustBeDeducted) {
    totalWithVATWithDeductible = totalInstructionsWithVAT - franchiseTotal
  }
  if (
    !hasRen &&
    !hasPecunary &&
    !hasGag &&
    (!asp || totalInstructionsWithVAT < maxAsp) &&
    compensationMinPrice.immediate <= 0 &&
    compensationMinPrice.deffered <= 0
  ) {
    return <div></div>
  }

  return (
    <div className={small ? 'small' : ''}>
      {(compensationMinPrice.immediate > 0 || compensationMinPrice.deffered > 0) && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.minPrice', { minPrice })}
        </div>
      )}
      {hasMixte && totalWithVATWithDeductible < configFloor && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.minPriceGestion')}
        </div>
      )}
      {hasMixte && totalWithVATWithDeductible > configCeilling && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.maxPriceGestion')}
        </div>
      )}

      {!hasMixte && hasGag && totalWithVATWithDeductible < compensatoryConfigGag.min && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.minGag')}
        </div>
      )}
      {!hasMixte && hasGag && totalWithVATWithDeductible > compensatoryConfigGag.max && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.maxGag')}
        </div>
      )}

      {!hasMixte && hasRen && totalWithVATWithDeductible < compensatoryConfigRen.min && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.minRen')}
        </div>
      )}
      {!hasMixte && hasRen && totalWithVATWithDeductible > compensatoryConfigRen.max && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.maxRen')}
        </div>
      )}

      {!hasMixte && hasPecunary && totalWithVATWithDeductible < compensatoryConfigPecunary.min && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.minIp')}
        </div>
      )}
      {!hasMixte && hasPecunary && totalWithVATWithDeductible > compensatoryConfigPecunary.max && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.maxIp')}
        </div>
      )}
      {asp && totalInstructionsWithVAT > maxAsp && (
        <div className="text-danger">
          <i className="fa fa-warning" /> {t('selfCare.alertCompensatory.maxAsp')}
        </div>
      )}
    </div>
  )
}

export default inject('CartStore', 'InstructionCtrl')(observer(AlertCompensatory))
