import { observable, action, decorate } from 'mobx'

class SupportingDocumentsLiteCtrl {
  showImage = false
  imageData = null

  setProperty(key, value) {
    this[key] = value
  }
}
const DecoratedSupportingDocumentsLiteCtrl = decorate(SupportingDocumentsLiteCtrl, {
  showImage: observable,
  imageData: observable,

  setProperty: action,
})

export default new DecoratedSupportingDocumentsLiteCtrl()
