import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import LoginCtrl from 'stores/Common/view/LoginCtrl'
import CustomerId from 'components/Forms/CustomerId'

class ChangePasswordModal extends React.Component {
  closeModal = () => LoginCtrl.setProperty('showModalForgottenPassword', false)
  onChangeCustomerId = e =>
    LoginCtrl.setProperty(
      'forgottenPasswordCustomerId',
      e.target.value.replace(/\s/g, '').toUpperCase(),
    )
  onChangeEmail = e => LoginCtrl.setProperty('forgottenPasswordEmail', e.target.value)
  submit = () => {
    LoginCtrl.requestPassword()
  }
  handleError = (errors, name) => {
    const error = errors.find(error => error.key === name)
    if (error) return [error.message]
    return []
  }

  render() {
    const {
      forgottenPasswordCustomerId,
      forgottenPasswordEmail,
      forgottenPasswordErrors,
    } = LoginCtrl
    const { t } = this.props

    return (
      <ModalWithBackdrop
        size="modal-md"
        show={LoginCtrl.showModalForgottenPassword}
        centered={true}
        close={this.closeModal}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t('loginPage.modal.forgottenPassword.title')}</h5>
          <button type="button" className="close" onClick={this.closeModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="row form-group">
              <div className="col-12 mb-3">{t('loginPage.modal.forgottenPassword.content')}</div>
            </div>
            <div className="row form-group">
              <div className="col-12 col-sm-4">
                {t('loginPage.modal.forgottenPassword.customerId')}
              </div>
              <div className="col-12 col-sm-8">
                <CustomerId
                  name="customerId"
                  onChange={this.onChangeCustomerId}
                  value={forgottenPasswordCustomerId}
                  errors={this.handleError(forgottenPasswordErrors, 'customerId')}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12 col-sm-4">{t('loginPage.modal.forgottenPassword.email')}</div>
              <div className="col-12 col-sm-8">
                <Input
                  className="form-control"
                  name="email"
                  type="text"
                  required
                  onChange={this.onChangeEmail}
                  value={forgottenPasswordEmail}
                  errors={this.handleError(forgottenPasswordErrors, 'email')}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer pb-2 pt-2">
          <button type="button" className="close-btn btn btn-secondary" onClick={this.closeModal}>
            {t('common.close')}
          </button>
          <Button
            className="request-password-btn btn btn-primary"
            onClick={this.submit}
            loading={LoginCtrl.forgottenPasswordState === 'pending'}
            disabled={LoginCtrl.forgottenPasswordState === 'pending'}
          >
            {t('loginPage.modal.forgottenPassword.validate')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default withRouter(withTranslation()(observer(ChangePasswordModal)))
