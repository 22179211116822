import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { isNil } from 'ramda'

import './Pv.css'

import { euro, percentage, roundDecimal } from 'utils/currency'
import InputPrice from 'components/InputPrice/InputPrice'
import { verifyValue } from 'utils'
import Tooltip from 'components/Tooltip/Tooltip'

const PvEstimation = ({
  PvCtrl: {
    estimation,
    setEstimation,
    vans,
    obsRates,
    obs,
    withOBWithoutVAT,
    withOBWithVAT,
    totals,
  },
  MissionStore: { isWaterDamage },
  t,
}) => {
  const onChangeEstimation = (e, initPrice) => {
    let value = e.target.value === '' ? 0 : parseFloat(e.target.value)
    if (roundDecimal(value) === roundDecimal(initPrice)) {
      value = null
    }

    setEstimation(e.target.name, value)
  }
  const WarningPrice = ({ initPrice, overridePrice }) => {
    if (overridePrice === null || overridePrice === undefined) return null

    return (
      <Tooltip
        className="d-inline-block"
        placement="top"
        text={`le montant a été modifié. Le montant initial : ${euro(initPrice)}`}
      >
        <i className="fa fa-exclamation-triangle text-warning clickable pr-2" />
      </Tooltip>
    )
  }

  const CellPrice = ({ inputName, initPrice, overridePrice }) => {
    return (
      <div className="d-flex justify-content-end">
        <WarningPrice initPrice={initPrice} overridePrice={overridePrice} />
        <InputPrice
          name={inputName}
          price={verifyValue(overridePrice, initPrice)}
          onChange={e => onChangeEstimation(e, initPrice)}
          width={80}
        />
      </div>
    )
  }

  const Row = ({ post }) => {
    return (
      <tr key={post}>
        <th scope="row">{t(`mission.conclusion.data.${post}`)}</th>
        <td>
          <CellPrice
            inputName={`${post}.vanOverride`}
            initPrice={vans[post]}
            overridePrice={estimation[post].vanOverride}
          />
        </td>
        <td>{isNil(obsRates[post]) ? '' : percentage(obsRates[post])}</td>
        <td>
          {isNil(obs[post]) && ''}
          {!isNil(obs[post]) && (
            <CellPrice
              inputName={`${post}.obsOverride`}
              initPrice={obs[post]}
              overridePrice={estimation[post].obsOverride}
            />
          )}
        </td>
        <td>
          <CellPrice
            inputName={`${post}.withOBWithoutVATOverride`}
            initPrice={withOBWithoutVAT[post]}
            overridePrice={estimation[post].withOBWithoutVATOverride}
          />
        </td>
        <td>
          <CellPrice
            inputName={`${post}.withOBWithVATOverride`}
            initPrice={withOBWithVAT[post]}
            overridePrice={estimation[post].withOBWithVATOverride}
          />
        </td>
      </tr>
    )
  }

  return (
    <Fragment>
      <div className="p-4 border d-block d-lg-none">{t('mission.conclusion.tableResponsive')}</div>

      <table className="table table-bordered d-none d-lg-table">
        <tbody>
          <tr>
            <th>{t('mission.conclusion.title.prejudice')}</th>
            <th className="text-center">{t(`mission.conclusion.computation.vanWithoutVAT`)}</th>
            <th className="text-center">{t('mission.conclusion.computation.obsolescenceRate')}</th>
            <th className="text-center">{t('mission.conclusion.computation.obsolescenceHT')}</th>
            <th className="text-center">{t('mission.conclusion.computation.totalHT')}</th>
            <th className="text-center">{t('mission.conclusion.computation.totalTTC')}</th>
          </tr>

          <Row post="property" />
          <Row post="embellishment" />
          {isWaterDamage && <Row post="leak" />}
          <Row post="relatedCost" />
          <Row post="otherDamage" />
          <Row post="furniture" />
          <tr>
            <th scope="row">Total HT</th>
            <td>{euro(totals.van)}</td>
            <td></td>
            <td>{euro(totals.obs)}</td>
            <td>{euro(totals.withOBWithoutVAT)}</td>
            <td></td>
          </tr>

          <tr>
            <th scope="row">Total TTC</th>
            <td>
              <CellPrice
                inputName={'totals.vanWithVATOverride'}
                initPrice={totals.vanWithWat}
                overridePrice={estimation.totals.vanWithVATOverride}
              />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>{euro(totals.withOBWithVAT)}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default compose(
  inject('PvCtrl', 'MissionStore'),
  withTranslation(),
  observer,
)(PvEstimation)
