import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { path } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import Form from 'components/DynForm/Form'
import Loader from 'components/Loader/Loader'
import PdfReader from 'pages/Mission/pages/Pv/PdfReader'
import ReactHtmlParser from 'react-html-parser'
import Button from 'components/Button/Button'
import ValidatorModal from 'pages/Mission/pages/Report/ValidatorModal.js'

const RequestMissionCreation = ({
  MissionStore: { cfa, isMissionClosed },
  NewMissionCtrl: {
    loading,
    form,
    save,
    loadData,
    reportContent,
    generateReport,
    sendNewMission,
    savingReport,
  },
}) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const focus = path(['state', 'focus'], location) || null

  useEffect(() => {
    loadData(id, cfa)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => form && form.resetForm()
  }, [form])

  useEffect(() => {
    generateReport(id)
  }, [generateReport, id])

  const nextStep = () => history.push(`/mission/${id}/report`)

  if (loading || !form) return <Loader />

  return (
    <CardLayout>
      <ValidatorModal />
      <div className="dynform-new-mission-creation">
        <Form formObj={form} nextStep={nextStep} focus={focus} saveService={save.bind(this, id)} />
      </div>
      <div className="report-content-wrapper">
        {isMissionClosed && <PdfReader />}
        {reportContent && !isMissionClosed && ReactHtmlParser(reportContent)}
        {!reportContent && <Loader />}
      </div>
      {reportContent && !isMissionClosed && (
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-primary mr-2 save-report-to-sd mt-4"
            onClick={() => sendNewMission(id)}
            loading={savingReport}
            disabled={savingReport}
          >
            Envoyer
          </Button>
        </div>
      )}
    </CardLayout>
  )
}

export default inject('NewMissionCtrl', 'MissionStore')(observer(RequestMissionCreation))
