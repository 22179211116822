import React from 'react'
import { toJS } from 'mobx'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { findIndex, propEq } from 'ramda'
import { Layer, Stage } from 'react-konva'
import MobileDetect from 'mobile-detect'

import './CanvasZone.css'
import ImageBackground from './ImageBackground'
import DrawCtrl, { DrawElements } from 'stores/Mission/view/DrawCtrl'
import RectangleDrawing from 'components/Draw/Rectangle'
import CircleDrawing from 'components/Draw/Circle'
import ArrowDrawing from 'components/Draw/Arrow'
import LineDrawing from 'components/Draw/Line'
import FreeDrawing from 'components/Draw/Free'
import TextDrawing from 'components/Draw/Text'
import CanvasTextArea from 'components/Draw/CanvasTextArea'
import encodedImage from 'utils/encodedImages'

class CanvasZone extends React.Component {
  componentDidMount() {
    DrawCtrl.setInitDrawZone(this.refs.canvasZone.getBoundingClientRect())
    if (DrawCtrl.photoEdit) {
      const image = new window.Image()
      encodedImage(DrawCtrl.imgBackgroundUrl).then(dataUrl => {
        image.src = dataUrl
        image.onload = () => {
          if (image.width > image.height) {
            const ratio = image.width / image.height
            image.width = DrawCtrl.drawZone.width
            image.height = image.width / ratio
            DrawCtrl.setInitDrawZone({ height: image.height, width: image.width })
          } else if (image.height > image.width) {
            const ratio = image.height / image.width
            image.height = DrawCtrl.drawZone.height
            image.width = image.height / ratio
            DrawCtrl.setInitDrawZone({ height: image.height, width: image.width })
          }
          DrawCtrl.setImgBackground(image)
        }
      })
    }
  }

  handleTouchStart = e => {
    DrawCtrl.initCurrentElement(e.currentTarget.pointerPos.x, e.currentTarget.pointerPos.y)
  }

  handleTouchMove = e => {
    DrawCtrl.setCurrentElement(e.currentTarget.pointerPos.x, e.currentTarget.pointerPos.y)
  }

  handleTouchEnd = e => {
    DrawCtrl.setElements(DrawCtrl.currentElement)
  }

  handleMouseDown = e => {
    if (!DrawCtrl.isDrawing) DrawCtrl.initCurrentElement(e.evt.offsetX, e.evt.offsetY)
  }

  handleMouseMove = e => {
    DrawCtrl.setCurrentElement(e.evt.offsetX, e.evt.offsetY)
  }

  handleMouseUp = e => {
    DrawCtrl.setElements(DrawCtrl.currentElement)
  }

  handleDragStart(e) {
    DrawCtrl.setOffset({ startX: e.evt.offsetX, startY: e.evt.offsetY })
  }

  handleDragEnd(e) {
    const draggedElementIndex = findIndex(propEq('id', e.target.attrs.id))(toJS(DrawCtrl.elements))
    DrawCtrl.setDraggedElement(draggedElementIndex, e.evt.offsetX, e.evt.offsetY)
  }

  getElementComponent = (element, index) => {
    switch (element.type) {
      case DrawElements.RECTANGLE:
        return (
          <RectangleDrawing
            key={`rectangle-${index}`}
            x={element.startX}
            y={element.startY}
            width={element.width}
            height={element.height}
            stroke={element.color}
            strokeWidth={element.strokeWidth}
            draggable={DrawCtrl.type === null}
            name={element.id}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onMouseOver={this.onMouseOverShape}
            id={element.id}
          />
        )
      case DrawElements.CIRCLE:
        return (
          <CircleDrawing
            key={`circle-${index}`}
            x={element.startX}
            y={element.startY}
            width={element.width}
            height={element.height}
            stroke={element.color}
            strokeWidth={element.strokeWidth}
            draggable={DrawCtrl.type === null}
            name={element.id}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onMouseOver={this.onMouseOverShape}
            id={element.id}
          />
        )
      case DrawElements.ARROW:
        return (
          <ArrowDrawing
            key={`arrow-${index}`}
            x={element.startX}
            y={element.startY}
            currentVectorX={element.currentVectorX}
            currentVectorY={element.currentVectorY}
            stroke={element.color}
            strokeWidth={element.strokeWidth}
            draggable={DrawCtrl.type === null}
            name={element.id}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onMouseOver={this.onMouseOverShape}
            id={element.id}
          />
        )
      case DrawElements.LINE:
        return (
          <LineDrawing
            key={`element-${index}`}
            x={element.startX}
            y={element.startY}
            currentVectorX={element.currentVectorX}
            currentVectorY={element.currentVectorY}
            stroke={element.color}
            strokeWidth={element.strokeWidth}
            draggable={DrawCtrl.type === null}
            name={element.id}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onMouseOver={this.onMouseOverShape}
            id={element.id}
          />
        )
      case DrawElements.FREE_DRAWING:
        return (
          <FreeDrawing
            key={`element-${index}`}
            startX={element.startX}
            startY={element.startY}
            cumulatedVector={element.cumulatedVector}
            stroke={element.color}
            strokeWidth={element.strokeWidth}
            draggable={DrawCtrl.type === null}
            name={element.id}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onMouseOver={this.onMouseOverShape}
            id={element.id}
          />
        )
      case DrawElements.TEXT_BOX:
        return (
          <TextDrawing
            key={`element-${index}`}
            x={element.startX}
            y={element.startY}
            widthWrapper={element.width}
            heightWrapper={element.height}
            text={element.text}
            fill={element.color}
            fontFamily={'OpenSans'}
            draggable={DrawCtrl.type === null}
            name={element.id}
            handleDragStart={this.handleDragStart}
            handleDragEnd={this.handleDragEnd}
            onMouseOver={this.onMouseOverShape}
            id={element.id}
            fontSize={element.fontSize}
          />
        )
      default:
        return null
    }
  }

  renderBackground() {
    if (DrawCtrl.imgBackground) {
      return <ImageBackground image={DrawCtrl.imgBackground} width={DrawCtrl.drawZone.width} />
    }
    return null
  }

  renderTextArea() {
    return <CanvasTextArea />
  }

  renderElements() {
    return toJS(DrawCtrl.elements).map((element, index) => {
      return this.getElementComponent(element, index)
    })
  }

  renderCurrentElement = () => {
    return this.getElementComponent(toJS(DrawCtrl.currentElement), 100)
  }

  render() {
    const md = new MobileDetect(window.navigator.userAgent)
    console.log('md.mobile()  ===> ', md.mobile())
    const stageProps = md.mobile()
      ? {
          onTouchStart: e => this.handleTouchStart(e),
          onTouchMove: e => this.handleTouchMove(e),
          onTouchEnd: e => this.handleTouchEnd(e),
          onContentMouseDown: e => this.handleMouseDown(e),
          onContentMouseUp: e => this.handleMouseUp(e),
          onContentMouseMove: e => this.handleMouseMove(e),
        }
      : {
          onContentMouseDown: e => this.handleMouseDown(e),
          onContentMouseUp: e => this.handleMouseUp(e),
          onContentMouseMove: e => this.handleMouseMove(e),
          onTouchStart: e => this.handleTouchStart(e),
          onTouchMove: e => this.handleTouchMove(e),
          onTouchEnd: e => this.handleTouchEnd(e),
        }
    return (
      <div
        className={classNames('canvas-zone', {
          'photo-edit': DrawCtrl.photoEdit,
          'text-edit': DrawCtrl.type === DrawElements.TEXT_AREA,
        })}
        ref={'canvasZone'}
      >
        {DrawCtrl.type &&
          DrawCtrl.type === DrawElements.TEXT_AREA &&
          DrawCtrl.isDrawing &&
          this.renderTextArea()}
        <Stage
          {...stageProps}
          width={DrawCtrl.drawZone ? DrawCtrl.drawZone.width : 1000}
          height={window.innerHeight - 160}
        >
          <Layer>
            {DrawCtrl.photoEdit && this.renderBackground()}
            {DrawCtrl.isDrawing && this.renderCurrentElement()}
            {this.renderElements()}
          </Layer>
        </Stage>
      </div>
    )
  }
}

export default withTranslation()(observer(CanvasZone))
