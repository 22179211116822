import { observable, action, toJS, decorate } from 'mobx'

import { b64toBlob, changeBackgroundColor } from 'utils/upload'
import { createSchema } from 'services/supportingDocuments'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'

export const DrawElements = Object.freeze({
  DRAG: 'drag',
  ARROW: 'arrow',
  LINE: 'line',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle',
  FREE_DRAWING: 'freeDrawing',
  TEXT_BOX: 'textBox',
  TEXT_AREA: 'textArea',
  UNDO: 'undo',
})

class DrawElementClass {
  // id,type,color,strokeWidth,startX,startY,currentX,currentY
  currentX = null
  currentY = null
  currentVectorX = null
  currentVectorY = null
  cumulatedVector = null
  width = 0
  height = 0
  draggable = true

  id = null
  type = null
  color = null
  startX = null
  startY = null
  strokeWidth = null
  fontSize = null

  constructor(data) {
    if (data) Object.assign(this, data)
  }
}

const DrawElement = decorate(DrawElementClass, {
  currentX: observable,
  currentY: observable,
  currentVectorX: observable,
  currentVectorY: observable,
  cumulatedVector: observable,
  width: observable,
  height: observable,
  draggable: observable,
})

class DrawCtrl {
  drawZone = null
  drawModal = false
  actionBar = false
  isDrawing = false
  type = null
  currentElement = {}
  elements = []
  colorPickerOpen = false
  color = '#000000'
  strokeWidth = 3
  strokeWidthOpen = false
  schemaName = ''
  loading = false
  photoEdit = false
  imgBackgroundUrl = null
  imgBackground = null
  offset = null
  fontSize = 14
  sdType = null

  setInitDrawZone(rect) {
    this.drawZone = rect
  }

  toggleDrawModal() {
    this.drawModal = !this.drawModal
    if (!this.drawModal) this.resetDrawingDatas()
  }

  resetDrawingDatas() {
    this.elements = []
    this.isDrawing = false
    this.type = null
    this.strokeWidth = 3
    this.schemaName = ''
    this.loading = false
    this.imgBackgroundUrl = null
    this.imgBackground = null
    this.photoEdit = false
    this.fontSize = 14
  }

  toggleActionBar() {
    this.actionBar = !this.actionBar
  }

  toggleColorPicker() {
    this.colorPickerOpen = !this.colorPickerOpen
  }

  toggleStrokeWidthSelect() {
    this.strokeWidthOpen = !this.strokeWidthOpen
  }

  setType(type) {
    this.type = this.type === type ? null : type
  }

  setColor(color) {
    this.color = color
    this.toggleColorPicker()
  }

  setStrokeWidth(strokeWidth) {
    this.strokeWidth = strokeWidth
    this.strokeWidthOpen = false
  }

  undo() {
    if (!!this.elements.length) {
      this.elements.splice(-1)
    }
  }

  setSchemaName(name) {
    this.schemaName = name
  }

  setCloseTextArea() {
    this.type = null
    this.isDrawing = null
    this.currentElement = {}
  }

  setTextBox(text) {
    this.type = null
    const textBoxElement = {
      ...toJS(this.currentElement),
      type: DrawElements.TEXT_BOX,
      startX: toJS(this.currentElement.startX),
      startY: toJS(this.currentElement.startY),
      text,
      fontSize: toJS(this.fontSize),
    }
    this.setElements(textBoxElement)
  }

  togglePhotoEdit() {
    this.photoEdit = !this.photoEdit
    this.toggleDrawModal()
  }

  setImgBackgroundUrl(url) {
    this.imgBackgroundUrl = url
  }

  setImgBackground(image) {
    this.imgBackground = image
  }

  setOffset(offset) {
    this.offset = offset
  }

  setFontSize(fontSize) {
    this.fontSize = fontSize
  }

  initCurrentElement(startX, startY) {
    if (this.type !== null) {
      this.isDrawing = true
      const unique_id = `${Date.now()}-${this.type}`
      this.currentElement = new DrawElement({
        id: unique_id,
        type: this.type,
        color: toJS(this.color),
        startX,
        startY,
        currentX: startX,
        currentY: startY,
        currentVectorX: 0,
        currentVectorY: 0,
        cumulatedVector: [0, 0],
        width: 0,
        height: 0,
        draggable: true,
        strokeWidth: this.strokeWidth,
        fontSize: this.fontSize,
      })
    }
    // this.currentElement = this.elements[unique_id]
  }

  setCurrentElement(currentX, currentY) {
    if (this.isDrawing && this.type !== DrawElements.TEXT_AREA) {
      const width = currentX - this.currentElement.startX
      const height = currentY - this.currentElement.startY

      const currentVectorX =
        this.currentElement.currentVectorX + (currentX - toJS(this.currentElement.currentX))
      const currentVectorY =
        this.currentElement.currentVectorY + (currentY - toJS(this.currentElement.currentY))
      this.currentElement = {
        ...this.currentElement,
        currentX,
        currentY,
        currentVectorX,
        currentVectorY,
        cumulatedVector: [...this.currentElement.cumulatedVector, currentVectorX, currentVectorY],
        width,
        height,
      }
    }
  }

  setElements(currentElement) {
    console.log('currentElement', currentElement)
    if (this.isDrawing && this.type !== DrawElements.TEXT_AREA) {
      //this.type = null
      this.elements.push(currentElement)
      this.isDrawing = false
    }
  }

  setDraggedElement(draggedElementIndex, endX, endY) {
    if (draggedElementIndex !== -1) {
      const els = toJS(this.elements)
      let el = els[draggedElementIndex]
      const offsetX = this.offset.startX - endX
      const offsetY = this.offset.startY - endY
      el = { ...el, startX: el.startX - offsetX, startY: el.startY - offsetY }
      els[draggedElementIndex] = el
      this.elements = els
      this.offset = null
    }
  }

  createSchema(wan) {
    const canvasTag = document.getElementsByTagName('canvas')
    const imageData = changeBackgroundColor(canvasTag[0], '#FFFFFF')
    const encodedFile = b64toBlob(imageData, 'image,jpeg')
    this.loading = true

    // FIX : don't know if this is on purpose
    // this.setTypeBeforeCreation()

    createSchema({
      url: `${SupportingDocumentsCtrl.ctrl.postUrl}/${wan}/documents`,
      name: this.schemaName,
      type: this.sdType || 'SOTH',
      file: encodedFile,
    })
      .then(
        action(() => {
          SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
          AlertCtrl.alert('success', 'mission.supportingDocuments.draw.success')
          if (this.photoEdit) SupportingDocumentsCtrl.setSliderModalDisplay(false)
          this.loading = false
          this.toggleDrawModal()
          this.sdType = null
        }),
      )
      .catch(
        action(() => {
          AlertCtrl.alert('danger', 'mission.supportingDocuments.draw.failure')
          this.loading = false
        }),
      )
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedDrawCtrl = decorate(DrawCtrl, {
  drawZone: observable,
  drawModal: observable,
  actionBar: observable,
  isDrawing: observable,
  type: observable,
  currentElement: observable,
  elements: observable,
  colorPickerOpen: observable,
  color: observable,
  strokeWidth: observable,
  strokeWidthOpen: observable,
  schemaName: observable,
  loading: observable,
  photoEdit: observable,
  imgBackgroundUrl: observable,
  imgBackground: observable,
  offset: observable,
  fontSize: observable,
  sdType: observable,

  setInitDrawZone: action,
  toggleDrawModal: action,
  resetDrawingDatas: action,
  toggleActionBar: action,
  toggleColorPicker: action,
  toggleStrokeWidthSelect: action,
  setType: action,
  setColor: action,
  setStrokeWidth: action,
  undo: action,
  setSchemaName: action,
  setCloseTextArea: action,
  setTextBox: action,
  togglePhotoEdit: action,
  setImgBackgroundUrl: action,
  setImgBackground: action,
  setOffset: action,
  setFontSize: action,
  initCurrentElement: action,
  setCurrentElement: action,
  setElements: action,
  setDraggedElement: action,
  createSchema: action,
  setProperty: action,
})

export default new DecoratedDrawCtrl()
