import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'

import CalendarHeader from './CalendarHeader'
import CalendarToolbar from './CalendarToolbar'
import Event from './Event'
import CalendarWithDnD from './CalendarWithDnD'

function Month({
  CalendarExpertStore: {
    loadingExperts,
    min,
    max,
    day,
    expand,
    view,
    expertsCalendar,
    selectedExperts,
  },
  UserStore: { isExpert },
}) {
  if (loadingExperts) return null

  let expert = null

  if (isExpert && selectedExperts.length === 1) {
    expert = selectedExperts[0]
  } else if (!isExpert && selectedExperts.length === 1) {
    expert = selectedExperts[0]
  } else if (!isExpert && selectedExperts.length >= 0) {
    expert = 'multiExpert'
  } else {
    console.warn(
      `Condition unknow for : isExpert => ${isExpert} selectedExperts length: ${selectedExperts.length}`,
    )
  }

  return (
    <div className="row no-gutters pb-5">
      <div className="col-12">
        <CalendarWithDnD
          max={max}
          min={min}
          day={day}
          defaultView="month"
          views={['month']}
          view={view}
          events={expertsCalendar}
          expert={expert}
          toolbar={true}
          components={{
            header: CalendarHeader,
            toolbar: CalendarToolbar,
            event: Event,
          }}
        />
      </div>
    </div>
  )
}

export default compose(
  inject('CalendarExpertStore', 'UserStore'),
  observer,
)(Month)
