import React, { useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from 'components/Button/Button'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, Modifier } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

function Form({
  BlocNoteStore: {
    sendMessage,
    fetchMessages,
    sendingMessage,
    validMessage,
    loadingMessages,
    currentMessage: { text, setProperty: setMessage },
  },
}) {
  const { t } = useTranslation()
  const { id } = useParams()

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  //setup for number of characters
  const editorRef = useRef(null)

  // CONFIG
  const MAX_LENGTH = 16000

  // DATA & FUNCTIONS
  const currentCount = editorState.getCurrentContent().getPlainText().length

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection()
    const isCollapsed = currentSelection.isCollapsed()

    let length = 0

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent()
      const startKey = currentSelection.getStartKey()
      const endKey = currentSelection.getEndKey()
      const startBlock = currentContent.getBlockForKey(startKey)
      const isStartAndEndBlockAreTheSame = startKey === endKey
      const startBlockTextLength = startBlock.getLength()
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset()
      const endSelectedTextLength = currentSelection.getEndOffset()
      const keyAfterEnd = currentContent.getKeyAfter(endKey)
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset()
      } else {
        let currentKey = startKey

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1
          } else if (currentKey === endKey) {
            length += endSelectedTextLength
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1
          }

          currentKey = currentContent.getKeyAfter(currentKey)
        }
      }
    }

    return length
  }

  const __handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = _getLengthOfSelectedText()

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log('you can type max 16000 characters')

      return 'handled'
    }
    return 'not-handled'
  }

  const _removeSelection = () => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endKey = selection.getEndKey()
    const endOffset = selection.getEndOffset()
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward')
      const tempEditorState = EditorState.push(editorState, newContent, 'remove-range')
      setEditorState(tempEditorState)
      return tempEditorState
    }
    return editorState
  }

  const _addPastedContent = (input, editorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length
    let remainingLength = MAX_LENGTH - inputLength

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength),
    )
    setEditorState(EditorState.push(editorState, newContent, 'insert-characters'))
  }
  const __handlePastedText = pastedText => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = _getLengthOfSelectedText()

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      const selection = editorState.getSelection()
      const isCollapsed = selection.isCollapsed()
      const tempEditorState = !isCollapsed ? _removeSelection() : editorState
      _addPastedContent(pastedText, tempEditorState)

      return 'handled'
    }
    return 'not-handled'
  }

  const updateTextDescription = async state => {
    setEditorState(state)
    const data = convertToRaw(editorState.getCurrentContent())
    setMessage('text', JSON.stringify(data))
  }

  const triggerMessageDispath = () => {
    setEditorState(() => EditorState.createEmpty())
    sendMessage()
  }

  return (
    <div className="form p-3 bg-light mb-3">
      <div className="row mb-2">
        <div className="col-12 col-lg-2 order-first order-lg-last mb-2 mb-lg-0">
          <Button
            className="btn btn-primary btn-block"
            onClick={() => {
              fetchMessages(id)
            }}
            disabled={loadingMessages}
            loading={loadingMessages}
          >
            <i className="fa fa-refresh" />
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-12">
          {
            <Editor
              ref={editorRef}
              key={'texteditor'}
              readOnly={false}
              placeholder={t('messaging.form.text')}
              editorState={editorState}
              toolbarClassName="wysiwyg-toolbar"
              wrapperClassName="wysiwyg-wrapper"
              editorClassName="wysiwyg-editor"
              handleBeforeInput={__handleBeforeInput}
              handlePastedText={__handlePastedText}
              onEditorStateChange={updateTextDescription}
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'fontSize',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'history',
                ],
                inline: {
                  options: [
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'superscript',
                    'subscript',
                  ],
                },
              }}
            />
          }
          <div style={{ position: 'absolute', right: 0 }}>
            {currentCount}/{MAX_LENGTH} {t('messaging.form.character')}
          </div>
          <Button
            className="btn btn-primary"
            onClick={triggerMessageDispath}
            disabled={sendingMessage || !validMessage}
          >
            {t('messaging.form.save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default inject('BlocNoteStore')(observer(Form))
