import { observable, computed, action, decorate } from 'mobx'
import i18next from 'i18next'
import { path, isNil } from 'ramda'

import Rate from 'stores/Mission/domain/Rate'
import UserStore from 'stores/Common/domain/UserStore'

export const minSelfRepairRate = 0.2
export const maxSelfRepairRate = 1

class Configuration {
  difficultyRate1 = null
  difficultyRate2 = null
  difficultyRate3 = null
  priceListCde = null
  priceListLabel = null
  priceLists = []
  priceListSelfRepairRate = null
  overridePriceListSelfRepairRate = null
  qualifications = []
  selfRepairRateByCustomerAndQualification = []
  selfRepairRate = 0
  materialMargin = null
  supplyMargin = null
  toolMargin = null
  minEE = null
  minGAG = null
  minREN = null
  maxEE = null
  maxGAG = null
  maxREN = null
  instructionDividend = 2
  aspDividend = 2
  instructionFloor = 600
  instructionLowCeilling = 5000
  instructionHighCeilling = 25000

  constructor(data) {
    Object.keys(data).forEach(key => {
      this.setProperty(key, data[key])
    })
  }

  setProperty(key, value) {
    if ('qualifications' === key) {
      this._setQualifications(value)
      return
    }

    if ('selfRepairRate' === key) {
      this._setSelfRepairRate(value)
      return
    }

    this[key] = value
  }

  _setQualifications(qualifications) {
    qualifications = qualifications.map(rate => new Rate(rate))
    qualifications.sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label < b.label) return 1
      return 0
    })

    this.qualifications = qualifications
  }

  _setSelfRepairRate(rate) {
    rate = parseFloat(rate)
    if (isNaN(rate)) return
    this.selfRepairRate = rate
  }

  get finalPriceListSelfRepairRate() {
    if (this.overridePriceListSelfRepairRate !== null)
      return this._minMaxSelfRepairRate(this.overridePriceListSelfRepairRate)
    if (this.priceListSelfRepairRate !== null)
      return this._minMaxSelfRepairRate(this.priceListSelfRepairRate)
    // default
    return this._minMaxSelfRepairRate(1)
  }

  _minMaxSelfRepairRate(rate) {
    return Math.min(Math.max(rate, minSelfRepairRate), maxSelfRepairRate)
  }

  get isSelfRepairRateAdjustable() {
    return this.priceListLabel !== null
  }

  get priceListOptions() {
    const priceListOptions = []

    // priceList can contains duplication
    this.priceLists.forEach(priceList => {
      const value = priceList.priceListCde.toString()
      const label = priceList.priceListLabel

      const isAlreadyInPriceList = priceListOptions.find(priceList => priceList.value === value)

      if (!isAlreadyInPriceList) {
        priceListOptions.push({
          value,
          label,
        })
      }
    })

    if (priceListOptions.length > 1 && UserStore.hasContractAgora) {
      priceListOptions.push({
        label: i18next.t('mission.calculation.searchPackage.catalog'),
        value: 'catalog',
      })
    }

    return priceListOptions
  }

  selfRepairRateByQualification = qualificationCde => {
    let selfRepairByCustomerQualification = path(
      [qualificationCde],
      this.selfRepairRateByCustomerAndQualification,
    )

    if (!isNil(selfRepairByCustomerQualification)) {
      return parseFloat(selfRepairByCustomerQualification)
    }

    return this.selfRepairRate
  }

  changeSelfRepairRate = (qualificationCde, selfRepairRate) => {
    let selfRepairByCustomerQualification = path(
      [qualificationCde],
      this.selfRepairRateByCustomerAndQualification,
    )

    // if the customer has self repair rate by qualification and zone for this qualification
    if (!isNil(selfRepairByCustomerQualification)) {
      this.selfRepairRateByCustomerAndQualification[qualificationCde] = selfRepairRate

      return
    }

    this.selfRepairRate = selfRepairRate
  }
}

const DecoratedConfiguration = decorate(Configuration, {
  difficultyRate1: observable,
  difficultyRate2: observable,
  difficultyRate3: observable,
  priceListCde: observable,
  priceListLabel: observable,
  priceLists: observable,
  priceListSelfRepairRate: observable,
  overridePriceListSelfRepairRate: observable,
  qualifications: observable,
  selfRepairRateByCustomerAndQualification: observable,
  selfRepairRate: observable,
  materialMargin: observable,
  supplyMargin: observable,
  toolMargin: observable,
  instructionDividend: observable,
  aspDividend: observable,
  instructionFloor: observable,
  instructionLowCeilling: observable,
  instructionHighCeilling: observable,

  setProperty: action,
  changeSelfRepairRate: action,

  finalPriceListSelfRepairRate: computed,
  isSelfRepairRateAdjustable: computed,
  priceListOptions: computed,
})

export default DecoratedConfiguration
