import React from 'react'
import { observer } from 'mobx-react'

import DatePicker from 'components/Forms/DatePicker'

const DateWrapper = ({ inputData }) => {
  const onChange = date => inputData.setProperty('value', date)

  return (
    <DatePicker
      className="form-control"
      {...inputData}
      onChange={onChange}
      selected={inputData.value ? new Date(inputData.value) : null}
      startOpen={inputData.autoFocus}
    />
  )
}

export default observer(DateWrapper)
