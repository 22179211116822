import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import GenerateReportLink from './GenerateReportLink'

import SmartLink from 'components/SmartLink/SmartLink'

const Temoignage = ({ UserStore: { customer }, type }) => {
  const { t } = useTranslation()
  const hasExpertiseSimplify = customer.hasContract('ES')
  if (type === 'D') {
    return (
      <Fragment>
        <h5 className="mt-4 mb-4">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          {!hasExpertiseSimplify && (
            <SmartLink page="riskConformity">{t('mission.sideNav.RiskConformity')}</SmartLink>
          )}
          <SmartLink page="conclusion">{t('mission.sideNav.Conclusion')}</SmartLink>
        </div>
        <h5 className="mt-4 mb-4">{t('mission.sideNav.titleReport')}</h5>
        <div className="nav flex-column nav-pills">
          <GenerateReportLink />
        </div>
      </Fragment>
    )
  }
  if (type === 'M') {
    return (
      <Fragment>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleEvaluation')}</h5>

        <div className="nav flex-column nav-pills">
          {!hasExpertiseSimplify && (
            <SmartLink page="riskConformity">{t('mission.sideNav.RiskConformity')}</SmartLink>
          )}
          <SmartLink page="conclusion">{t('mission.sideNav.Conclusion')}</SmartLink>
        </div>
        <h5 className="pt-3 nav-border-bottom">{t('mission.sideNav.titleReport')}</h5>
        <div className="nav flex-column nav-pills">
          <GenerateReportLink />
        </div>
      </Fragment>
    )
  }
}

export default inject('UserStore')(observer(Temoignage))
