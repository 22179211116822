import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import LanguageSection from './LanguageSection'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import ProtectedLink from 'components/ProtectedLink/ProtectedLink'
import QlikLink from './QlikLink'

class MobileMenu extends React.Component {
  componentWillUnmount() {
    this.onResetMenu()
  }

  onOpenMenu = () => this.props.UiCtrl.toggleNavbar(this.props.UiCtrl.navbarOpen)
  onResetMenu = () => this.props.UiCtrl.setNavbar(null)
  logout = () => this.props.UserStore.logout()
  switchRole = mode => {
    this.props.UiCtrl.toggleNavbar(this.props.UiCtrl.navbarOpen)
    this.props.history.push('/')
    this.props.UserStore.switchMode(mode)
  }

  render() {
    const {
      t,
      menu,
      onChangeLanguage,
      UiCtrl: { language, navbarOpen },
      UserStore: { fullName, mode, modes },
    } = this.props
    const { newMissions } = DashboardCtrl

    const drawTabs = (tab, i) => {
      let link
      tab = tab.toString().toLowerCase()
      switch (tab) {
        case 'user':
          link = (
            <Fragment key={i}>
              <li className="nav-item">
                <NavLink to="/parameters/GeneralInformation">
                  {t('header.navbar.userDropdown.parameters')}
                </NavLink>
              </li>
              <li className="nav-item">
                <div className="nav-link user-infos text-primary">
                  <span>{t('header.navbar.userDropdown.languages')} : </span>
                  <span
                    className={classNames('language-option', { active: language === 'fr-FR' })}
                    onClick={onChangeLanguage.bind(this, 'fr-FR')}
                  >
                    FR
                  </span>
                  <span className="divider" />
                  <span
                    className={classNames('language-option', { active: language === 'en-US' })}
                    onClick={onChangeLanguage.bind(this, 'en-US')}
                  >
                    EN
                  </span>
                  <span className="divider" />
                  <span
                    className={classNames('language-option', { active: language === 'nl-NL' })}
                    onClick={onChangeLanguage.bind(this, 'nl-NL')}
                  >
                    NL
                  </span>
                  <span className="divider" />
                  <span
                    className={classNames('language-option', { active: language === 'de-DE' })}
                    onClick={onChangeLanguage.bind(this, 'de-DE')}
                  >
                    DE
                  </span>
                </div>
              </li>
              {modes.length > 1 ? (
                <li className="nav-item">
                  <div className="nav-link text-primary">
                    <span
                      className={classNames('language-option', { active: mode === 'expert' })}
                      onClick={this.switchRole.bind(this, 'expert')}
                    >
                      {t('modes.expert')} <i className="fa fa-briefcase" />
                    </span>
                    <span className="divider" />
                    <span
                      className={classNames('language-option', { active: mode === 'insurer' })}
                      onClick={this.switchRole.bind(this, 'insurer')}
                    >
                      {t('modes.insurer')} <i className="fa fa-desktop" />
                    </span>
                  </div>
                </li>
              ) : (
                ''
              )}

              <QlikLink />

              <li className="nav-item" onClick={this.logout}>
                <div className="nav-link text-primary">
                  {t('header.navbar.userDropdown.logout')} <i className="fa fa-sign-out" />
                </div>
              </li>
            </Fragment>
          )
          break
        case 'messages':
          link = (
            <li className="nav-item" key={i}>
              <ProtectedLink exact to="/messages">
                {t('header.navbar.links.messages')}
              </ProtectedLink>
            </li>
          )
          break
        default:
          link = (
            <li className="nav-item" key={i}>
              <NavLink
                exact
                to={`/${tab}`}
                className="nav-link text-primary"
                activeClassName="active"
              >
                {tab === 'mission' || tab === 'claim' ? (
                  <span className="badge badge-danger ml-1">
                    {newMissions === 0 ? '' : newMissions > 9 ? '(9+)' : `(${newMissions})`}
                  </span>
                ) : (
                  ''
                )}
                {t(`header.navbar.links.${tab}`)}
              </NavLink>
            </li>
          )
          break
      }

      return link
    }

    return (
      <React.Fragment>
        <button
          className={classNames('navbar-toggler mr-2 mr-lg-0', { collapsed: !navbarOpen })}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          onClick={this.onOpenMenu}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* NO TOCAR */}
        <div
          className={classNames(
            'collapse navbar-collapse collapsing ml-2 ml-lg-0',
            { show: navbarOpen },
            { closeNav: !navbarOpen && navbarOpen !== null },
            { mobLogin: !fullName },
          )}
          id="navbarSupportedContent"
        >
          {fullName ? (
            <ul className="navbar-nav navigation ml-auto">
              {menu.map((tab, i) => drawTabs(tab, i))}
            </ul>
          ) : (
            <LanguageSection onChangeLanguage={onChangeLanguage} language={language} />
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  inject('UiCtrl', 'UserStore'),
  withRouter,
  withTranslation(),
  observer,
)(MobileMenu)
