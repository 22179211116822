import { observable, action, computed, decorate } from 'mobx'
import { uid } from 'utils'

class CoverageExecutionTime {
  key = ''
  name = ''
  time = null

  id = uid(10)

  constructor({ key, name, time }) {
    this.key = key
    this.name = name
    this.setTime(time)
  }

  setTime(time) {
    time = parseInt(time, 10)
    if (isNaN(time)) time = null
    if (time > 1440) time = 1440
    this.time = time
  }

  get asJson() {
    return {
      coverage: this.key,
      time: this.time,
    }
  }
}

const DecoratedCoverageExecutionTime = decorate(CoverageExecutionTime, {
  key: observable,
  name: observable,
  time: observable,

  setTime: action,

  asJson: computed,
})

export default DecoratedCoverageExecutionTime
