import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { compose } from 'recompose'

import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import AddRCLink from './AddRCLink'
import RelatedCostItem from './RelatedCostItem'
import { euro } from 'utils/currency'
import { relatedCostsPrice } from 'utils/cart'

const RelatedCostList = ({
  title,
  packageType,
  endpoint,
  history,
  list,
  match: {
    params: { id, type },
  },
  MissionStore: { isIRSI, selectedIRSICart, insuredInformationId },
}) => {
  const [open, setOpen] = useState(true)

  if (isIRSI) {
    if (packageType === 'overflow' || packageType === 'noGuarantee') {
      if (selectedIRSICart === null && list.length > 0) {
      } else if (selectedIRSICart !== insuredInformationId) {
        return null
      }
    }
  }

  if (isIRSI) {
    if (packageType === 'overflow' || packageType === 'noGuarantee') {
      if (selectedIRSICart === null && list.length > 0) {
      } else if (selectedIRSICart !== insuredInformationId) {
        return null
      }
    }
  }

  if (isIRSI) {
    list = list.filter(data => data.involvedParty === selectedIRSICart)
  }

  const totalWithVAT = relatedCostsPrice(list, packageType)

  const showList = () => {
    if (list.length > 0) setOpen(!open)
  }
  const editRelatedCost = relatedCost => {
    AddRelatedCostCtrl.setProperty('context', 'edit')
    AddRelatedCostCtrl.setProperty('relatedCost', relatedCost)
    history.push(`/${type}/${id}/calculation/${endpoint}`)
  }

  return (
    <div className="container border-bottom border-color-primary">
      <div className="row no-gutters align-items-center mt-2 mb-2">
        <div className="col-7 clickable" onClick={showList}>
          <span className="p-1 mr-2">
            <i className={classNames('fa', { 'fa-angle-down': !open, 'fa-angle-up': open })} />
          </span>
          <span className="font-weight-bold">{title}</span>
        </div>

        <div className="col-2 text-right d-none d-lg-block">{euro(totalWithVAT)}</div>
        <div className="col-2 d-inline-block d-lg-none" />
        <div className="col-2" />

        <div className="col-1 text-right">
          <AddRCLink id={id} type={type} endpoint={endpoint} packageType={packageType} />
        </div>
      </div>

      {open
        ? list.map(relatedCost => (
            <RelatedCostItem
              key={`relatedCost-${relatedCost.id}`}
              relatedCost={relatedCost}
              editRelatedCost={editRelatedCost}
              packageType={packageType}
            />
          ))
        : null}
    </div>
  )
}

export default compose(
  inject('MissionStore'),
  withRouter,
  observer,
)(RelatedCostList)
