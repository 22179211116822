import React from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Document, Page } from 'react-pdf'

import Loader from 'components/Loader/Loader'

const PdfReader = ({ ReportCtrl: { reportContent, numPages, setProperty: rprtCtrSetProprty } }) => {
  const { t } = useTranslation()
  const onDocumentLoadSuccess = ({ numPages }) => rprtCtrSetProprty('numPages', numPages)

  if (!reportContent) return null

  return (
    <div>
      <Document
        file={reportContent}
        loading={<Loader />}
        onLoadSuccess={onDocumentLoadSuccess}
        noData={t('mission.report.contentFailure')}
      >
        {Array.from({ length: numPages || 1 }).map((_, i) => (
          <Page key={i} pageNumber={i + 1} />
        ))}
      </Document>
    </div>
  )
}

export default inject('ReportCtrl')(observer(PdfReader))
