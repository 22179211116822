import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { format } from 'date-fns'
import classNames from 'classnames'

import CalendarHeader from './CalendarHeader'
import CalendarToolbar from './CalendarToolbar'
import Event from './Event'
import CalendarWithDnD from './CalendarWithDnD'

function Week({
  CalendarExpertStore: {
    loadingExperts,
    min,
    max,
    day,
    expand,
    view,
    expertsCalendar,
    selectedExperts,
  },
  UserStore: { isExpert },
}) {
  if (loadingExperts) return null

  let expert = null

  if (isExpert && selectedExperts.length === 1) {
    expert = selectedExperts[0]
  } else if (!isExpert && selectedExperts.length === 1) {
    expert = selectedExperts[0]
  } else if (!isExpert && selectedExperts.length >= 0) {
    expert = 'multiExpert'
  } else {
    console.warn(
      `Condition unknow for : isExpert => ${isExpert} selectedExperts length: ${selectedExperts.length}`,
    )
  }

  return (
    <div className="row no-gutters view-week pb-5">
      <div className="col-12">
        <button className="btn btn-primary rounded-circle expand-min" onClick={() => expand('min')}>
          <i
            className={classNames('fa', {
              'fa-chevron-up': format(min, 'k') === '8',
              'fa-chevron-down': format(min, 'k') !== '8',
            })}
          />
        </button>
        <CalendarWithDnD
          max={max}
          min={min}
          day={day}
          defaultView="week"
          views={['week', 'work_week']}
          view={view}
          events={expertsCalendar}
          expert={expert}
          toolbar={true}
          components={{
            header: CalendarHeader,
            toolbar: CalendarToolbar,
            event: Event,
          }}
        />
        <button className="btn btn-primary rounded-circle expand-max" onClick={() => expand('max')}>
          <i
            className={classNames('fa', {
              'fa-chevron-down': format(max, 'k') === '19',
              'fa-chevron-up': format(max, 'k') !== '19',
            })}
          />
        </button>
      </div>
    </div>
  )
}

export default compose(
  inject('CalendarExpertStore', 'UserStore'),
  observer,
)(Week)
