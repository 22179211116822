import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import InvolvedPartyName from 'pages/Mission/pages/Conclusion/InvolvedPartyName'
import RENForm from './RENForm'

const Multiple = ({
  options,
  CartStore: { packagesWithoutSelfRepair, RENByIP },
  MissionStore: { isIRSI },
}) => {
  if (isIRSI) {
    return (
      <Fragment>
        {RENByIP.map(IP => {
          return (
            <Fragment key={`ventilation-involved-party-${IP.id}`}>
              <div className="row no-gutters mb-1 align-items-center">
                <InvolvedPartyName involvedPartyId={IP.id} />
              </div>

              {IP.packages.map(packageData => (
                <RENForm packageData={packageData} options={options} key={packageData.id} />
              ))}
            </Fragment>
          )
        })}
      </Fragment>
    )
  }

  return (
    <Fragment>
      {packagesWithoutSelfRepair.map(packageData => (
        <RENForm packageData={packageData} options={options} key={packageData.id} />
      ))}
    </Fragment>
  )
}

export default inject('MissionStore', 'CartStore')(observer(Multiple))
