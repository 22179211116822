import React from 'react'
import { observer } from 'mobx-react'

import Radio from 'components/Forms/Radio'

const RadioWrapper = ({ inputData }) => {
  const onChange = e => inputData.setProperty('value', e.target.value)

  return <Radio className="form-control" {...inputData} onChange={onChange} />
}

export default observer(RadioWrapper)
