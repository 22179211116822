import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import MissionStore from 'stores/Mission/domain/MissionStore'
import CartStore from 'stores/Mission/domain/CartStore'
import InputPrice from 'components/InputPrice/InputPrice'
import LastUpdatedAt from 'pages/Mission/components/LastUpdatedAt'
import Loader from 'components/Loader/Loader'

const Qualification = observer(({ qualification }) => {
  const onChange = e => qualification.changeHourPrice(e.target.value)

  const { label, hourPrice } = qualification

  return (
    <div className="col-12 col-lg-6 row mr-2">
      <div className="col-6">{label}</div>
      <div className="col-6 text-right">
        <span>
          <InputPrice
            name={label}
            price={hourPrice}
            onChange={onChange}
            disabled={MissionStore.isMissionClosed}
            width={70}
          />{' '}
          / H
        </span>
      </div>
    </div>
  )
})

const QualificationList = observer(() => {
  const {
    configuration: { qualifications },
  } = CartStore

  return (
    <div className="row mb-3">
      {qualifications.map(qualification => {
        return (
          <Qualification qualification={qualification} key={`qualification-${qualification.cde}`} />
        )
      })}
    </div>
  )
})

const SelfRepairRate = observer(({ t }) => {
  const {
    configuration: { selfRepairRate },
  } = CartStore

  return (
    <div className="row mb-3">
      <div className="col-12 col-lg-6 row mr-2">
        <div className="col-6">{t('mission.rates.selfRepairLabel')}</div>
        <div className="col-6 text-right">
          <span>
            <InputPrice
              name="selfRepairRate"
              price={selfRepairRate}
              onChange={e => CartStore.configuration.setProperty('selfRepairRate', e.target.value)}
              disabled={MissionStore.isMissionClosed}
              width={70}
            />{' '}
            / H
          </span>
        </div>
      </div>
    </div>
  )
})

class Rates extends React.Component {
  render() {
    const { t } = this.props
    const { configuration } = CartStore

    return (
      <CardLayout className="rate">
        <div className="container pl-0 pr-0">
          {configuration === null ? (
            <Loader />
          ) : (
            <React.Fragment>
              <h4 className="font-weight-normal">{t('mission.rates.qualification')}</h4>
              <QualificationList />
              <h4 className="font-weight-normal">{t('mission.rates.selfRepair')}</h4>
              <SelfRepairRate t={t} />
              <LastUpdatedAt />
            </React.Fragment>
          )}
        </div>
      </CardLayout>
    )
  }
}

export default withRouter(withTranslation()(observer(Rates)))
