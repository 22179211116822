import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Input from 'components/Forms/Input'
import DatePicker from 'components/Forms/DatePicker'
import Button from 'components/Button/Button'

const Filters = ({
  InboxStore: {
    insurerClaimId,
    insuredName,
    assigneeUserName,
    sender,
    sendAt,
    wan,
    setProperty,
    currentPage,
    fetchInbox,
  },
  UiCtrl: { isMobile },
}) => {
  const { t } = useTranslation()

  const onChange = e => {
    setProperty(e.target.name, e.target.value)
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
          <label>{t('messaging.inbox.insurerClaimId')}</label>
          <Input
            className="form-control"
            name="insurerClaimId"
            placeholder={t('messaging.inbox.insurerClaimId')}
            value={insurerClaimId}
            onChange={onChange}
            autoComplete="cc-csc"
          />
        </div>

        {!isMobile && (
          <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
            <label>{t('messaging.inbox.insuredName')}</label>
            <Input
              className="form-control"
              name="insuredName"
              placeholder={t('messaging.inbox.insuredName')}
              value={insuredName}
              onChange={onChange}
              autoComplete="cc-csc"
            />
          </div>
        )}

        {!isMobile && (
          <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
            <label>{t('messaging.inbox.assigneeUserName')}</label>
            <Input
              className="form-control"
              name="assigneeUserName"
              placeholder={t('messaging.inbox.assigneeUserName')}
              value={assigneeUserName}
              onChange={onChange}
              autoComplete="cc-csc"
            />
          </div>
        )}

        {!isMobile && (
          <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
            <label>{t('messaging.inbox.sender')}</label>
            <Input
              className="form-control"
              name="sender"
              placeholder={t('messaging.inbox.sender')}
              value={sender}
              onChange={onChange}
              autoComplete="cc-csc"
            />
          </div>
        )}
      </div>

      {!isMobile && (
        <div className="row d-flex align-items-center">
          <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
            <label>{t('messaging.inbox.sendAt')}</label>
            <DatePicker
              className="form-control"
              name="sendAt"
              selected={sendAt}
              onChange={date => setProperty('sendAt', date)}
              maxDate={new Date()}
              autoComplete="cc-csc"
              isClearable
            />
          </div>

          <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
            <label>{t('messaging.inbox.wan')}</label>
            <Input
              className="form-control"
              name="wan"
              placeholder={t('messaging.inbox.wan')}
              value={wan}
              onChange={onChange}
              autoComplete="cc-csc"
            />
          </div>

          <div className="col-12 col-md-6 col-lg-3 align-self-end mb-2">
            <div className="btn-group">
              <Button
                className="btn btn-primary search"
                onClick={() => {
                  fetchInbox(currentPage)
                }}
              >
                <i className="fa fa-filter" /> {t('messaging.inbox.filter')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default inject('InboxStore', 'UiCtrl')(observer(Filters))
