import EXIF from 'exif-js'

const ImagesProperties = Object.freeze({
  MAX_WIDTH: 1024,
  MAX_HEIGHT: 768,
})

const imageCreation = file => {
  const image = new window.Image()
  image.src = file.file.preview
  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      reject(new Error('mission.upload.errorImageCreation'))
    }
  })
}

const canvasCreation = async function(width, height, image) {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')
  const properties = await getImageProperties(image).then(res => {
    return res
  })

  if (properties && properties['Orientation'] && properties['Orientation'] === 6) {
    ctx.translate(canvas.width - 130, 0)
    ctx.rotate((90 * Math.PI) / 180)
    ctx.save()
  }

  ctx.drawImage(image, 0, 0, width, height)
  return canvas.toDataURL('image/jpeg', 0.85)
}

const handleFormatImage = (imageWidth, imageHeight) => {
  const maxWidth = ImagesProperties.MAX_WIDTH
  const maxHeight = ImagesProperties.MAX_HEIGHT
  if (imageWidth > imageHeight) {
    if (imageWidth > maxWidth) {
      imageHeight *= maxWidth / imageWidth
      imageWidth = maxWidth
    }
  } else {
    if (imageHeight > maxHeight) {
      imageWidth *= maxHeight / imageHeight
      imageHeight = maxHeight
    }
  }
  return { width: imageWidth, height: imageHeight }
}

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ''
  sliceSize = sliceSize || 512
  const encodedString = b64Data.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
  const byteCharacters = atob(encodedString)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: contentType })
}

const resizeImage = async function(file) {
  try {
    const img = await imageCreation(file)
    const dimensions = handleFormatImage(img.width, img.height)
    const dataUrl = await canvasCreation(dimensions.width, dimensions.height, img).then(res => res)
    const fileToReturn = file.extraData
      ? {
          file: file.file,
          preview: dataUrl,
          type: file.type,
          name: file.name,
          progressBar: 0,
          extraData: file.extraData,
        }
      : {
          file: file.file,
          preview: dataUrl,
          type: file.type,
          name: file.name,
          progressBar: 0,
        }
    return fileToReturn
  } catch (error) {
    throw error
  }
}

export const compute = async function(imagesFiles) {
  try {
    let i = 0
    // eslint-disable-next-line
    for (const imageFile of imagesFiles) {
      if (/image/.test(imageFile['file']['type'])) {
        const a = await resizeImage(imageFile)
        imagesFiles[i] = a
      }
      i++
    }
    return imagesFiles
  } catch (error) {
    throw error
  }
}

const getImageProperties = file => {
  return new Promise(resolve => {
    EXIF.getData(file, function() {
      const tags = EXIF.getAllTags(this)
      resolve(tags)
    })
  })
}

export const changeBackgroundColor = (canvasTag, backgroundColor) => {
  const context = canvasTag.getContext('2d')
  const canvas = context.canvas
  //cache height and width
  const w = canvas.width
  const h = canvas.height
  let data
  //get the current ImageData for the canvas.
  data = context.getImageData(0, 0, w, h)

  //store the current globalCompositeOperation
  const compositeOperation = context.globalCompositeOperation

  //set to draw behind current content
  context.globalCompositeOperation = 'destination-over'

  //set background color
  context.fillStyle = backgroundColor

  //draw background / rect on entire canvas
  context.fillRect(0, 0, w, h)

  //get the image data from the canvas
  const imageData = canvas.toDataURL('image/jpeg', 0.85)

  //clear the canvas
  context.clearRect(0, 0, w, h)

  //restore it with original / cached ImageData
  context.putImageData(data, 0, 0)
  context.globalCompositeOperation = compositeOperation
  return imageData
}

export const dataURLtoFile = (dataUrl, fileName) => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], fileName, { type: mime })
}
