import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'

import './Calendar.css'
import PageLayout from 'components/PageLayout/PageLayout'
import Loader from 'components/Loader/Loader'
import ErrorBoundary from 'components/ErrorBoundary'
import Filters from './Filters'
import CalendarContainer from './CalendarContainer'
import AppointmentModal from './AppointmentModal'

function MyCalendar({
  UserStore: {
    id,
    firstName,
    lastName,
    email,
    customer: { id: customerId },
    isInsurer,
    isExpert,
  },
  CalendarExpertStore: {
    loadingExperts,
    getExperts,
    getExpert,
    getSchedules,
    day,
    fromPlanner,
    setProperty,
  },
}) {
  useEffect(() => {
    if (isInsurer && fromPlanner) {
      return () => {
        setProperty('fromPlanner', false)
      }
    } else if (isInsurer) {
      getExperts(customerId)
    } else if (isExpert) {
      getExpert({ id, firstName, lastName, email, customerId })
    } else {
      console.warn('Your role is not defined to be used in the calendar page.')
    }
    setProperty('day', new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getSchedules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day])

  if (loadingExperts)
    return (
      <div className="page-calendar border rounded bg-white p-3 mb-4">
        <Loader />
      </div>
    )

  return (
    <ErrorBoundary>
      <div className="page-calendar border rounded bg-white p-3 mb-4">
        <Filters />
        <CalendarContainer />
        <AppointmentModal />
      </div>
    </ErrorBoundary>
  )
}

export default compose(
  inject('UserStore', 'CalendarExpertStore'),
  PageLayout,
  observer,
)(MyCalendar)
