import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import Label from 'components/Label/Label'
import Select from 'components/ResponsiveSelect/ResponsiveSelect'

const SelectExpertInput = ({
  t,
  UserStore: { isExpert },
  CalendarExpertStore: { viewType, expertValue },
  AppointmentCtrl: {
    currentAppointment: { assignee, setProperty },
  },
}) => {
  if (isExpert || viewType === 'day') return null

  const onChange = e => {
    setProperty(e.target.name, e.target.value)
  }

  return (
    <div className="row form-group">
      <div className="col-sm-3 d-flex align-items-center">
        <Label required>{t('calendar.appointment.selectExpert')}</Label>
      </div>
      <div className="col">
        <Select name="assignee" onChange={onChange} value={assignee} options={expertValue} />
      </div>
    </div>
  )
}

export default compose(
  inject('UserStore', 'CalendarExpertStore', 'AppointmentCtrl'),
  withTranslation(),
  observer,
)(SelectExpertInput)
