import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { subDays, isValid } from 'date-fns'
import { path } from 'ramda'

import CommonStore from 'stores/Common/domain/CommonStore'
import SupportingDocumentsCtrl, {
  SupportingDocTypes,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import { baseUrl } from 'services/utils'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'
import Button from 'components/Button/Button'
import DatePicker from 'components/Forms/DatePicker'

class UpdateSection extends React.Component {
  setTranslatedError(field) {
    const { t } = this.props
    let errors = SupportingDocumentsCtrl.invoiceError

    if (errors[field]) {
      return errors[field].map(error => {
        return t(error)
      })
    }

    return []
  }

  renderInvoiceForm = () => {
    const {
      sd,
      t,
      MissionStore: { missionStatus },
    } = this.props
    const invoiceDate = path(['extraData', SupportingDocTypes.INVOICE, 'date'], sd)

    return (
      <React.Fragment>
        <div className="form-group">
          <label>{t('mission.supportingDocuments.invoice.number')}</label>
          <Input
            type="text"
            name="invoice-number"
            className="form-control"
            value={sd.extraData && sd.extraData[SupportingDocTypes.INVOICE].number}
            onChange={e =>
              SupportingDocumentsCtrl.setInvoiceFormFields('number', e.target.value, sd.id)
            }
            placeholder={t('mission.supportingDocuments.invoice.number')}
            errors={this.setTranslatedError('number')}
            disabled={missionStatus === 'closed'}
          />
        </div>

        <div className="form-group">
          <label>{t('mission.supportingDocuments.invoice.date')}</label>
          <DatePicker
            name="invoice-date"
            className="form-control"
            selected={isValid(new Date(invoiceDate)) ? new Date(invoiceDate) : null}
            onChange={selected =>
              SupportingDocumentsCtrl.setInvoiceFormFields('date', selected, sd.id)
            }
            isClearable
            errors={this.setTranslatedError('date')}
            maxDate={subDays(new Date(), 1)}
            disabled={missionStatus === 'closed'}
          />
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {
      t,
      sd,
      MissionStore: { isMissionClosed },
      ClaimStore: { isClaimClosed },
      UserStore: { token },
    } = this.props

    if (SupportingDocumentsCtrl.sdToUpdate === null) return null

    if (isMissionClosed || isClaimClosed) {
      return (
        <div className="btn-wrapper">
          <a
            className="btn btn-primary btn-block"
            href={`${baseUrl()}/supportingDocuments/v1/${sd.id}/attachment?access_token=${token}`}
          >
            <i className="fa fa-download" /> {t('mission.report.download')}
          </a>
        </div>
      )
    }

    return (
      <div className="update-form-wrapper">
        <form>
          <div className="form-group">
            <label>{t('mission.supportingDocuments.update.nameLabel')}</label>
            <Input
              type="text"
              className="form-control"
              value={sd.name}
              onChange={e => SupportingDocumentsCtrl.setName(e.target.value, sd.id)}
            />
          </div>
          <div className="form-group">
            <label>{t('mission.supportingDocuments.update.typeLabel')}</label>
            <Select
              className="form-control"
              name="sdCategory"
              options={CommonStore['supportingDocumentsTypes'][SupportingDocumentsCtrl.sdCategory]}
              value={sd.type.key}
              onChange={e => SupportingDocumentsCtrl.setTypeAndName(e.target.value, sd.id)}
            />
          </div>
          {sd.type.category === SupportingDocTypes.INVOICE && this.renderInvoiceForm()}
        </form>
        <div className="btn-wrapper">
          <Button
            className="btn-override btn btn-primary btn-block"
            onClick={() => {
              SupportingDocumentsCtrl.updateSupportingDocument(sd.id, sd.extraData)
            }}
            loading={SupportingDocumentsCtrl.updateLoading}
          >
            {t('common.validate')}
          </Button>
        </div>

        <div className="btn-wrapper">
          <a
            className="btn btn-primary btn-block"
            href={`${baseUrl()}/supportingDocuments/v1/${sd.id}/attachment?access_token=${token}`}
          >
            <i className="fa fa-download" /> {t('mission.report.download')}
          </a>
        </div>
      </div>
    )
  }
}

export default inject('MissionStore', 'ClaimStore', 'UserStore')(
  withTranslation()(observer(UpdateSection)),
)
