import React from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { compose } from 'recompose'

import './StatusSteps.css'
import Tooltip from 'components/Tooltip/Tooltip'
import { OVERRAN_STEPS } from 'stores/Claim/domain/ClaimStore'

const StatusSteps = ({
  current,
  UserStore: { isExpert, isClaimManager },
  CommonStore: { claimCFI, claimCFA, claimCFM },
  ClaimStore: { isClaimOverran },
}) => {
  let steps = isExpert ? claimCFA : isClaimManager ? claimCFM : claimCFI

  if (isClaimOverran) {
    steps = steps.filter(step => OVERRAN_STEPS.indexOf(step.key) > -1)
  }

  return (
    <ul className="status-steps-wrapper mb-0">
      {steps.map(step => (
        <li
          className={classNames('step-wrapper', {
            active: step.key === current,
          })}
          key={step.key}
        >
          <div>
            <Tooltip text={step.value} className="step">
              <div className="inner-circle" />
            </Tooltip>
          </div>
        </li>
      ))}
    </ul>
  )
}

StatusSteps.defaultProps = {
  current: '',
}

StatusSteps.propTypes = {
  current: PropTypes.string.isRequired,
}

export default compose(
  inject('UserStore', 'CommonStore', 'ClaimStore'),
  observer,
)(StatusSteps)
