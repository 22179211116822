import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import styled from 'styled-components'

import CartStore from 'stores/Mission/domain/CartStore'
import MissionStore from 'stores/Mission/domain/MissionStore'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import FurnitureCatalogDetails from 'pages/Mission/pages/Calculation/FurnitureCatalogDetails'

const Spinner = styled.i`
  font-size: 22px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Furniture = withRouter(
  observer(
    class Furniture extends React.Component {
      onClick = furnitureData => {
        const {
          path,
          params: { id, type },
        } = this.props.match
        const endpoint = path.split('/').pop()
        const furnitureType = endpoint === 'searchOutdoorFurniture' ? 'Outdoor' : 'Regular'
        furnitureData.setProperty(
          'involvedParty',
          MissionStore.isIRSI ? MissionStore.selectedIRSICart : null,
        )
        AddFurnitureCtrl.setFurniture(furnitureData, 'new')
        if (!CartStore.easyEstimation) {
          this.props.history.push(`/${type}/${id}/calculation/add${furnitureType}Furniture`)
        }
      }

      render() {
        const { furniture } = this.props

        return (
          <FurnitureCatalogDetails
            onClick={this.onClick.bind(this, furniture)}
            furniture={furniture}
          />
        )
      }
    },
  ),
)

const SearchResults = observer(({ t }) => {
  const { searching, totalSearchFurniture } = SearchFurnitureCtrl

  if (searching)
    return (
      <h6 className="text-primary">
        <Spinner className="fa fa-spinner" />
      </h6>
    )

  return <h6>{t('mission.calculation.searchFurniture.result', { count: totalSearchFurniture })}</h6>
})

const ShowMore = withTranslation()(
  observer(({ t, fetchMore }) => {
    const { searching, totalSearchFurniture, list } = SearchFurnitureCtrl
    if (searching || totalSearchFurniture === 0 || totalSearchFurniture === list.length) return null

    return (
      <div className="show-more mt-3 text-right">
        <button className="btn btn-primary" onClick={fetchMore}>
          {t('mission.calculation.searchFurniture.showMoreFurnitures')}
        </button>
      </div>
    )
  }),
)

class ListSearch extends React.Component {
  fetchMore = () => SearchFurnitureCtrl.searchFurniture(this.props.match.params.id)

  render() {
    const { t, className } = this.props
    const { list, activeSearch } = SearchFurnitureCtrl

    if (!activeSearch) return null

    return (
      <div className={className ? className : 'search-result container pl-0 pr-0'}>
        <SearchResults t={t} />

        <div className="list container">
          {list.map(furniture => {
            return <Furniture key={furniture.id} furniture={furniture} />
          })}
        </div>

        <ShowMore fetchMore={this.fetchMore} />
      </div>
    )
  }
}

export default compose(
  withRouter,
  withTranslation(),
  observer,
)(ListSearch)
