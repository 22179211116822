import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { path, isNil } from 'ramda'

import './Furnitures.css'
import { euro } from 'utils/currency'
import { furnitureSkipComputationRules } from 'utils/cart'

const Data = ({ data, totals, column }) => {
  const skip = path([data.id], furnitureSkipComputationRules)
  if (skip && skip.indexOf(column) > -1) return <td className="text-center">-</td>
  return <td className="text-center align-middle">{euro(totals[data.id][column])}</td>
}

const Row = ({ data, totals }) => {
  const rowFinalPrice = path([data.id, 'finalPriceWithVAT'], totals)
  if (isNil(rowFinalPrice) || rowFinalPrice === 0) return null

  return (
    <tr>
      <th>{data.trans_default}</th>
      <Data data={data} totals={totals} column="totalWithVAT" />
      <Data data={data} totals={totals} column="totalObsWithVAT" />
      <Data data={data} totals={totals} column="totalUsedWithVAT" />
      <Data data={data} totals={totals} column="finalPriceWithVAT" />
    </tr>
  )
}

const Furnitures = ({
  CartStore: { furnituresPerCategory },
  CommonStore: { furnitureCategories },
}) => {
  const { t } = useTranslation()

  return (
    <table className="table table-bordered d-none d-md-table">
      <tbody>
        <tr>
          <th className="align-middle">{t('mission.conclusion.data.furniture')}</th>
          <th className="text-center align-middle fixed-col">
            {t('mission.conclusion.computation.vnrWithVAT')}
          </th>
          <th className="text-center align-middle fixed-col">
            {t('mission.conclusion.computation.vrnWithVATWithObs')}
          </th>
          <th className="text-center align-middle fixed-col">
            Valeur
            <br /> occ. TTC
          </th>
          <th className="text-center align-middle fixed-col">
            Montant
            <br /> garanti TTC
          </th>
        </tr>
        {furnituresPerCategory.furnituresLength === 0 && (
          <tr>
            <td className="text-center align-middle" colSpan="6">
              {t('mission.conclusion.data.empty')}
            </td>
          </tr>
        )}
        {furnituresPerCategory.furnituresLength > 0 && (
          <React.Fragment>
            {furnitureCategories.map(category => {
              return (
                <Row
                  key={`category-${category.id}`}
                  data={category}
                  totals={furnituresPerCategory}
                />
              )
            })}
            <tr>
              <th>{t('mission.conclusion.computation.total')}</th>
              <td className="text-center align-middle">
                {euro(furnituresPerCategory.totals.totalWithVAT)}
              </td>
              <td className="text-center align-middle">
                {euro(furnituresPerCategory.totals.totalObsWithVAT)}
              </td>
              <td className="text-center align-middle">
                {euro(furnituresPerCategory.totals.totalUsedWithVAT)}
              </td>
              <td className="text-center align-middle">
                {euro(furnituresPerCategory.totals.finalPriceWithVAT)}
              </td>
            </tr>
          </React.Fragment>
        )}
      </tbody>
    </table>
  )
}

export default inject('CartStore', 'CommonStore')(observer(Furnitures))
