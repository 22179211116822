import { observable, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class Label extends AbstractField {
  text = ''
  errors = []
  show = true
  type = 'label'
  fontSize = '12px'
  fontWeight = 'normal'
  textAlign = 'left'

  constructor(data) {
    super(data)

    this.type = data.type || 'label'
    this.show = data.show
    this.fontSize = data.fontSize || '12px'
    this.fontWeight = data.fontWeight || 'normal'
    this.textAlign = data.textAlign || 'left'
    this.text = data.label || ''
  }
}

const DecoratedLabel = decorate(Label, {
  errors: observable,
  show: observable,
})

export default DecoratedLabel
