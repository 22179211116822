import { isNil } from 'ramda'

import request from 'services/httpRequest'

// AUTH API need form data only for this endpoint
const _formData = (customerId, userName, password, refreshToken = null) => {
  const formData = new FormData()
  formData.append('client_id', process.env.REACT_APP_CLIENT_ID)

  if (refreshToken) {
    formData.append('refresh_token', refreshToken)
    formData.append('grant_type', 'refresh_token')
  } else {
    formData.append('username', `${customerId}\\${userName}`)
    formData.append('password', password)
    formData.append('grant_type', 'password')
  }
  return formData
}

const _formatErrors = err => {
  let formattedErrors = { globals: [], fields: [] }
  if (err.data.errors)
    err.data.errors.forEach(error => {
      if (!error.source) {
        formattedErrors.globals.push(error.detail)
        return
      }

      formattedErrors.fields.push({
        key: error.source.pointer.split('/').pop(),
        message: error.detail,
      })
    })

  return formattedErrors
}

export const auth = async ({ customerId, userName, password }) => {
  try {
    const res = await request({
      method: 'post',
      url: '/oauth/v2/token',
      data: _formData(customerId, userName, password),
    })
    return res
  } catch (err) {
    return Promise.reject(err.data)
  }
}

export const me = async token => {
  try {
    const res = await request({
      method: 'get',
      url: '/users/v1/me',
      params: {
        access_token: token,
      },
    })

    const userInfo = {
      id: res.data.attributes.id,
      firstName: res.data.attributes.firstName,
      lastName: res.data.attributes.lastName,
      fullName: res.data.attributes.fullName,
      email: res.data.attributes.email,
      passwordChangedAt: res.data.attributes.password_changed_at,
      roles: res.data.attributes.roles.filter(role => role !== 'ROLE_USER'),
      customer: res.data.attributes.customer,
      logo: res.data.attributes.logo,
      qlikAccess: res.data.attributes.qlikAccess,
      easyEstimationDefault: isNil(res.data.attributes.easyEstimationByDefault)
        ? false
        : res.data.attributes.easyEstimationByDefault,
    }
    return Promise.resolve(userInfo)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const requestPassword = async ({ customerId, email }) => {
  const data = {
    data: {
      type: 'requestPassword',
      attributes: {
        customerId,
        email,
        referer: window.location.origin,
      },
    },
  }

  try {
    await request({
      method: 'POST',
      url: `/users/v1/request-password`,
      data,
    })
  } catch (err) {
    return Promise.reject(_formatErrors(err))
  }
}

export const resetPassword = async ({ token, email, password, repeatPassword }) => {
  const data = {
    data: {
      type: 'resetPassword',
      attributes: {
        token,
        email,
        plainPassword: {
          first: password,
          second: repeatPassword,
        },
      },
    },
  }

  try {
    await request({
      method: 'POST',
      url: `/users/v1/reset-password`,
      data,
    })
  } catch (err) {
    return Promise.reject(_formatErrors(err))
  }
}
