import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import Message from './Message'
import Loader from 'components/Loader/Loader'

function List({ BlocNoteStore: { loadingMessages, messages } }) {
  return (
    <div className="list">
      {loadingMessages ? (
        <Loader />
      ) : (
        messages.map(message => {
          return (
            <div
              className={classNames({
                'text-left': !message.isSelf,
                'text-right': message.isSelf,
              })}
              key={`message-${message.id}`}
            >
              <Message data={message} />
            </div>
          )
        })
      )}
    </div>
  )
}

export default compose(inject('BlocNoteStore'), withTranslation(), observer)(List)
