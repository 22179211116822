import { observable, action, decorate, runInAction } from 'mobx'

import { createClaim, getClaimForm } from 'services/claim'

import Form from 'utils/dynform/Form'

class CreateClaimCtrl {
  showModal = false
  loading = false
  form = null

  setProperty(key, value) {
    this[key] = value
  }

  save = async formData => {
    let claim = await createClaim(formData)
    return claim.wan
  }

  loadData = async () => {
    this.loading = true

    try {
      const form = await getClaimForm()
      form.name = 'work_cfa'
      runInAction(() => {
        this.form = new Form({ form, isCreationForm: true })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

const DecoratedCreateClaimCtrl = decorate(CreateClaimCtrl, {
  showModal: observable,
  loading: observable,
  form: observable,

  loadData: action,
  setProperty: action.bound,
  save: action,
})

export default new DecoratedCreateClaimCtrl()
