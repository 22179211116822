import React, { Fragment, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'

import './ModalWithBackdrop.css'

const ModalWithBackdrop = ({
  close,
  children,
  show,
  size,
  className,
  centered,
  scrollable,
  draggable,
  limitHeight,
  backdropOpacity,
  setGrabbing,
  modalContentClassName,
  withBackdrop,
  navigation,
  backdropColor,
}) => {
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false)
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false)
    }
    // eslint-disable-next-line
  }, [])

  const handleKeyUp = e => {
    if (e.keyCode === 27) close()
  }

  if (!show) return null

  return (
    <Fragment>
      <div className={classNames('modal fade show modalWithBackdrop', className, { navigation })}>
        <div
          className={classNames('handle modal-dialog', size, {
            'min-height': limitHeight,
            'modal-dialog-centered': centered,
            'modal-dialog-scrollable': scrollable,
          })}
        >
          <Draggable
            handle=".modal-header"
            onStart={() => {
              setGrabbing(true)
              return draggable
            }}
            onStop={() => {
              setGrabbing(false)
            }}
          >
            <div className={classNames('modal-content', modalContentClassName)}>{children}</div>
          </Draggable>
        </div>
      </div>
      {withBackdrop && (
        <div
          className={classNames('modal-backdrop fade show')}
          style={{
            opacity: backdropOpacity,
            backgroundColor: backdropColor,
          }}
        />
      )}
    </Fragment>
  )
}

ModalWithBackdrop.defaultProps = {
  draggable: false,
  size: 'modal-lg',
  centered: false,
  close: () => {},
  scrollable: false,
  limitHeight: false,
  backdropOpacity: 0.5,
  setGrabbing: () => {},
  modalContentClassName: '',
  withBackdrop: true,
  navigation: false,
  backdropColor: 'none',
}

ModalWithBackdrop.propTypes = {
  draggable: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  centered: PropTypes.bool,
  close: PropTypes.func,
  size: PropTypes.oneOf(['modal-xl', 'modal-lg', 'modal-md', 'modal-sm', '']),
  scrollable: PropTypes.bool,
  limitHeight: PropTypes.bool,
  backdropOpacity: PropTypes.number,
  setGrabbing: PropTypes.func,
  modalContentClassName: PropTypes.string,
  withBackdrop: PropTypes.bool,
  navigation: PropTypes.bool,
  backdropColor: PropTypes.string,
}

export default ModalWithBackdrop
