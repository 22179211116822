import React from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil } from 'ramda'

import { euro } from 'utils/currency'
import InputPrice from 'components/InputPrice/InputPrice'

const FranchiseDeductible = ({
  CartStore: { deductible, setProperty, deductibleOverride },
  ConclusionCtrl: { isFranchiseMustBeDeducted, franchiseMustBeDeducted },
}) => {
  const { t } = useTranslation()

  let settlementPresented = '-'
  let totalPresented = '-'

  if (franchiseMustBeDeducted === 'TO_REPAY') {
    if (!isNil(deductibleOverride) && !isNil(deductibleOverride.immediateSettlement)) {
      settlementPresented = `+ ${euro(deductibleOverride.immediateSettlement)}`
    } else {
      settlementPresented = `+ ${euro(deductible.immediateSettlement)}`
    }
    totalPresented = euro(0)
  }

  if (!isFranchiseMustBeDeducted) {
    return (
      <tr>
        <th scope="row">{t('mission.conclusion.data.deductible')}</th>
        <td className="text-center">-</td>
        <td className="text-center">-</td>
        <td className="text-center">-</td>
        <td className="text-center">-</td>
        <td className="text-center">-</td>
      </tr>
    )
  }
  const onChange = e => {
    setProperty(e.target.name, e.target.value === '' ? 0 : Math.abs(parseFloat(e.target.value)))
  }

  return (
    <tr>
      <th scope="row">{t('mission.conclusion.data.deductible')}</th>
      <td>
        <InputPrice
          name={`deductibleOverride.immediateSettlement`}
          price={
            isNil(deductibleOverride.immediateSettlement)
              ? -deductible.immediateSettlement
              : -deductibleOverride.immediateSettlement
          }
          onChange={onChange}
          width={80}
        />
      </td>
      <td className="text-center">-</td>

      <td>
        {'-'}
        {isNil(deductibleOverride.immediateSettlement)
          ? euro(deductible.immediateSettlement)
          : euro(deductibleOverride.immediateSettlement)}
      </td>

      <td
        className={classNames({
          'text-center': settlementPresented === '-',
        })}
      >
        {settlementPresented}
      </td>

      <td
        className={classNames({
          'text-center': totalPresented === '-',
        })}
      >
        {totalPresented}
      </td>
    </tr>
  )
}

export default inject('CartStore', 'ConclusionCtrl')(observer(FranchiseDeductible))
