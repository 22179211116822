import React from 'react'
import PropTypes from 'prop-types'
import Label from 'components/Label/Label'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import InputPrice from 'components/InputPrice/InputPrice'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'

const LineSelectEdit = ({
  label,
  amountLabel,
  packageCde,
  packageType,
  price,
  targetPrice,
  onChangeCde,
  onChangePrice,
  onCancel,
  onConfirm,
  descriptionRequired = false,
  description,
  onChangeDescription,
}) => {
  return (
    <div>
      <div className="row mb-2">
        <div className="col-8">
          <Label required>{label}</Label>
        </div>
        <div className="col-2 text-right">
          <Label required>{amountLabel}</Label>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-8">
          <div className="col-12">
            <ResponsiveSelect
              name="cde"
              isSearchable
              value={packageCde}
              onChange={onChangeCde}
              options={packageType}
            />
          </div>
          {descriptionRequired && (
            <div className="col-12 mt-2">
              <Input
                type="text"
                onChange={onChangeDescription}
                name="description"
                className="form-control"
                value={description}
                placeholder="Description *"
              />
            </div>
          )}
        </div>
        <div className="col-2">
          <InputPrice
            name={targetPrice}
            price={price}
            classNamesForPrice="font-weight-bold text-primary"
            disableShowUnit
            maxLengthNumber={6}
            onChange={onChangePrice}
          />
        </div>
        <div className="col-2">
          <button className="btn btn-outline-primary rounded-circle mr-3" onClick={onCancel}>
            <i className="fa fa-times"></i>
          </button>
          <Button className="btn btn-outline-primary rounded-circle" onClick={onConfirm}>
            <i className="fa fa-check"></i>
          </Button>
        </div>
      </div>
    </div>
  )
}

LineSelectEdit.propTypes = {
  label: PropTypes.string,
  amountLabel: PropTypes.string,
  onChangeCde: PropTypes.func,
  packageCde: PropTypes.string,
  packageType: PropTypes.array,
  price: PropTypes.number,
  targetPrice: PropTypes.string,
  onChangePrice: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  descriptionRequired: PropTypes.bool,
  description: PropTypes.string,
  onChangeDescription: PropTypes.func,
}

export default LineSelectEdit
