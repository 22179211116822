import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import InputPrice from 'components/InputPrice/InputPrice'
import SettlementRow from './SettlementRow'
import { euro } from 'utils/currency'
import FranchiseDeductible from './FranchiseDeductible'

const RepairSettlements = ({
  t,
  CartStore: {
    settlements,
    setProperty,
    upfrontExpert,
    totalSettlementColumn,
    totalSettlements,
    totalBaseSettlementColumn,
  },
  MissionStore: { suffixVATLabel, isWaterDamage },
  PrejudiceStore: { payloads },
}) => {
  const onChange = e => {
    setProperty(e.target.name, e.target.value === '' ? 0 : parseFloat(e.target.value))
  }
  const exceed = totalSettlementColumn.totalContractSettlement > totalBaseSettlementColumn

  return (
    <React.Fragment>
      <h5 className="mt-2 mb-2">{t('mission.conclusion.title.settlementsTitle')}</h5>
      <div className="p-4 border d-block d-lg-none">{t('mission.conclusion.tableResponsive')}</div>

      <table className="table table-bordered d-none d-lg-table">
        <tbody>
          <tr>
            <th>{t('mission.conclusion.title.prejudice')}</th>
            <th className="text-center">
              {t(`mission.conclusion.computation.immediateSettlement${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.defferedSettlement${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.totalContractSettlement${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.settlementPresented${suffixVATLabel}`)}
            </th>
            <th className="text-center">
              {t(`mission.conclusion.computation.totalPresented${suffixVATLabel}`)}
            </th>
          </tr>

          {settlements.map(settlement => {
            if (payloads.length === 0 && settlement.name === 'options') return null
            else if (settlement.name === 'leak' && !isWaterDamage) return null
            return <SettlementRow key={`settlement-${settlement.name}`} settlement={settlement} />
          })}

          <tr>
            <th scope="row">{t('mission.conclusion.data.totalData')}</th>
            <td>{euro(totalSettlementColumn.immediateSettlement)}</td>
            <td>{euro(totalSettlementColumn.defferedSettlement)}</td>
            <td
              className={classNames({
                'text-success': !exceed,
                'text-danger': exceed,
              })}
            >
              {exceed && <i className="fa fa-warning" />}{' '}
              {euro(totalSettlementColumn.totalContractSettlement)}
            </td>
            <td>{euro(totalSettlementColumn.settlementPresented)}</td>
            <td>{euro(totalSettlementColumn.totalPresented)}</td>
          </tr>

          <FranchiseDeductible />

          <tr>
            <th scope="row">{t('mission.conclusion.data.upfrontExpert')}</th>
            <td>
              <InputPrice
                name="upfrontExpert.immediateSettlement"
                price={upfrontExpert.immediateSettlement}
                onChange={onChange}
                width={80}
              />
            </td>
            <td>
              <InputPrice
                name="upfrontExpert.defferedSettlement"
                price={upfrontExpert.defferedSettlement}
                onChange={onChange}
                width={80}
              />
            </td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
          </tr>

          <tr>
            <th scope="row">{t('mission.conclusion.computation.total')}</th>
            <td className="font-weight-bold">{euro(totalSettlements.immediateSettlement)}</td>
            <td className="font-weight-bold">{euro(totalSettlements.defferedSettlement)}</td>
            <td
              className={classNames('font-weight-bold', {
                'text-success': !exceed,
                'text-danger': exceed,
              })}
            >
              {exceed && <i className="fa fa-warning" />}{' '}
              {euro(totalSettlements.totalContractSettlement)}
            </td>
            <td className="font-weight-bold">{euro(totalSettlements.settlementPresented)}</td>
            <td className="font-weight-bold">{euro(totalSettlements.totalPresented)}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default compose(
  inject('CartStore', 'PrejudiceStore', 'MissionStore'),
  withTranslation(),
  observer,
)(RepairSettlements)
