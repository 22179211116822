import React, { Fragment } from 'react'
import moment from 'moment'
import { path, type } from 'ramda'

import { formatDate, uid } from 'utils'
import { euro } from 'utils/currency'

const Table = ({
  headers,
  fields,
  values = [],
  classNames = '',
  noValue = '',
  title,
  addDivider = true,
}) => {
  return (
    <Fragment>
      {addDivider && <div className="divider" />}
      {title && (
        <div className="row">
          <div className="font-weight-bold">{title}</div>
        </div>
      )}
      <div className={`d-flex flex-column align-items-center ${classNames}`}>
        <table border="1" className="line-display table table-bordered table-sm">
          <thead>
            <tr className="table-primary">
              {!!headers.length && headers.map(header => <th key={header}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {!!values.length &&
              values.map(value => {
                const id = value.id ? value.id : uid(8)

                return (
                  <tr key={id}>
                    {!!fields.length &&
                      fields.map(field => {
                        let val = path(field.split('.'), value)
                        val = type(val) === 'Array' ? val : [val]

                        return (
                          <td key={field}>
                            {val.map(v => {
                              v = type(v) === 'Number' ? euro(v) : v
                              v =
                                moment(v, 'YYYY-MM-DD', true).isValid() ||
                                moment(v, 'YYYY-DD-MM', true).isValid()
                                  ? formatDate(v, false)
                                  : v
                              return <div key={uid(8)}>{v}</div>
                            })}
                          </td>
                        )
                      })}
                  </tr>
                )
              })}

            {!values.length && (
              <tr>
                <td colSpan={headers.length}>
                  <div className="d-flex flex-column align-items-center">{noValue}</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default Table
