import { observable, computed, action, decorate } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class InputNumber extends AbstractField {
  value = ''
  errors = []
  show = true
  originalValue = null

  type = 'number'
  choices = null
  maxLength = null
  maxValue = null
  minValue = null
  multiple = false
  expanded = false
  children = null

  constructor(data) {
    super(data)

    this.type = data.type || 'number'
    this.choices = data.choices || null
    this.maxLength = data.maxLength || null
    this.maxValue = isFinite(data.maxValue) ? data.maxValue : null
    this.minValue = isFinite(data.minValue) ? data.minValue : null

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    this.show = data.show
    this.originalValue = this.value
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedInputNumber = decorate(InputNumber, {
  value: observable,
  errors: observable,
  show: observable,
  originalValue: observable,

  setOriginalData: action,

  changed: computed,
})

export default DecoratedInputNumber
