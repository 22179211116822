import request from 'services/httpRequest'

export const fetchMessages = async ({ wan = null, filter = null, pageSize = 'all' }) => {
  const url = `/blocNote/v1/${wan || ''}`

  const params = { 'page[size]': pageSize }
  if (filter) params[`filter[${filter.key}]`] = String(filter.value)

  const { meta, data: messages } = await request({ method: 'GET', url, params })
  return { meta, data: messages.map(({ attributes }) => attributes) }
}

export const postMessage = async message => {
  try {
    await request({
      method: 'POST',
      url: `/blocNote/v1/send`,
      data: { data: { type: 'bloc_note', attributes: message } },
    })
  } catch (err) {
    throw err
  }
}
