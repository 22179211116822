import { observable, action, decorate, runInAction } from 'mobx'

import { fetchAgreementProtocol, saveAgreementProtocol } from 'services/missionForm'
import Form from 'utils/dynform/Form'

class AgreementProtocol {
  loading = false
  form = null

  loadData = async (wan, data) => {
    this.loading = true

    try {
      const form = await fetchAgreementProtocol(wan)
      form.name = 'agreementProtocol'
      runInAction(() => {
        this.form = new Form({ form, data })
      })
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    await saveAgreementProtocol(wan, formData)
  }
}

const DecoreatedAgreementProtocol = decorate(AgreementProtocol, {
  loading: observable,
  form: observable,

  loadData: action,
  save: action,
})

export default new DecoreatedAgreementProtocol()
