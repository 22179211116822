import React from 'react'
import { inject, observer } from 'mobx-react'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const SelectRole = ({
  CommonStore: { businessRolesForSelect, personalRolesForSelect },
  errors,
  required,
}) => {
  const onChange = e => PartyInvolvedCtrl.party.setRole(e.target.value)

  const { thirdPartyKey } = PartyInvolvedCtrl.party
  const selectData =
    PartyInvolvedCtrl.party.partyType === 'personal'
      ? personalRolesForSelect
      : businessRolesForSelect

  return (
    <ResponsiveSelect
      name="thirdPartyKey"
      className="quality"
      required={required}
      value={thirdPartyKey}
      onChange={onChange}
      options={selectData}
      errors={errors}
      isSearchable
    />
  )
}
export default inject('CommonStore')(observer(SelectRole))
