import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import Address from 'components/Address/Address'
import { concatAddress } from 'utils'

const AddressDeparture = ({ t, day, day: { dayOfWeek, setProperty } }) => {
  const onSelected = value => {
    setProperty('addressLine1', value.addressLine1)
    setProperty('addressLine2', value.addressLine2)
    setProperty('addressLine3', value.addressLine3)
    setProperty('city', value.city)
    setProperty('geometry', { lat: value.lat, long: value.long })
    setProperty('streetNumber', value.streetNumber)
    setProperty('zipCode', value.zipCode)
  }

  return (
    <div className="row no-gutters mb-3 align-items-center">
      <div className="col-sm-12 col-md-2 col-lg-2">
        {t(`parameters.tourConfiguration.${dayOfWeek}`)}
      </div>
      <div className="col-sm-12 col-md-10 col-lg-10">
        <Address
          types={['address']}
          initialValue={concatAddress(day)}
          value={day}
          onSelected={onSelected}
        />
      </div>
    </div>
  )
}

export default withTranslation()(observer(AddressDeparture))
