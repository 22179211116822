import React from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import { concatNames } from 'utils'

const PartyLine = ({ data }) => {
  const openModal = party => PartyInvolvedCtrl.showForm(party)
  const { civility, firstName, lastName, thirdPartyName, companyName } = data
  const name = lastName || firstName ? concatNames(civility, lastName, firstName) : ''
  const toDisplay = [name, thirdPartyName, companyName]

  return (
    <tr>
      {toDisplay.map((info, i) => (
        <td key={i} onClick={() => openModal(data)}>
          {info}
        </td>
      ))}
    </tr>
  )
}

export default compose(observer)(PartyLine)
