import request from 'services/httpRequest'

export const changePassword = async changePassword => {
  try {
    const res = await request({
      method: 'PUT',
      url: '/users/v1/password',
      data: {
        data: {
          type: 'change_password',
          attributes: { ...changePassword },
        },
      },
    })
    return res
  } catch (err) {
    const errors = []
    if (err.status !== 401) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
    }
    return Promise.reject(errors)
  }
}
