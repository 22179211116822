import React from 'react'
import { inject, observer } from 'mobx-react'

import Button from 'components/Button/Button'

const SaveEstimationButton = ({ className, CartStore: { save, showSaveButton } }) => {
  if (!showSaveButton) return null

  return (
    <Button
      className={className}
      onClick={() => {
        save()
      }}
    >
      Sauvegarder l'estimation
    </Button>
  )
}

export default inject('CartStore')(observer(SaveEstimationButton))
