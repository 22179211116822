import React, { useEffect, useState } from 'react'
import { fetchQlikToken, sendTokenToQlik } from 'services/qlik'
//import { Redirect } from 'react-router-dom'

const Qlik = () => {
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const qlikInfo = await fetchQlikToken()
      await sendTokenToQlik(qlikInfo.token)
      setLoading(false)
      setToken(qlikInfo.token)
    }
    fetchData()
  }, [])

  if (loading || !token) return null

  //window.open('https://analytics.sidexa.fr/sh_jwt/hub/', '_blank')
  //return <Redirect to="/" />

  return (
    <iframe
      id="testQlik"
      src="https://analytics.sidexa.fr/sh_jwt/hub/"
      width="100%"
      height="800px"
      title="qlik"
    ></iframe>
  )
}

export default Qlik
