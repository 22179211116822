import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'

class CanvasTextArea extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      textAreaValue: '',
    }
  }

  componentDidMount() {
    this.textareaBox.focus()
  }

  onChangeTextArea = e => {
    this.setState({ textAreaValue: e.target.value })
  }

  handleConfirm = e => {
    DrawCtrl.setTextBox(this.state.textAreaValue)
  }

  handleCancel = e => {
    DrawCtrl.setCloseTextArea(this.state.textAreaValue)
  }

  render() {
    const { t } = this.props
    const { textAreaValue } = this.state
    return (
      <div
        style={{
          width: 220,
          height: 100,
          position: 'absolute',
          overflow: 'hidden',
          top:
            DrawCtrl.currentElement.startY > DrawCtrl.drawZone.height - 110
              ? DrawCtrl.drawZone.height - 110
              : DrawCtrl.currentElement.startY,
          left:
            DrawCtrl.currentElement.startX > DrawCtrl.drawZone.width - 230
              ? DrawCtrl.drawZone.width - 230
              : DrawCtrl.currentElement.startX,
          background: '#FFFFFF',
          zIndex: 990,
          padding: '5px',
        }}
      >
        <textarea
          style={{
            width: 210,
            height: 50,
            maxHeight: 150,
            fontSize: DrawCtrl.fontSize - 1,
            resize: 'none',
          }}
          onChange={e => this.onChangeTextArea(e)}
          value={textAreaValue}
          placeholder={t('mission.supportingDocuments.draw.textArea.placeHolder')}
          ref={textarea => {
            this.textareaBox = textarea
          }}
        />
        <div
          className="btn-group d-flex justify-content-between"
          style={{ background: '#FFFFFF', padding: '5px', width: 210, zIndex: 395 }}
        >
          <div className="btn-group btn-group-sm font-size">
            <button
              className={classNames('btn btn-outline-secondary font-size-small', {
                active: DrawCtrl.fontSize === 14,
              })}
              onClick={() => DrawCtrl.setFontSize(14)}
            >
              <i className="fa fa-font" />
            </button>
            <button
              className={classNames('btn btn-outline-secondary font-size-medium', {
                active: DrawCtrl.fontSize === 18,
              })}
              onClick={() => DrawCtrl.setFontSize(18)}
            >
              <i className="fa fa-font" />
            </button>
            <button
              className={classNames('btn btn-outline-secondary font-size-large', {
                active: DrawCtrl.fontSize === 22,
              })}
              onClick={() => DrawCtrl.setFontSize(22)}
            >
              <i className="fa fa-font" />
            </button>
          </div>
          <div className="btn-group">
            <button
              className="btn btn btn-secondary btn-sm mr-1"
              onClick={() => this.handleCancel()}
            >
              <i className="fa fa-times" />
            </button>
            <button className="btn btn-primary btn-sm" onClick={() => this.handleConfirm()}>
              {t('common.validate')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(observer(CanvasTextArea))
