import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import Input from 'components/Forms/Input'
import Label from 'components/Label/Label'

class CompanyInfo extends React.Component {
  onChange = e => {
    PartyInvolvedCtrl.party.setProperty(e.target.name, e.target.value)
  }
  onChangePhone = e => {
    const phoneNumber = e.target.value.replace('0', '+33')
    const trimed = phoneNumber.replace(/ /g, '')
    PartyInvolvedCtrl.party.setProperty(e.target.name, trimed)
  }
  handleError = (errors, name) => {
    const error = errors.find(error => error.key === name)
    if (error) return [error.value]
    return []
  }

  render() {
    const { t } = this.props
    const {
      companyEmailAddress,
      maskCompanyPhone,
      maskCompanyFax,
      companyName,
      partyType,
      errors,
    } = PartyInvolvedCtrl.party
    if (partyType === 'personal') return null

    return [
      <div key="company-info-1" className="row form-group">
        <div className="col-6">
          <Label required>{t('partyInvolved.modal.companyName')}</Label>
          <Input
            required
            className="form-control"
            name="companyName"
            value={companyName}
            placeholder={t('partyInvolved.modal.companyName')}
            onChange={this.onChange}
            errors={this.handleError(errors, 'companyName')}
          />
        </div>
        <div className="col-6">
          <label>{t('partyInvolved.modal.companyEmail')}</label>
          <Input
            className="form-control"
            name="companyEmailAddress"
            value={companyEmailAddress}
            placeholder={t('partyInvolved.modal.companyEmail')}
            onChange={this.onChange}
            errors={this.handleError(errors, 'companyEmailAddress')}
          />
        </div>
      </div>,
      <div key="company-info-2" className="row form-group">
        <div className="col-6">
          <label>{t('partyInvolved.modal.companyPhone')}</label>
          <Input
            className="form-control"
            name="companyPhoneNumber"
            value={maskCompanyPhone}
            placeholder={t('partyInvolved.modal.companyPhone')}
            onChange={this.onChangePhone}
            errors={this.handleError(errors, 'companyPhoneNumber')}
          />
        </div>
        <div className="col-6">
          <label>{t('partyInvolved.modal.companyFax')}</label>
          <Input
            className="form-control"
            name="companyFax"
            value={maskCompanyFax}
            placeholder={t('partyInvolved.modal.companyFax')}
            onChange={this.onChangePhone}
            errors={this.handleError(errors, 'companyFax')}
          />
        </div>
      </div>,
    ]
  }
}

export default withTranslation()(observer(CompanyInfo))
