import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const QlickLink = ({ UiCtrl: { deviceType }, UserStore: { customer, hasQlikAccess } }) => {
  const { t } = useTranslation()
  const history = useHistory()

  if (!hasQlikAccess) return null

  if (deviceType === 'mobile') {
    return (
      <React.Fragment>
        <li className="nav-item">
          <span className="nav-link text-primary" onClick={() => history.push('qlik')}>
            {t('header.navbar.links.qlik')}
          </span>
        </li>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <span className="divider" />
      <li className="nav-item">
        <span className="nav-link text-primary" onClick={() => history.push('qlik')}>
          {t('header.navbar.links.qlik')}
        </span>
      </li>
    </React.Fragment>
  )
}

export default inject('UiCtrl', 'UserStore')(observer(QlickLink))
