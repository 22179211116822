import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import CartStore from 'stores/Mission/domain/CartStore'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import CollapseList from './CollapseList'

class PropertyList extends React.Component {
  setContext = () => SearchPackageCtrl.resetSearchContext('DAT_PROP')

  render() {
    const { t } = this.props
    const { id, type } = this.props.match.params
    const { propertyPackagesByRoom } = CartStore

    return [
      <div className="container" key="cart-type-price-label">
        <div className="row no-gutters d-none d-lg-flex">
          <div className="col-7" />
          <div className="col-2 text-right font-weight-bold">
            {t('mission.calculation.cart.priceCatalog')}
          </div>
          <div className="col-2 text-right font-weight-bold">
            {t('mission.calculation.cart.selfRepair')}
          </div>
          <div className="col-1" />
        </div>
      </div>,
      <div
        className="container border-bottom border-color-primary"
        key="cart-property-list-package"
      >
        <CollapseList
          title={t('mission.calculation.cart.titleProperty')}
          type="DAT_PROP"
          rooms={propertyPackagesByRoom}
        >
          <NavLink
            className="btn btn-outline-primary rounded-circle"
            to={`/${type}/${id}/calculation/searchPackage`}
            onClick={this.setContext}
          >
            <i className="fa fa-plus" />
          </NavLink>
        </CollapseList>
      </div>,
    ]
  }
}

export default withRouter(withTranslation()(observer(PropertyList)))
