import React from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, useHistory } from 'react-router-dom'

import CreatePackageCtrl from 'stores/Mission/view/CreatePackageCtrl'
import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'

const LinkToOperation = ({ sdItemId, CartStore: { cartSD } }) => {
  const history = useHistory()
  const { id } = useParams()

  if (cartSD.sdIds.indexOf(sdItemId) === -1) return null

  const detailSD = cartSD.detailSDs[sdItemId]

  const goTo = () => {
    switch (detailSD.type) {
      // PACKAGES
      case 'package':
        DetailPackageCtrl.setPackage(detailSD.data)
        history.push(`/mission/${id}/calculation/detailPackage`)
        break
      case 'customPackage':
        CreatePackageCtrl.setPackage(detailSD.data)
        history.push(`/mission/${id}/calculation/createPackage`)
        break
      case 'outdoorPackage':
        DetailPackageCtrl.setPackage(detailSD.data)
        history.push(`/mission/${id}/calculation/detailOutdoorPackage`)
        break
      case 'customOutdoorPackage':
        CreatePackageCtrl.setPackage(detailSD.data)
        history.push(`/mission/${id}/calculation/createOutdoorPackage`)
        break

      // FURNITURES
      case 'furniture':
        AddFurnitureCtrl.setFurniture(detailSD.data, 'edit')
        history.push(`/mission/${id}/calculation/addRegularFurniture`)
        break
      case 'outdoorFurniture':
        AddFurnitureCtrl.setFurniture(detailSD.data, 'edit')
        history.push(`/mission/${id}/calculation/addOutdoorFurniture`)
        break

      // RELATED COSTS
      case 'relatedCost':
        AddRelatedCostCtrl.setProperty('context', 'edit')
        AddRelatedCostCtrl.setProperty('relatedCost', detailSD.data)
        history.push(`/mission/${id}/calculation/addRelatedCost`)
        break
      case 'noGuarantee':
        AddRelatedCostCtrl.setProperty('context', 'edit')
        AddRelatedCostCtrl.setProperty('relatedCost', detailSD.data)
        history.push(`/mission/${id}/calculation/addNoGuarantee`)
        break
      case 'overflow':
        AddRelatedCostCtrl.setProperty('context', 'edit')
        AddRelatedCostCtrl.setProperty('relatedCost', detailSD.data)
        history.push(`/mission/${id}/calculation/addOverflow`)
        break

      default:
        break
    }
  }

  return (
    <button className="link-to-report btn btn-circle" onClick={goTo}>
      <i className="fa fa-external-link" />
    </button>
  )
}

export default inject('CartStore')(observer(LinkToOperation))
