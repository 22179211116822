import { observable, computed, action, decorate } from 'mobx'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState } from 'draft-js'
import AbstractField from 'utils/dynform/AbstractField'

class TextArea extends AbstractField {
  value = ''
  originalValue = null
  editorState = null
  errors = []
  show = true
  wysiwyg = false
  originalWysiwyg = false
  wysiwygMix = false

  type = 'textarea'
  choices = null
  maxLength = null
  multiple = false
  expanded = false

  children = null

  constructor(data) {
    super(data)

    this.type = data.type || 'textarea'
    this.choices = data.choices || null
    this.maxLength = data.maxLength || null
    this.wysiwyg = data.wysiwyg || false
    this.wysiwygMix = data.wysiwygMix || false

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''

    if (this.wysiwygMix && this.value && !this.value.includes('<p>')) {
      this.wysiwyg = false
    }

    this.show = data.show
    this.originalValue = this.value
    this.originalWysiwyg = this.wysiwyg

    this.generateEditorStateFormValue()
  }

  toggleWysiwyg = () => {
    if (!this.wysiwygMix) {
      return
    }

    if (this.wysiwyg) {
      this.wysiwyg = !this.wysiwyg
      this.convertWysiwygtoTextarea()
    } else {
      this.wysiwyg = !this.wysiwyg
      this.convertTextareaToWysiwyg()
    }
  }

  convertTextareaToWysiwyg() {
    this.value = `<p>${this.value}</p>`
    this.generateEditorStateFormValue()
  }

  convertWysiwygtoTextarea() {
    this.value = this.editorState.getCurrentContent().getPlainText()
  }

  generateEditorStateFormValue() {
    if (!this.wysiwyg) return

    const contentBlock = htmlToDraft(this.value)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      this.editorState = EditorState.createWithContent(contentState)
    }
  }

  get changed() {
    return this.value !== this.originalValue || this.originalWysiwyg !== this.wysiwyg
  }

  convertEditorContentToText() {
    if (!this.editorState) {
      this.value = ''
      return
    }

    let value = draftToHtml(convertToRaw(this.editorState.getCurrentContent()))
    if (value === '<p></p>') value = ''
    this.value = value
  }

  setOriginalData() {
    this.value = this.originalValue
    this.wysiwyg = this.originalWysiwyg
    this.generateEditorStateFormValue()
  }
}

const DecoratedTextArea = decorate(TextArea, {
  value: observable,
  originalValue: observable,
  errors: observable,
  show: observable,
  editorState: observable,
  wysiwyg: observable,
  wysiwygMix: observable,

  setOriginalData: action,
  convertEditorContentToText: action,
  generateEditorStateFormValue: action,
  toggleWysiwyg: action,
  convertTextareaToWysiwyg: action,
  convertWysiwygtoTextarea: action,

  changed: computed,
})

export default DecoratedTextArea
