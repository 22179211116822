import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })

    // You can log the error to an error reporting service
    // logErrorToSPS(error, errorInfo)
  }

  render() {
    const { error, errorInfo } = this.state
    const { t, children } = this.props

    if (errorInfo) {
      return (
        <Fragment>
          <div className="d-flex align-items-center">
            <h1>{t('common.unknownError')}</h1>
            <button className="btn btn-info ml-4" onClick={() => window.location.reload()}>
              <i className="fa fa-refresh" />
            </button>
          </div>
          <details className="whiteSpace-pre-wrap">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </Fragment>
      )
    }

    return children
  }
}

export default withTranslation()(ErrorBoundary)
