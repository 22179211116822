import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const ConfirmBox = ({ message, callback }) => {
  const [isHidden, setIsHidden] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsHidden(false)
  }, [callback])

  const yes = () => {
    callback(true)
    setIsHidden(true)
  }

  const no = () => {
    callback(false)
    setIsHidden(true)
  }

  if (isHidden) return null

  return (
    <div className="modal d-block">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer pb-2 pt-2">
            <button type="button" className="btn btn-primary" onClick={yes}>
              {t('common.yes')}
            </button>
            <button type="button" className="btn btn-secondary" onClick={no}>
              {t('common.no')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmBox

ConfirmBox.propTypes = {
  message: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
}
