import React, { useEffect } from 'react'
import { inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { getDataLocal, isAvailable as isLocalStorageAvailable } from 'stores/localStorage'
import { isAvailable as isSessionStorageAvailable } from 'stores/sessionStorage'
import AlertCenter from 'components/AlertCenter/AlertCenter'
import Main from 'components/Main/Main'
import ToolBar from 'components/ToolBar/ToolBar'
import CalculationBar from 'components/CalculationBar/CalculationBar'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Header from 'components/Header/Header'
import ErrorBoundary from 'components/ErrorBoundary'
import withClearCache from './ClearCache'

const App = ({
  UserStore: { logout, loadUserData, setProperty: setPropUserStore },
  i18n,
  UiCtrl: { checkApiStatus, changeLanguage },
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    checkApiStatus()
      .then(status => {
        if (status === 200) {
          const userInfo = getDataLocal('userInfo')
          if (
            process.env.REACT_APP_SOLERA_ENV === 'PROD' &&
            userInfo &&
            !userInfo.passwordChangedAt
          ) {
            //Here we have a token but user still hasn't changed his password
            //So we just reset the token
            setPropUserStore('token', null)
            setPropUserStore('loading', false)
          } else if (userInfo) {
            loadUserData(userInfo)
          }
        } else {
          logout(false)
        }
      })
      .catch(err => logout(false))
    // eslint-disable-next-line
  }, [])

  const ClearCacheComponent = withClearCache(App)

  const changeLangue = lng => {
    i18n.changeLanguage(lng)
    changeLanguage(lng)
  }
  if (!(isLocalStorageAvailable() && isSessionStorageAvailable())) {
    document.body.classList.add('login-background')
    return (
      <div className="App cookies-disabled">
        <Header changeLanguage={changeLangue} />
        <ClearCacheComponent />
        <ModalWithBackdrop show close={() => {}} centered>
          <div className="modal-header">
            <h5 className="modal-title">{t('cookies.title')}</h5>
          </div>
          <div className="modal-body">
            <p>{t('cookies.description')}</p>
          </div>
        </ModalWithBackdrop>
      </div>
    )
  }

  return (
    <div className="App">
      <ErrorBoundary>
        <Main />
      </ErrorBoundary>
      <AlertCenter />
      <ToolBar />
      <CalculationBar />
    </div>
  )
}

export default inject('UiCtrl', 'UserStore')(App)
