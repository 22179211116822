import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import CanvasZone from './CanvasZone'
import DrawCtrl, { DrawElements } from 'stores/Mission/view/DrawCtrl'
import Input from 'components/Forms/Input'
import forceLandscape from 'assets/switch-iphone.png'
import DrawColorPicker from './DrawColorPicker'
import StrokeWidth from './StrokeWidth'
import { isRequiredValid } from 'utils/supportingDocumentsForm'
import Button from 'components/Button/Button'
import StrokeWidthSelect from './StrokeWidthSelect'

class DrawModalMobile extends React.Component {
  componentDidMount() {
    document.addEventListener('click', e => this.onClickOutside(e), true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', e => this.onClickOutside(e), true)
  }

  onClickOutside = e => {
    if (DrawCtrl.strokeWidthOpen) {
      if (!this.strokeMenu.contains(e.target)) {
        DrawCtrl.toggleStrokeWidthSelect()
        e.preventDefault()
      }
    } else if (DrawCtrl.colorPickerOpen) {
      if (!this.colorPickerMenu.contains(e.target)) {
        DrawCtrl.toggleColorPicker()
      }
      e.preventDefault()
    }
  }

  setSchemaName = e => DrawCtrl.setSchemaName(e.target.value)
  createSchema = () => DrawCtrl.createSchema(this.props.match.params.id)
  toggleDrawModal = () => DrawCtrl.toggleDrawModal()
  undo = () => DrawCtrl.undo()
  toggleStrokeWidthSelect = () => DrawCtrl.toggleStrokeWidthSelect()
  toggleColorPicker = () => DrawCtrl.toggleColorPicker()
  setType = type => DrawCtrl.setType(type)

  render() {
    const {
      t,
      UiCtrl: { isLandscape },
    } = this.props
    const colorPicker = {
      color: DrawCtrl.color,
    }
    if (!DrawCtrl.drawModal) return null

    if (!isLandscape) {
      return (
        <div className="modal drawModalMobile">
          <div className="modal-dialog">
            <div className="modal-content">
              <img
                height="176"
                width="151"
                src={forceLandscape}
                alt="Landscape orientation needed"
                className="mx-auto d-block mt-5"
              />
              <p className="text-center">{t('mission.supportingDocuments.draw.forcePortrait')}</p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={classNames('modal drawModalMobile')}>
        <nav className="navbar sticky-top navbar-light">
          <div
            className={classNames('d-flex align-items-center', {
              'justify-content-between': !DrawCtrl.photoEdit,
              'justify-content-end': DrawCtrl.photoEdit,
            })}
          >
            {!DrawCtrl.photoEdit && (
              <form>
                <Input
                  type="text"
                  className="form-control"
                  value={DrawCtrl.schemaName}
                  onChange={this.setSchemaName}
                  required
                  placeholder={t('mission.supportingDocuments.draw.name')}
                  errors={[]}
                  maxLength={64}
                />
              </form>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <div className="btn-group mr-2">
                <Button
                  className={classNames('btn btn-outline-primary')}
                  disabled={!isRequiredValid(DrawCtrl.schemaName) || DrawCtrl.elements.length === 0}
                  onClick={this.createSchema}
                  loading={DrawCtrl.loading}
                >
                  <i className="fa fa-check" />
                </Button>
              </div>
              <div className="btn-group close-drawing-btn">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={this.toggleDrawModal}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-1 toolbar">
                  <div className="btn-group-vertical" ref="toolBar">
                    <div className="btn-group mb-2">
                      <button
                        type="button"
                        className={classNames('btn btn-outline-primary', {
                          disabled: DrawCtrl.elements.length === 0,
                        })}
                        onClick={this.undo}
                      >
                        <i className="fa fa-undo" />
                      </button>
                    </div>
                    <div
                      className={classNames('btn-group mb-2', { show: DrawCtrl.strokeWidthOpen })}
                    >
                      <button
                        className="btn btn-outline-primary stroke-width-btn"
                        onClick={this.toggleStrokeWidthSelect}
                      >
                        <StrokeWidth strokeWidth={DrawCtrl.strokeWidth} />
                      </button>
                      <div ref={strokeMenu => (this.strokeMenu = strokeMenu)}>
                        <StrokeWidthSelect />
                      </div>
                    </div>
                    <div className="btn-group mb-4">
                      <button
                        type="button"
                        className={classNames('btn btn-outline-primary')}
                        onClick={this.toggleColorPicker}
                      >
                        <i className="fa fa-tint" style={colorPicker} />
                      </button>
                    </div>
                    <div className="btn-group mb-2">
                      <button
                        type="button"
                        className={classNames('btn btn-outline-primary', {
                          active: DrawCtrl.type === DrawElements.ARROW,
                        })}
                        onClick={this.setType.bind(this, DrawElements.ARROW)}
                      >
                        <i className="fa fa-arrow-circle-right" />
                      </button>
                    </div>
                    <div className="btn-group mb-2">
                      <button
                        type="button"
                        className={classNames('btn btn-outline-primary', {
                          active: DrawCtrl.type === DrawElements.CIRCLE,
                        })}
                        onClick={this.setType.bind(this, DrawElements.CIRCLE)}
                      >
                        <i className="fa fa-circle" />
                      </button>
                    </div>
                    <div className="btn-group ">
                      <button
                        type="button"
                        className={classNames('btn btn-outline-primary', {
                          active: DrawCtrl.type === DrawElements.FREE_DRAWING,
                        })}
                        onClick={this.setType.bind(this, DrawElements.FREE_DRAWING)}
                      >
                        <i className="fa fa-pencil" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-11">
                  <CanvasZone
                    height={this.refs.toolBar && this.refs.toolBar.getBoundingClientRect().height}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {DrawCtrl.colorPickerOpen && (
          <div ref={colorPickerMenu => (this.colorPickerMenu = colorPickerMenu)}>
            <DrawColorPicker />
          </div>
        )}
      </div>
    )
  }
}

export default inject('UiCtrl')(withRouter(withTranslation()(observer(DrawModalMobile))))
