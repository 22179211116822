import { observable, action, computed, decorate, runInAction, reaction } from 'mobx'
import { path } from 'ramda'

import { fetchCalendar } from 'services/calendar'
import Appointment from './Appointment'
import { positionColors, getRange } from 'utils/calendar'
import { convertUTCDateToLocalDate } from 'utils'

class CalendarExpert {
  id = null
  firstName = ''
  lastName = ''
  email = ''
  customerId = ''
  appointments = []
  position = null

  constructor(data) {
    this.id = data.id
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.email = data.email
    this.customerId = data.customerId

    this.positionColorHandler = reaction(
      () => this.positionColor,
      () => {
        this.appointments.forEach(appointment =>
          appointment.setProperty('expertColor', this.positionColor),
        )
      },
    )
  }

  get uid() {
    return `${this.customerId}+${this.email}`
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get optionsFormat() {
    return {
      value: this.id,
      label: this.fullName,
      positionColor: this.positionColor,
    }
  }

  get calendar() {
    return this.appointments.map(appointment => appointment.asEvent)
  }

  get positionColor() {
    if (this.position === null || this.position > 11) {
      return {
        background: '#e6e6e6',
        fontColor: '#333333',
      }
    }

    return positionColors[this.position]
  }

  getCalendar = async (day, view) => {
    try {
      this.loading = true
      const res = await fetchCalendar(this.id, getRange(day, view))

      runInAction(() => {
        this.appointments = res.map(appointment => {
          return new Appointment({
            ...appointment,
            start: convertUTCDateToLocalDate(new Date(appointment.startDate)),
            end: convertUTCDateToLocalDate(new Date(appointment.endDate)),
            endRecurrentDate: appointment.endRecurrentDate
              ? convertUTCDateToLocalDate(new Date(appointment.endRecurrentDate))
              : null,
            type: appointment.type.id,
            assignee: appointment.assignee.id,
            address: appointment.address || null,
            expertiseCharacteristic: path(['expertiseCharacteristic', 'value'], appointment),
            expertiseType: path(['expertiseType', 'value'], appointment),
            expertiseTypeKey: path(['expertiseType', 'key'], appointment),
            missionNature: path(['missionNature', 'value'], appointment),
            expertColor: this.positionColor,
          })
        })
      })
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setProperty(key, value) {
    this[key] = value
  }
}

decorate(CalendarExpert, {
  appointments: observable,
  position: observable,

  fullName: computed,
  optionsFormat: computed,
  calendar: computed,
  uid: computed,

  setProperty: action,
  getCalendar: action,
})

export default CalendarExpert
