import { observable, action, runInAction, computed, decorate } from 'mobx'

import { fetchDepartures, updateDepartures } from 'services/tour'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

class DayClass {
  addressLine1 = ''
  addressLine2 = ''
  addressLine3 = ''
  city = ''
  country = 'FR'
  geometry = { lat: 0, long: 0 }
  streetNumber = ''
  zipCode = ''

  dayOfWeek = 0

  constructor(dayOfWeek) {
    this.dayOfWeek = dayOfWeek
  }

  setProperty(key, value) {
    this[key] = value
  }

  updateData(data) {
    Object.assign(this, data)
  }
}

const Day = decorate(DayClass, {
  addressLine1: observable,
  addressLine2: observable,
  addressLine3: observable,
  city: observable,
  country: observable,
  geometry: observable,
  streetNumber: observable,
  zipCode: observable,

  setProperty: action.bound,
  updateData: action.bound,
})

class TourConfigurationCtrl {
  days = [1, 2, 3, 4, 5, 6, 7].map(dayOfWeek => new Day(dayOfWeek))
  loading = false
  saving = false

  get asJson() {
    return this.days.map(day => {
      return {
        dayOfWeek: day.dayOfWeek,
        address: {
          addressLine1: day.addressLine1,
          addressLine2: day.addressLine2,
          addressLine3: day.addressLine3,
          city: day.city,
          country: day.country,
          latitude: day.geometry.lat,
          longitude: day.geometry.long,
          streetNumber: day.streetNumber,
          zipCode: day.zipCode,
        },
      }
    })
  }

  async loadData() {
    this.loading = true
    try {
      const res = await fetchDepartures()
      runInAction(() => {
        this.days = this.days.map(day => {
          const resDay = res.find(resDay => resDay.dayOfWeek === day.dayOfWeek)
          if (resDay) day.updateData(resDay.address)
          return day
        })
        this.loading = false
      })
    } catch (err) {
      AlertCtrl.alert('danger', 'common.error500')
    }
  }

  async save() {
    this.saving = true
    try {
      await updateDepartures(this.asJson)
      runInAction(() => {
        this.saving = false
      })
      AlertCtrl.alert('success', 'common.saveSuccess')
    } catch (err) {
      AlertCtrl.alert('danger', 'common.error500')
      runInAction(() => {
        this.saving = false
      })
    }
  }
}

const DecoratedTourConfigurationCtrl = decorate(TourConfigurationCtrl, {
  days: observable,
  loading: observable,
  saving: observable,

  loadData: action.bound,
  save: action.bound,

  asJson: computed,
})

export default new DecoratedTourConfigurationCtrl()
