import React from 'react'
import { inject, observer } from 'mobx-react'
import { format, isEqual, isPast, getISODay, addMinutes } from 'date-fns'
import classNames from 'classnames'

function TimeSlot({
  value,
  PlannerStore: {
    selectedExpert,
    selectedExpertData,
    selectedTimeSlot,
    select,
    checkAvailability,
    checkAllAvailability,
    withinMissionDuration,
  },
}) {
  const slot = format(value, 'H:mm')
  const isPastBool = isPast(value)
  const valueAfterTime = addMinutes(value, 15)
  const isPastAfterTime = isPast(valueAfterTime)

  if (isPastBool && isPastAfterTime) {
    return <span className="invisible-border">-</span>
  }

  if (isPastBool && !isPastAfterTime) {
    if (selectedExpertData && selectedExpertData.lastLocation(value, true)) {
      const [cp, city] = selectedExpertData.lastLocation(valueAfterTime, true).split('\n')
      return (
        <span className="max-height-slot text-truncate m-0 p-0">
          {cp}
          <br />
          {city}
        </span>
      )
    }

    return <span className="invisible-border">-</span>
  }

  let available = true
  const isInMissionDuration = withinMissionDuration(value)

  if (selectedExpert === '' && selectedTimeSlot === null) available = checkAllAvailability(value)
  else if (selectedTimeSlot !== null && selectedExpert === '')
    available = checkAllAvailability(value)
  else available = checkAvailability(value, selectedExpert)

  if (available) {
    return (
      <div
        className={classNames(
          'clickable invisible-border',
          {
            'selected-time-slot': isEqual(value, selectedTimeSlot),
          },
          { 'mission-duration': isInMissionDuration },
        )}
        onClick={() => select('selectedTimeSlot', value)}
        data-cy={`day-${getISODay(value)}-${slot.replace(':', '')}`}
      >
        {slot}
      </div>
    )
  }

  if (selectedExpertData && checkAvailability(valueAfterTime, selectedExpert)) {
    const [cp, city] = selectedExpertData.lastLocation(valueAfterTime, true).split('\n')

    return (
      <span className="invisible-border max-height-slot text-truncate m-0 p-0">
        {cp}
        <br />
        {city}
      </span>
    )
  }

  return (
    <span className={classNames('invisible-border', { 'mission-duration': isInMissionDuration })}>
      -
    </span>
  )
}

export default inject('PlannerStore')(observer(TimeSlot))
