import React, { useState, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import RecapRowLayout from 'pages/Mission/pages/Calculation/DetailPackage/RecapRowLayout'
import Toggle from 'components/Toggle/Toggle'
import { euro } from 'utils/currency'
import {
  LabelObsolescence,
  DisabledLabelObsolescence,
} from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'
import {
  LabelVAT,
  DisabledLabelVAT,
} from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'

//to handle click outside
let useClickOutside = handler => {
  let domNodeFurniture = useRef()

  useEffect(() => {
    let maybeHandler = event => {
      if (!domNodeFurniture.current.contains(event.target)) {
        handler()
      }
    }

    document.addEventListener('mousedown', maybeHandler)

    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  })

  return domNodeFurniture
}

const FurnitureRecap = ({ furniture, isClosed }) => {
  const [showRecap, setShowRecap] = useState(false)

  //to handle click outside
  let domNodeFurniture = useClickOutside(() => {
    setShowRecap(false)
  })

  const { t } = useTranslation()
  const setCompensation = e => {
    if (e.target.value === true) {
      furniture.setProperty('correction', 0)
      furniture.setProperty('compensation', 'used')
    } else {
      furniture.setProperty('modalityAcquisition', 'new')
    }
  }

  return (
    <div ref={domNodeFurniture} className="covering-recap-wrapper">
      <button
        className={classNames('btn rounded-circle mr-1', {
          'btn-primary': showRecap,
          'btn-outline-primary': !showRecap,
        })}
        onClick={() => setShowRecap(!showRecap)}
      >
        <i
          className={classNames('fa', {
            'fa-search-minus': showRecap,
            'fa-search-plus': !showRecap,
          })}
        />
      </button>

      {showRecap && (
        <div className="covering-recap border p-3">
          <RecapRowLayout
            labelComponent={
              <div className="d-flex align-items-center">
                <span className="mr-2"> {t('mission.calculation.furniture.secondHand')} :</span>
                <Toggle
                  name="compensation"
                  onChange={setCompensation}
                  checked={furniture.compensation === 'used'}
                  disabled={isClosed}
                />
              </div>
            }
            priceComponent={
              <div className="row">
                <div className="col-6 text-right"></div>
                <div className="col-6 text-right"></div>
              </div>
            }
            packageLayout={false}
          />

          <hr />

          <RecapRowLayout
            labelComponent={
              <span className="recap-label mr-3">
                {t('mission.calculation.furniture.totalVrn')}
              </span>
            }
            priceComponent={euro(furniture.totalPriceVAT)}
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={
              isClosed ? (
                <DisabledLabelVAT packageData={furniture} />
              ) : (
                <LabelVAT
                  packageData={furniture}
                  onChange={e => furniture.setProperty(e.target.name, e.target.value)}
                />
              )
            }
            priceComponent={euro(furniture.totalVAT)}
            withoutUnit
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={
              isClosed ? (
                <DisabledLabelObsolescence packageData={furniture} />
              ) : (
                <LabelObsolescence
                  packageData={furniture}
                  onChange={e => furniture.setProperty('correction', e.target.value)}
                />
              )
            }
            priceComponent={<span>{euro(furniture.totalObsolescence)}</span>}
            withoutUnit
            packageLayout={false}
          />

          <RecapRowLayout
            labelComponent={
              <span className="recap-label">{t('mission.calculation.furniture.totalVd')}</span>
            }
            priceComponent={euro(furniture.finalPrice)}
            packageLayout={false}
          />
        </div>
      )}
    </div>
  )
}

export default inject('CartStore')(observer(FurnitureRecap))
