import { observable, computed, action, decorate } from 'mobx'
import { groupBy, forEachObjIndexed } from 'ramda'
import { isNil } from 'ramda'

import Furniture from 'stores/Mission/domain/Furniture'

class FurnitureCatalog extends Furniture {
  type = 'catalog'
  parentProduct = null
  productId = null
  image = null
  releaseYear = null
  releasePrice = 0
  features = []
  group = null
  groupId = null
  storeProducts = []
  productType = ''
  purchasePrice = null

  constructor(data = null) {
    super(data)

    if (!data) return

    this.productId = data.productId
    this.image = data.image
    this.releaseYear = data.releaseYear
    this.releasePrice = parseInt(data.releasePrice, 10) || 0
    this.features = data.features
    this.group = data.group
    this.groupId = data.groupId
    this.setParentProduct(data.parentProduct)
    this.storeProducts = isNil(data.storeProducts) ? [] : data.storeProducts
    this.productType = data.productType
    this.purchasePrice = data.purchasePrice
    this.originalData = this.asJson
  }

  toJsonHelper() {
    const specific = {
      parentProduct: this.parentProduct ? this.parentProduct.asJson : null,
      type: this.type,
      productId: this.productId,
      image: this.image,
      releaseYear: this.releaseYear,
      releasePrice: this.releasePrice,
      features: this.features,
      group: this.group,
      groupId: this.groupId,
      storeProducts: this.storeProducts,
      productType: this.productType,
      purchasePrice: this.purchasePrice,
    }

    return { ...super.toJsonHelper(), ...specific }
  }

  setParentProduct(parentProduct) {
    if (!parentProduct) return
    if (!parentProduct instanceof FurnitureCatalog) this.parentProduct = parentProduct
    else this.parentProduct = new FurnitureCatalog(parentProduct)
  }

  get featuresByGroup() {
    const arr = []
    forEachObjIndexed(
      (features, group) => {
        arr.push({ name: group, features })
      },
      groupBy(feature => {
        return feature.group
      })(this.features),
    )

    return arr
  }
}

const DecoratedFurnitureCatalog = decorate(FurnitureCatalog, {
  parentProduct: observable,
  productId: observable,
  image: observable,
  releaseYear: observable,
  releasePrice: observable,
  features: observable,
  group: observable,
  groupId: observable,
  storeProducts: observable,
  productType: observable,
  purchasePrice: observable,

  setParentProduct: action,

  featuresByGroup: computed,
})

export default DecoratedFurnitureCatalog
