import React from 'react'
import classNames from 'classnames'

const Li = ({ faIcon, srOnly, onClick, disabled, children, active }) => {
  return (
    <li className={classNames('page-item clickable', { disabled, active, invisible: disabled })}>
      <div className="page-link" onClick={onClick}>
        {faIcon && (
          <span>
            <i className={`fa fa-${faIcon}`} />
          </span>
        )}
        {srOnly && <span className="sr-only">{srOnly}</span>}
        {children}
      </div>
    </li>
  )
}

export default Li
