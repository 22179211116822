import React, { useEffect, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import PrejudiceTable from './PrejudiceTable'
import RecapTable from './RecapPrejudiceTable'
import AnnexTable from './AnnexPrejudiceTable'

const Pejudice = ({
  PrejudiceStore: { payloads, save, resetData, loading: LoadingPrej, changed },
  CartStore: { loadingCart },
}) => {
  const { t } = useTranslation()
  useEffect(() => resetData, [resetData])

  if (loadingCart || LoadingPrej) return <Loader />
  if (!payloads.length) return null

  return (
    <div className="prejudice">
      <Prompt when={changed} message={t('common.quitWithoutSave')} />

      <CardLayout>
        <div className="d-block d-md-none p-3 text-center">
          {t('mission.conclusion.tableResponsive')}
        </div>

        <div className="d-none d-md-block">
          {payloads.map(payload => (
            <div key={payload.id} className="mb-3">
              <RecapTable {...{ payload }} />
              {payload.isExpanded && (
                <Fragment>
                  <PrejudiceTable {...{ payload }} />
                  <AnnexTable {...{ payload }} />
                </Fragment>
              )}
            </div>
          ))}

          <div className="mt-3 d-flex justify-content-end">
            <button
              className="btn btn-primary px-2"
              disabled={loadingCart || !changed}
              onClick={save}
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      </CardLayout>
    </div>
  )
}

export default inject('PrejudiceStore', 'CartStore')(observer(Pejudice))
