import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InputMask from 'react-input-mask'

import InputErrors from 'components/Forms/InputErrors'

class CustomerId extends React.Component {
  render() {
    const { name, value, placeholder, required, onChange, errors } = this.props
    return (
      <React.Fragment>
        <InputMask
          className={classNames(
            'form-control',
            { error: errors && !!errors.length, required },
            { 'bg-required': required && !value },
          )}
          name={name}
          mask="** ****** * **"
          maskChar=""
          required={required}
          alwaysShowMask={false}
          onChange={onChange}
          value={value || ''}
          placeholder={placeholder}
        />
        <InputErrors errors={errors} />
      </React.Fragment>
    )
  }
}

CustomerId.defaultProps = {
  required: true,
}

CustomerId.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default CustomerId
