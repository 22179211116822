import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import CartStore from 'stores/Mission/domain/CartStore'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import { euro } from 'utils/currency'

const FurnitureItem = ({
  t,
  furniture,
  furniture: { id, toString, finalPrice, location },
  match: {
    params: { id: missionId, type: urlType },
  },
  history,
  index = '',
}) => {
  const goTo = () => {
    AddFurnitureCtrl.setFurniture(furniture, 'edit')
    const type = location.charAt(0).toUpperCase() + location.slice(1)
    history.push(`/${urlType}/${missionId}/calculation/add${type}Furniture`)
  }
  const removeFurnitureFromCart = () => CartStore.removeFurniture(id)

  return (
    <div className="row no-gutters align-items-center mt-2 mb-2">
      <div className="package-title col-12 col-lg-7 pl-4 text-truncate" onClick={goTo}>
        {index && `${index} - `} {toString}
      </div>
      <div className="col-6 col-sm-5 col-lg-2 pl-4 pl-lg-0 text-left text-lg-right text-primary">
        <button className="btn btn-outline-primary">
          <i className="fa fa-book d-inline-block d-lg-none" aria-hidden="true" />{' '}
          {euro(finalPrice)}
        </button>
      </div>
      <div className="col-6 col-sm-5 col-lg-2" />
      <div className="col-sm-2 col-lg-1 d-none d-sm-inline-block text-right">
        <ButtonWithConfirmation
          className="btn btn-outline-primary rounded-circle"
          icon
          iconClassName="fa fa-trash"
          onClick={removeFurnitureFromCart}
          confirmText={t('mission.calculation.cart.confirmDelete')}
        />
      </div>
    </div>
  )
}

export default compose(
  withRouter,
  withTranslation(),
  observer,
)(FurnitureItem)
