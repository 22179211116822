import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import DashboardCtrl from 'stores/Common/view/DashboardCtrl'
import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import Card from './Card'
import Loader from 'components/Loader/Loader'

class ListCards extends React.Component {
  render() {
    const { t } = this.props
    const { missionsView, missionsByPage, headers, loading } = DashboardCtrl
    const {
      currentFilter: { missionStatus },
    } = DashboardFilterStore
    const show = missionsView === 'listCard' ? 'd-block' : 'd-none'

    if (loading || missionsByPage.length === 0)
      return (
        <div className={classNames('card-mission text-center mt-2 p-5', show)}>
          {loading ? <Loader /> : <span>{t(`dashboard.filter.noResult${missionStatus}`)}</span>}
        </div>
      )

    return (
      <div className={classNames('dashboard-list-card', show)}>
        {missionsByPage.map(mission => (
          <Card key={`card-${mission.wan}`} headers={headers} data={mission} />
        ))}
      </div>
    )
  }
}

export default withTranslation()(observer(ListCards))
