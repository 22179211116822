import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import CardLayout from 'components/CardLayout/CardLayout'
import CoverageExecutionTimeStore from 'stores/Parameters/Coverage/CoverageExecutionTimeStore'
import Loader from 'components/Loader/Loader'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'

class CoverageExecutionTime extends React.Component {
  componentDidMount() {
    CoverageExecutionTimeStore.loadData()
  }

  onDurationChange(coverageExecutionTime, e) {
    coverageExecutionTime.setTime(e.target.value)
  }

  onClick() {
    CoverageExecutionTimeStore.save()
  }

  render() {
    const { t } = this.props
    const { loading, coverageExecutionTimes, saving } = CoverageExecutionTimeStore
    return (
      <div className="coverage-executionTime-page">
        <div className="row no-gutters">
          <h5 className="mb-4">{t('parameters.coverageExecutionTime.title')}</h5>
        </div>

        <CardLayout className="coverage-executionTime">
          {loading ? (
            <Loader />
          ) : (
            <form>
              <div className="form-row">
                {coverageExecutionTimes.map(coverageExecutionTime => (
                  <div className="col-md-12 col-lg-6" key={coverageExecutionTime.id}>
                    <div className="form-group row">
                      <label
                        className="col-md-8 col-sm-12 col-form-label"
                        htmlFor={coverageExecutionTime.id}
                      >
                        {coverageExecutionTime.name}
                      </label>
                      <div className="col-md-4 col-sm-12">
                        <div className="input-group">
                          <Input
                            type="text"
                            id={coverageExecutionTime.id}
                            className="form-control"
                            value={coverageExecutionTime.time ? coverageExecutionTime.time : ''}
                            onChange={this.onDurationChange.bind(this, coverageExecutionTime)}
                            max={1440}
                            autoComplete="off"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">mn</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end">
                <Button className="btn btn-primary" loading={saving} onClick={this.onClick}>
                  {t('common.submit')}
                </Button>
              </div>
            </form>
          )}
        </CardLayout>
      </div>
    )
  }
}

export default withTranslation()(observer(CoverageExecutionTime))
