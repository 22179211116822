import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ImageFallback = props => {
  const [error, setError] = useState(false)
  const onError = () => {
    setError(true)
  }

  if (error) {
    return (
      <div className="text-center p-4">
        <i className="fa fa-image" style={{ fontSize: '2em' }} />
      </div>
    )
  }

  return <img {...props} onError={onError} alt={props.alt} />
}

ImageFallback.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default ImageFallback
