import { observable, action, toJS, decorate, runInAction } from 'mobx'

import MissionStore from 'stores/Mission/domain/MissionStore'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import UiCtrl from 'stores/Common/view/UiCtrl'
import { SupportingDocument } from 'stores/Common/domain/SupportingDocumentStore'
import { createSupportingDocuments } from 'services/supportingDocuments'
import SupportingDocumentsCtrl, {
  SupportingDocTypes,
  SupportingDocHorsDarvaFileSize,
} from 'stores/Common/view/SupportingDocumentsCtrl'
import { compute } from 'utils/upload'
import { isRequiredValid, isDateBeforeToday } from 'utils/supportingDocumentsForm'
import CommonStore from '../domain/CommonStore'
import i18next from 'i18next'

class UploadCtrl {
  modalDisplay = false
  acceptedFiles = []
  successUploaded = []
  uploadedFails = []
  rejectedFiles = []
  loading = false
  defaultType = ''
  displayUploadModal = true
  invoiceModalDisplay = false
  invoiceAcceptedFiles = []
  invoiceRejectedFiles = []
  attachmentAcceptedFiles = []
  attachmentRejectedFiles = []
  invoiceError = {}

  context = 'supportingDocument'

  addSupportingDocument = () => {}

  setModalDisplay(open) {
    this.modalDisplay = open
    if (!this.modalDisplay && !!this.acceptedFiles.length) this.acceptedFiles = []
    if (!this.modalDisplay && !!this.successUploaded.length) {
      this.successUploaded = []
      SupportingDocumentsCtrl.fetchSupportingDocuments(MissionStore.id)
    }
    if (!this.modalDisplay && !!this.rejectedFiles.length) this.rejectedFiles = []
  }

  setInvoiceModalDisplay(open) {
    this.invoiceModalDisplay = open
    if (!open) {
      this.invoiceError = {}
      this.defaultType = ''
      this.invoiceAcceptedFiles = []
    }
  }

  setDisplayUploadModal(open) {
    this.displayUploadModal = open
  }

  setInvoiceAcceptedFiles(acceptedFiles) {
    if (!!acceptedFiles.length) {
      this.invoiceAcceptedFiles = acceptedFiles.reduce((arr, current, index) => {
        arr.push(
          new SupportingDocument({
            name: current.name,
            type: { key: this.defaultType },
            file: current,
            progressBar: 0,
            extraData: {
              [SupportingDocTypes.INVOICE]: {
                number: '',
                date: null,
              },
            },
          }),
        )
        return arr
      }, [])
      this.setInvoiceModalDisplay(true)
    }
  }

  setAttachmentAcceptedFiles(acceptedFiles, wan) {
    if (!!acceptedFiles.length) {
      this.acceptedFiles = acceptedFiles.reduce((arr, current, index) => {
        arr.push(
          new SupportingDocument({
            name: current.name,
            type: { key: this.defaultType },
            file: current,
            progressBar: 0,
          }),
        )
        return arr
      }, [])
      this.createAttachmentDocument(wan)
    }
  }

  setInvoiceRejectedFiles(rejectedFiles) {
    this.invoiceRejectedFiles = rejectedFiles
    if (!!rejectedFiles.length) {
      rejectedFiles.forEach(rejectedFile => {
        // dirty translations to fix with i18next
        const msg =
          UiCtrl.language !== 'fr-FR'
            ? `${rejectedFile.name} can't be uploaded, please check the file extension`
            : `${rejectedFile.name} ne peut être téléchargé, vérfiez l'extension du fichier`
        AlertCtrl.alert('danger', msg)
      })
    }
  }

  invoiceUploadFormValidation(wan) {
    this.invoiceError = {}
    Object.keys(toJS(this.invoiceAcceptedFiles[0].extraData[SupportingDocTypes.INVOICE])).forEach(
      field => {
        if (
          field !== 'date' &&
          !isRequiredValid(
            this.invoiceAcceptedFiles[0].extraData[SupportingDocTypes.INVOICE][field],
          )
        ) {
          this.setInvoiceError(field, 'form.requiredField')
        }
      },
    )
    this.invoiceDateValidation()

    // case no errors
    if (Object.keys(this.invoiceError).length === 0) {
      this.createInvoiceDocument(wan, toJS(this.invoiceAcceptedFiles[0].extraData))
    }
  }

  setInvoiceError(field, errorMsg) {
    this.invoiceError = this.invoiceError[field]
      ? {
          ...this.invoiceError,
          ...(this.invoiceError[field] = [...this.invoiceError[field], errorMsg]),
        }
      : { ...this.invoiceError, [field]: [errorMsg] }
  }

  invoiceDateValidation() {
    if (this.invoiceAcceptedFiles[0].extraData[SupportingDocTypes.INVOICE].date === null) {
      this.setInvoiceError('date', 'form.requiredField')
    } else if (
      !isDateBeforeToday(this.invoiceAcceptedFiles[0].extraData[SupportingDocTypes.INVOICE].date)
    ) {
      this.setInvoiceError('date', 'form.invoiceDate')
    }
  }

  setAcceptedFiles(acceptedFiles) {
    if (!!acceptedFiles.length) {
      this.acceptedFiles = acceptedFiles.reduce((arr, current, index) => {
        if (current.size >= SupportingDocHorsDarvaFileSize.SIZE && CommonStore.isCoporal) {
          AlertCtrl.alert('danger', current.name + ' ' + i18next.t('common.fileSizeMsg'))
        } else {
          arr.push(
            new SupportingDocument({
              name: current.name,
              type: { key: this.defaultType },
              file: current,
              progressBar: 0,
            }),
          )
        }
        if (arr.length === 0) {
          this.setDisplayUploadModal(false)
        } else {
          this.setDisplayUploadModal(true)
        }
        return arr
      }, [])
      if (!this.displayUploadModal) {
        this.setModalDisplay(false)
      } else {
        this.setModalDisplay(true)
      }
    }
  }

  setRejectedFiles(rejectedFiles) {
    this.rejectedFiles = rejectedFiles
    if (!!rejectedFiles.length) {
      rejectedFiles.forEach(rejectedFile => {
        // dirty translations to fix with i18next
        const msg =
          UiCtrl.language !== 'fr-FR'
            ? `${rejectedFile.name} can't be uploaded, please check the file extension`
            : `${rejectedFile.name} ne peut être téléchargé, vérfiez l'extension du fichier`
        AlertCtrl.alert('danger', msg)
      })
    }
  }

  createInvoiceDocument(wan) {
    this.loading = true
    compute(toJS(this.invoiceAcceptedFiles)).then(result => {
      this.processDocCreation(result, wan).then(
        action(() => {
          if (this.uploadedFails.length === 0) {
            SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
            this.setInvoiceModalDisplay(false)
            SupportingDocumentsCtrl.setDropZoneFiltered(false)
          } else {
            this.invoiceAcceptedFiles = [...this.uploadedFails]
            this.uploadedFails = []
          }
          this.loading = false
        }),
      )
    })
  }

  createAttachmentDocument(wan) {
    this.loading = true
    compute(toJS(this.acceptedFiles)).then(result => {
      this.processDocCreation(result, wan).then(
        action(() => {
          if (this.uploadedFails.length === 0) {
            SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
            SupportingDocumentsCtrl.setDropZoneFiltered(false)
          } else {
            this.acceptedFiles = [...this.uploadedFails]
            this.uploadedFails = []
          }
          this.loading = false
        }),
      )
    })
  }

  processDocCreation = async (docsToSend, wan) => {
    let index = 0
    // eslint-disable-next-line
    for (const docToSend of docsToSend) {
      await createSupportingDocuments({
        url: `${SupportingDocumentsCtrl.ctrl.postUrl}/${wan}/documents`,
        name: docToSend.name,
        type: docToSend.type,
        file: docToSend.file,
        index,
        extraData: docToSend.extraData,
      })
        .then(
          action(res => {
            AlertCtrl.alert('success', 'mission.upload.createSuccess')
            if (this.context === 'supportingDocumentPackage') {
              this.addSupportingDocument(res.data.id)
            }

            if (this.context === 'supportingDocumentWorkRequest') {
              this.addSupportingDocument(res.data.id)
            }

            if (this.context === 'supportingDocumentWorkRequestAttachment') {
              this.addSupportingDocument(res.data.id)
            }
          }),
        )
        .catch(
          action(err => {
            console.error(err)
            if (err.status === 422) {
              try {
                AlertCtrl.alert('danger', err.data.errors[0].detail)
              } catch (e) {
                AlertCtrl.alert('danger', 'mission.upload.createFailure')
              }
            } else {
              AlertCtrl.alert('danger', 'mission.upload.createFailure')
            }

            docToSend.progressBar = 0
            this.uploadedFails.push(docToSend)
          }),
        )
      index++
    }
  }

  async createSupportingDocuments(wan) {
    this.loading = true
    try {
      const res = await compute(toJS(this.acceptedFiles))
      await this.processDocCreation(res, wan)
      runInAction(() => {
        if (this.uploadedFails.length === 0) {
          SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
          this.setModalDisplay(false)
          SupportingDocumentsCtrl.setDropZoneFiltered(false)
        } else {
          this.acceptedFiles = [...this.uploadedFails]
          this.uploadedFails = []
        }
      })
    } catch (error) {
      console.warn(error)
      AlertCtrl.alert('danger', error.message)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setProgressBar(index, percentageCompleted, invoiceContext) {
    if (invoiceContext) {
      const progress = [...this.invoiceAcceptedFiles]
      progress[index]['progressBar'] = percentageCompleted
      this.invoiceAcceptedFiles = progress
    } else {
      const progress = [...this.acceptedFiles]
      progress[index]['progressBar'] = percentageCompleted
      this.acceptedFiles = progress
    }
  }

  setDefaultType(category) {
    this.defaultType = category
  }

  setProperty(key, value) {
    this[key] = value
  }
}

const DecoratedUploadCtrl = decorate(UploadCtrl, {
  modalDisplay: observable,
  acceptedFiles: observable,
  successUploaded: observable,
  uploadedFails: observable,
  rejectedFiles: observable,
  loading: observable,
  defaultType: observable,
  invoiceModalDisplay: observable,
  invoiceAcceptedFiles: observable,
  invoiceRejectedFiles: observable,
  attachmentAcceptedFiles: observable,
  attachmentRejectedFiles: observable,
  invoiceError: observable,

  setModalDisplay: action,
  setInvoiceModalDisplay: action,
  setDisplayUploadModal: action,
  setInvoiceAcceptedFiles: action,
  setInvoiceRejectedFiles: action,
  setAttachmentAcceptedFiles: action,
  setattachmentRejectedFiles: action,
  invoiceUploadFormValidation: action,
  setInvoiceError: action,
  invoiceDateValidation: action,
  setAcceptedFiles: action,
  setRejectedFiles: action,
  createInvoiceDocument: action,
  createAttachmentDocument: action,
  processDocCreation: action,
  createSupportingDocuments: action,
  setProgressBar: action,
  setDefaultType: action,
  setProperty: action,
})

export default new DecoratedUploadCtrl()
