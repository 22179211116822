import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'

import './report.css'
import CardLayout from 'components/CardLayout/CardLayout'
import InfoModal from 'components/InfoModal'
import Loader from 'components/Loader/Loader'
import Divider from './Divider'
import ReportActions from './ReportActions'
import PostReportActions from './PostReportActions'
import PdfReader from './PdfReader'
import EmailModal from './EmailModal'
import ImageModal from './ImageModal'
import ReportModal from './ReportModal'
import ValidatorModal from './ValidatorModal'
import { isNil } from 'ramda'

const Report = ({
  MissionStore: { isMissionClosed, id, isMissionCorporel, isHorsDarva },
  ReportCtrl: {
    reportValidator,
    sendToDarva,
    saveReportToSD,
    reportContent,
    sendReportPending,
    handleKeyDown,
    setProperty,
    activateAntiSpam,
    resetReportType,
    getReportType,
    loading,
    generateReport,
    currentReportObj,
    currentReportType,
    setRectifReportReason,
  },
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [darvaRes, setDarvaRes] = useState({
    messages: '',
    success: true,
    openModal: false,
    header: t('mission.report.darvaSent.headerModal'),
  })

  useEffect(() => {
    getReportType(id)
    return () => {
      resetReportType()
    }
  }, [getReportType, resetReportType, id])

  useEffect(() => {
    if (!isNil(currentReportObj) && !isMissionCorporel) {
      generateReport(id, isMissionClosed)
    }
  }, [id, isMissionClosed, generateReport, currentReportObj, isMissionCorporel])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      setProperty('showValidatorModal', false)
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  const getSuccessMessagePath = () => {
    const sucessMessageMapping = {
      WR: 'successWorkRequest',
      WR_REC: 'successWorkRequestRec',
      WR_COM: 'successWorkRequestCom',
    }

    return currentReportType in sucessMessageMapping
      ? sucessMessageMapping[currentReportType]
      : 'success'
  }

  const sendDarva = async () => {
    const isValid = await reportValidator(id)

    if (isValid === 204) {
      const { isSent, messages = [] } = await sendToDarva(id)
      console.log('isHorsDarva  ', isHorsDarva)
      let headerMessage = isHorsDarva
        ? t(`mission.report.darvaSent.headerModalOutOfDarva`)
        : t(`mission.report.darvaSent.${isSent ? 'headerModal' : 'failure'}`)
      setDarvaRes({
        messages: isSent ? [t(`mission.report.darvaSent.${getSuccessMessagePath()}`)] : messages,
        success: isSent,
        openModal: true,
        header: headerMessage,
      })
    }
    activateAntiSpam()
  }

  const sendDarvaWithReason = async ({ reason, submittableReportType }) => {
    const isValid = await reportValidator(id)

    if (isValid === 204) {
      const { isSent, messages = [] } = await setRectifReportReason(id, {
        reason,
        submittableReportType,
      })
      console.log('isHorsDarva  ', isHorsDarva)
      let headerMessage = isHorsDarva
        ? t(`mission.report.rectifSent.headerModalOutOfDarva`)
        : t(`mission.report.rectifSent.${isSent ? 'headerModal' : 'failure'}`)
      setDarvaRes({
        messages: isSent ? [t(`mission.report.rectifSent.${getSuccessMessagePath()}`)] : messages,
        success: isSent,
        openModal: true,
        header: headerMessage,
      })
      setShowModal(false)
      activateAntiSpam()
    }
  }

  if (loading) return <Loader />
  return (
    <CardLayout className="generate-report">
      <ReportModal {...{ showModal, setShowModal, sendDarvaWithReason }} />
      <InfoModal {...darvaRes} onClose={() => setDarvaRes({ openModal: false })} />
      <ValidatorModal />
      {!isMissionCorporel && <EmailModal />}
      {!isMissionCorporel && <ImageModal />}
      {!isMissionCorporel && <ReportActions setShowModal={setShowModal} sendDarva={sendDarva} />}
      {!isMissionCorporel ? (
        !sendReportPending ? (
          <div className="report-content-wrapper">
            {isMissionClosed && <PdfReader />}
            {!!reportContent &&
              !isMissionClosed &&
              ReactHtmlParser(reportContent, { transform: Divider })}
            {!reportContent && <Loader />}
          </div>
        ) : (
          <Loader
            loadingMsg={
              isHorsDarva
                ? t('mission.report.loadingSentOutOfDarva')
                : t('mission.report.loadingSent')
            }
          />
        )
      ) : (
        ''
      )}
      {/* SEND REPORT BUTTON*/}
      <PostReportActions
        setShowModal={setShowModal}
        sendDarva={sendDarva}
        saveReportToSD={() => saveReportToSD(id)}
      />
    </CardLayout>
  )
}

export default inject('MissionStore', 'ReportCtrl', 'CartStore')(observer(Report))
