import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { runInAction } from 'mobx'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import './SearchFurniture.css'
import CardLayout from 'components/CardLayout/CardLayout'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import ListSearch from './ListSearch'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import RadioTabs from './RadioTabs'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import { Redirect } from 'react-router-dom'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import Furniture from 'stores/Mission/domain/Furniture'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const SearchFurniture = ({
  t,
  match: {
    params: { id, type },
    path,
  },
  UserStore: { customer },
  MissionStore: { isIRSI, selectedIRSICart },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const endpoint = path.split('/').pop()
  const { search, roomsForSelect, selectedRoom } = SearchFurnitureCtrl
  const searchOutdoorCondition = endpoint === 'searchOutdoorFurniture'
  const location = searchOutdoorCondition ? 'outdoor' : 'regular'

  const searchFurnitures = () => SearchFurnitureCtrl.searchFurniture(id, location)
  const onChange = e => SearchFurnitureCtrl.setProperty(e.target.name, e.target.value)
  const onChangeRoom = e => {
    const room = SearchFurnitureCtrl.rooms.find(room => room.cde === e.target.value)
    if (room) SearchFurnitureCtrl.setProperty('selectedRoom', room)
  }
  const onEnterKey = e => {
    if (e.keyCode === 13 && SearchFurnitureCtrl.search.length > 0)
      SearchFurnitureCtrl.searchFurniture(id, location)
  }

  if (!customer.hasContract('RP')) {
    AddFurnitureCtrl.setFurniture(
      runInAction(
        () => new Furniture({ location, involvedParty: isIRSI ? selectedIRSICart : null }),
      ),
      'new',
    )
    return (
      <Redirect
        to={`/${type}/${id}/calculation/add${location.charAt(0).toUpperCase() +
          location.slice(1)}Furniture`}
      />
    )
  }

  return (
    <CardLayout className="add-furniture">
      <div className="search-fields container pl-0 pr-0 mb-3">
        <div className="row no-gutters mb-3 d-flex justify-content-between">
          <div className="col-12">
            <Breadcrumb />
          </div>
        </div>

        <div className="row no-gutters mb-3 align-items-center">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <RadioTabs />
          </div>
        </div>

        {searchOutdoorCondition && (
          <div className="row no-gutters mb-3">
            <div className="col-3">
              <span className="tab-filter-title">
                {t('mission.calculation.searchPackage.room')}
              </span>
              <ResponsiveSelect
                name="selectedRoom"
                value={selectedRoom.label}
                options={roomsForSelect}
                onChange={onChangeRoom}
              />
            </div>
            <div className="col-9" />
          </div>
        )}

        <div className="row no-gutters">
          <div className="col-12">
            <div className="input-group" onKeyDown={onEnterKey}>
              <Input
                className="form-control"
                name="search"
                value={search}
                placeholder={`${t('mission.calculation.searchFurniture.search')} ${t(
                  `mission.calculation.furniture.${location}`,
                )}`}
                onChange={onChange}
                autoComplete="off"
              />
              <div className="input-group-append">
                <Button
                  className="btn btn-primary"
                  disabled={search.length === 0}
                  onClick={searchFurnitures}
                >
                  <i className="fa fa-search" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ListSearch furnitureLocation={location} />
    </CardLayout>
  )
}

export default compose(
  inject('UserStore', 'MissionStore'),
  withTranslation(),
  observer,
)(SearchFurniture)
