import React, { useEffect } from 'react'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import MissionHeaderCtrl from 'stores/Mission/view/MissionHeaderCtrl'
import StatusSteps from 'components/StatusSteps/StatusSteps'
import Button from 'components/Button/Button'

const Header = ({
  t,
  MissionStore: { loading, claim, isWorkCFA, status, id },
  RemoteAssessmentCtrl: { setProperty },
  UserStore: { customer },
}) => {
  const {
    showGallery,
    handleKeyDown,
    showExtDarvaBtn,
    checkExtranetDarva,
    loadingExtDarvaLink,
  } = MissionHeaderCtrl

  const openModalInfo = () => {
    MissionHeaderCtrl.setProperty('headerInfo', !MissionHeaderCtrl.headerInfo)
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  if (loading) return null

  return (
    <div className="container px-0 update-mission">
      <div className="row ml-0 mr-0">
        <div className="col-12 col-md-12 col-lg-12 px-0 d-flex align-items-center mb-3">
          <div className="col-7 px-0 mb-0 d-flex align-items-center">
            <h5 className="d-inline px-0 mb-0 ml-2 ml-lg-0 mr-3">
              {t('mission.titleSinister')}
              &nbsp;
              <span>
                {claim.insurerClaimId}
                <i
                  className={classNames('fa fa-file-text-o ml-2 clickable', {
                    'text-primary': MissionHeaderCtrl.headerInfo,
                  })}
                  onClick={openModalInfo}
                />

                <i
                  className={classNames('fa fa-photo ml-2 clickable', {
                    'text-primary': showGallery,
                  })}
                  onClick={() => MissionHeaderCtrl.setProperty('showGallery', !showGallery)}
                />
                {customer.hasContract('CAM') && (
                  <Button
                    className="btn btn-primary ml-2"
                    onClick={() => {
                      setProperty('showRemoteAssessmentForm', true)
                    }}
                  >
                    {t('remoteAssessment.openRemoteAssessment')}
                  </Button>
                )}

                {showExtDarvaBtn && (
                  <Button
                    className={classNames('btn btn-danger ml-2')}
                    onClick={() => checkExtranetDarva(id)}
                    loading={loadingExtDarvaLink}
                  >
                    {t('mission.report.checkExtranetDarva')}
                  </Button>
                )}
              </span>
            </h5>
          </div>

          <div className="d-none d-lg-block col-5 px-0">
            {!isWorkCFA ? <StatusSteps current={status.key} /> : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  inject('MissionStore', 'RemoteAssessmentCtrl', 'UserStore'),
  withTranslation(),
  observer,
)(Header)
