import request from 'services/httpRequest'

export const getStickyNote = async wan => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/sticky-note/`,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const createStickyNote = async (wan, formData) => {
  const data = {
    data: {
      type: 'StickyNote',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/sticky-note/create`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const updateStickyNote = async (wan, formData) => {
  const data = {
    data: {
      type: 'StickyNote',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'PUT',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/sticky-note/update`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const deleteStickyNote = async wan => {
  try {
    const res = await request({
      method: 'DELETE',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/sticky-note/delete`,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}
