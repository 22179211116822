import request from 'services/httpRequest'

export const fetchCoverages = () => {
  return request({
    method: 'GET',
    url: '/coverages/v1/',
    params: {
      'page[size]': 100,
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const postCoverageExecutionTime = async coverageExecutionTimes => {
  try {
    const res = await request({
      method: 'post',
      url: '/bre/v1/coverage/executionTime',
      data: {
        data: {
          type: 'breCustomerCoverageExecutionTimes',
          attributes: {
            breCustomerCoverageExecutionTimes: coverageExecutionTimes,
          },
        },
      },
    })

    return Promise.resolve(res)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const listCoverageExecutionTime = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/bre/v1/coverage/executionTime',
      params: {
        'page[size]': 100,
      },
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
