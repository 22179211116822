import React, { Fragment } from 'react'
import { inject } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import MissionButtons from 'pages/Mission/components/MissionButtons'
import SmartLink from 'components/SmartLink/SmartLink'
import GenerateReportLink from './GenerateReportLink'
import ChocVTAM from './ChocVTAM'
import VolDeContenu from './VolDeContenu'
import Temoignage from './Temoignage'
import ConfigurationDesLieux from './ConfigurationDesLieux'
import AutreMotif from './AutreMotif'
import CommunicationIncendie from './CommunicationIncendie'
import Corporel from './Corporel'

class MissionMobile extends React.Component {
  render() {
    const {
      menu,
      t,
      MissionStore: {
        isHorsDarva,
        chocVTAMMenu,
        communicationINCENDIEMenu,
        volDeCONTENUMenu,
        temoignageMenu,
        configurationDesLieuxMenu,
        autreMotifMenu,
        missionDivers,
        id = useParams(),
        missionCorporel,
      },
      MessagingStore: { notifications },
      UiCtrl: { toggleSideNav },
    } = this.props
    const onClick = () => toggleSideNav(false)

    const drawGroup = group => {
      let title = ''
      if ((group === 'Evaluation' || group === 'Report') && isHorsDarva) {
        menu[group] = []
      } else {
        title = (
          <h5 className="pt-3 nav-border-bottom" key={group}>
            {t('mission.sideNav.title' + group)}
          </h5>
        )
      }
      return title
    }

    const drawOption = (group, option) => {
      let translationPath = option === 'Messaging' ? 'messaging.title' : 'mission.sideNav.' + option
      let link = ''
      if (group === 'Evaluation' || group === 'Report') {
        if (!isHorsDarva) {
          group === 'Report'
            ? (link = <GenerateReportLink />)
            : (link = <SmartLink page={option}>{t('mission.sideNav.' + option)}</SmartLink>)
        }
      } else {
        link = (
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/mission/${id}/${option}`}
            key={option}
            onClick={onClick}
          >
            {t(translationPath)}
            {option === 'Messaging' && notifications > 0 ? (
              <span className="badge badge-danger ml-1" key={group}>
                {notifications}
              </span>
            ) : (
              ''
            )}
          </NavLink>
        )
      }
      return link
    }

    const drawReportEvaluation = () => {
      let link = (
        <Fragment>
          {missionDivers && chocVTAMMenu && <ChocVTAM type="M" />}
          {missionDivers && volDeCONTENUMenu && <VolDeContenu type="M" />}
          {missionDivers && temoignageMenu && <Temoignage type="M" />}
          {missionDivers && configurationDesLieuxMenu && <ConfigurationDesLieux type="M" />}
          {missionDivers && autreMotifMenu && <AutreMotif type="M" />}
          {missionDivers && communicationINCENDIEMenu && <CommunicationIncendie type="M" />}
          {missionCorporel && <Corporel type="M" />}
        </Fragment>
      )
      return link
    }

    return (
      <div className="side-nav">
        {Object.keys(menu).map((group, i) => [
          drawGroup(group),
          <Fragment key={i}>{menu[group].map(option => drawOption(group, option, i))}</Fragment>,
        ])}
        {isHorsDarva && drawReportEvaluation()}
        <div className="mt-4 justify-content-between">
          <MissionButtons />
        </div>
      </div>
    )
  }
}

export default compose(
  inject('MissionStore', 'MessagingStore', 'PrejudiceStore', 'UserStore', 'UiCtrl'),
  withTranslation(),
)(MissionMobile)
