import React from 'react'
import { observer } from 'mobx-react'

import Address from 'components/Address/Address'
import { concatAddress } from 'utils'

const AddressWrapper = ({ inputData }) => {
  const onChangeAddress = address => {
    const value = {
      ...address,
      geometry: { long: address.long, lat: address.lat },
      country: address.country === 'FR' ? 'France' : address.country,
    }
    inputData.setProperty('value', value)
  }

  return (
    <Address
      {...inputData}
      value={inputData.value || null}
      onSelected={onChangeAddress}
      country={inputData.countryIso}
      initialValue={inputData.value ? concatAddress(inputData.value) : ''}
    />
  )
}

export default observer(AddressWrapper)
