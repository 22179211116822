import { observable, computed, action, runInAction, decorate } from 'mobx'
import { reduce } from 'ramda'

import UiCtrl from 'stores/Common/view/UiCtrl'
import {
  fetchTypeOfVictims,
  fetchPartyInvolvedRoles,
  fetchInvolvedPartyResponsabilities,
} from 'services/partyInvolved'
import { fetchSupportingDocumentsTypes } from 'services/supportingDocuments'
import { fetchCoverages } from 'services/coverage'
import {
  getMotiveRetardOptions,
  getObjectRetardOptions,
  fetchMotifCancelType,
} from 'services/mission'
import { fetchInsurers } from 'services/insurer'
import { fetchExpertiseTypes } from 'services/expertiseTypes'
import { fetchMissionInsurerType } from 'services/dashboardFilters'
import { fetchClaimStatus } from 'services/claimStatus'
import { fetchTechnicityLevels } from 'services/userManagement'
import { fetchAppointmentType } from 'services/calendar'
import { SupportingDocTypes } from 'stores/Common/view/SupportingDocumentsCtrl'
import { furnituresCategories } from 'services/calculation'
import { fetchExperts } from 'services/planner'
import { fetchRiskTypes } from 'services/riskTypes'
import { fetchContractNatures } from 'services/contractNatures'
import { fetchInsuranceCompanies } from 'services/insuranceCompanies'
import UserStore from 'stores/Common/domain/UserStore'
import { sortByCaseInsensitive } from 'utils'
import { getDataLocal } from 'stores/localStorage'
import ClaimStore from '../../Claim/domain/ClaimStore'
import MissionStore from '../../Mission/domain/MissionStore'

const CFI = Object.freeze([
  'CIMR',
  'CIMQ',
  'CIMW',
  'CIMS',
  'CIAF',
  'CIAD',
  'CIRS',
  'CICC',
  'CIMO',
  'CICT',
])
const CFA = Object.freeze(['CAPP', 'CAAF', 'CAAD', 'CARS', 'CACC'])
const CFM = Object.freeze(['CMPD', 'CMDE', 'CMDD', 'CMIN', 'CMPE', 'CMCA', 'CMCC'])

export const HORS_DARVA_TAGS = Object.freeze([
  'MATMUT_DIVERS',
  'MATMUT_SUIVI_DIVERS',
  'MATMUT_CORPORAL',
  'MATMUT_SUIVI_CORPORAL',
])

export const HORS_DARVA_TAGS_DIVERS = Object.freeze(['MATMUT_DIVERS', 'MATMUT_SUIVI_DIVERS'])

export const HORS_DARVA_TAGS_CORPOREL = Object.freeze(['MATMUT_CORPORAL', 'MATMUT_SUIVI_CORPORAL'])

export const HORS_DARVA_CHIFFRAGE = Object.freeze([
  'T04', // Divers - choc vtam
  'T06', // Divers - autre motif
  'T02', // Divers - enquête sur les lieux
  'T03', // Divers - enquête auprès des autorités
  'T05', // Divers - spécifique ugs 65
  'T50', // Divers - mission ime
  'U', // Divers
])

export const HORS_DARVA_NO_CHIFFRAGE = Object.freeze([
  'R01', // Mission corporel - mission initiale
  'R02', // Mission corporel - provision directe
  'R03', // Mission corporel - transaction directe sans instruction
  'R04', // Mission corporel - transaction directe avec instructions
  'S01', // Suivi dossier corporel - suivi de dossier corporel
  'S02', // Suivi dossier corporel - provision
  'S03', // Suivi dossier corporel - transaction sans instruction
  'S04', // Suivi dossier corporel - transaction avec instructions
  'T01', // Divers - demande de témoignage
])

export const HORS_DARVA_CANCEL_REASON = Object.freeze([
  'CANCELED_INJURED_DEFICIENCY',
  'BAD_STATUS_OTHER',
  'BAD_STATUS_INSURED_REFUSED_EXPERTISE',
])

class CommonStore {
  partyInvolvedBusinessRoles = []
  partyInvolvedPersonalRoles = []
  partyInvolvedCoporalRoles = []
  partyInvolvedResponsabilities = []
  partyInvolvedTypeOfVictims = []

  supportingDocumentsTypes = {}
  claimStatus = []
  coverages = []
  insurers = []
  expertiseTypes = []
  missionTypes = []
  technicityLevels = []
  appointmentType = []
  experts = []
  relatedCostType = []
  furnitureCategories = []
  riskTypes = []
  insuranceCompanies = []

  motiveRetards = []
  objectRetards = []
  cancelMotives = []

  coveragePromise = null

  get claimCFI() {
    return this.claimStatus.filter(status => CFI.indexOf(status.key) > -1)
  }

  get claimCFA() {
    return this.claimStatus.filter(status => CFA.indexOf(status.key) > -1)
  }

  get claimCFM() {
    return this.claimStatus.filter(status => CFM.indexOf(status.key) > -1)
  }

  get isCoporal() {
    return MissionStore.isMissionCorporel || ClaimStore.isMissionCorporel
  }

  //added to get filter for party responsiblity
  get isCoporalType() {
    if (ClaimStore.horsDarvaTag) {
      return ClaimStore.horsDarvaTag
    }
    if (MissionStore.horsDarvaTag) {
      return MissionStore.horsDarvaTag
    }
    return null
  }
  get isHorsDarva() {
    return ClaimStore.isHorsDarva || MissionStore.isHorsDarva
  }

  get businessRolesForSelect() {
    return this.isCoporal
      ? this.partyInvolvedCoporalRoles.map(party => ({
          value: party.key,
          label: party.name,
        }))
      : this.partyInvolvedBusinessRoles.map(party => ({
          value: party.key,
          label: party.name,
        }))
  }

  get personalRolesForSelect() {
    return this.isCoporal
      ? this.partyInvolvedCoporalRoles.map(party => ({
          value: party.key,
          label: party.name,
        }))
      : this.partyInvolvedPersonalRoles.map(party => ({
          value: party.key,
          label: party.name,
        }))
  }

  get responsabilitiesForSelect() {
    return this.partyInvolvedResponsabilities.map(responsability => ({
      value: responsability.key,
      label: responsability.name,
    }))
  }

  get typeOfVictimeForSelect() {
    return this.partyInvolvedTypeOfVictims.map(typeOfVictim => ({
      value: typeOfVictim.key,
      label: typeOfVictim.name,
    }))
  }

  get supportingDocTypesForDocuments() {
    return Object.keys(this.supportingDocumentsTypes[SupportingDocTypes.DOCUMENT])
  }

  get coveragesForSelectInput() {
    return this.coverages.map(coverage => ({
      value: coverage.attributes.key,
      label: coverage.attributes.value,
    }))
  }

  get coveragesCfmForSelectInput() {
    const coverageCfm = ['GLBK', 'COLV', 'ELDG', 'WTDG', 'EXPL', 'FIRE', 'VDLM', 'CLET', 'THFT']
    return this.coverages
      .filter(coverage => coverageCfm.indexOf(coverage.attributes.key) !== -1)
      .map(coverage => ({
        value: coverage.attributes.key,
        label: coverage.attributes.value,
      }))
  }

  get coveragesData() {
    return reduce(
      (acc, coverage) => {
        acc[coverage.attributes.key] = coverage.attributes.value
        return acc
      },
      {},
      this.coverages,
    )
  }

  get insurersForSelectInput() {
    return sortByCaseInsensitive('label')(this.insurers)
  }

  get expertiseTypesForSelectInput() {
    return this.expertiseTypes.map(expertise => ({
      value: expertise.attributes.key,
      label: expertise.attributes.value,
    }))
  }

  get technicityLevelsForSelectInput() {
    return this.technicityLevels.map(technicityLevel => {
      return {
        value: technicityLevel.key,
        label: technicityLevel.value,
      }
    })
  }

  get appointmentTypeForSelectInput() {
    return this.appointmentType.map(appointmentType => ({
      value: appointmentType.id,
      label: appointmentType.label,
    }))
  }

  get missionTypesForSelectInput() {
    const missionTypes = this.missionTypes.map(missionType => ({
      value: missionType.attributes.key,
      label: missionType.attributes.value,
    }))
    missionTypes.push({
      value: 'all',
      label: UiCtrl.language === 'fr-FR' ? 'Tout type de mission' : 'All type of mission',
    })
    return missionTypes
  }

  get furnitureCategoriesOptions() {
    return this.furnitureCategories.map(category => ({
      label: category.trans_default,
      value: category.id,
    }))
  }

  get insuranceCompaniesForSelect() {
    return this.insuranceCompanies.map(ic => ({
      value: ic.id,
      label: ic.value,
    }))
  }

  get contractNaturesForSelect() {
    return this.contractNatures.map(ic => ({
      value: ic.id,
      label: ic.value,
    }))
  }
  get formatMotifs() {
    const { sd13Available } = ClaimStore.claim
    if (sd13Available === false) {
      return this.motifsCancellation
        .filter(motif => motif.attributes.key !== 'CANCELED_INJURED_DEFICIENCY')
        .map(motif => {
          return {
            value: motif.attributes.key,
            label: motif.attributes.value,
          }
        })
    }
    return this.motifsCancellation.map(motif => {
      return {
        value: motif.attributes.key,
        label: motif.attributes.value,
      }
    })
  }

  get getMotifCancelType() {
    const { sd13Available } = MissionStore.cfa
    const cancelReasons = [
      'CANCELED_INJURED_DEFICIENCY',
      'BAD_STATUS_OTHER',
      'BAD_STATUS_INSURED_REFUSED_EXPERTISE',
    ]
    if (MissionStore.isHorsDarva === true) {
      return this.cancelMotives
        .filter(motif => cancelReasons.indexOf(motif.attributes.key) !== -1)
        .map(motif => {
          return {
            value: motif.attributes.key,
            label: motif.attributes.value,
          }
        })
    }
    if (sd13Available === false && MissionStore.isHorsDarva === false) {
      return this.cancelMotives
        .filter(motif => motif.attributes.key !== 'CANCELED_INJURED_DEFICIENCY')
        .map(motif => {
          return {
            value: motif.attributes.key,
            label: motif.attributes.value,
          }
        })
    }
    return this.cancelMotives.map(motif => {
      return {
        value: motif.attributes.key,
        label: motif.attributes.value,
      }
    })
  }

  findMissionTypeValue(missionTypeKey) {
    const missionType = this.missionTypesForSelectInput.find(
      missionType => missionType.value === missionTypeKey,
    )
    if (missionType) return missionType.label
    console.log(`Warning : key ${missionTypeKey} not found in mission types array`)
    return null
  }

  getInsuranceCompanyNameFromGta(gta) {
    const insuranceCompany = this.insuranceCompanies.filter(ic => ic.id === gta)
    return insuranceCompany.value
  }

  async loadData() {
    try {
      const [
        resPIBusiness,
        resPIPersonal,
        resPICoporal,
        resPIResponsabilities,
        claimStatus,
        technicityLevels,
        appointmentType,
        motiveRetards,
        objectRetards,
        resFurnitureCategories,
        resRiskTypes,
        contractNatures,
        insuranceCompanies,
        cancelMotives,
        partyInvolvedTypeOfVictims,
      ] = await Promise.all([
        fetchPartyInvolvedRoles('professional'),
        fetchPartyInvolvedRoles('personal'),
        fetchPartyInvolvedRoles('CORPORAL'),
        fetchInvolvedPartyResponsabilities(this.isCoporalType),
        fetchClaimStatus(),
        fetchTechnicityLevels(),
        fetchAppointmentType(),
        getMotiveRetardOptions(),
        getObjectRetardOptions(),
        furnituresCategories(),
        fetchRiskTypes(),
        fetchContractNatures(),
        fetchInsuranceCompanies(),
        fetchMotifCancelType(),
        fetchTypeOfVictims(),
      ])
      runInAction(() => {
        this.partyInvolvedBusinessRoles = resPIBusiness
        this.partyInvolvedPersonalRoles = resPIPersonal
        this.partyInvolvedResponsabilities = resPIResponsabilities
        this.partyInvolvedCoporalRoles = resPICoporal
        this.claimStatus = claimStatus
        this.technicityLevels = technicityLevels
        this.appointmentType = appointmentType
        this.motiveRetards = motiveRetards
        this.objectRetards = objectRetards
        this.furnitureCategories = resFurnitureCategories
        this.riskTypes = resRiskTypes
        this.contractNatures = contractNatures
        this.insuranceCompanies = insuranceCompanies
        this.cancelMotives = cancelMotives
        this.partyInvolvedTypeOfVictims = partyInvolvedTypeOfVictims
      })
    } catch (err) {
      console.log(err)
    }
  }

  async refreshInvolvedPartyResponsabilitiesData() {
    try {
      const [resPIResponsabilities] = await Promise.all([
        fetchInvolvedPartyResponsabilities(this.isCoporalType),
      ])
      runInAction(() => {
        this.partyInvolvedResponsabilities = resPIResponsabilities
      })
    } catch (err) {
      console.log(err)
    }
  }

  async refreshInvolvedPartyRoleData() {
    try {
      const [resPIBusiness, resPIPersonal, resPICoporal] = await Promise.all([
        fetchPartyInvolvedRoles('professional'),
        fetchPartyInvolvedRoles('personal'),
        fetchPartyInvolvedRoles('CORPORAL', this.isCoporalType),
      ])
      runInAction(() => {
        this.partyInvolvedBusinessRoles = resPIBusiness
        this.partyInvolvedPersonalRoles = resPIPersonal
        this.partyInvolvedCoporalRoles = resPICoporal
      })
    } catch (err) {
      console.log(err)
    }
  }

  loadSupportingDocumentsTypesData(wan, type) {
    fetchSupportingDocumentsTypes(wan, type)
      .then(
        action(res => {
          this.supportingDocumentsTypes = res.data.reduce((obj, current) => {
            if (obj[current.attributes.category]) {
              obj[current.attributes.category] = {
                ...obj[current.attributes.category],
                [current.attributes.key]: current.attributes.value,
              }
            } else {
              obj[current.attributes.category] = {
                [current.attributes.key]: current.attributes.value,
              }
            }
            return obj
          }, {})
        }),
      )
      .catch(err => console.log(err))
  }

  async loadCoverages(reload = false) {
    if (!reload && this.coveragePromise) return this.coveragePromise
    if (reload || this.coverages.length === 0) {
      this.coveragePromise = fetchCoverages()
        .then(
          action(res => {
            this.coverages = res
          }),
        )
        .catch(err => {
          console.log(err)
        })
      return this.coveragePromise
    }
  }

  loadInsurers(reload = false) {
    if (!UserStore.isInsurer && (reload || this.insurers.length === 0)) {
      fetchInsurers(getDataLocal('customerId'))
        .then(
          action(res => {
            this.insurers = res.data.map(insurer => ({
              value: insurer.attributes.customer.id,
              label: insurer.attributes.customer.name,
            }))
          }),
        )
        .catch(
          action(err => {
            console.log(err)
          }),
        )
    }
  }

  loadExpertiseTypes(reload = false) {
    if (reload || this.expertiseTypes.length === 0) {
      fetchExpertiseTypes()
        .then(
          action(res => {
            this.expertiseTypes = res
          }),
        )
        .catch(err => {
          console.log(err)
        })
    }
  }

  async loadMissionTypes() {
    try {
      const res = await fetchMissionInsurerType()
      runInAction(() => {
        this.missionTypes = res
      })
    } catch (err) {
      console.log(err)
    }
  }

  loadExperts = async wan => {
    try {
      const experts = await fetchExperts(wan)
      runInAction(() => (this.experts = experts))
    } catch (err) {
      console.log(err)
    }
  }

  get expertsForSelect() {
    return this.experts.map(expert => ({
      value: expert.id,
      label: (expert.lastname || '') + ' ' + (expert.firstName || ''),
    }))
  }

  get motiveRetardsOptions() {
    return this.motiveRetards.map(option => ({
      value: option.attributes.id,
      label: option.attributes.value,
    }))
  }

  get objectRetardOptions() {
    return this.objectRetards.map(option => ({
      value: option.attributes.id,
      label: option.attributes.value,
    }))
  }

  get riskTypesOptions() {
    return this.riskTypes.map(riskType => ({ value: riskType.id, label: riskType.value }))
  }
}

const DecoratedCommonStore = decorate(CommonStore, {
  partyInvolvedBusinessRoles: observable,
  partyInvolvedPersonalRoles: observable,
  partyInvolvedCoporalRoles: observable,
  partyInvolvedResponsabilities: observable,
  partyInvolvedTypeOfVictims: observable,
  supportingDocumentsTypes: observable,
  claimStatus: observable,
  coverages: observable,
  insurers: observable,
  expertiseTypes: observable,
  missionTypes: observable,
  technicityLevels: observable,
  appointmentType: observable,
  experts: observable,
  furnitureCategories: observable,
  riskTypes: observable,

  claimCFI: computed,
  claimCFA: computed,
  claimCFM: computed,
  businessRolesForSelect: computed,
  personalRolesForSelect: computed,
  responsabilitiesForSelect: computed,
  typeOfVictimeForSelect: computed,
  isHorsDarva: computed,
  supportingDocTypesForDocuments: computed,
  coveragesForSelectInput: computed,
  coveragesCfmForSelectInput: computed,
  coveragesData: computed,
  insurersForSelectInput: computed,
  expertiseTypesForSelectInput: computed,
  missionTypesForSelectInput: computed,
  technicityLevelsForSelectInput: computed,
  appointmentTypeForSelectInput: computed,
  expertsForSelect: computed,
  furnitureCategoriesOptions: computed,
  riskTypesOptions: computed,

  loadData: action,
  refreshInvolvedPartyResponsabilitiesData: action,
  refreshInvolvedPartyRoleData: action,
  loadSupportingDocumentsTypesData: action,
  loadCoverages: action,
  loadInsurers: action,
  loadExpertiseTypes: action,
  loadExperts: action,
})

export default new DecoratedCommonStore()
