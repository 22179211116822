import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import CreateClaimCtrl from 'stores/Mission/view/CreateClaimCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Loader from 'components/Loader/Loader'
import Form from 'components/DynForm/Form'

const CreateClaimModal = ({ t }) => {
  const [grabbing, setGrabbing] = useState(false)
  const { showModal, loading, loadData, setProperty, save, form } = CreateClaimCtrl
  const history = useHistory()

  const onSave = async formData => {
    const wan = await save(formData)
    setProperty('showModal', false)
    history.push(`mission/${wan}/MissionInformation`)
  }

  useEffect(() => {
    if (showModal) {
      loadData()
    }
  }, [showModal, loadData])

  const onClose = () => setProperty('showModal', false)

  if (!showModal) return null

  return (
    <ModalWithBackdrop
      show={true}
      size="modal-xl"
      close={onClose}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t('dashboard.createManagerClaim.modal.title')}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        {(loading || !form) && <Loader />}
        {!loading && form && (
          <Form formObj={form} saveService={onSave.bind(this)} isCreationForm={true} />
        )}
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(withTranslation(), observer)(CreateClaimModal)
