import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'

import CalendarWithDnD from './CalendarWithDnD'
import Event from './Event'

function Day({ expert, events, CalendarExpertStore: { min, max, day } }) {
  return (
    <CalendarWithDnD
      max={max}
      min={min}
      day={day}
      defaultView="day"
      views={['day']}
      toolbar={false}
      events={events}
      expert={expert}
      components={{
        event: Event,
      }}
    />
  )
}

export default compose(
  inject('CalendarExpertStore'),
  observer,
)(Day)
