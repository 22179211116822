import { path } from 'ramda'

const _reassign = [
  { endpoint: '/darva/v1/change/expert/', status: 422, message: '422_overrun', type: 'danger' },
]
const _downLoadReport = [
  {
    endpoint: '/download-pdf/STD_REPORT',
    status: 206,
    message: '206_downloadReport',
    type: 'warning',
  },
]
const _retrieveDarva = [
  {
    endpoint: '/retrieve-report',
    status: 404,
    message: '404_retrieveDarva',
    type: 'danger',
  },
]

const _customErrors = [..._reassign, ..._retrieveDarva, ..._downLoadReport]

const customHttpCode = response => {
  let httpCode = null
  const url = path(['config', 'url'], response)
  const status = path(['status'], response)

  for (let i = 0; i < _customErrors.length; i++) {
    const element = _customErrors[i]

    if (new RegExp(element.endpoint).test(url) && element.status === status) {
      httpCode = element
      break
    }
  }
  return httpCode
}

export default customHttpCode
