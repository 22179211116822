import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

import CartStore from 'stores/Mission/domain/CartStore'
import { minSelfRepairRate, maxSelfRepairRate } from 'stores/Mission/domain/Configuration'

const SliderWithTooltip = createSliderWithTooltip(Slider)

class SelfRepairSlider extends React.Component {
  onChange = value => {
    CartStore.configuration.setProperty('overridePriceListSelfRepairRate', parseFloat(value / 100))
  }

  render() {
    const { t } = this.props
    const {
      configuration: { finalPriceListSelfRepairRate },
      hasBpuPackage,
      packagesPriceListLabels,
      isSelfRepairRateAdjustable,
    } = CartStore

    const value = parseInt(finalPriceListSelfRepairRate * 100, 10)

    if (!(isSelfRepairRateAdjustable && hasBpuPackage)) return null

    return (
      <div className="container border-top border-color-primary pt-4">
        <p className="font-weight-bold">{t('mission.calculation.selfRepairSlider.title')}</p>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={'text-center'}>
              <span className={'float-left'}>{minSelfRepairRate * 100}%</span>
              <span className="font-weight-bold text-primary">{value}%</span>
              <span className={'float-right'}>{maxSelfRepairRate * 100}%</span>
            </div>
            <div className={'p-3'}>
              <SliderWithTooltip
                min={minSelfRepairRate * 100}
                max={maxSelfRepairRate * 100}
                tipFormatter={value => `${value}%`}
                onChange={this.onChange}
                value={value}
              />
            </div>
          </div>
          <div className="col-12 col-md-5 offset-md-1">
            <p>
              {t('mission.calculation.selfRepairSlider.description')}
              {packagesPriceListLabels}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(observer(SelfRepairSlider))
