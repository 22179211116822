import request from 'services/httpRequest'

export const fetchExpertiseTypes = () => {
  return request({
    method: 'GET',
    url: '/businessData/v1/expertiseType',
    params: {
      order: 'DESC',
      field: 'key',
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
