import { observable, action, decorate } from 'mobx'
import { isWithinInterval, isSameDay, isEqual, getISODay, max, isAfter } from 'date-fns'

import { concatAddress } from 'utils'

class PlannerExpert {
  id = null
  firstName = ''
  lastName = ''
  fullName = ''
  legal = 0
  corporate = 0
  home = 0
  schedule = []
  tours = []

  constructor(data) {
    this.id = data.id
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.fullName = data.fullName
    this.tours = data.tours
    this.email = data.email
  }

  checkAvailability(timeSlot) {
    let available = true

    for (let i = 0; i < this.schedule.length; i++) {
      const unavailability = this.schedule[i]
      const unavailable = isWithinInterval(timeSlot, {
        start: new Date(unavailability.startDate),
        end: new Date(unavailability.endDate),
      })
      if (unavailable) return false
    }

    return available
  }

  lastLocation(timeSlot, onlyCityAndZipCode = false) {
    const ofTheDay = this.schedule.filter(unavailability =>
      isSameDay(timeSlot, new Date(unavailability.endDate)),
    )

    const previousUnavailability = max(
      ofTheDay
        .map(({ endDate }) => new Date(endDate))
        .filter(endDate => isAfter(timeSlot, endDate)),
    )

    const last = this.schedule.find(unavailability =>
      isEqual(previousUnavailability, new Date(unavailability.endDate)),
    )

    if (last) return concatAddress(last.address, onlyCityAndZipCode)

    const dayISO = getISODay(timeSlot)
    const departure = this.tours.find(tour => tour.dayOfWeek === dayISO)
    if (departure) return concatAddress(departure.address, onlyCityAndZipCode)
    return null
  }

  setProperty(key, value) {
    this[key] = value
  }
}

decorate(PlannerExpert, {
  schedule: observable,

  lastLocation: action.bound,
  setProperty: action.bound,
})

export default PlannerExpert
