import { action, observable, computed, runInAction, decorate } from 'mobx'

import {
  qualifyMission,
  fetchMotifCancelType,
  cancelMission,
  fetchPoles,
  reassignMission,
} from 'services/claim'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import CreateMissionFormCtrl from 'stores/Claim/view/CreateMissionFormCtrl'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import ClaimStore from 'stores/Claim/domain/ClaimStore'
import PlannerStore from 'stores/Claim/PlannerStore'

class CreateMissionCtrl {
  motifsCancellation = []
  poles = []
  showSummary = true
  showConfirmation = false
  confirmId = null
  submitting = false
  reassigning = false
  canceling = false

  loadData = wan => {
    CreateMissionFormCtrl.loadData(wan)
    this._loadMotifCancellationTypes()
    this._loadMotifPoles()
    PartyInvolvedStore.clearSelectedCreateMission()
    SupportingDocumentsCtrl.clearCreateMissionSelectedSd()
  }

  saveMissionForm = async ({ wan, send = false, putOnWaiting = false }) => {
    const isValid = CreateMissionFormCtrl.checkValidation()
    if (!isValid) return

    this.submitting = true

    const { reloadClaimStatus, lastMissionID } = ClaimStore
    const body = CreateMissionFormCtrl.getPostBody

    if (send) {
      this.showSummary = false
      this.showConfirmation = true
      this.confirmId = null
      body.assigneeUser = PlannerStore.selectedExpert
      body.appointmentDate = PlannerStore.selectedTimeSlot
        ? PlannerStore.selectedTimeSlot.toISOString()
        : null
      body.send = true
    } else {
      body.putOnWaiting = putOnWaiting
    }

    try {
      const res = await qualifyMission({
        body,
        wan,
        missionId: lastMissionID,
      })
      await reloadClaimStatus(wan)
      runInAction(() => {
        if (CreateMissionFormCtrl.form) {
          CreateMissionFormCtrl.form.inputs.forEach(input =>
            input.setProperty('originalValue', input.value),
          )
        }
        if (send) {
          this.confirmId = res.id
          AlertCtrl.alert('success', 'claim.mission.successPlanning')
        } else
          AlertCtrl.alert(
            'success',
            `claim.mission.${putOnWaiting ? 'successWaiting' : 'successQualification'}`,
          )

        this.submitting = false
      })
    } catch (err) {
      if (send) {
        AlertCtrl.alert('danger', 'claim.mission.failedPlanning')
        this.resetConfirmationModal()
        runInAction(() => {
          this.confirmId = null
        })
      } else AlertCtrl.alert('danger', 'claim.mission.failedQualification')

      runInAction(() => {
        this.submitting = false
      })
      return Promise.reject(err)
    }
  }

  resetForm = () => {
    CreateMissionFormCtrl.resetDatas()
    PartyInvolvedStore.clearSelectedCreateMission()
    SupportingDocumentsCtrl.clearCreateMissionSelectedSd()
  }

  resetConfirmationModal() {
    this.showSummary = true
    this.showConfirmation = false
  }

  cancelMission = async (wan, { motif, message }) => {
    try {
      runInAction(() => (this.canceling = true))
      await cancelMission(wan, { cancellationClosure: motif, message })
      AlertCtrl.alert('success', 'claim.mission.successCancel')
    } catch (err) {
      AlertCtrl.alert('danger', 'claim.mission.failedCancel')
      throw err
    } finally {
      runInAction(() => (this.canceling = false))
    }
  }

  reassignPole = async (wan, { pole, message }) => {
    try {
      runInAction(() => (this.reassigning = true))
      await reassignMission(wan, { customerId: pole, message })
      AlertCtrl.alert('success', 'mission.qualification.successReassign')
    } catch (err) {
      AlertCtrl.alert('danger', 'mission.qualification.failedReassign')
    } finally {
      runInAction(() => (this.reassigning = false))
    }
  }

  setProperty(key, value) {
    this[key] = value
  }

  get formatMotifs() {
    const { sd13Available } = ClaimStore.claim
    const cancelReasons = [
      'CANCELED_INJURED_DEFICIENCY',
      'BAD_STATUS_OTHER',
      'BAD_STATUS_INSURED_REFUSED_EXPERTISE',
    ]
    if (ClaimStore.isHorsDarva === true) {
      return this.motifsCancellation
        .filter(motif => cancelReasons.indexOf(motif.attributes.key) !== -1)
        .map(motif => {
          return {
            value: motif.attributes.key,
            label: motif.attributes.value,
          }
        })
    }
    if (sd13Available === false && ClaimStore.isHorsDarva === false) {
      return this.motifsCancellation
        .filter(motif => motif.attributes.key !== 'CANCELED_INJURED_DEFICIENCY')
        .map(motif => {
          return {
            value: motif.attributes.key,
            label: motif.attributes.value,
          }
        })
    }
    return this.motifsCancellation.map(motif => {
      return {
        value: motif.attributes.key,
        label: motif.attributes.value,
      }
    })
  }

  get formatPoles() {
    return this.poles.map(pole => ({
      value: pole.attributes.id,
      label: pole.attributes.name,
    }))
  }

  _loadMotifCancellationTypes = async () => {
    try {
      const motifs = await fetchMotifCancelType()
      runInAction(() => {
        this.motifsCancellation = motifs
      })
    } catch (err) {
      AlertCtrl.alert('danger', 'claim.mission.failedFetchCancellationTypes')
      console.log(err)
    }
  }

  _loadMotifPoles = async () => {
    try {
      const poles = await fetchPoles()
      runInAction(() => {
        this.poles = poles
      })
    } catch (err) {
      AlertCtrl.alert('danger', 'claim.mission.failedFetchPoles')
      console.log(err)
    }
  }
}

const DecoratedCreateMissionCtrl = decorate(CreateMissionCtrl, {
  motifsCancellation: observable,
  poles: observable,
  showSummary: observable,
  showConfirmation: observable,
  confirmId: observable,
  submitting: observable,
  reassigning: observable,
  canceling: observable,

  formatMotifs: computed,
  formatPoles: computed,

  loadData: action,
  resetForm: action,
  setProperty: action,
  saveMissionForm: action.bound,
  resetConfirmationModal: action.bound,
})

export default new DecoratedCreateMissionCtrl()
