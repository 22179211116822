import React, { useEffect } from 'react'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const SuggestedExpert = ({
  CreateMissionFormCtrl: {
    currentAddressInput,
    currentTechnicalLevelInput,
    suggestedExpertId,
    setProperty,
    suggestedExperts,
    loadSuggestedExperts,
  },
  t,
}) => {
  useEffect(() => {
    loadSuggestedExperts()
  }, [currentAddressInput.value, currentTechnicalLevelInput.value, loadSuggestedExperts])
  const onChange = e => {
    setProperty(e.target.name, e.target.value)
  }

  return (
    <div className="form-group row no-gutters">
      <label className="col-sm-4">{t('mission.suggestedExpert')}</label>
      <div className="col">
        <ResponsiveSelect
          isSearchable={true}
          name="suggestedExpertId"
          value={suggestedExpertId}
          options={suggestedExperts}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default compose(
  inject('CreateMissionFormCtrl'),
  withTranslation(),
  observer,
)(SuggestedExpert)
