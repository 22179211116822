import React from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'

import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import Input from 'components/Forms/Input'

const QuickDates = withTranslation()(
  observer(({ t, onClick }) => {
    const dates = [
      { label: t('dashboard.filter.date.today'), key: 'today' },
      { label: t('dashboard.filter.date.todayMorning'), key: 'todayMorning' },
      { label: t('dashboard.filter.date.todayAfternoon'), key: 'todayAfternoon' },
      { label: t('dashboard.filter.date.tomorrow'), key: 'tomorrow' },
      { label: t('dashboard.filter.date.tomorrowMorning'), key: 'tomorrowMorning' },
      { label: t('dashboard.filter.date.tomorrowAfternoon'), key: 'tomorrowAfternoon' },
      { label: t('dashboard.filter.date.all'), key: null },
    ]

    return (
      <div className="quick-dates">
        {dates.map(date => (
          <div className="date" key={`date-${date.key}`} onClick={onClick.bind(this, date)}>
            {date.label}
          </div>
        ))}
      </div>
    )
  }),
)

class DateFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focusedInput: null,
      display: false,
    }
  }

  onFocusRangePick = focusedInput => this.setState({ focusedInput })
  isOutsideRange = () => false
  onClick = date => {
    DashboardFilterStore.currentFilter.upsertDynamicFilter({
      key: this.props.name,
      value: date,
      type: 'date',
    })
    this.setState({ display: false })
  }
  rangePick = ({ startDate, endDate }) => {
    const date = {}
    if (startDate) date.startDate = moment(startDate).startOf('day')
    if (endDate) date.endDate = moment(endDate).endOf('day')

    DashboardFilterStore.currentFilter.upsertDynamicFilter({
      key: this.props.name,
      value: date,
      type: 'date',
    })
  }

  closeDateFilter = () => {
    this.setState({ display: false })
  }
  onFocus = () => this.setState({ display: true })

  render() {
    const { focusedInput, display } = this.state
    const {
      t,
      value,
      UiCtrl: { isMobile },
    } = this.props

    const startDate = value && value.startDate ? moment(value.startDate) : null
    const endDate = value && value.endDate ? moment(value.endDate) : null

    let dateText = ''

    if (value && typeof value === 'object') {
      if (value.label) dateText = value.label
      else {
        if (startDate) dateText = `${startDate.format('DD/MM/YYYY')}`
        dateText += ' -> '
        if (endDate) dateText += `${endDate.format('DD/MM/YYYY')}`
      }
    }

    return (
      <React.Fragment>
        <Input
          className="form-control"
          style={{ height: '38px' }}
          name="date-filter"
          onFocus={this.onFocus.bind(this)}
          value={dateText}
        />
        {display && (
          <React.Fragment>
            <div className="date-filter card">
              <div className="card-body p-1 ">
                <DateRangePicker
                  startDatePlaceholderText={t('dashboard.filter.date.startDate')}
                  endDatePlaceholderText={t('dashboard.filter.date.endDate')}
                  monthFormat="MMMM YYYY"
                  startDate={startDate}
                  startDateId="start-id"
                  endDate={endDate}
                  endDateId="end-id"
                  block={true}
                  onDatesChange={this.rangePick}
                  focusedInput={focusedInput}
                  onFocusChange={this.onFocusRangePick}
                  hideKeyboardShortcutsPanel
                  isOutsideRange={this.isOutsideRange}
                  withFullScreenPortal={isMobile}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  minimumNights={0}
                />
              </div>
              <QuickDates onClick={this.onClick.bind(this)} />
            </div>
            <div className="date-filter-overlay" onClick={this.closeDateFilter} />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default inject('UiCtrl')(withTranslation()(observer(DateFilter)))
