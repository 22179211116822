import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import AlertCtrl from 'stores/Common/view/AlertCtrl'

const AssigneeModal = ({
  setAssignee,
  assignee,
  showModal,
  setShowModal,
  ClaimStore: { changeAssignee, loading, reassigning },
  CommonStore: { expertsForSelect: options },
}) => {
  const [option, setOption] = useState(null)
  const { t } = useTranslation()
  const { id: wan } = useParams()

  const onClose = () => setShowModal(false)
  const onValid = async () => {
    const isChanged = await changeAssignee(wan, option)
    if (isChanged) {
      setAssignee(options.find(({ value }) => option === value))
      AlertCtrl.alert('success', t('claim.mission.reassignSuccess'))
    } else {
      AlertCtrl.alert('danger', t('claim.mission.reassignFailed'))
    }
    setShowModal(false)
  }

  if (loading) return <Loader />

  return (
    <ModalWithBackdrop size="modal-md" show={showModal} close={onClose}>
      <div className="modal-header">
        <h5 className="modal-title">{t('claim.mission.reassignMissionModal')}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <div className="row no-gutters form-group">
          <div className="col-10">
            <ResponsiveSelect
              options={options.filter(option => option.value !== assignee.value)}
              value={option}
              onChange={e => setOption(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <Button
          className="btn btn-primary"
          onClick={onValid}
          disabled={!option || reassigning}
          loading={reassigning}
        >
          {t('mission.qualification.validate')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default inject('CommonStore', 'ClaimStore')(observer(AssigneeModal))
