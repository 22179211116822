import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import Input from 'components/Forms/Input'

class ObsolescenceCalculator extends React.Component {
  showObsolescenceCalculator = () => {
    this.props.controller.setProperty(
      'showObsolescenceCalculator',
      !this.props.controller.showObsolescenceCalculator,
    )
  }
  onChange = e => this.props.controller.packageData.setProperty(e.target.name, e.target.value)

  render() {
    const { t } = this.props
    const { showObsolescenceCalculator } = this.props.controller
    const { lifetime, age } = this.props.controller.packageData

    return (
      <div className="obsolescence-calculator d-inline">
        <i className="fa fa-calculator ml-3 clickable" onClick={this.showObsolescenceCalculator} />

        {showObsolescenceCalculator
          ? [
              <div key="calculator" className="calculator p-3">
                <div className="font-weight-bold mb-2">
                  {t('mission.calculation.detailPackage.obsolescenceCalculatorTitle')}
                </div>

                <div className="row no-gutters d-flex align-items-center mb-2">
                  <span className="col-5">{t('mission.calculation.detailPackage.lifeTime')}:</span>
                  <div className="col-7 text-right">
                    {t('mission.calculation.detailPackage.years', { count: lifetime })}
                  </div>
                </div>

                <div className="row no-gutters d-flex align-items-center mb-2">
                  <span className="col-5">{t('mission.calculation.detailPackage.age')} :</span>
                  <div className="col-7 text-right">
                    <Input
                      name="age"
                      type="number"
                      className="percentage-input form-control d-inline text-right"
                      min={0}
                      max={300}
                      value={age}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>,
              <div key="overlay" className="overlay" onClick={this.showObsolescenceCalculator} />,
            ]
          : null}
      </div>
    )
  }
}

export default withTranslation()(observer(ObsolescenceCalculator))
