import { observable, action, decorate, runInAction } from 'mobx'
import { sendRemoteAssessmentInvitation, remoteAssessment } from 'services/remoteAssessment'
import { getLocale } from 'stores/localStorage'
class RemoteAssessmentCtrl {
  showRemoteAssessmentForm = false
  sending = false
  involvedParty = null
  phoneNumber = ''
  email = ''
  sessionStarted = false
  token = null
  sessionId = null
  showScreenshotModal = false

  startSession = async wan => {
    this.sending = true
    try {
      const res = await sendRemoteAssessmentInvitation(
        wan,
        this.phoneNumber,
        this.email,
        getLocale(),
      )
      runInAction(() => {
        this.sending = false
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  checkInvitationStatus = async wan => {
    this.sending = true
    try {
      const res = await remoteAssessment(wan)
      runInAction(() => {
        this.sending = false
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  resetForm = () => {
    this.phoneNumber = ''
    this.email = ''
    this.involvedParty = null
  }

  endSession = () => {
    this.token = null
    this.sessionId = null
    this.sessionStarted = false
    this.email = ''
    this.involvedParty = null
    this.showRemoteAssessmentForm = false
    this.showScreenshotModal = false
  }
}

const RemoteAssessmentCtrlDecorated = decorate(RemoteAssessmentCtrl, {
  showRemoteAssessmentForm: observable,
  phoneNumber: observable,
  email: observable,
  sessionStarted: observable,
  token: observable,
  sessionId: observable,
  involvedParty: observable,
  showScreenshotModal: observable,
  sending: observable,

  startSession: action,
  resetForm: action,
  endSession: action,
  setProperty: action,
  openScreenshotModal: action,
  closeScreenshotModal: action,
  uploadScreenshot: action,
  checkInvitationStatus: action,
})

export default new RemoteAssessmentCtrlDecorated()
