import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { compose } from 'recompose'

import 'components/StatusSteps/StatusSteps.css'
import Tooltip from 'components/Tooltip/Tooltip'

const MeteoSteps = ({ setHour, meteo, meteoData }) => {
  let steps = meteoData
  const [currentStep, setCurrentStep] = useState(0)
  return (
    <ul className="status-steps-wrapper mb-0">
      {steps.map(step => (
        <li className="step-wrapper" key={step.key}>
          <div>
            <Tooltip
              setHour={setHour}
              setCurrentStep={setCurrentStep}
              meteo={meteo}
              id={step.id}
              text={step.time}
              className="step"
            >
              <div className={step.key <= currentStep ? 'inner-circle' : 'no-style'} />
            </Tooltip>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default compose(observer)(MeteoSteps)
