import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import DashboardFilterStore from 'stores/Common/domain/DashboardFilterStore'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import DashboardCtrl from 'stores/Common/view/DashboardCtrl'

class FilterMissionStatus extends React.Component {
  onChangeFilter = e =>
    DashboardFilterStore.currentFilter.setProperty(e.target.name, e.target.value)

  render() {
    const { t } = this.props
    const {
      currentFilter: { missionStatus },
    } = DashboardFilterStore
    const { filters } = DashboardCtrl
    const allOpen = {
      value: 'allOpen',
      label: t('dashboard.filter.allMissions'),
    }
    const filterOptions = filters.map(filter => {
      return { value: filter.key, label: t(filter.name) }
    })
    filterOptions.push(allOpen)

    return (
      <ResponsiveSelect
        className="select-input"
        name="missionStatus"
        isSearchable
        onChange={this.onChangeFilter}
        value={missionStatus}
        options={filterOptions}
      />
    )
  }
}

export default withTranslation()(observer(FilterMissionStatus))
