import React, { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { path } from 'ramda'
import ReactHtmlParser from 'react-html-parser'
import classNames from 'classnames'

import Loader from 'components/Loader/Loader'
import Divider from 'pages/Mission/pages/Report/Divider'
import PdfReader from 'pages/Mission/pages/Report/PdfReader'
import LiteGallery from 'pages/Mission/components/LiteGallery'

const Report = ({
  ReportCtrl: { reportContent, reportIrsiContent, SDAttachedToReport, loadingSDDetails },
  ClaimStore: { getMission },
}) => {
  const [show, setShow] = useState('report')
  const { missionId } = useParams()
  const { t } = useTranslation()
  const mission = getMission(missionId)
  const isCurrentMissionClosed = path(['status', 'id'], mission) === 'CACC'

  const showReport = show === 'report'
  const showIrsiReport = show === 'irsi-report'
  const showSD = show === 'sd'

  return (
    <React.Fragment>
      <div className="mt-5 row">
        <ul className="col-12 nav nav-tabs">
          <li className="nav-item">
            <div
              className={classNames('nav-link', {
                active: showReport,
                'text-primary': showReport,
                clickable: !showReport,
              })}
              onClick={() => setShow('report')}
            >
              {t('claim.mission.report')}
            </div>
          </li>

          {reportIrsiContent && (
            <li className="nav-item">
              <div
                className={classNames('nav-link', {
                  active: showIrsiReport,
                  'text-primary': showIrsiReport,
                  clickable: !showIrsiReport,
                })}
                onClick={() => setShow('irsi-report')}
              >
                {t('claim.mission.irsiReport')}
              </div>
            </li>
          )}

          {!isCurrentMissionClosed && (
            <li className="nav-item">
              <div
                className={classNames('nav-link', {
                  active: showSD,
                  'text-primary': showSD,
                  clickable: !showSD,
                })}
                onClick={() => setShow('sd')}
              >
                {t('claim.mission.supportingDocuments')}
              </div>
            </li>
          )}
        </ul>
      </div>

      {showReport && (
        <div className="report-content-wrapper">
          {isCurrentMissionClosed && <PdfReader />}

          {!!reportContent &&
            !isCurrentMissionClosed &&
            ReactHtmlParser(reportContent, { transform: Divider })}

          {!reportContent && <Loader />}
        </div>
      )}

      {showIrsiReport && (
        <div className="report-content-wrapper">
          {ReactHtmlParser(reportIrsiContent, { transform: Divider })}
        </div>
      )}

      {showSD && !isCurrentMissionClosed && (
        <div className="px-3">
          {loadingSDDetails ? (
            <Loader />
          ) : (
            <LiteGallery
              supportingDocuments={SDAttachedToReport}
              thumbnailWidth="250px"
              thumbnailHeight="190px"
              emptyMessage={t('mission.report.noAttachments')}
            />
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default inject('ReportCtrl', 'ClaimStore')(observer(Report))
