import React from 'react'
import { Marker } from 'react-google-maps'
import { withTranslation } from 'react-i18next'

import { GOOGLE_MAP_API_KEY } from 'utils/constants'
import GoogleMapsWrapper from './GoogleMapsWrapper'
import Start from 'assets/departure.png'
import Chosen from 'assets/mark-end.png'
import End from 'assets/arrival.png'

class Map extends React.Component {
  state = {
    markers: [],
  }
  _mapRef = null

  render() {
    const { t } = this.props

    return (
      <GoogleMapsWrapper
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY.key}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        isMarkerShown={true}
        defaultCenter={{
          lat: this.props.destination.lat,
          lng: this.props.destination.long,
        }}
        defaultZoom={14}
        markers={this.props.markers}
        destination={this.props.destination}
        assignee={this.props.assignee}
        longitude={this.props.longitude}
        latitude={this.props.latitude}
        viewStreet={this.props.streetView}
      >
        {this.props['latitude'] && this.props['longitude'] && (
          <Marker
            key={'start'}
            draggable={false}
            position={{ lat: this.props.latitude, lng: this.props.longitude }}
            icon={Start}
            options={{ height: 32 }}
            title={t('mission.information.map.departure')}
          />
        )}
        {this.props.markers &&
          this.props.markers.map((marker, i) => (
            <Marker
              key={i}
              draggable={false}
              position={marker}
              icon={
                this.props.assignee && this.props.assignee.name === marker.name ? Chosen : Start
              }
              options={{ height: 32 }}
              title={marker.name}
            />
          ))}
        <Marker
          key={'end'}
          draggable={false}
          position={{ lat: this.props.destination.lat, lng: this.props.destination.long }}
          icon={End}
          options={{ height: 32 }}
          title={t('mission.information.map.arrival')}
        />
      </GoogleMapsWrapper>
    )
  }
}

export default withTranslation()(Map)
