import React from 'react'

export default ({ areaPresent, areaType }) => {
  if (!areaPresent) return ' U'

  if (areaType === 'M2')
    return (
      <React.Fragment>
        {' '}
        m<sup>2</sup>
      </React.Fragment>
    )
  else if (areaType === 'ML') return ' ml'

  return null
}
