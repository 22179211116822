import { isNil } from 'ramda'

import request from 'services/httpRequest'
import { formatValidator, formatValidatorClaimFileInsurer } from 'utils/report'

export const generateReport = async (wan, isMissionClosed, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/report-pdf`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  return await request({
    method: 'get',
    responseType: isMissionClosed ? 'blob' : null,
    url,
  })
}

export const generateClaimReport = async (wan, isMissionClosed, reportType) => {
  let url = `/claimFiles/v1/${wan}/report-pdf`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  return await request({
    method: 'get',
    responseType: isMissionClosed ? 'blob' : null,
    url,
  })
}

export const sendEmail = (wan, mail, subject, content, attachments, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/report-pdf-send`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }
  const items = Object.keys(attachments).map(key => attachments[key])

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'report_pdf_send',
        attributes: { email: mail, subject: subject, content: content, attachments: items },
      },
    },
  })
}

export const sendStandardDocument = (wan, mail, subject, content, attachments) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/send-standard-document`

  const items = Object.keys(attachments).map(key => attachments[key])

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'standard_email',
        attributes: {
          email: mail,
          subject: subject,
          content: content,
          attachments: items,
          wan: wan,
        },
      },
    },
  })
}

export const sendStandardDocumentManager = (wan, mail, subject, content, attachments) => {
  let url = `/claimFiles/v1/${wan}/send-standard-document`

  const items = Object.keys(attachments).map(key => attachments[key])

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'standard_email',
        attributes: {
          email: mail,
          subject: subject,
          content: content,
          attachments: items,
          wan: wan,
        },
      },
    },
  })
}

export const sendStandardDocumentClaimManager = (wan, mail, subject, content, attachments) => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/send-standard-document`

  //commented and kept as reference 121121
  // const items = []
  // // eslint-disable-next-line
  // for (const [key, value] of Object.entries(attachments)) {
  //   items.push(value)
  // }

  const items = Object.keys(attachments).map(key => attachments[key])

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'standard_email',
        attributes: {
          email: mail,
          subject: subject,
          content: content,
          attachments: items,
          wan: wan,
        },
      },
    },
  })
}

export const saveReportToSD = (wan, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/save-report-to-sd/${reportType}`

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'save-report-to-sd',
      },
    },
  })
}

export const sendEmailCfm = (wan, mail) => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/report-pdf-send`

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'report_pdf_send',
        attributes: { email: mail },
      },
    },
  })
}

export const downloadReportPdf = async (wan, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/download-pdf`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  return await request({
    method: 'get',
    responseType: 'blob',
    url,
  })
}
export const retreiveIrsiReportFromDarva = async wan => {
  let url = `/darva/v1/report/${wan}/retreive/rec`

  return await request({
    method: 'get',
    url,
  })
}

export const syncDarva = async wan => {
  return await request({
    method: 'get',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/sync-darva`,
  })
}

export const getTransparentLink = async (wan, wanType) => {
  return await request({
    method: 'get',
    url: `darva/v1/report/${wan}/transparent-link-${wanType}`,
  })
}

export const sendDarva = (wan, darvaReportType) => {
  let url = `/darva/v1/report/${wan}`

  if (!isNil(darvaReportType)) {
    url += `/${darvaReportType}`
  }

  return request({
    method: 'post',
    url,
  })
}

export const sendClaimDarva = (wan, darvaReportType) => {
  let url = `/darva/v1/claimReport/${wan}`

  if (!isNil(darvaReportType)) {
    url += `/${darvaReportType}`
  }

  return request({
    method: 'post',
    url,
  })
}

export const sendRectifReportReason = (wan, attributes, darvaReportType) => {
  let url = `/darva/v1/report/${wan}`

  if (!isNil(darvaReportType)) {
    url += `/${darvaReportType}`
  }

  return request({
    method: 'post',
    url,
    data: {
      data: {
        type: 'rectificativeReportReason',
        attributes,
      },
    },
  })
}

export const setPredictableDelay = async (wan, attributes) => {
  try {
    await request({
      method: 'post',
      url: `/darva/v1/report/${wan}/delay`,
      data: {
        data: {
          type: 'trackingPredictableDelay',
          attributes,
        },
      },
    })
    return true
  } catch (err) {
    return false
  }
}

export const getReportType = async wan => {
  try {
    const { data } = await request({
      method: 'get',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/report-type`,
    })

    return data.attributes
  } catch (err) {
    console.warn(`WARNING Error fetching report-type of wan: ${wan}`)
    return null
  }
}

export const getReportValidator = async (wan, reportType) => {
  let url = `/claimFiles/v1/claimFileAssessor/${wan}/validator-for-darva-report`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  const res = await request(
    {
      method: 'GET',
      url,
    },
    true,
  )

  if (res.status === 204) {
    return 204
  }

  return formatValidator(res.data.data.attributes)
}

export const getReportValidatorClaimFileInsurer = async (wan, reportType) => {
  let url = `/claimFiles/v1/${wan}/validator-for-claimFileInsurer`

  if (!isNil(reportType)) {
    url += `/${reportType}`
  }

  const res = await request(
    {
      method: 'GET',
      url,
    },
    true,
  )

  if (res.status === 204) {
    return 204
  }

  return formatValidatorClaimFileInsurer(res.data.data.attributes)
}

export const fetchSDDetail = async ({ wan, sdId }) => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/documents/${sdId}`,
    })
    return res.data.attributes
  } catch (error) {
    console.log(error)
    return null
  }
}

export const retrieveDarva = async wan => {
  try {
    const res = await request({
      method: 'GET',
      url: `claimFiles/v1/claimFileAssessor/${wan}/retrieve-report`,
    })
    return res
  } catch (error) {
    throw error
  }
}
