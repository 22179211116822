import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Slider from './Slider/Slider'

const SliderModal = ({ t }) => {
  const [grabbing, setGrabbing] = useState(false)
  const closeSlider = () => SupportingDocumentsCtrl.setSliderModalDisplay(false)

  return (
    <ModalWithBackdrop
      className={classNames('sliderModal', { 'draw-edit': DrawCtrl.photoEdit })}
      show={SupportingDocumentsCtrl.sliderModalDisplay}
      size="modal-xl"
      close={closeSlider}
      draggable
      scrollable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t('mission.supportingDocuments.edit')}</h5>
        <button type="button" className="close" onClick={closeSlider}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Slider />
      </div>
    </ModalWithBackdrop>
  )
}

export default withTranslation()(observer(SliderModal))
