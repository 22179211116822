import React, { Fragment, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import TH from './TH'
import TD from './TD'
import { formatCurrency } from 'utils/currency'
import PrejudiceModal from './PrejudiceModal'
import { optionsTva } from './PrejudiceOptionsDatas'
import Input from 'components/InputNumber/InputNumber'
import Select from 'components/ResponsiveSelect/ResponsiveSelect'

const PrejudiceTable = ({ payload, MissionStore: { damageDescription } }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const isTVA = damageDescription.taxSystemType !== 'TODO'

  return (
    <Fragment>
      {showModal && (
        <PrejudiceModal
          {...{ showModal, setShowModal, payload }}
          header={t('mission.prejudice.modalPrejudice')}
        />
      )}

      <table border="1" className="line-display table table-bordered table-sm">
        <thead>
          <tr className="table-primary">
            <TH></TH>
            <TH>{t('mission.prejudice.description')}</TH>
            <TH colSpan={isTVA ? 6 : 5}>{t('mission.prejudice.amount')}</TH>
            <TH></TH>
          </tr>
          <tr className="table-primary">
            <TH>
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-sm btn-primary-outline text-info"
              >
                <i className="fa fa-plus"></i>
              </button>
            </TH>
            <TH>
              {t('mission.prejudice.prejudices')}
              <span className="red-bold">*</span>
            </TH>
            <TH>
              {t('mission.prejudice.amountHT')}
              <span className="red-bold">*</span>
            </TH>
            {isTVA && <TH>{t('mission.prejudice.rateVAT')}</TH>}
            <TH>{t('mission.prejudice.obsolescenceRate')}</TH>
            <TH>{t('mission.prejudice.obsolescence')}</TH>
            <TH>
              {t('mission.prejudice.immediateCompensation')}
              <span className="red-bold">*</span>
            </TH>
            <TH>{t('mission.prejudice.obsolescenceReturn')}</TH>
            <TH></TH>
          </tr>
        </thead>

        <tbody>
          {payload.prejudicesComputed.map(
            ({
              id,
              cde,
              totalWithoutVAT,
              vatRate,
              obsolescenceRate,
              obsolescenceWithoutVAT,
              immediateCompensation,
              obsolescenceReturn,
            }) => (
              <tr key={id}>
                <TD></TD>
                <TD className="text-left pl-2">{t(`mission.prejudice.options.${cde}`)}</TD>
                <TD>
                  <Input
                    className="form-control"
                    name="damages"
                    value={totalWithoutVAT}
                    onChange={e => payload.editDammages(id, e.target.value)}
                    placeholder={t('mission.prejudice.damages')}
                    minValue={0}
                  />
                </TD>
                {isTVA && (
                  <TD>
                    <Select
                      className="width-select"
                      options={optionsTva}
                      value={String(vatRate)}
                      onChange={e => payload.editTVA(id, e.target.value)}
                    />
                  </TD>
                )}
                <TD>
                  <Input
                    className="form-control"
                    name="obsolescenceRate"
                    value={obsolescenceRate}
                    onChange={e => payload.editRateObsolescence(id, e.target.value)}
                    placeholder={t('mission.prejudice.obsolescenceRate')}
                    minValue={0}
                    maxValue={100}
                  />
                </TD>
                <TD>{formatCurrency(obsolescenceWithoutVAT)}</TD>
                <TD>{formatCurrency(immediateCompensation)}</TD>
                <TD>
                  <Input
                    className="form-control"
                    name="obsolescenceReturn"
                    value={obsolescenceReturn}
                    onChange={e => payload.editObsolescenceReturn(id, e.target.value)}
                    placeholder={t('mission.prejudice.obsolescenceReturn')}
                    minValue={0}
                  />
                </TD>
                <TD>
                  <button className="btn text-danger btn-sm" onClick={() => payload.delete(id)}>
                    <i className="fa fa-times"></i>
                  </button>
                </TD>
              </tr>
            ),
          )}
          {!payload.prejudicesComputed.length && (
            <tr>
              <td colSpan={isTVA ? 9 : 8}>
                <div className="d-flex flex-column align-items-center">
                  {t('mission.prejudice.noValue')}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  )
}

export default inject('MissionStore')(observer(PrejudiceTable))
