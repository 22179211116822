import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import StrokeWidth from './StrokeWidth'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'

class StrokeWidthSelect extends React.Component {
  render() {
    const { t } = this.props
    return (
      <ul
        className={classNames('dropdown-menu stroke-width', {
          show: DrawCtrl.strokeWidthOpen,
        })}
      >
        <li className="dropdown-item" onClick={() => DrawCtrl.setStrokeWidth(1)}>
          <StrokeWidth strokeWidth={1} />
          <span>{t('mission.supportingDocuments.draw.stroke.thin')}</span>
        </li>
        <li className="dropdown-divider" />
        <li className="dropdown-item" onClick={() => DrawCtrl.setStrokeWidth(3)}>
          <StrokeWidth strokeWidth={3} />
          <span>{t('mission.supportingDocuments.draw.stroke.medium')}</span>
        </li>
        <li className="dropdown-divider" />
        <li className="dropdown-item" onClick={() => DrawCtrl.setStrokeWidth(6)}>
          <StrokeWidth strokeWidth={6} />
          <span>{t('mission.supportingDocuments.draw.stroke.wide')}</span>
        </li>
      </ul>
    )
  }
}

export default withTranslation()(observer(StrokeWidthSelect))
