import { observable, computed, action, runInAction, decorate } from 'mobx'
import { isNil, equals, path } from 'ramda'
import i18next from 'i18next'
import moment from 'moment'

import { replace, splitEvery } from 'ramda'
import CommonStore from 'stores/Common/domain/CommonStore'
import MissionStore from 'stores/Mission/domain/MissionStore'
import { savePartyInvolved } from 'services/partyInvolved'

const findPartyName = (key, partyTypes) => {
  if (partyTypes.length === 0) return null
  const partyType = partyTypes.find(party => party.key === key)
  if (partyType) return partyType.name
  else {
    console.warn(`key: ${key} not found in partyTypes constants`)
    return null
  }
}

const convertPhone = phone => {
  if (!phone) return null
  const formated = replace('+33', '0', phone)
  const maskPhone = splitEvery(2, formated)
  return maskPhone.join(' ')
}

class PartyInvolved {
  // Original data
  originalData = null
  // DATA
  id = null

  firstName = null
  lastName = null
  additionalInformation = null
  civility = 'M.'
  companyEmailAddress = null
  companyFax = null
  companyName = null
  companyPhoneNumber = null
  emailAddress = null
  phoneCode = null
  phoneNumber = null
  mobilePhone = null
  professionalPhone = null
  type = 'personal'
  country = 'FR'
  involvedPartyResponsabilityKey = 'UNDEFINED'
  involvedPartyResponsabilityName = null
  typeOfVictimKey = null
  typeOfVictimName = null
  subscriptionDate = null
  identityCode = null
  // Flatten thirdPartyRole object
  thirdPartyKey = null
  thirdPartyName = null
  thirdPartyType = 'personal'
  lastThirdPartyKeyPerson = ''
  lastThirdPartyKeyBusiness = ''
  // Flatten address object
  streetNumber = null
  addressLine1 = null
  addressLine2 = null
  addressLine3 = null
  city = null
  zipCode = null
  attendance = false
  optOut = false
  selectedCreateMission = false
  updatedAt = null
  deletable = true
  // Front data
  errors = []

  get partyType() {
    if (!this.type) return 'personal'
    return this.type
  }

  get changedAttendance() {
    if (this.originalData === null) return true
    return this.originalData.attendance !== this.attendance
  }

  get changed() {
    if (this.originalData === null) return true
    return !equals(this.asObject, this.originalData)
  }

  get maskCompanyPhone() {
    return convertPhone(this.companyPhoneNumber)
  }

  get maskCompanyFax() {
    return convertPhone(this.companyFax)
  }

  get asObject() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      additionalInformation: this.additionalInformation,
      civility: this.civility,
      companyEmailAddress: this.companyEmailAddress,
      companyFax: this.companyFax,
      companyName: this.companyName,
      companyPhoneNumber: this.companyPhoneNumber,
      emailAddress: this.emailAddress,
      mobilePhone: this.mobilePhone,
      phoneCode: parseInt(this.phoneCode, 10),
      phoneNumber: this.phoneNumber,
      professionalPhone: this.professionalPhone,
      attendance: this.attendance,
      type: this.type,
      country: this.country,
      streetNumber: this.streetNumber,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      addressLine3: this.addressLine3,
      city: this.city,
      zipCode: this.zipCode,
      thirdPartyKey: this.thirdPartyKey,
      thirdPartyName: this.thirdPartyName,
      thirdPartyType: this.thirdPartyType,
      involvedPartyResponsabilityKey: this.involvedPartyResponsabilityKey,
      typeOfVictimKey: this.typeOfVictimKey,
      typeOfVictimName: this.typeOfVictimName,
      involvedPartyResponsabilityName: this.involvedPartyResponsabilityName,
      subscriptionDate: this.subscriptionDate,
      identityCode: this.identityCode,
      optOut: this.optOut,
    }
  }

  get asJson() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      additionalInformation: this.additionalInformation,
      civility: this.civility,
      companyEmailAddress: this.companyEmailAddress,
      companyFax: this.companyFax,
      companyName: this.companyName,
      companyPhoneNumber: this.companyPhoneNumber,
      emailAddress: this.emailAddress,
      mobilePhone: this.mobilePhone,
      phoneCode: this.phoneCode,
      phoneNumber: this.phoneNumber,
      professionalPhone: this.professionalPhone,
      type: this.type,
      country: this.country,
      thirdPartyRole: this.thirdPartyKey,
      involvedPartyResponsability: this.involvedPartyResponsabilityKey,
      typeOfVictimKey: this.typeOfVictimKey,
      typeOfVictimName: this.typeOfVictimName,
      subscriptionDate: this.subscriptionDate ? this.subscriptionDate.format('YYYY-MM-DD') : null,
      identityCode: this.identityCode,
      streetNumber: this.streetNumber,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      addressLine3: this.addressLine3,
      city: this.city,
      zipCode: this.zipCode,
      optOut: this.optOut,
    }
  }

  get asSelectOption() {
    const label = this.type === 'business' ? this.companyName : `${this.lastName} ${this.firstName}`
    return {
      label,
      value: this.id,
    }
  }

  get isUnknownAddress() {
    return this.zipCode === '99999'
  }

  get isValid() {
    return !isNil(this.zipCode) && this.zipCode.length > 0
  }

  get isInIRSI() {
    if (!MissionStore.isIRSI) return false

    return MissionStore.IRSIInvolvedParty.indexOf(this.id) > -1
  }

  constructor(data) {
    if (data) {
      this.setOriginalData(data)
      this.merge(this.originalData)
    } else {
      this.thirdPartyName = findPartyName(
        this.thirdPartyKey,
        CommonStore.partyInvolvedPersonalRoles,
      )
      const responsability = CommonStore.partyInvolvedResponsabilities.find(
        responsability => responsability.key === this.involvedPartyResponsabilityKey,
      )
      if (responsability) this.involvedPartyResponsabilityName = responsability.name
    }
  }

  setOriginalData(data) {
    this.originalData = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      additionalInformation: data.additionalInformation,
      civility: data.civility || 'M.',
      companyEmailAddress: data.companyEmailAddress || null,
      companyFax: data.companyFax || null,
      companyName: data.companyName || null,
      companyPhoneNumber: data.companyPhoneNumber || null,
      emailAddress: data.emailAddress,
      mobilePhone: data.mobilePhone,
      phoneCode: data.phoneCode,
      phoneNumber: data.phoneNumber,
      professionalPhone: data.professionalPhone,
      attendance: data.attendance,
      type: data.type,
      country: data.country || 'FR',
      streetNumber: path(['address', 'streetNumber'], data),
      addressLine1: path(['address', 'addressLine1'], data),
      addressLine2: path(['address', 'addressLine2'], data),
      addressLine3: path(['address', 'addressLine3'], data),
      city: path(['address', 'city'], data),
      zipCode: path(['address', 'zipCode'], data),
      thirdPartyKey: path(['thirdPartyRole', 'key'], data),
      thirdPartyName: path(['thirdPartyRole', 'name'], data),
      thirdPartyType: path(['thirdPartyRole', 'type'], data),
      lastThirdPartyKeyPerson:
        data.type === 'personal' ? path(['thirdPartyRole', 'key'], data) : '',
      lastThirdPartyKeyBusiness:
        data.type === 'business' ? path(['thirdPartyRole', 'key'], data) : '',
      involvedPartyResponsabilityKey: path(['involvedPartyResponsability', 'key'], data),
      typeOfVictimKey: path(['typeOfVictim', 'key'], data),
      typeOfVictimName: path(['typeOfVictim', 'value'], data),
      involvedPartyResponsabilityName: path(['involvedPartyResponsability', 'name'], data),
      subscriptionDate: data.subscriptionDate ? moment(data.subscriptionDate) : null,
      identityCode: data.identityCode,
      optOut: data.optOut,
      updatedAt: data.updatedAt,
      deletable: data.deletable,
    }
  }

  merge(data) {
    this.id = data.id
    this.firstName = data.firstName
    this.lastName = data.lastName
    this.additionalInformation = data.additionalInformation
    this.civility = data.civility
    this.companyEmailAddress = data.companyEmailAddress || null
    this.companyFax = data.companyFax || null
    this.companyName = data.companyName || null
    this.companyPhoneNumber = data.companyPhoneNumber || null
    this.emailAddress = data.emailAddress
    this.mobilePhone = data.mobilePhone
    this.phoneCode = data.phoneCode
    this.phoneNumber = data.phoneNumber
    this.professionalPhone = data.professionalPhone
    this.attendance = data.attendance
    this.type = data.type || 'personal'
    this.country = data.country
    this.streetNumber = data.streetNumber
    this.addressLine1 = data.addressLine1
    this.addressLine2 = data.addressLine2
    this.addressLine3 = data.addressLine3
    this.city = data.city
    this.zipCode = data.zipCode
    this.thirdPartyKey = data.thirdPartyKey
    this.thirdPartyName = data.thirdPartyName
    this.thirdPartyType = data.thirdPartyType
    this.lastThirdPartyKeyPerson = data.lastThirdPartyKeyPerson
    this.lastThirdPartyKeyBusiness = data.lastThirdPartyKeyBusiness
    this.involvedPartyResponsabilityKey = data.involvedPartyResponsabilityKey
    this.typeOfVictimKey = data.typeOfVictimKey
    this.typeOfVictimName = data.typeOfVictimName
    this.involvedPartyResponsabilityName = data.involvedPartyResponsabilityName
    this.subscriptionDate = data.subscriptionDate
    this.identityCode = data.identityCode
    this.optOut = data.optOut || false
    this.updatedAt = data.updatedAt
    this.deletable = data.deletable
  }

  setProperty(key, value) {
    switch (key) {
      case 'type':
        // handle default type for select input
        this.thirdPartyKey =
          this.type === 'personal' ? this.lastThirdPartyKeyBusiness : this.lastThirdPartyKeyPerson
        this.thirdPartyType = this.type === 'personal' ? 'business' : 'personal'
        this.thirdPartyName = findPartyName(
          this.thirdPartyKey,
          this.thirdPartyType === 'personal'
            ? CommonStore.partyInvolvedPersonalRoles
            : CommonStore.partyInvolvedBusinessRoles,
        )
        this.type = value
        break
      default:
        this[key] = value
    }
  }

  setRole(value) {
    this.thirdPartyKey = value
    let role = {}
    if (CommonStore.isCoporal) {
      if (this.partyType === 'personal') {
        role = CommonStore.partyInvolvedCoporalRoles.find(party => party.key === value)
        this.lastThirdPartyKeyPerson = value
      } else {
        role = CommonStore.partyInvolvedCoporalRoles.find(party => party.key === value)
        this.lastThirdPartyKeyBusiness = value
      }
    } else {
      if (this.partyType === 'personal') {
        role = CommonStore.partyInvolvedPersonalRoles.find(party => party.key === value)
        this.lastThirdPartyKeyPerson = value
      } else {
        role = CommonStore.partyInvolvedBusinessRoles.find(party => party.key === value)
        this.lastThirdPartyKeyBusiness = value
      }
    }
    console.log('role ::: ', role)
    this.thirdPartyKey = role.key
    this.thirdPartyName = role.name
    this.thirdPartyType = role.type
  }
  setResponsability(value) {
    const responsability = CommonStore.partyInvolvedResponsabilities.find(
      responsability => responsability.key === value,
    )
    this.involvedPartyResponsabilityKey = responsability.key
    this.involvedPartyResponsabilityName = responsability.name
  }

  setTypeOfVictime(value) {
    const typeOfVictim = CommonStore.partyInvolvedTypeOfVictims.find(
      typeOfVictim => typeOfVictim.key === value,
    )
    this.typeOfVictimKey = typeOfVictim.key
    this.typeOfVictimName = typeOfVictim.name
  }

  selectParty() {
    this.attendance = !this.attendance
  }

  selectCreateMission() {
    this.selectedCreateMission = !this.selectedCreateMission
  }

  restoreOriginalData() {
    this.merge(this.originalData)
  }

  onSelectAddress({
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    country,
    streetNumber,
    zipCode,
  }) {
    this.streetNumber = streetNumber
    this.addressLine1 = addressLine1
    this.addressLine2 = addressLine2
    this.addressLine3 = addressLine3
    this.zipCode = zipCode
    this.city = city
    this.country = country || 'FR'
  }

  checkErrors() {
    const requiredKeys = ['thirdPartyKey', 'involvedPartyResponsabilityKey']
    // Remove required for profesionnal
    if (this.partyType === 'personal') {
      requiredKeys.push('firstName')
      requiredKeys.push('lastName')
      requiredKeys.push('civility')
    }
    const value = i18next.t('common.requiredInputError')
    const errors = []

    requiredKeys.forEach(key => {
      if (!this[key]) errors.push({ key, value })
    })
    if (errors.length > 0) {
      this.errors = errors
      return false
    }
    if (!this.thirdPartyKey) {
      this.errors.push({ key: 'thirdPartyKey', value })
      return false
    }
    if (this.partyType !== 'personal' && !this.companyName) {
      this.errors.push({ key: 'companyName', value })
      return false
    }

    this.errors = []
    return true
  }

  setUnknownAddress() {
    if (this.isUnknownAddress) {
      this.addressLine1 = null
      this.zipCode = null
      this.city = null
    } else {
      this.addressLine1 = 'INCONNU'
      this.zipCode = '99999'
      this.city = '-'
    }
  }

  async save(url) {
    if (!this.checkErrors()) throw new Error('Form is invalid. (Some fields are required)')
    if (
      this.addressLine1 === null ||
      this.zipCode === null ||
      this.city === null ||
      this.addressLine1 === '' ||
      this.zipCode === '' ||
      this.city === ''
    ) {
      this.addressLine1 = 'INCONNU'
      this.zipCode = '99999'
      this.city = '-'
    }

    try {
      const res = await savePartyInvolved({
        url: this.id ? `${url}/${this.id}` : url,
        method: this.id ? 'patch' : 'post',
        partyInvolvedData: this.asJson,
      })
      runInAction(() => {
        this.id = res.data.id
        this.setOriginalData(res.data.attributes)
        this.updatedAt = res.data.attributes.updatedAt
      })
      return res
    } catch (err) {
      if (err.status === 422) {
        runInAction(() => {
          this.errors = err.errors
        })
      }
      throw err
    }
  }
}

const DecoratedPartyInvolved = decorate(PartyInvolved, {
  firstName: observable,
  lastName: observable,
  additionalInformation: observable,
  civility: observable,
  companyEmailAddress: observable,
  companyFax: observable,
  companyName: observable,
  companyPhoneNumber: observable,
  emailAddress: observable,
  mobilePhone: observable,
  phoneCode: observable,
  phoneNumber: observable,
  professionalPhone: observable,
  type: observable,
  country: observable,
  involvedPartyResponsabilityKey: observable,
  involvedPartyResponsabilityName: observable,
  typeOfVictimKey: observable,
  typeOfVictimName: observable,
  subscriptionDate: observable,
  identityCode: observable,
  thirdPartyKey: observable,
  thirdPartyName: observable,
  thirdPartyType: observable,
  streetNumber: observable,
  addressLine1: observable,
  addressLine2: observable,
  addressLine3: observable,
  city: observable,
  zipCode: observable,
  attendance: observable,
  optOut: observable,
  selectedCreateMission: observable,
  errors: observable,

  save: action,
  restoreOriginalData: action,
  selectCreateMission: action,
  selectParty: action,
  setResponsability: action,
  setTypeOfVictime: action,
  setRole: action,
  setProperty: action,
  merge: action,
  setOriginalData: action,
  onSelectAddress: action.bound,
  checkErrors: action,
  setUnknownAddress: action.bound,

  asJson: computed,
  asObject: computed,
  changed: computed,
  changedAttendance: computed,
  partyType: computed,
  asSelectOption: computed,
  isUnknownAddress: computed,
  isValid: computed,
  isInIRSI: computed,
})

export default DecoratedPartyInvolved
