import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { compose } from 'recompose'
import moment from 'moment'

import TimeSlot from './TimeSlot'
import Toolbar from './Toolbar'
import Loader from 'components/Loader/Loader'

const localizer = momentLocalizer(moment)

function Planner({ PlannerStore: { min, max, currentDate, changeWeek, loadingSchedule } }) {
  if (loadingSchedule) return <Loader />

  return (
    <Calendar
      className="calendar"
      step={15}
      date={currentDate}
      min={min}
      max={max}
      localizer={localizer}
      events={[]}
      defaultView="work_week"
      views={{
        work_week: true,
      }}
      startAccessor="start"
      endAccessor="end"
      components={{
        timeSlotWrapper: TimeSlot,
        toolbar: Toolbar,
      }}
      onNavigate={date => changeWeek(date)}
    />
  )
}

export default compose(
  inject('PlannerStore'),
  withTranslation(),
  observer,
)(Planner)
