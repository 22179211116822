import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { path, isNil } from 'ramda'

import CardLayout from 'components/CardLayout/CardLayout'
import { formatDate, concatAddress, formatPhone, isInspectionMission } from 'utils'
import Table from 'components/Table'
import FieldsBloc from 'components/FieldsBloc'
import CollapseRow from 'components/CollapseRow/CollapseRow'
import { formatCurrency } from 'utils/currency'
import Field from '../../components/Field'

const Claim = ({
  t,
  match: {
    params: { id: wan },
  },
  ClaimStore,
  ClaimStore: { claim: clm, loading },
  MissionStore,
  UserStore: { isExpert },
}) => {
  if (loading) return null

  const claim = isExpert ? MissionStore.cfa.cfi : clm
  const isIME = isExpert ? MissionStore.isIME : ClaimStore.isIME
  const previousClaimTable = {
    fields: ['previousClaimId', 'previousDisasterDate', 'previousPaidIndemnity'],
    values: path(['claimInformation', 'previousClaim'], claim) || [],
    headers: [
      t('mission.claim.previousClaimId'),
      t('mission.claim.previousDisasterDate'),
      t('mission.claim.paidIndemnity'),
    ],
    noValue: t('mission.claim.noClaimFound'),
  }

  const personsIdentified = {
    fields: isIME
      ? ['thirdPartyRole.name', 'nameByType', 'qualifyingValues', 'referenceValues']
      : ['thirdPartyRole.name', 'nameByType', 'secondaryThirdPartyRole'],
    values: path(['involvedParties'], claim) || [],
    headers: isIME
      ? [
          t('mission.claim.quality'),
          t('mission.claim.name'),
          t('mission.claim.qualifying'),
          t('mission.claim.reference'),
        ]
      : isInspectionMission(claim)
      ? [t('mission.claim.quality'), t('mission.claim.name'), t('mission.claim.precisions')]
      : [t('mission.claim.quality'), t('mission.claim.name'), t('mission.claim.codeIdentiy')],
    noValue: t('mission.claim.noPersonFound'),
  }

  const claimAmount = path(['claimInformation', 'claimAmount', 'amount'], claim)

  const coverageValue =
    path(['claimInformation', 'coverage', 'value'], claim) +
    (path(['claimInformation', 'eventType', 'value'], claim)
      ? ' (' + path(['claimInformation', 'eventType', 'value'], claim) + ')'
      : '')

  return (
    <CardLayout className="claim">
      <Field
        name={t('mission.claim.lossDate')}
        value={formatDate(path(['claimInformation', 'dateOfLoss'], claim))}
      />


      <Field
        name={t('mission.claim.id')}
        value={path(['claimInformation', 'insurerClaimId'], claim)}
      ></Field>
      {isInspectionMission(claim) && (
        <Field
          name={t('mission.claim.event')}
          value={path(['claimInformation', 'eventType', 'value'], claim)}
        ></Field>
      )}
      {isInspectionMission(claim) && (
        <Field
          name={t('mission.claim.claimNature')}
          value={path(['claimInformation', 'coverage', 'value'], claim)}
        ></Field>
      )}
      {!isInspectionMission(claim) && (
        <Field name={t('mission.claim.claimNature')} value={coverageValue}></Field>
      )}
      <FieldsBloc
        noTopDivider
        fields={[
          {
            name: t('mission.claim.guarantee'),
            value:
              path(['contract', 'guarantees[0]', 'guarantee', 'value'], claim) ||
              path(['contract', 'guarantees[0]', 'guaranteeStringName'], claim),
          },
          {
            name: t('mission.claim.amount'),
            value:
              claimAmount !== 0 && !isNil(claimAmount)
                ? formatCurrency(
                    claimAmount,
                    path(['claimInformation', 'claimAmount', 'currency'], claim),
                  )
                : null,
          },
          {
            name: isIME
              ? t('mission.claim.circumstances')
              : t('mission.claim.startingOriginDescription'),
            value: path(['claimInformation', 'startingOriginDescription'], claim),
          },
          {
            name: t('mission.claim.observedDammage'),
            value: path(['claimInformation', 'observedDammage'], claim),
          },
          {
            name: t('mission.claim.observations'),
            value: path(['claimInformation', 'observations'], claim),
          },
          {
            name: t('mission.claim.reclamation'),
            value: path(['contract', 'riskDescription', 'reclamation'], claim),
          },
          {
            name: t('mission.claim.reclamationToBeSpecified'),
            value: path(['claimInformation', 'claimTypeKey'], claim),
          },
          {
            name: t('mission.claim.darvaComments'),
            value: path(['contract', 'riskDescription', 'comment'], claim),
          },
        ]}
      />
      <Table
        title={t('mission.claim.previousClaimTitle')}
        {...previousClaimTable}
        classNames="mx-4"
      />
      {!isInspectionMission(claim) && (
        <Table
          title={t('mission.claim.personsIdentified')}
          {...personsIdentified}
          classNames="mx-4"
        />
      )}
      {isInspectionMission(claim) && (
        <Table title={t('mission.claim.personsToMeet')} {...personsIdentified} classNames="mx-4" />
      )}

      <FieldsBloc
        fields={[
          {
            name: t('mission.claim.victimQuality'),
            value: path(
              ['claimantInformation', 'victimInformation', 'corporalQuality', 'value'],
              claim,
            ),
          },
          {
            name: t('mission.claim.victimNumber'),
            value: path(['claimantInformation', 'victimInformation', 'victimNumber'], claim),
          },
          {
            name: t('mission.claim.victimAge'),
            value: path(['claimantInformation', 'victimInformation', 'age'], claim),
            unit: t('common.years'),
          },
          {
            name: t('mission.claim.refundRight'),
            value: path(
              ['claimantInformation', 'victimInformation', 'droitIndemnisationLibelle'],
              claim,
            ),
          },
          {
            name: t('mission.claim.refundRightRate'),
            value: path(
              ['claimantInformation', 'victimInformation', 'tauxDroitIndemnisation'],
              claim,
            ),
          },
          {
            name: t('mission.claim.sexe'),
            value: path(['claimantInformation', 'claimant', 'civility'], claim),
          },
        ]}
      />
      <div className="divider" />
      <div className="row">
        <div className="font-weight-bold">{t('mission.claim.managerIdentificationTitle')}</div>
      </div>
      <CollapseRow
        rowLabel={t('mission.claim.name')}
        rowValue={`${path(['contract', 'salesAgent', 'firstName'], claim) || ''} ${path(
          ['contract', 'salesAgent', 'lastName'],
          claim,
        ) || ''}`}
        rows={[
          {
            key: t('mission.contact.address'),
            val: concatAddress(path(['contract', 'salesAgent', 'address'], claim)) || '-',
          },
          {
            key: t('mission.contact.phoneNumber'),
            val: formatPhone(path(['contract', 'salesAgent', 'phoneNumber'], claim)) || '-',
          },
          {
            key: t('mission.contact.email'),
            val: path(['contract', 'salesAgent', 'email'], claim) || '-',
          },
          {
            key: t('mission.claim.ug'),
            val: path(['contract', 'salesAgent', 'cde'], claim) || '-',
          },
        ]}
      />
      <FieldsBloc
        title={t('mission.claim.additionalInformation')}
        fields={[
          {
            name: t('mission.claim.numberOfAffectedParts'),
            value: path(['claimInformation', 'numberOfDamagedRooms'], claim),
          },
          {
            name: t('mission.claim.comment'),
            value: path(['comment'], claim),
          },
        ]}
      />
    </CardLayout>
  )
}

export default withTranslation()(inject('ClaimStore', 'UserStore', 'MissionStore')(observer(Claim)))
