import React from 'react'
import { observer } from 'mobx-react'
import { TwitterPicker } from 'react-color'

import DrawCtrl from 'stores/Mission/view/DrawCtrl'

const DrawColorPicker = () => {
  if (!DrawCtrl.colorPickerOpen) return null
  return (
    <div className="color-picker-mob">
      <TwitterPicker
        colors={[
          '#e6194b',
          '#3cb44b',
          '#ffe119',
          '#0082c8',
          '#f58231',
          '#ffffff',
          '#f032e6',
          '#aa6e28',
          '#000000',
        ]}
        width={135}
        height={275}
        triangle={'hide'}
        onChange={color => {
          DrawCtrl.setColor(color.hex)
        }}
      />
    </div>
  )
}

export default observer(DrawColorPicker)
