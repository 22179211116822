import React from 'react'
import classNames from 'classnames'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'

import './Login.css'
import PageLayout from 'components/PageLayout/PageLayout'
import Button from 'components/Button/Button'
import LoginCtrl from 'stores/Common/view/LoginCtrl'
import CookieDisclaimer from 'components/CookieDisclaimer/CookieDisclaimer'
import ChangePasswordModal from './ChangePasswordModal'
import ForgottenPasswordModal from './ForgottenPasswordModal'
import CustomerId from 'components/Forms/CustomerId'
import packageJson from '../../../package.json'
import { getBuildDate } from '../../services/utils'

class LoginPage extends React.Component {
  componentDidMount() {
    document.body.classList.add('login-background')
  }

  componentWillUnmount() {
    document.body.classList.remove('login-background')
  }

  onChange = e => LoginCtrl.setProperty(e.target.name, e.target.value)
  onChangeCustomerId = e =>
    LoginCtrl.setProperty(e.target.name, e.target.value.replace(/\s/g, '').toUpperCase())
  onSubmit = e => {
    e.preventDefault()
    LoginCtrl.authentification()
  }
  onEnterKey = e => {
    if (e.keyCode === 13 && LoginCtrl.verifyForm) this.onSubmit(e)
  }
  showForgottenPassword = e => {
    e.preventDefault()
    LoginCtrl.setProperty('showModalForgottenPassword', true)
  }

  render() {
    const { customerId, userName, password, verifyForm, loading } = LoginCtrl
    const {
      t,
      UiCtrl: { apiVersion },
    } = this.props

    return (
      <div>
        <div className="login-page test-reload text-center">
          <form
            autoComplete="on"
            className="login-form"
            name="loginForm"
            onKeyDown={this.onEnterKey}
          >
            <CustomerId
              name="customerId"
              onChange={this.onChangeCustomerId}
              value={customerId}
              placeholder={t('loginPage.customerId')}
            />
            <input
              className="form-control"
              name="userName"
              placeholder={t('loginPage.userName')}
              type="text"
              value={userName}
              onChange={this.onChange}
              maxLength="64"
              autoComplete={'username'}
            />
            <input
              className="form-control"
              name="password"
              placeholder={t('loginPage.password')}
              type="password"
              value={password}
              onChange={this.onChange}
              maxLength="64"
              autoComplete={'current-password'}
            />

            <Button
              className={classNames('btn btn-primary login-btn btn-block')}
              onClick={this.onSubmit}
              disabled={!verifyForm || loading}
              loading={loading}
            >
              {t('loginPage.connect')}
            </Button>
            <Button
              onClick={this.showForgottenPassword}
              className="mt-2 btn btn-secondary btn-sm forgotten-password-btn"
            >
              {t('loginPage.forgottenPassword')}
            </Button>
          </form>

          <small className="text-white">
            {t('loginPage.sps')} : {process.env.REACT_APP_TAG ? process.env.REACT_APP_TAG : '-'} /{' '}
          </small>

          <small className="text-white">
            {t('loginPage.api')} : {apiVersion ? apiVersion : '-'}
          </small>

          <small className="text-white">
            <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
          </small>
        </div>

        <ChangePasswordModal />

        <ForgottenPasswordModal />

        <CookieDisclaimer />
      </div>
    )
  }
}

export default compose(inject('UiCtrl'), PageLayout, observer)(LoginPage)
