import React, { Fragment } from 'react'
import { inject } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import MissionButtons from 'pages/Mission/components/MissionButtons'
import SmartLink from 'components/SmartLink/SmartLink'
import GenerateReportLink from './GenerateReportLink'
import ChocVTAM from './ChocVTAM'
import VolDeContenu from './VolDeContenu'
import Temoignage from './Temoignage'
import ConfigurationDesLieux from './ConfigurationDesLieux'
import AutreMotif from './AutreMotif'
import CommunicationIncendie from './CommunicationIncendie'
import Corporel from './Corporel'

class MissionDesktop extends React.Component {
  render() {
    const {
      menu,
      t,
      MissionStore: {
        isHorsDarva,
        chocVTAMMenu,
        communicationINCENDIEMenu,
        volDeCONTENUMenu,
        temoignageMenu,
        configurationDesLieuxMenu,
        autreMotifMenu,
        missionDivers,
        id = useParams(),
        missionCorporel,
      },
      MessagingStore: { notifications },
    } = this.props

    const drawGroup = group => {
      let title = ''
      if ((group === 'Evaluation' || group === 'Report') && isHorsDarva) {
        menu[group] = []
      } else {
        title = (
          <h5 className="mt-4 mb-4" key={group}>
            {t('mission.sideNav.title' + group)}
          </h5>
        )
      }
      return title
    }

    const drawOption = (group, option) => {
      let translationPath = option === 'Messaging' ? 'messaging.title' : 'mission.sideNav.' + option
      let link = ''
      if (group === 'Evaluation' || group === 'Report') {
        if (!isHorsDarva) {
          group === 'Report'
            ? (link = (
                <div className="nav flex-column nav-pills" key={option}>
                  <GenerateReportLink />
                </div>
              ))
            : (link = (
                <div className="nav flex-column nav-pills" key={option}>
                  <SmartLink page={option}>{t('mission.sideNav.' + option)}</SmartLink>
                </div>
              ))
        }
      } else {
        link = (
          <div className="nav flex-column nav-pills" key={option}>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={`/mission/${id}/${option}`}
              key={option}
            >
              {t(translationPath)}
              {option === 'Messaging' && notifications > 0 ? (
                <span className="badge badge-danger ml-1" key={group}>
                  {notifications}
                </span>
              ) : (
                ''
              )}
            </NavLink>
          </div>
        )
      }
      return link
    }

    const drawReportEvaluation = () => {
      let link = (
        <Fragment>
          {missionDivers && chocVTAMMenu && <ChocVTAM type="D" />}
          {missionDivers && volDeCONTENUMenu && <VolDeContenu type="D" />}
          {missionDivers && temoignageMenu && <Temoignage type="D" />}
          {missionDivers && configurationDesLieuxMenu && <ConfigurationDesLieux type="D" />}
          {missionDivers && autreMotifMenu && <AutreMotif type="D" />}
          {missionDivers && communicationINCENDIEMenu && <CommunicationIncendie type="D" />}
          {missionCorporel && <Corporel type="D" />}
        </Fragment>
      )
      return link
    }

    return (
      <Fragment>
        {Object.keys(menu).map((group, i) => [
          drawGroup(group),
          <Fragment key={i}>{menu[group].map(option => drawOption(group, option, i))}</Fragment>,
        ])}
        {isHorsDarva && drawReportEvaluation()}
        <div className="mt-4 justify-content-between">
          <MissionButtons />
        </div>
      </Fragment>
    )
  }
}

export default compose(
  inject('MissionStore', 'MessagingStore', 'PrejudiceStore', 'UserStore'),
  withTranslation(),
)(MissionDesktop)
