import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import DetailPackageCtrl from 'stores/Mission/view/DetailPackageCtrl'
import Tooltip from 'components/Tooltip/Tooltip'
import { euro } from 'utils/currency'

export default withTranslation()(
  observer(({ t }) => {
    const { warningMinPrice, minPrice } = DetailPackageCtrl.packageData

    if (!warningMinPrice) return null

    return (
      <Tooltip
        className="d-inline-block"
        placement="top"
        clickable
        text={`${t('mission.calculation.detailPackage.minPriceWarning')} : ${euro(minPrice)}`}
      >
        <i className="fa fa-exclamation-triangle text-warning clickable pr-2" />
      </Tooltip>
    )
  }),
)
