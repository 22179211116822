import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { add } from 'ramda'
import PropTypes from 'prop-types'

import './Covering.css'
import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import Dropdown from '../components/Dropdown'
import Tabs from '../components/Tabs'
import RoomModal from './RoomModal'
import CurrentRoom from './CurrentRoom'

const PropertyEmbellishment = ({
  className,
  store,
  CartStore: { propertyPackages, embellishmentPackages },
  ManagerClaimStore: { isClaimClosed },
  MissionStore: { isMissionClosed },
}) => {
  const [showNewForm, setShowNewForm] = useState(false)
  const { t } = useTranslation()
  const { type } = useParams()
  const { listRooms, selectedRoom, setProperty, currentRCP, deleteRoom } = PropertyEmbellishmentCtrl
  const total = [...propertyPackages, ...embellishmentPackages].reduce(
    (acc, data) => add(acc, data.finalPrice),
    0,
  )
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className={className}>
      <RoomModal showNewForm={showNewForm} setShowNewForm={setShowNewForm} />

      <Dropdown
        title={`${t('mission.calculation.cart.titleProperty')} / ${t(
          'mission.calculation.cart.titleEmbellishment',
        )}`}
        priceType={`${t('mission.calculation.cart.totalWithVAT')} :`}
        total={total}
        showDropdown
        showLabel
      >
        {!isClosed && (
          <Tabs
            addText={t('mission.calculation.createPackage.addLocalisation')}
            list={listRooms}
            listKey="id"
            selected={selectedRoom}
            onSelect={cde => {
              setShowNewForm(false)
              setProperty('selectedRoom', cde)
            }}
            add={() => setProperty('showRoomModal', true)}
            showConfirmButton={currentRCP.length > 0}
            showDeleteAction={!showNewForm}
            deleteTab={deleteRoom}
            disabled={isClosed}
          />
        )}

        <CurrentRoom
          showNewForm={showNewForm}
          setShowNewForm={setShowNewForm}
          store={store}
          isClosed={isClosed}
        />
      </Dropdown>
    </div>
  )
}

PropertyEmbellishment.defaultProps = {
  store: null,
}

PropertyEmbellishment.propTypes = {
  store: PropTypes.object.isRequired,
}

export default inject(
  'CartStore',
  'ManagerClaimStore',
  'MissionStore',
)(observer(PropertyEmbellishment))
