import request from 'services/httpRequest'

export const fetchMessages = async ({ wan = null, filter = null, pageSize = 'all' }) => {
  const url = `/messages/v1/${wan || ''}`

  const params = { 'page[size]': pageSize }
  if (filter) params[`filter[${filter.key}]`] = String(filter.value)

  const { meta, data: messages } = await request({ method: 'GET', url, params })
  return { meta, data: messages.map(({ attributes }) => attributes) }
}

export const fetchNotifications = async ({ wan = null, filter = null, pageSize = 'all' }) => {
  const url = `/messages/v1/notifications/${wan || ''}`

  const params = { 'page[size]': pageSize }
  if (filter) params[`filter[${filter.key}]`] = String(filter.value)

  const { meta, data: messages } = await request({ method: 'GET', url, params })
  return { meta, data: messages.map(({ attributes }) => attributes) }
}

export const fetchInboxMessages = async (filters, page) => {
  const params = filters
  params['page[number]'] = page

  const { meta, data } = await request({
    method: 'GET',
    url: '/messages/v1/inbox',
    params,
  })

  return {
    meta,
    messages: data.map(({ attributes }) => attributes),
  }
}

export const fetchContexts = async (cfiWan = null) => {
  let url = '/messages/v1/contexts/'

  if (cfiWan) url += cfiWan
  try {
    const res = await request({
      method: 'GET',
      url,
    })
    return res.data.attributes
  } catch (err) {
    throw err
  }
}

export const fetchContextsMissionInspection = async (cfaWan = null) => {
  let url = '/messages/v1/inspection/contexts/'

  if (cfaWan) url += cfaWan
  try {
    const res = await request({
      method: 'GET',
      url,
    })
    return res.data.attributes
  } catch (err) {
    throw err
  }
}

export const fetchCfmContexts = async cfmWan => {
  let url = `/messages/v1/claimFileManager/contexts/${cfmWan}`

  try {
    const res = await request({
      method: 'GET',
      url,
    })
    return res.data.attributes
  } catch (err) {
    throw err
  }
}

export const postMessage = async (context, message) => {
  try {
    await request({
      method: 'POST',
      url: `/messages/v1/send/${context}`,
      data: { data: { type: 'message', attributes: message } },
    })
  } catch (err) {
    throw err
  }
}

export const postMarkAsRead = async messageId => {
  try {
    const res = await request({ method: 'POST', url: `/messages/v1/mark-as-read/${messageId}` })
    return res
  } catch (error) {
    throw error
  }
}
export const postBadinterDisableAlert = async messageId => {
  try {
    const res = await request({
      method: 'POST',
      url: `/messages/v1/badinter-disable-alert/${messageId}`,
    })
    return res
  } catch (error) {
    throw error
  }
}
export const postSDR63 = async (wan, attributes) => {
  try {
    return request({
      method: 'post',
      url: `/claimFiles/v1/claimFileAssessor/${wan}/sdr63`,
      data: {
        data: {
          type: 'sdrChiffrage',
          attributes,
        },
      },
    })
  } catch (error) {
    throw error
  }
}

export const duplicateAttachments = async ({ url, attachmentIds }) => {
  try {
    const res = await request({
      method: 'POST',
      url,
      data: { data: { type: 'DocumentCollectionType', attributes: { items: attachmentIds } } },
    })

    return res
  } catch (err) {
    throw err
  }
}
