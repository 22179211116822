import React from 'react'
import { inject, observer } from 'mobx-react'

import InputPrice from 'components/InputPrice/InputPrice'

const InputPriceWrapper = ({ inputData, CartStore }) => {
  const onChange = e => {
    inputData.setProperty('value', e.target.value)
  }

  if (inputData.name === 'workAmount') {
    inputData.setProperty('value', CartStore.workAmount)
  }

  return <InputPrice {...inputData} className="ml-3" price={inputData.value} onChange={onChange} />
}

export default inject('CartStore')(observer(InputPriceWrapper))
