import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import Firm from './Firm'
import Button from 'components/Button/Button'

function ListFirm({ t, OverrunStore: { firms, selectedFirm, setProperty } }) {
  if (firms.length === 0) return <div className="mb-3">{t('claim.overrun.noFirm')}</div>
  return (
    <React.Fragment>
      {firms.map(firm => (
        <Firm key={firm.id} data={firm} />
      ))}

      <Button
        className="btn btn-success btn-block mt-3 mb-3"
        onClick={() => {
          setProperty('showConfirmFirmModal', true)
        }}
        disabled={selectedFirm === ''}
      >
        {t('claim.overrun.assign')}
      </Button>
    </React.Fragment>
  )
}

export default compose(
  inject('OverrunStore'),
  withTranslation(),
  observer,
)(ListFirm)
