import React from 'react'
import { withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

const AISection = ({ t, sd }) => {
  if (sd.visionResults === null) return null

  return (
    <div className="ai-wrapper">
      {sd.visionResults && sd.visionResults.pagesWithMatchingImages ? (
        <div>
          <div className="fraud-div">
            <i className="fa fa-exclamation-triangle" />{' '}
            {t('mission.supportingDocuments.ai.fraudAlertTitle')}
          </div>
          <div className="fraud-div-results">
            <ul>
              {sd.visionResults.pagesWithMatchingImages.map((value, key) => {
                return key < 3 ? (
                  <li key={`link-to-fraud-${key}`}>
                    <a href={value.url} target="_blank" rel="noopener noreferrer">
                      {t('mission.supportingDocuments.ai.linkToFraud', { count: key + 1 })}
                    </a>
                  </li>
                ) : null
              })}
            </ul>
          </div>
        </div>
      ) : null}

      {sd.visionAlerts && sd.visionAlerts.includes('DISTANCE ALERT') ? (
        <div>
          <div
            className="fraud-div clickable"
            onClick={() => sd.setProperty('showLocationPhoto', !sd.showLocationPhoto)}
          >
            <i className="fa fa-exclamation-triangle" />{' '}
            {t('mission.supportingDocuments.ai.distanceAlertTitle')} <i className="fa fa-map" />
          </div>
          <div className="fraud-div-results">
            {t('mission.supportingDocuments.ai.distanceAlertText', {
              distance: Math.round(sd.extraData.distanceBetween / 1000),
            })}
          </div>
        </div>
      ) : null}

      {sd.visionResults &&
      sd.visionResults.safeSearchAnnotation &&
      (sd.visionResults.safeSearchAnnotation.adult === 'VERY_LIKELY' ||
        sd.visionResults.safeSearchAnnotation.adult === 'LIKELY') ? (
        <div className="adult-div">
          <i className="fa fa-thermometer-empty" />{' '}
          {t('mission.supportingDocuments.ai.adultAlertTitle')}
        </div>
      ) : null}

      {sd.visionResults && sd.visionResults.faceAnnotations ? (
        <div className="face-div">
          <i className="fa fa-info-circle " />{' '}
          {t('mission.supportingDocuments.ai.faceCount', {
            count: sd.visionResults.faceAnnotations.nb,
          })}
          <br />
          {t('mission.supportingDocuments.ai.faceQuestion')}
        </div>
      ) : null}

      {sd.visionResults && sd.visionResults.labelAnnotations ? (
        <div className="label-div">
          {t('mission.supportingDocuments.ai.labelsTitle')} :<br />
          <ul>
            {sd.visionResults.labelAnnotations.map((label, index) => {
              return (
                <li style={{ textTransform: 'capitalize' }} key={`label-description-${index}`}>
                  {label.description} ({Math.round(label.score * 100)}%)
                </li>
              )
            })}
          </ul>
        </div>
      ) : null}

      {sd.visionResults && sd.visionResults.webEntities ? (
        <div className="label-div">
          {t('mission.supportingDocuments.ai.webEntitiesTitle')} :<br />
          <ul>
            {sd.visionResults.webEntities.map((label, index) => {
              return index < 3 ? (
                <li style={{ textTransform: 'capitalize' }} key={`label-description-${index}`}>
                  {label.description} ({Math.round(label.score * 100)})
                </li>
              ) : null
            })}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

export default withTranslation()(observer(AISection))
