import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { compose } from 'recompose'

import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import TextArea from 'components/Forms/TextArea'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import SupportingDocumentLite from 'pages/Mission/pages/Calculation/SupportingDocumentLite'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import RecapRowLayout from 'pages/Mission/pages/Calculation/DetailPackage/RecapRowLayout'
import InputPrice from 'components/InputPrice/InputPrice'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import { LabelVAT } from 'pages/Mission/pages/Calculation/DetailPackage/RecapComponents'
import LastUpdatedAt from 'pages/Mission/components/LastUpdatedAt'
import SelectInvolvedParty from 'pages/Mission/pages/Calculation/SelectInvolvedParty'

import { euro } from 'utils/currency'

const AddRelatedCost = ({
  t,
  history,
  match: {
    params: { id, type },
  },
  CartStore: { isVentilationIPAvailable, addRelatedCost },
  MissionStore: { isIRSI },
}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {
    context,
    relatedCost,
    setRelatedCost,
    overflowType,
    noGuaranteeType,
    relatedCostType,
    irsiRC,
  } = AddRelatedCostCtrl
  const goToCart = () => history.push(`/${type}/${id}/calculation/listPackage`)
  const addSupportingDocument = id => AddRelatedCostCtrl.relatedCost.addSupportingDocument(id)
  const onChange = e => AddRelatedCostCtrl.relatedCost.setProperty(e.target.name, e.target.value)
  const addRelatedCostToCart = () => {
    addRelatedCost(AddRelatedCostCtrl.relatedCost)
    history.push(`/${type}/${id}/calculation/listPackage`)
  }

  if (relatedCost === null) return <Redirect to={`/${type}/${id}/calculation/listPackage`} />

  const {
    cde,
    description,
    priceWithoutVAT,
    priceWithVAT,
    vat,
    totalVAT,
    supportingDocuments,
    validRelatedCost,
    packageType,
    involvedParty,
  } = relatedCost

  let optionsType = []
  switch (packageType) {
    case 'relatedCost':
      optionsType = isIRSI ? [...relatedCostType, ...irsiRC] : relatedCostType
      break
    case 'overflow':
      optionsType = overflowType
      break
    case 'noGuarantee':
      optionsType = noGuaranteeType
      break
    default:
      optionsType = []
  }

  const onChangeInvolvedParty = e => {
    AddRelatedCostCtrl.relatedCost.setProperty(e.target.name, e.target.value)
    setRelatedCost(cde, e.target.value, optionsType, packageType)
  }

  const onChangeCde = e => {
    setRelatedCost(e.target.value, involvedParty, optionsType, packageType)
  }

  return (
    <CardLayout className="add-furniture">
      <div className="container pl-0 pr-0">
        <div className="row no-gutters mb-1">
          <div className="col-10 col-sm-10 col-md-9">
            <Breadcrumb />
          </div>
          <div className="col-2 col-md-3 text-right">
            <button className="btn btn-outline-primary" onClick={goToCart}>
              <i className="fa fa-calculator" />
              <span className="d-none d-none-sm d-none-md d-lg-inline">
                &nbsp;
                {t('mission.calculation.detailPackage.backToCart')}
              </span>
            </button>
          </div>
        </div>
        {isVentilationIPAvailable && packageType === 'relatedCost' && (
          <div className="row no-gutters mb-3">
            <div className="col-6">
              <Label>{t('mission.calculation.involvedParty.concerned')}</Label>
              <SelectInvolvedParty onChange={onChangeInvolvedParty} value={involvedParty} />
            </div>
          </div>
        )}

        <div className="row no-gutters mb-3">
          <div className="col-12">
            <Label required>{t('mission.calculation.relatedCost.type')}</Label>
            <ResponsiveSelect
              name="cde"
              isSearchable
              onChange={onChangeCde}
              value={cde}
              options={optionsType}
            />
          </div>
        </div>

        <div className="row no-gutters mb-3">
          <div className="col-12">
            <Label required>{t('mission.calculation.furniture.description')} </Label>
            <TextArea
              className="form-control"
              name="description"
              value={description}
              onChange={onChange}
              errors={[]}
            />
          </div>
        </div>

        <div className="row no-gutters mb-3">
          <div className="col-6">
            <SupportingDocumentLite
              supportingDocuments={supportingDocuments}
              addSupportingDocument={addSupportingDocument}
            />
          </div>

          <div className="recap col-6">
            <RecapRowLayout
              packageLayout={false}
              labelComponent={
                <span className="recap-label">
                  {t('mission.calculation.relatedCost.totalWithoutVAT')}
                </span>
              }
              priceComponent={
                <InputPrice
                  name="priceWithoutVAT"
                  price={priceWithoutVAT}
                  classNamesForPrice="font-weight-bold text-primary"
                  disableShowUnit
                  maxLengthNumber={6}
                  onChange={onChange}
                />
              }
            />

            <RecapRowLayout
              packageLayout={false}
              labelComponent={<LabelVAT packageData={{ vat }} onChange={onChange} />}
              priceComponent={euro(totalVAT)}
            />

            <RecapRowLayout
              packageLayout={false}
              labelComponent={
                <span className="recap-label">{t('mission.calculation.cart.totalWithVAT')}</span>
              }
              priceComponent={
                <InputPrice
                  name="priceWithVAT"
                  price={priceWithVAT}
                  classNamesForPrice="font-weight-bold text-primary"
                  disableShowUnit
                  maxLengthNumber={6}
                  onChange={onChange}
                />
              }
            />
          </div>
        </div>

        {context === 'new' && (
          <div className="row justify-content-center">
            <Button
              className="btn btn-primary"
              onClick={addRelatedCostToCart}
              disabled={!validRelatedCost}
            >
              {t('mission.calculation.relatedCost.addBtn')}{' '}
              {t(
                `mission.calculation.relatedCost.add${packageType.charAt(0).toUpperCase() +
                  packageType.slice(1)}`,
              )}
            </Button>
          </div>
        )}

        {context !== 'new' && <LastUpdatedAt />}
      </div>
    </CardLayout>
  )
}

export default compose(
  inject('CartStore', 'MissionStore'),
  withTranslation(),
  observer,
)(AddRelatedCost)
