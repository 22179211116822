import { observable, action, computed, decorate, runInAction } from 'mobx'

import { setDataLocal, getDataLocal } from 'stores/localStorage'
import { getTransparentLink } from 'services/report'

class MissionHeaderCtrl {
  modalStatus = false
  headerInfo = null
  reasonValue = ''
  showGallery = false
  showExtDarvaBtn = false
  loadingExtDarvaLink = false
  lastTimeKeyDown = new Date()
  lastTypeKeyCodes = []

  constructor() {
    this.headerInfo = getDataLocal('headerInfo')
    this.showGallery = getDataLocal('mission-header-show-gallery')
  }

  changeMissionReason(value) {
    this.reasonValue = value
  }

  get isValidMissionReason() {
    return this.reasonValue.length > 0
  }

  toggleModal(status) {
    this.modalStatus = status
  }

  setProperty(key, value) {
    this[key] = value
    if (key === 'headerInfo') {
      setDataLocal(key, value)
    } else if (key === 'showGallery') {
      setDataLocal('mission-header-show-gallery', value)
    }
  }

  checkExtranetDarva = async (wan, wanType = 'cfa') => {
    try {
      this.loadingExtDarvaLink = true
      const res = await getTransparentLink(wan, wanType)
      runInAction(() => {
        this.showExtDarvaBtn = false
        this.loadingExtDarvaLink = false
      })
      window.open(res.link, '_blank')
    } catch (err) {
      console.log(err)
    }
  }

  handleKeyDown = e => {
    const now = new Date()
    if (now - this.lastTimeKeyDown > 1000) {
      this.lastTypeKeyCodes = []
    }

    this.lastTimeKeyDown = now
    const keyCode = e.keyCode
    this.lastTypeKeyCodes.push(keyCode)

    if (this.lastTypeKeyCodes.length >= 10) {
      // konami cheat code Up, Up, Down, Down, Left, Right, Left, Right, B, A
      const konami = this.lastTypeKeyCodes.slice(-10).join('-')
      if (konami === '38-38-40-40-37-39-37-39-66-65') {
        this.showExtDarvaBtn = true
      }
    }
  }
}

const DecoratedMissionHeaderCtrl = decorate(MissionHeaderCtrl, {
  modalStatus: observable,
  headerInfo: observable,
  reasonValue: observable,
  showGallery: observable,
  showExtDarvaBtn: observable,
  loadingExtDarvaLink: observable,
  showSyncDarvaBtn: observable,

  changeMissionReason: action,
  toggleModal: action,
  setProperty: action,
  handleKeyDown: action,
  checkExtranetDarva: action,

  isValidMissionReason: computed,
})

export default new DecoratedMissionHeaderCtrl()
