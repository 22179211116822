import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { path } from 'ramda'
import { withRouter } from 'react-router-dom'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import Label from 'components/Label/Label'
import TextArea from 'components/Forms/TextArea'
import { concatAddress } from 'utils'
import { euro } from 'utils/currency'
import classNames from 'classnames'
import DatePicker from 'components/Forms/DatePicker'
import PhoneNumber from 'components/Forms/PhoneNumber'

const ConfirmFirm = ({
  t,
  ManagerClaimStore: { claim },
  PartyInvolvedStore: { findMobilePhone },
  LastBillCtrl: {
    showConfirmFirmModal,
    assignationComment,
    insuredPhoneNumber,
    setProperty,
    currentJob,
    assigning,
    sendJob,
    dueDate,
  },
}) => {
  const [grabbing, setGrabbing] = useState(false)

  const closeModal = () => {
    setProperty('showConfirmFirmModal', false)
    setProperty('assignationComment', '')
  }

  const onChangeComment = e => {
    setProperty('assignationComment', e.target.value)
  }

  const onChangeDueDate = date => {
    setProperty('dueDate', date)
  }

  const onChangeInsuredPhoneNumber = phoneNumber => {
    setProperty('insuredPhoneNumber', phoneNumber)
  }

  const { calculationData } = currentJob

  const insuredId = path(['insuredInformation', 'involvedPartyId'], claim)

  const insuredHasMobilePhoneNumber = !!findMobilePhone(insuredId)

  return (
    <ModalWithBackdrop
      size="modal-md"
      show={showConfirmFirmModal}
      close={closeModal}
      draggable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">{t('lastbill.confirmFirmTitle')}</h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <p className="text-primary font-weight-bold">{t('lastbill.reparationType')}</p>

        {calculationData.map((calculation, idx) => (
          <div key={`${currentJob.id}-${idx}`} className="row no-gutters">
            <div className="col-9">{calculation.label}</div>
            <div className="col-3 btn btn-outline-primary selected-price">
              {euro(calculation.totalWithVAT)}
            </div>
          </div>
        ))}

        <div className="font-weight-bold mb-2 mt-4">
          {t('lastbill.addressOfLoss')} :{' '}
          {concatAddress(path(['claimInformation', 'addressOfLoss'], claim))}
        </div>

        <div className="form-group row mt-2">
          <Label htmlFor="dueDate" className="col-4 col-form-label" required>
            {t('lastbill.dueDate')}
          </Label>
          <div className="col-8 date-picker-modal">
            <DatePicker
              className="form-control"
              name="dueDate"
              dateFormat="dd/MM/y HH:mm"
              showTimeSelect
              minDate={new Date()}
              onChange={onChangeDueDate}
              selected={dueDate}
              timeIntervals={15}
            />
          </div>
        </div>

        {!insuredHasMobilePhoneNumber && (
          <div className="form-group row mt-2">
            <Label htmlFor="dueDate" className="col-4 col-form-label" required>
              {t('lastbill.phoneNumber')}
            </Label>
            <div className="col-8">
              <PhoneNumber
                className="form-control"
                onChange={onChangeInsuredPhoneNumber}
                value={insuredPhoneNumber}
                country="FR"
                autoComplete="off"
              />
            </div>
          </div>
        )}

        <div>
          <Label required>{t('lastbill.assignationComment')}</Label>
          <TextArea
            className="form-control"
            name="assignationComment"
            value={assignationComment}
            onChange={onChangeComment}
          />
        </div>
      </div>

      <div className="modal-footer pb-2 pt-2">
        <Button
          className="btn btn-secondary"
          data-cy="cancel"
          onClick={closeModal}
          disabled={assigning}
        >
          {t('common.cancel')}
        </Button>

        <Button
          className="btn btn-primary"
          data-cy="submit"
          onClick={sendJob}
          disabled={
            assigning ||
            assignationComment.length === 0 ||
            !dueDate ||
            (!insuredHasMobilePhoneNumber && !insuredPhoneNumber)
          }
          loading={assigning}
        >
          {t('claim.planner.confirmExpert.confirm')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  inject('ManagerClaimStore', 'LastBillCtrl', 'PartyInvolvedStore'),
  withRouter,
  withTranslation(),
  observer,
)(ConfirmFirm)
