import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import ImageLoader from 'react-load-image'
import classNames from 'classnames'

import './SDItem.css'
import { baseUrl } from 'services/utils'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import FallBackThumbnail from 'components/FallBackThumbnail/FallBackThumbnail'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import Info from './Info'

const SDItem = ({
  sdItem,
  UserStore: { token },
  onSelectItem,
  deleteItem,
  thumbnailSize,
  selected = false,
  canDelete = false,
  showInfo = true,
}) => {
  const id = sdItem.id ? sdItem.id : sdItem

  return (
    <div className={classNames({ 'small-item': thumbnailSize === 'small' })}>
      <div className="sd-item col-lg-3 col-md-4 col-xs-6" onClick={onSelectItem}>
        {canDelete && (
          <button className="btn btn-circle delete-icon" onClick={deleteItem}>
            <i className="fa fa-trash clickable" />
          </button>
        )}

        <ImageLoader
          className={classNames({ 'sd-item-selected': selected })}
          src={`${baseUrl()}/supportingDocuments/v1/${id}/thumbnail?access_token=${token}`}
          onLoad={() => {
            SupportingDocumentsCtrl.setImagesLoaded(id)
          }}
          onError={() => {
            SupportingDocumentsCtrl.setImagesLoaded(id)
          }}
        >
          <img
            className={classNames({ 'sd-item-selected': selected })}
            alt={'supporting document'}
          />
          <div>
            <FallBackThumbnail mimeType={sdItem.mimeType} />
          </div>
          <ImageLoading />
        </ImageLoader>

        {showInfo && <Info sdItem={sdItem} />}
      </div>
    </div>
  )
}

export default compose(
  inject('UserStore'),
  observer,
)(SDItem)
