import { observable, action, decorate, runInAction, computed } from 'mobx'
import { path, isNil } from 'ramda'

import CartStore from 'stores/Mission/domain/CartStore'
import ManagerClaimStore from 'stores/ManagerClaim/domain/ManagerClaimStore'
import { fetchInstruction, saveInstruction } from 'services/claimManagerForm'
import Form from 'utils/dynform/Form'
import { fetchManagerClaim } from 'services/managerClaim'

class InstructionCtrl {
  loading = false
  form = null
  irsiRuleHandler = null

  get isFranchiseMustBeDeducted() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('isFranchiseMustBeDeducted')
    return isNil(inputData) ? false : inputData.value
  }

  get franchiseMustBeDeducted() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('franchiseMustBeDeducted')
    return isNil(inputData) ? false : inputData.value
  }

  get isAspInvoice() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('presenceInvoiceOrQuote')
    return isNil(inputData) ? false : inputData.value === 'INVOICE'
  }

  get presenceInvoiceOrQuote() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('presenceInvoiceOrQuote')
    return isNil(inputData) ? false : inputData.value
  }

  get aspAmount() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('aspAmount')
    return isNil(inputData) ? false : inputData.value
  }

  get isAspAdvanceAlreadyPaid() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('isAspAdvanceAlreadyPaid')
    return isNil(inputData) ? false : inputData.value
  }

  get aspAdvanceAmount() {
    if (isNil(this.form)) return null

    const inputData = this.form.getInput('aspAdvanceAmount')
    return isNil(inputData) ? false : inputData.value
  }

  loadData = async (wan, sinisterData, withoutLoading = false) => {
    if (!withoutLoading) {
      this.loading = true
    }

    try {
      const form = await fetchInstruction(wan)
      form.name = 'sinister'
      runInAction(() => {
        this.form = new Form({ form, data: sinisterData })
      })
    } catch (err) {
      console.error(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  save = async (wan, formData) => {
    try {
      await CartStore.save('cart', false, false)
      await saveInstruction(wan, formData)
      const claim = await fetchManagerClaim(wan)
      await this.loadData(wan, claim, true)

      ManagerClaimStore.setProperty('claim', claim)

      // IRSI RULE
      if (
        path(['claimInformation', 'eventType', 'id'], claim) === 'EV0030' &&
        path(['damageDescription', 'applicableConventionType', 'id'], claim) === 'IRSI'
      ) {
        CartStore.instructionsIRSIRule()
      } else if (
        (path(['damageDescription', 'hasOtherDamages'], claim) === true ||
          path(['claimInformation', 'thirdPartyIsResponsible'], claim) === true) &&
        (path(['causesAndCircumstances', 'insuredResponsibility', 'id'], claim) ===
          'AGGRIEVED_AND_AUTHOR' ||
          path(['causesAndCircumstances', 'insuredResponsibility', 'id'], claim) === 'AUTHOR')
      ) {
        CartStore.instructionsPresentedRule()
      }
    } catch (error) {
      console.error(error)
    }
  }
}

const DecoratedInstruction = decorate(InstructionCtrl, {
  loading: observable,
  form: observable,

  isFranchiseMustBeDeducted: computed,
  franchiseMustBeDeducted: computed,
  presenceInvoiceOrQuote: computed,
  aspAmount: computed,
  isAspAdvanceAlreadyPaid: computed,
  aspAdvanceAmount: computed,
  isAspInvoice: computed,

  loadData: action,
  save: action,
})

export default new DecoratedInstruction()
