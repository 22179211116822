import React from 'react'
import { inject, observer } from 'mobx-react'

import Label from 'components/Label/Label'
import Input from 'components/Forms/Input'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const RENForm = ({ packageData, options, MissionStore: { isMissionClosed } }) => {
  const onChange = (packageData, e) => {
    if (e.target.name === 'ren') e.target.value = e.target.value === 'true'
    packageData.setProperty(e.target.name, e.target.value)

    // RESET company name when changing to REN
    if (e.target.name === 'ren' && e.target.value) {
      packageData.setProperty('companyName', '')
    }
  }

  return (
    <div className="row no-gutters mb-1 align-items-center">
      <div className="col-12 col-sm-6">
        <Label>{packageData.label}</Label>
      </div>
      <div className="col-12 col-sm-2">
        <ResponsiveSelect
          name="ren"
          disabled={isMissionClosed}
          onChange={onChange.bind(this, packageData)}
          value={packageData.ren.toString()}
          options={options}
        />
      </div>

      {!packageData.ren && (
        <div className="col-12 col-sm-4 pl-2">
          <Input
            className="form-control"
            name="companyName"
            required
            value={packageData.companyName}
            onChange={onChange.bind(this, packageData)}
            style={{ height: '38px' }}
          />
        </div>
      )}
    </div>
  )
}

export default inject('MissionStore')(observer(RENForm))
