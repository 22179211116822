import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { add, subtract } from 'ramda'

import TableDescriptionPrejudices from './TableDescriptionPrejudices'

const TotalTableDescriptionPrejudices = ({
  CartStore: {
    totalsFromCart,
    totalRelatedCostsAnnexPriceWithoutVAT,
    totalRelatedCostsAnnexPriceWithVAT,
  },
  PrejudiceStore,
  showPrejudiceStore = true,
}) => {
  const { catalog, selfRepair } = totalsFromCart
  const vans = {
    property: add(
      catalog.totalPropertyWithoutObsolescenceWithoutVAT,
      selfRepair.totalPropertyWithoutObsolescenceWithoutVAT,
    ),
    embellishment: add(
      catalog.totalEmbellishmentWithoutObsolescenceWithoutVAT,
      selfRepair.totalEmbellishmentWithoutObsolescenceWithoutVAT,
    ),
    leak: add(
      catalog.totalLeakWithoutObsolescenceWithoutVAT,
      selfRepair.totalLeakWithoutObsolescenceWithoutVAT
    ),
    relatedCost: totalRelatedCostsAnnexPriceWithoutVAT,
    outdoor: add(
      catalog.totalOutdoorWithoutObsolescenceWithoutVAT,
      selfRepair.totalOutdoorWithoutObsolescenceWithoutVAT,
    ),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.vanWithoutVAT : 0,
  }

  const obs = {
    property: add(
      catalog.totalPropertyObslescenceWithoutVAT,
      selfRepair.totalPropertyObslescenceWithoutVAT,
    ),
    embellishment: add(
      catalog.totalEmbellishmentObslescenceWithoutVAT,
      selfRepair.totalEmbellishmentObslescenceWithoutVAT,
    ),
    outdoor: add(
      catalog.totalOutdoorObslescenceWithoutVAT,
      selfRepair.totalOutdoorObslescenceWithoutVAT,
    ),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.realObsolescence : 0,
  }

  const returns = {
    property: add(catalog.totalPropertyORWithoutVAT, selfRepair.totalPropertyORWithoutVAT),
    embellishment: add(
      catalog.totalEmbellishmentORWithoutVAT,
      selfRepair.totalEmbellishmentORWithoutVAT,
    ),
    outdoor: add(catalog.totalOutdoorORWithoutVAT, selfRepair.totalOutdoorORWithoutVAT),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.guaranteedObsolescence : 0,
  }

  const noReturns = {
    property: subtract(obs.property, returns.property),
    embellishment: subtract(obs.embellishment, returns.embellishment),
    outdoor: subtract(obs.outdoor, returns.outdoor),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.nonGuaranteedObsolescence : 0,
  }

  const withOBWithoutVAT = {
    property: subtract(vans.property, obs.property),
    embellishment: subtract(vans.embellishment, obs.embellishment),
    outdoor: subtract(vans.outdoor, obs.outdoor),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.obsolescenceDeductedWithoutVAT : 0,
  }

  const withOBWithVAT = {
    property: add(
      subtract(catalog.totalPropertyWithVAT, catalog.totalPropertyObsolescence),
      subtract(selfRepair.totalPropertyWithVAT, selfRepair.totalPropertyObsolescence),
    ),
    embellishment: add(
      subtract(catalog.totalEmbellishmentWithVAT, catalog.totalEmbellishmentObsolescence),
      subtract(selfRepair.totalEmbellishmentWithVAT, selfRepair.totalEmbellishmentObsolescence),
    ),
    leak: add(catalog.totalLeakFinalPrice, selfRepair.totalLeakFinalPrice), // Obsolescence not applicable to leaks
    relatedCost: totalRelatedCostsAnnexPriceWithVAT,
    outdoor: add(
      subtract(catalog.totalOutdoorWithVAT, catalog.totalOutdoorObsolescence),
      subtract(selfRepair.totalOutdoorWithVAT, selfRepair.totalOutdoorObsolescence),
    ),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.obsolescenceDeductedWithVAT : 0,
  }

  const finalPrice = {
    property: add(catalog.totalPropertyFinalPrice, selfRepair.totalPropertyFinalPrice),
    embellishment: add(
      catalog.totalEmbellishmentFinalPrice,
      selfRepair.totalEmbellishmentFinalPrice,
    ),
    leak: add(catalog.totalLeakFinalPrice, selfRepair.totalLeakFinalPrice),
    relatedCost: totalRelatedCostsAnnexPriceWithVAT,
    outdoor: add(catalog.totalOutdoorFinalPrice, selfRepair.totalOutdoorFinalPrice),
    options: !!PrejudiceStore.payloads.length ? PrejudiceStore.finalPrice : 0,
  }

  return (
    <TableDescriptionPrejudices
      showPrejudiceStore={showPrejudiceStore}
      vans={vans}
      obs={obs}
      returns={returns}
      noReturns={noReturns}
      withOBWithoutVAT={withOBWithoutVAT}
      withOBWithVAT={withOBWithVAT}
      finalPrice={finalPrice}
    />
  )
}

export default compose(
  inject('CartStore', 'PrejudiceStore'),
  observer,
)(TotalTableDescriptionPrejudices)
