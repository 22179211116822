import React from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import AlertImage from './AlertImage'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import { path } from 'ramda'

const Info = ({ sdItem }) => {
  if (!toJS(SupportingDocumentsCtrl.imageLoaded[sdItem.id])) return null

  let background = path(['typeKeysFilterList', sdItem.type.key, 'color'], SupportingDocumentStore)
  background = background ? background : 'white'

  return (
    <div className="sd-infos">
      <AlertImage sdItem={sdItem} />

      <p className="sd-name">
        <span
          className="badge"
          style={{
            background: background,
          }}
        >
          &nbsp;
        </span>
        &nbsp;
        {sdItem.name}
      </p>
    </div>
  )
}

export default observer(Info)
