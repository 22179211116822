import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  format,
  parseISO,
} from 'date-fns'

import './LastUpdatedAt.css'
import CartStore from 'stores/Mission/domain/CartStore'

class LastUpdatedAt extends React.Component {
  intervalId = null
  state = { v: 0 }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({ v: this.state.v + 1 })
    }, 60000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const { t } = this.props
    const { lastUpdatedAt, saving } = CartStore
    const now = new Date()

    if (lastUpdatedAt === null) return null

    let lastUpdate = 'lastUpdatedAt'
    let showTime = null
    const minutes = differenceInMinutes(now, parseISO(lastUpdatedAt))
    const hours = differenceInHours(now, parseISO(lastUpdatedAt))
    const days = differenceInDays(now, parseISO(lastUpdatedAt))

    if (minutes === 0) showTime = t('common.leastThanMinute')
    else if (minutes < 60) showTime = t('common.minute', { count: minutes })
    else if (minutes > 60 && hours < 24) showTime = t('common.hour', { count: hours })
    else if (days < 3) showTime = t('common.day', { count: days })
    else {
      lastUpdate = 'lastUpdatedAtWithDate'
      showTime = format(parseISO(lastUpdatedAt), t('common.datetimeFormat'))
    }

    return (
      <div className="last-updated-at text-right font-italic">
        {saving ? t('common.lastUpdateAtSaving') : `${t(`common.${lastUpdate}`)} ${showTime}`}
      </div>
    )
  }
}

export default withTranslation()(observer(LastUpdatedAt))
