import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import DatePicker from 'react-datepicker'
import { getDay } from 'date-fns'
import classNames from 'classnames'

const dateFnsLocales = {
  'en-US': require('date-fns/locale/en-US'),
  'fr-FR': require('date-fns/locale/fr'),
}

function PlannerDatePicker({
  PlannerStore: { min, currentDate, changeWeek },
  UiCtrl: { language },
}) {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0 && day !== 6
  }
  const locale = dateFnsLocales[language]

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="my-4">
        <button
          className={classNames(
            'btn',
            { 'btn-primary': showDatePicker },
            { 'btn-outline-primary': !showDatePicker },
          )}
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          <i className="fa fa-calendar"></i>
        </button>
      </div>
      {showDatePicker && (
        <DatePicker
          selected={currentDate}
          onChange={date => changeWeek(date)}
          filterDate={isWeekday}
          locale={locale}
          minDate={min}
          inline
        />
      )}
    </div>
  )
}

export default compose(
  inject('PlannerStore', 'UiCtrl'),
  withTranslation(),
  observer,
)(PlannerDatePicker)
