import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import SmartLink from './SmartLink'
import TotalPackages from './TotalPackages'

const OutdoorFurnitureLinks = ({
  t,
  match: {
    path,
    params: { id, type },
  },
  UserStore: { customer },
  MissionStore: { isMissionClosed },
  ManagerClaimStore: { isClaimClosed },
}) => {
  const endpoint = path.split('/').pop()
  const hasRP = customer.hasContract('RP')
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  return (
    <div className="breadcrumb m-0 p-0 d-flex align-items-center">
      <SmartLink
        path={`/${type}/${id}/calculation/listPackage`}
        selected={endpoint === 'listPackage'}
      >
        {t('mission.calculation.cart.breadcrumb')} <TotalPackages />
      </SmartLink>
      <i
        className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
          hide: endpoint === 'listPackage',
        })}
      />

      {!isClosed && hasRP && (
        <React.Fragment>
          <SmartLink
            path={`/${type}/${id}/calculation/searchOutdoorPackage`}
            selected={endpoint === 'searchOutdoorPackage'}
          >
            {t('mission.calculation.breadcrumb.searchOutdoorFurniture')}
          </SmartLink>
          <i
            className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
              hide: endpoint === 'searchOutdoorPackage',
            })}
          />

          <SmartLink
            path={`/${type}/${id}/calculation/searchOutdoorFurniture`}
            selected={endpoint === 'searchOutdoorFurniture'}
          >
            {t('mission.calculation.breadcrumb.searchOutdoorFurniture')}
          </SmartLink>
          <i
            className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
              hide: endpoint === 'searchOutdoorFurniture',
            })}
          />
        </React.Fragment>
      )}

      <SmartLink
        path={`/${type}/${id}/calculation/addOutdoorFurniture`}
        selected={endpoint === 'addOutdoorFurniture'}
        hide={endpoint !== 'addOutdoorFurniture'}
      >
        {t('mission.calculation.furniture.breadcrumbNewFurniture')}{' '}
        {t('mission.calculation.furniture.outdoor')}
      </SmartLink>
    </div>
  )
}

export default compose(
  inject('MissionStore', 'ManagerClaimStore', 'UserStore'),
  withRouter,
  withTranslation(),
  observer,
)(OutdoorFurnitureLinks)
