import request from 'services/httpRequest'

export const fetchMissionInsurerType = async () => {
  try {
    const res = await request({
      method: 'get',
      url: '/businessData/v1/missionInsurerType',
    })
    return res.data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveDashboardFilter = async ({ dashboardType, filters }) => {
  try {
    const res = await request({
      method: 'post',
      url: '/dashboard/v1/filters',
      data: {
        data: {
          type: 'dashboard',
          attributes: {
            service: dashboardType,
            filters,
          },
        },
      },
    })
    return res
  } catch (err) {
    return Promise.reject(err)
  }
}
