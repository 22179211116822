import React from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { add } from 'ramda'

import RecapRowLayout from 'pages/Mission/pages/Calculation/Cart/RecapRowLayout'
import { euro } from 'utils/currency'
import { calculTotalPackages } from 'utils/cart'

const TotalRecap = ({
  CartStore: {
    packages,
    packagesWithoutOutdoor,
    furnitures,
    relatedCostsAnnex,
    overflowPackages,
    noGuaranteePackages,
    outdoorPackages,
    totalCatalog,
    totalSelfRepair,
  },
  MissionStore: { isIRSI, selectedIRSICart },
}) => {
  const { t } = useTranslation()
  if (!isIRSI) {
    return (
      <RecapRowLayout
        className="container mt-3 mb-2"
        key="original-total"
        col1={<span className="text-primary">{t('mission.calculation.cart.finalTotal')}</span>}
        col2={<span className="text-primary">{euro(totalCatalog)}</span>}
        col3={<span className="text-primary">{euro(totalSelfRepair)}</span>}
      />
    )
  }
  const filterByIP = data => data.involvedParty === selectedIRSICart
  const computeTotalRC = (acc, data) => add(acc, data.priceWithVAT)
  // CATALOG
  const packagesIP = packagesWithoutOutdoor.filter(filterByIP)
  const furnituresIP = furnitures.filter(filterByIP)
  const RCAnnexIP = relatedCostsAnnex.filter(filterByIP)
  const overflowIP = overflowPackages.filter(filterByIP)
  const noGuaranteeIP = noGuaranteePackages.filter(filterByIP)
  const outdoorPackagesIP = outdoorPackages.filter(filterByIP)

  const totalCatalogIP =
    calculTotalPackages({ packages: packagesIP, key: 'finalPriceCatalog', selfRepair: false }) +
    furnituresIP.reduce((acc, furniture) => add(acc, furniture.finalPrice), 0) +
    RCAnnexIP.reduce(computeTotalRC, 0) +
    overflowIP.reduce(computeTotalRC, 0) +
    noGuaranteeIP.reduce(computeTotalRC, 0) +
    outdoorPackagesIP.reduce((acc, outdoor) => {
      if (outdoor.selfRepair === false || outdoor.selfRepair === undefined)
        return add(acc, outdoor.finalPrice)
      return acc
    }, 0)

  // SELF REPAIR
  const packagesSelfRepairIP = packages.filter(filterByIP)

  const totalSelfRepairIP = calculTotalPackages({
    packages: packagesSelfRepairIP,
    key: 'finalPriceSelfRepair',
    selfRepair: true,
  })

  return (
    <RecapRowLayout
      className="container mt-3 mb-2"
      key="original-total"
      col1={<span className="text-primary">{t('mission.calculation.cart.finalTotal')}</span>}
      col2={<span className="text-primary">{euro(totalCatalogIP)}</span>}
      col3={<span className="text-primary">{euro(totalSelfRepairIP)}</span>}
    />
  )
}

export default inject('CartStore', 'MissionStore')(observer(TotalRecap))
