import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

function Filters({
  t,
  history,
  CalendarExpertStore: {
    view,
    viewType,
    expertOptions,
    expertValue,
    changeView,
    setExpert,
    calendarNavigation,
  },
  UserStore: { isInsurer },
}) {
  const views = [
    { value: 'day', label: t('calendar.filters.viewDay') },
    { value: 'week', label: t('calendar.filters.viewWeek') },
    { value: 'work_week', label: t('calendar.filters.viewWorkWeek') },
    { value: 'month', label: t('calendar.filters.viewMonth') },
  ]
  const colourStyles =
    isInsurer && viewType === 'week'
      ? {
          multiValue: (styles, { data }) => ({
            ...styles,
            background: data.positionColor.background,
          }),
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.positionColor.fontColor,
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.positionColor.fontColor,
            ':hover': {
              backgroundColor: data.positionColor.background,
              color: 'white',
            },
          }),
        }
      : {}

  return (
    <div className="row mb-3">
      <div className="col-10 mb-5 col-md-2 mb-md-0">
        {view === 'day' && (
          <button
            className="btn-today-filter btn btn-success mr-3"
            onClick={() => calendarNavigation({ direction: 'today' })}
          >
            {t('calendar.filters.today')}
          </button>
        )}
      </div>

      <div className="col-12 order-12 col-md-3 order-md-3">
        <ResponsiveSelect
          name="selectView"
          className="select-view"
          placeholder={t('calendar.filters.view')}
          options={views}
          value={view}
          disabledSort={true}
          onChange={e => changeView(e.target.value)}
        />
      </div>

      <div className="col-12 mt-2 mt-md-0 order-12 col-md-6 order-md-6">
        {isInsurer && (
          <ResponsiveSelect
            name="expertValue"
            className="select-expert"
            placeholder={t('calendar.filters.selectExpert')}
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            options={expertOptions}
            value={expertValue}
            onChange={e => setExpert(e.target.value)}
            colourStyles={colourStyles}
          />
        )}
      </div>

      <div className="col-2 order-10 col-md-1 order-md-last d-flex align-items-center justify-content-center quit-calendar">
        <i className="fa fa-close clickable" onClick={() => history.goBack()} />
      </div>
    </div>
  )
}

export default compose(
  inject('CalendarExpertStore', 'UserStore'),
  withRouter,
  withTranslation(),
  observer,
)(Filters)
