import { observable, decorate, computed } from 'mobx'

import AbstractField from 'utils/dynform/AbstractField'

class Signature extends AbstractField {
  value = ''
  show = true
  type = 'signature'

  constructor(data) {
    super(data)
    this.type = data.type || 'signature'
    this.show = data.show
    this.signForField = data.signForField || null

    if (data.value) this.value = data.value
    else if (data.default) this.value = data.default
    else this.value = ''
    this.originalValue = this.value
  }

  get changed() {
    return this.value !== this.originalValue
  }

  setOriginalData() {
    this.value = this.originalValue
  }
}

const DecoratedSignature = decorate(Signature, {
  value: observable,
  originalValue: observable,
  show: observable,

  changed: computed,
})

export default DecoratedSignature
