import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import classNames from 'classnames'

import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'
import Form from './Form'

const PartyModal = ({
  t,
  match: {
    params: { id },
  },
}) => {
  const [grabbing, setGrabbing] = useState(false)
  const { id: partyId, changed, isValid } = PartyInvolvedCtrl.party
  const {
    showModalForm,
    saving,
    ctrl: { showActions },
  } = PartyInvolvedCtrl

  function closeModal() {
    if (PartyInvolvedCtrl.showModalForm) PartyInvolvedCtrl.showForm(null, true)
  }
  function onSave() {
    PartyInvolvedCtrl.savePartyInvolved(id)
  }

  return (
    <ModalWithBackdrop
      show={showModalForm}
      size="modal-lg"
      close={closeModal}
      draggable
      scrollable
      setGrabbing={setGrabbing}
    >
      <div className={classNames('modal-header', { grabbable: !grabbing, grabbing: grabbing })}>
        <h5 className="modal-title">
          {partyId ? t('partyInvolved.modal.titleEdit') : t('partyInvolved.modal.titleNew')}
        </h5>
        <button type="button" className="close" onClick={closeModal}>
          <span>&times;</span>
        </button>
      </div>
      <Form />
      <div className="modal-footer pb-2 pt-2">
        {showActions && (
          <Button
            className="btn btn-primary"
            onClick={onSave}
            disabled={saving || !changed || !isValid}
            dataCy="party-modal-save"
          >
            {t('partyInvolved.modal.save')}
          </Button>
        )}
        <button type="button" className="btn btn-secondary" onClick={closeModal}>
          {t('partyInvolved.modal.close')}
        </button>
      </div>
    </ModalWithBackdrop>
  )
}

export default compose(
  withRouter,
  withTranslation(),
  observer,
)(PartyModal)
