import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classNames from 'classnames'

import Button from 'components/Button/Button'
import { concatAddress } from 'utils'

const Firm = ({ data: { id, name, address, phoneNumber }, selectedFirm, selectFirm }) => {
  return (
    <div
      className={classNames('clickable rounded mb-3 p-2', {
        'border border-primary': selectedFirm === id,
        'border border-white': selectedFirm !== id,
      })}
      onClick={() => {
        selectFirm(id)
      }}
    >
      <span className="font-weight-bold mb-2">{name}</span>
      <br />
      {concatAddress(address)}
      <br />
      {phoneNumber}
    </div>
  )
}

function ListFirm({ t, LastBillCtrl: { firms, selectedFirm, selectFirm, setProperty } }) {
  if (firms.length === 0) return <div className="mb-3">{t('lastbill.noFirm')}</div>
  return (
    <React.Fragment>
      {firms.map(firm => (
        <Firm key={firm.id} data={firm} selectFirm={selectFirm} selectedFirm={selectedFirm} />
      ))}

      <Button
        className="btn btn-success btn-block mt-3 mb-3"
        onClick={() => {
          setProperty('showConfirmFirmModal', true)
        }}
        disabled={selectedFirm === ''}
      >
        {t('lastbill.assign')}
      </Button>
    </React.Fragment>
  )
}

export default compose(
  inject('LastBillCtrl'),
  withTranslation(),
  observer,
)(ListFirm)
