import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const PackageType = ({ t, match: { path } }) => {
  const { packageType } = SearchPackageCtrl
  const last = path.split('/').pop()
  const packageTypeOptions = [
    { label: t('mission.calculation.cart.titleProperty'), value: 'DAT_PROP' },

    { label: t('mission.calculation.searchPackage.noFilter'), value: 'noFilter' },
  ]
  const onChange = e => SearchPackageCtrl.setProperty(e.target.name, e.target.value)
  if (last === 'searchPackage')
    packageTypeOptions.push({
      label: t('mission.calculation.cart.titleEmbellishment'),
      value: 'DAT_EMB',
    })

  return (
    <ResponsiveSelect
      className="package-type"
      name="packageType"
      value={packageType}
      options={packageTypeOptions}
      onChange={onChange}
    />
  )
}

export default compose(
  withRouter,
  withTranslation(),
  observer,
)(PackageType)
