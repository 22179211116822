import { observable, computed, action, runInAction, decorate } from 'mobx'

import PartyInvolved from 'stores/Common/domain/PartyInvolved'
import { getPartyInvolvedDB } from 'services/partyInvolvedDB'
import { concatAddress } from 'utils'

class PartyInvolvedDBStore {
  partyInvolvedDB = []

  get partyInvolvedList() {
    return this.partyInvolvedDB.map(partyInvolved => {
      const {
        id,
        companyName,
        firstName,
        lastName,
        streetNumber,
        addressLine1,
        addressLine2,
        addressLine3,
        zipCode,
        city,
      } = partyInvolved
      const label = `${companyName ? companyName : ''} ${lastName} ${firstName} - ${concatAddress({
        streetNumber,
        addressLine1,
        addressLine2,
        addressLine3,
        zipCode,
        city,
      })}`
      return {
        value: id,
        label,
      }
    })
  }

  async loadData(customerId) {
    try {
      const res = await getPartyInvolvedDB(customerId)
      runInAction(() => {
        this.partyInvolvedDB = res.data.map(
          partyInvolved => new PartyInvolved(partyInvolved.attributes),
        )
      })
    } catch (err) {}
  }

  addParty(newPI) {
    const party = this.partyInvolvedDB.find(party => party.id === newPI.id)
    if (!party) this.partyInvolvedDB.push(newPI)
  }

  deleteParty(id) {
    this.partyInvolvedDB = this.partyInvolvedDB.filter(partyInvolved => partyInvolved.id !== id)
  }
}
const DecoratedPartyInvolvedDBStore = decorate(PartyInvolvedDBStore, {
  partyInvolvedDB: observable,

  loadData: action,
  addParty: action,
  deleteParty: action,

  partyInvolvedList: computed,
})

export default new DecoratedPartyInvolvedDBStore()
