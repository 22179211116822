import React from 'react'
import { compose } from 'recompose'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router'

import './ResetPassword.css'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import PageLayout from 'components/PageLayout/PageLayout'
import ResetPasswordCtrl from 'stores/Common/view/ResetPasswordCtrl'
import CookieDisclaimer from 'components/CookieDisclaimer/CookieDisclaimer'
import classNames from 'classnames'

class ResetPasswordPage extends React.Component {
  UNSAFE_componentWillMount() {
    const { token } = this.props.match.params
    ResetPasswordCtrl.setProperty('token', token)
  }

  componentDidMount() {
    document.body.classList.add('login-background')
  }

  componentWillUnmount() {
    document.body.classList.remove('login-background')
  }

  handleError = (errors, name) => {
    const error = errors.find(error => error.key === name)
    if (error) return [error.message]
    return []
  }

  onChange = e => ResetPasswordCtrl.setProperty(e.target.name, e.target.value)
  onSubmit = () => {
    ResetPasswordCtrl.resetPassword()
  }

  render() {
    const { t } = this.props
    const { email, password, repeatPassword, errors, saving, passwordChanged } = ResetPasswordCtrl

    if (passwordChanged) return <Redirect to="/" />

    return (
      <div>
        <form
          autoComplete="on"
          className="reset-password-form modal-content"
          name="resetPasswordForm"
        >
          <div className="modal-header">
            <h5 className="modal-title text-primary">{t('resetPasswordPage.title')}</h5>
          </div>
          <div className="modal-body">
            <div className="row form-group">
              <div className="col-12 col-sm-4">{t('resetPasswordPage.email')}</div>
              <div className="col-12 col-sm-8">
                <Input
                  className="form-control"
                  name="email"
                  type="text"
                  value={email}
                  required
                  onChange={this.onChange}
                  errors={this.handleError(errors, 'email')}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12 col-sm-4">{t('resetPasswordPage.password')}</div>
              <div className="col-12 col-sm-8">
                <Input
                  className="form-control"
                  name="password"
                  type="password"
                  value={password}
                  required
                  onChange={this.onChange}
                  errors={this.handleError(errors, 'first')}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12 col-sm-4">{t('resetPasswordPage.repeatPassword')}</div>
              <div className="col-12 col-sm-8">
                <Input
                  className="form-control"
                  name="repeatPassword"
                  type="password"
                  value={repeatPassword}
                  required
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer pb-2 pt-2">
            <Button
              className={classNames('reset-password-btn btn btn-primary')}
              onClick={this.onSubmit}
              loading={saving}
              disabled={saving}
            >
              {t('resetPasswordPage.submit')}
            </Button>
          </div>
        </form>
        <CookieDisclaimer />
      </div>
    )
  }
}

export default compose(PageLayout)(observer(ResetPasswordPage))
