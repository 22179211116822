import request from 'services/httpRequest'

export const getStickyNote = async wan => {
  try {
    const res = await request({
      method: 'GET',
      url: `/claimFiles/v1/${wan}/sticky-note/`,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}
