import { test } from 'ramda'

export const getLocale = () => {
  let locale = getDataLocal('locale')
  if (locale) {
    if (test(/_/, locale)) {
      const formatLocale = locale.replace(/_/g, '-')
      setDataLocal('locale', formatLocale)
      return locale.replace(/_/g, '-')
    }
    return locale
  }
  return null
}

export const setDataLocal = (key, value) => {
  if (!isAvailable()) return

  try {
    const data = JSON.stringify(value)
    localStorage.setItem(key, data)
  } catch (err) {
    console.warn('Error stringify data ')
  }
}

export const getDataLocal = key => {
  if (!isAvailable()) return

  try {
    const data = localStorage.getItem(key)
    try {
      return JSON.parse(data)
    } catch (err) {
      console.warn(err)
      return null
    }
  } catch (err) {
    return null
  }
}

export const deleteDataLocal = key => {
  if (!isAvailable()) return

  localStorage.removeItem(key)
}

export const isAvailable = () => {
  try {
    const storage = window['localStorage']
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}
