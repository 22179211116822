import { observable, action, decorate, runInAction } from 'mobx'

import {
  fetchNewMissionCreationRequestForm,
  saveNewMissionCreationRequestForm,
} from 'services/claim'
import Form from 'utils/dynform/Form'
import ClaimStore from '../domain/ClaimStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SDExpertCtrl from 'stores/Common/view/SDExpertCtrl'
import { sendClaimDarva } from 'services/report'
import { handleSendToDarvaErrors } from 'utils/report'
import { generateClaimReport } from 'services/report'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import { path } from 'ramda'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'

class NewMission {
  loading = false
  form = null
  attachments = []
  loaded = false
  reportContent = ''
  numPages = 0
  savingReport = false

  loadData = async (wan, requestMissionCreationClaimData) => {
    this.loading = true
    try {
      const form = await fetchNewMissionCreationRequestForm(wan)
      form.name = 'requestMissionCreationClaim'
      runInAction(() => {
        this.form = new Form({ form, data: requestMissionCreationClaimData })
      })
    } catch (err) {
      console.log(err)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  addSupportingDocumentAttachment = id => {
    this.attachments.push(id)
  }

  removeSupportingDocumentAttachment = deleteId => {
    this.attachments = this.attachments.filter(attachmentId => attachmentId !== deleteId)
  }

  save = async (wan, formData) => {
    try {
      let claim = await saveNewMissionCreationRequestForm(wan, formData)

      let reportSupportingDocuments = []
      if (claim.reports && claim.reports[0] && claim.reports[0].supportingDocuments) {
        reportSupportingDocuments = Object.values(claim.reports[0].supportingDocuments)
      }

      SDExpertCtrl.loadSDReport(reportSupportingDocuments)
      SupportingDocumentsCtrl.fetchSupportingDocuments(wan)

      this.generateReport(wan)

      ClaimStore.updateNewMission(claim.newMission)
    } catch (error) {
      console.error(error)
    }
  }
  setReportContent = content => {
    this.reportContent = content
  }

  generateReport = async wan => {
    this.setReportContent('')

    try {
      const res = await generateClaimReport(
        wan,
        ClaimStore.isClaimClosed,
        'MISSION_CREATION_REPORT',
      )

      runInAction(() => {
        if (res.body) {
          this.setReportContent(res.body)
        } else {
          this.setReportContent(res)
        }
      })
    } catch (error) {
      console.warn(error)
      AlertCtrl.alert('danger', 'mission.report.contentFailure')
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  sendNewMission = async wan => {
    this.savingReport = true

    try {
      const isValid = await ReportCtrl.newMissionValidatorClaimFileInsurer(wan)
      if (isValid === 204) {
        await sendClaimDarva(wan, 'MISSION_CREATION_REPORT')
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
        AlertCtrl.alert('success', 'mission.report.newMissionSent')
      }
    } catch (err) {
      const error = handleSendToDarvaErrors(err)
      AlertCtrl.alert('danger', path(['messages', 0], error))
    } finally {
      runInAction(() => {
        this.savingReport = false
      })
    }
  }
}

const DecoratedNewMission = decorate(NewMission, {
  loading: observable,
  form: observable,
  attachments: observable,
  loaded: observable,
  numPages: observable,
  reportContent: observable,
  savingReport: observable,

  loadData: action,
  addSupportingDocumentAttachment: action,
  removeSupportingDocumentattachment: action,
  save: action,
  generateReport: action,
  setProperty: action,
  sendNewMission: action,
})

export default new DecoratedNewMission()
