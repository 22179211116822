import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Select from 'components/ResponsiveSelect/ResponsiveSelect'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import Button from 'components/Button/Button'

const PrejudiceModal = ({ payload, showModal, setShowModal, header, isPrejudice = true }) => {
  const [option, setOption] = useState(null)
  const { t } = useTranslation()

  const onClose = () => setShowModal(false)
  const onValid = async () => {
    isPrejudice ? payload.addPrejudice(option) : payload.addAnnex(option)
    setShowModal(false)
  }

  return (
    <ModalWithBackdrop size="modal-md" show={showModal} close={onClose}>
      <div className="modal-header">
        {/* <h5 className="modal-title">{t('mission.prejudice.modal')}</h5> */}
        <h5 className="modal-title">{header}</h5>
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>

      <div className="modal-body px-5">
        <Select
          options={isPrejudice ? payload.prejudicesOptions : payload.annexOptions}
          value={option}
          onChange={e => setOption(e.target.value)}
        />
      </div>

      <div className="modal-footer pb-2 pt-2">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <Button className="btn btn-primary" onClick={onValid} disabled={!option}>
          {t('common.validate')}
        </Button>
      </div>
    </ModalWithBackdrop>
  )
}

export default observer(PrejudiceModal)
