import { reduce, add, subtract, isNil } from 'ramda'

const _computeTotalPrice = ({ packages, catalogKey, selfRepairKey, furnitureKey = null }) => {
  return reduce(
    (acc, packageData) => {
      let price = 0

      if (packageData.modalityAcquisition !== undefined && furnitureKey)
        price = packageData[furnitureKey]
      else if (packageData.selfRepair && !isNil(selfRepairKey)) price = packageData[selfRepairKey]
      else price = packageData[catalogKey]

      return add(acc, price)
    },
    0,
    packages,
  )
}

export const computeVans = ({ properties, embellishments, leaks, relatedCosts, outdoors }) => {
  const relatedCostsAnnex = relatedCosts.filter(rc => rc.type === 'ANNEX')

  return {
    property: _computeTotalPrice({
      packages: properties,
      catalogKey: 'quantityOriginalTotalPriceCatalog',
      selfRepairKey: 'quantityOriginalTotalPriceSelfRepair',
    }),
    embellishment: _computeTotalPrice({
      packages: embellishments,
      catalogKey: 'quantityOriginalTotalPriceCatalog',
      selfRepairKey: 'quantityOriginalTotalPriceSelfRepair',
    }),
    leak: _computeTotalPrice({
      packages: leaks,
      catalogKey: 'quantityOriginalTotalPriceCatalog',
    }),
    relatedCost: _computeTotalPrice({
      packages: relatedCostsAnnex,
      catalogKey: 'priceWithoutVAT',
    }),
    outdoor: _computeTotalPrice({
      packages: outdoors,
      catalogKey: 'quantityOriginalTotalPriceCatalog',
      selfRepairKey: 'quantityOriginalTotalPriceSelfRepair',
      furnitureKey: 'price',
    }),
  }
}

export const computeObs = ({ properties, embellishments, outdoors }) => {
  return {
    property: _computeTotalPrice({
      packages: properties,
      catalogKey: 'totalPriceCatalogObsolescenceWithoutVAT',
      selfRepairKey: 'totalPriceSelfRepairObsolescenceWithoutVAT',
    }),
    embellishment: _computeTotalPrice({
      packages: embellishments,
      catalogKey: 'totalPriceCatalogObsolescenceWithoutVAT',
      selfRepairKey: 'totalPriceSelfRepairObsolescenceWithoutVAT',
    }),
    outdoor: _computeTotalPrice({
      packages: outdoors,
      catalogKey: 'totalPriceCatalogObsolescenceWithoutVAT',
      selfRepairKey: 'totalPriceSelfRepairObsolescenceWithoutVAT',
      furnitureKey: 'obsWithoutVAT',
    }),
  }
}

export const computeReturns = ({ properties, embellishments, outdoors }) => {
  return {
    property: _computeTotalPrice({
      packages: properties,
      catalogKey: 'totalCatalogORWithoutVAT',
      selfRepairKey: 'totalSelfRepairORWithoutVAT',
    }),
    embellishment: _computeTotalPrice({
      packages: embellishments,
      catalogKey: 'totalCatalogORWithoutVAT',
      selfRepairKey: 'totalSelfRepairORWithoutVAT',
    }),
    outdoor: _computeTotalPrice({
      packages: outdoors,
      catalogKey: 'totalCatalogORWithoutVAT',
      selfRepairKey: 'totalSelfRepairORWithoutVAT',
      furnitureKey: 'ORWithoutVAT',
    }),
  }
}

export const computeWithOBWithVAT = ({
  properties,
  embellishments,
  leaks,
  relatedCosts,
  outdoors,
}) => {
  return {
    property: subtract(
      _computeTotalPrice({
        packages: properties,
        catalogKey: 'totalPriceCatalogWithVAT',
        selfRepairKey: 'totalPriceSelfRepairWithVAT',
      }),
      _computeTotalPrice({
        packages: properties,
        catalogKey: 'totalPriceCatalogObsolescence',
        selfRepairKey: 'totalSelfRepairObsolescence',
      }),
    ),
    embellishment: subtract(
      _computeTotalPrice({
        packages: embellishments,
        catalogKey: 'totalPriceCatalogWithVAT',
        selfRepairKey: 'totalPriceSelfRepairWithVAT',
      }),
      _computeTotalPrice({
        packages: embellishments,
        catalogKey: 'totalPriceCatalogObsolescence',
        selfRepairKey: 'totalSelfRepairObsolescence',
      }),
    ),
    leak: _computeTotalPrice({
      packages: leaks,
      catalogKey: 'finalPriceCatalog',
    }),
    relatedCost: _computeTotalPrice({
      packages: relatedCosts.filter(rc => rc.type === 'ANNEX'),
      catalogKey: 'priceWithVAT',
    }),
    outdoor: subtract(
      _computeTotalPrice({
        packages: outdoors,
        catalogKey: 'totalPriceCatalogWithVAT',
        selfRepairKey: 'totalPriceSelfRepairWithVAT',
        furnitureKey: 'totalPriceVAT',
      }),
      _computeTotalPrice({
        packages: outdoors,
        catalogKey: 'totalPriceCatalogObsolescence',
        selfRepairKey: 'totalSelfRepairObsolescence',
        furnitureKey: 'totalObsolescence',
      }),
    ),
  }
}

export const computeFinalPrice = ({
  properties,
  embellishments,
  leaks,
  relatedCosts,
  outdoors,
}) => {
  const relatedCostsAnnex = relatedCosts.filter(rc => rc.type === 'ANNEX')

  return {
    property: _computeTotalPrice({
      packages: properties,
      catalogKey: 'finalPriceCatalog',
      selfRepairKey: 'finalPriceSelfRepair',
    }),
    embellishment: _computeTotalPrice({
      packages: embellishments,
      catalogKey: 'finalPriceCatalog',
      selfRepairKey: 'finalPriceSelfRepair',
    }),
    leak: _computeTotalPrice({
      packages: leaks,
      catalogKey: 'finalPriceCatalog',
    }),
    relatedCost: _computeTotalPrice({
      packages: relatedCostsAnnex,
      catalogKey: 'priceWithVAT',
    }),
    outdoor: _computeTotalPrice({
      packages: outdoors,
      catalogKey: 'finalPriceCatalog',
      selfRepairKey: 'finalPriceSelfRepair',
      furnitureKey: 'finalPrice',
    }),
  }
}
