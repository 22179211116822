import React from 'react'
import ImageLoader from 'react-load-image'
import { extractSdIdFromUrl } from 'utils/index'
import ImageLoading from 'components/ImageLoading/ImageLoading'
import FallBackThumbnail from 'components/FallBackThumbnail/FallBackThumbnail'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'

const Divider = (node, index) => {
  // Prevent warnings and remove whitespace in nodes
  if (node.type === 'text' && node.data === ' ') {
    return null
  }
  if (node.type === 'tag' && node.name === 'page') {
    node.attribs = { class: 'page-divider' }
    node.name = 'div'
    return undefined
  }
  if (node.type === 'tag' && node.name === 'page_footer') {
    return null
  }

  // POC image launcher
  if (
    node.type === 'tag' &&
    node.name === 'img' &&
    node.attribs.class !== 'logo' &&
    node.attribs.class !== 'logo-sh' &&
    node.attribs.class !== 'signature'
  ) {
    const { src } = node.attribs // extract the actual url
    const sdId = extractSdIdFromUrl(src.replace('http://', 'https://'))
    if (sdId !== '') {
      return (
        <ImageLoader key={`${index}-image`} src={node.attribs.src}>
          <img
            onClick={() => {
              ReportCtrl.setImageDisplayUrl(sdId)
              ReportCtrl.toggleImageDisplayModal()
            }}
            style={{ width: '99%' }}
            alt="Supporting documents attached to th report"
          />
          <FallBackThumbnail />
          <ImageLoading />
        </ImageLoader>
      )
    }
    return undefined
  }
}

export default Divider
