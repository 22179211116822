import React from 'react'
import classNames from 'classnames'

export default ({ className, col1, col2, col3 }) => {
  return (
    <div className={classNames('row no-gutters pb-2 pt-2 d-none d-md-flex', className)}>
      <div className="col-3 font-weight-bold">{col1}</div>
      <div className="col-1 col-lg-2" />
      <div className="col-1 col-lg-2" />
      <div className="col-3 col-lg-2 text-right">{col2}</div>
      <div className="col-3 col-lg-2 text-right">{col3}</div>
      <div className="col-1 d-none d-lg-flex" />
    </div>
  )
}
