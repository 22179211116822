import { isNil, uniq } from 'ramda'

import { capitalize } from 'utils'

export const DYNAMIC_SELECT_FILTER_MANAGER = Object.freeze([
  'mission.assigneeUser.fullName',
  'contract.insurer.companyName',
  'mission.missionInsurerInformation.coverage.value',
  'mission.missionInsurerInformation.missionNature.value',
  'mission.missionInsurerInformation.expertiseCharacteristic.value',
])

export const DYNAMIC_SELECT_FILTER_EXPERT = Object.freeze([
  'cfi.contract.insurer.companyName',
  'cfi.claimInformation.coverage.value',
  'mission.missionInsurerInformation.missionNature.value',
  'mission.missionInsurerInformation.expertiseCharacteristic.value',
])

export const dynSelectFilterName = filterPath => {
  const filterPathArray = filterPath.split('.')

  if (filterPathArray[filterPathArray.length - 1] === 'value') {
    filterPathArray.pop()
    return capitalize(filterPathArray.pop())
  }

  return capitalize(filterPathArray.pop())
}

export const reduceKey = (key, array) => {
  const reducedArray = array.reduce((acc, element) => {
    if (!isNil(element[key])) {
      acc.push(element[key])
    }
    return acc
  }, [])

  const uniqArray = uniq(reducedArray)

  return uniqArray.map(element => {
    return {
      value: element,
      label: element,
    }
  })
}
