import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import FurnitureCatalogDetails from 'pages/Mission/pages/Calculation/FurnitureCatalogDetails'

class ReplacementProductsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = { grabbing: false }
  }

  closeModal() {
    AddFurnitureCtrl.setProperty('showModalReplacementProducts', false)
  }

  chooseProduct = furniture => {
    AddFurnitureCtrl.setReplacementProduct(furniture)
    this.closeModal()
  }

  setGrabbing = value => {
    this.setState({ grabbing: value })
  }

  render() {
    const { t } = this.props
    const { showModalReplacementProducts, replacementProducts, furniture } = AddFurnitureCtrl

    const { grabbing } = this.state

    return (
      <ModalWithBackdrop
        size="modal-lg"
        show={showModalReplacementProducts}
        centered={true}
        close={this.closeModal}
        className={'replacementProductsModal'}
        draggable
        scrollable
        setGrabbing={this.setGrabbing.bind(this)}
      >
        <div
          className={classNames('modal-header d-block', {
            grabbable: !grabbing,
            grabbing: grabbing,
          })}
        >
          <h5 className="modal-title">
            {t('mission.calculation.furniture.replacementProductsModal.title')}
          </h5>
          <button type="button" className="close" onClick={this.closeModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body overflow-x-hidden">
          <div className="originalProduct mb-3">
            <p>{t('mission.calculation.furniture.replacementProductsModal.originalProduct')}</p>
            <FurnitureCatalogDetails furniture={furniture} />
          </div>

          <div className="replacementProducts">
            <p>{t('mission.calculation.furniture.replacementProductsModal.replacementProducts')}</p>
            {replacementProducts.map(replacementProduct => {
              return (
                <FurnitureCatalogDetails
                  key={replacementProduct.id}
                  furniture={replacementProduct}
                  onClick={this.chooseProduct.bind(this, replacementProduct)}
                />
              )
            })}
          </div>
        </div>
      </ModalWithBackdrop>
    )
  }
}

export default withTranslation()(observer(ReplacementProductsModal))
