import React from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import SmartLink from './SmartLink'
import TotalPackages from './TotalPackages'

const RelatedCostLinks = ({ id, hideElements, last }) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const { context } = AddRelatedCostCtrl
  const packageType = t(`mission.calculation.relatedCost.${last}`)

  return (
    <div className="breadcrumb m-0 p-0 d-flex align-items-center">
      <SmartLink path={`/${type}/${id}/calculation/listPackage`} selected={hideElements === 2}>
        {t('mission.calculation.cart.breadcrumb')} <TotalPackages />
      </SmartLink>
      <i
        className={classNames('fa fa-chevron-right mt-1 mr-2 ml-2', {
          hide: hideElements !== 0 && hideElements !== 1,
        })}
      />
      {context === 'new' ? (
        <SmartLink
          path={`/${type}/${id}/calculation/${last}`}
          selected={hideElements === 0}
          hide={hideElements !== 0}
        >
          {t('mission.calculation.relatedCost.breadcrumbNew')} {packageType}
        </SmartLink>
      ) : (
        <SmartLink
          path={`/${type}/${id}/calculation/${last}`}
          selected={hideElements === 0}
          hide={hideElements !== 0}
        >
          {t('mission.calculation.relatedCost.breadcrumbModify')} {packageType}
        </SmartLink>
      )}{' '}
    </div>
  )
}

export default compose(
  inject('MissionStore', 'UserStore'),
  observer,
)(RelatedCostLinks)
