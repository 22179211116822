import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import CommonStore from 'stores/Common/domain/CommonStore'

class FiltersDropZoneUpdateType extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readyForDrops: {},
      windowHeight: window.innerHeight,
    }
  }

  componentDidMount() {
    document.addEventListener('drop', e => this.drop(e), true)
  }

  componentWillUnmount() {
    document.removeEventListener('drop', e => this.drop(e), true)
  }

  addEffectStyle = (e, index) => {
    if (!this.state.readyForDrops[index]) {
      this.setState({ readyForDrops: { [index]: true } })
    }
  }

  drop = (e, type) => {
    e.preventDefault()
    const classNames = e.target.className.split(' ')
    if (classNames.indexOf('dropzone-update') !== -1) {
      if (type && SupportingDocumentsCtrl.sdDragged !== null) {
        if (
          type !==
          SupportingDocumentsCtrl.getSdById(SupportingDocumentsCtrl.sdDragged)['type']['key']
        ) {
          SupportingDocumentsCtrl.setTypeAndName(type, SupportingDocumentsCtrl.sdDragged)
          SupportingDocumentsCtrl.updateSupportingDocument(SupportingDocumentsCtrl.sdDragged)
          return
        }
        SupportingDocumentsCtrl.setDropZoneFilteredUpdateType(false)
      }
      return
    }
    SupportingDocumentsCtrl.setDropZoneFilteredUpdateType(false)
  }

  allowScrollingDuringDrag = e => {
    const heightPosition = e.clientY
    if (this.state.windowHeight - heightPosition < 150) {
      window.scroll(0, this.state.windowHeight)
    }
    if (heightPosition < 150) {
      window.scroll(0, 0)
    }
  }

  render() {
    if (!SupportingDocumentsCtrl.dropZoneFilteredUpdateType) return null

    return (
      <div className="list row justify-content-center filter-drop-zone update-type">
        {Object.keys(CommonStore.supportingDocumentsTypes[SupportingDocumentsCtrl.sdCategory]).map(
          (type, index) => {
            return (
              type !== 'DINV' && (
                <div
                  key={index}
                  className="sd-drop-item col-lg-3 col-md-4 col-xs-6 dropzone-update"
                  onDragEnter={e => e.preventDefault()}
                  onDragLeave={e => e.preventDefault()}
                  onDragOver={e => {
                    this.addEffectStyle(e, index)
                    this.allowScrollingDuringDrag(e)
                    e.preventDefault()
                  }}
                  onDrop={e => this.drop(e, type)}
                >
                  <div
                    className={classNames(
                      'sd-drop-item-inner filter-drop-zone-update-item dropzone-update',
                      {
                        readyForDrops: this.state.readyForDrops[index],
                      },
                    )}
                  >
                    <div name={'sd-dropzone'} className="dropzone dropzone-update">
                      <i className="fa fa-arrows-alt dropzone-update" />
                      <p className="dropzone-update">
                        {
                          CommonStore.supportingDocumentsTypes[SupportingDocumentsCtrl.sdCategory][
                            type
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </div>
              )
            )
          },
        )}
      </div>
    )
  }
}

export default observer(FiltersDropZoneUpdateType)
