import React from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'

import DashboardCtrl from 'stores/Common/view/DashboardCtrl'

class ToggleView extends React.Component {
  onClick = value => DashboardCtrl.setProperty('missionsView', value)

  render() {
    const { missionsView } = DashboardCtrl
    const { isMobile } = this.props.UiCtrl

    if (isMobile) return null

    return (
      <div className="d-flex align-items-center toggle-icons">
        <i
          className={classNames('fa fa-bars clickable mr-2 text-primary', {
            active: missionsView === 'listTable',
          })}
          onClick={this.onClick.bind(this, 'listTable')}
        />
        <i
          className={classNames('fa fa-th clickable text-primary', {
            active: missionsView === 'listCard',
          })}
          onClick={this.onClick.bind(this, 'listCard')}
        />
      </div>
    )
  }
}
export default inject('UiCtrl')(observer(ToggleView))
