import React from 'react'
import { observer, inject } from 'mobx-react'
import { Carousel } from 'react-responsive-carousel'
import { withTranslation } from 'react-i18next'

import './Slider.css'
import SupportingDocumentsCtrl, { isImage } from 'stores/Common/view/SupportingDocumentsCtrl'
import SupportingDocumentStore from 'stores/Common/domain/SupportingDocumentStore'
import DrawCtrl from 'stores/Mission/view/DrawCtrl'
import { baseUrl } from 'services/utils'
import AISection from 'pages/SupportingDocuments/AISection'
import UpdateSection from 'pages/SupportingDocuments/UpdateSection'
import SliderItem from './SliderItem'

class Slider extends React.Component {
  onClick = sd => {
    DrawCtrl.setImgBackgroundUrl(
      `${baseUrl()}/supportingDocuments/v1/${sd.id}/attachment?access_token=${
        this.props.UserStore.token
      }`,
    )
    DrawCtrl.setSchemaName(`${sd.name}_${Date.now()}`)
    DrawCtrl.togglePhotoEdit()
    DrawCtrl.setProperty('sdType', sd.type.key)
  }

  render() {
    const { t } = this.props

    return (
      <Carousel
        className="sd-slider"
        selectedItem={SupportingDocumentsCtrl.selectedSlide}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        onChange={e => SupportingDocumentsCtrl.setSelectedSlide(e)}
        swipeable={false}
      >
        {SupportingDocumentStore.sdFiltered.map((sd, index) => {
          return (
            <div className="slide-wrapper" key={index}>
              <div className="row">
                <div className="col-md-8 col-lg-8 col-xl-8 col-sm-12">
                  <SliderItem slide={sd} />
                </div>
                <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12">
                  {isImage(sd.mimeType) && (
                    <button
                      key={'photoEdit'}
                      className="btn btn-block btn-primary photo-edit mb-2"
                      onClick={this.onClick.bind(this, sd)}
                    >
                      {t('mission.supportingDocuments.draw.modifyImage')}
                    </button>
                  )}
                  {isImage(sd.mimeType) && <AISection sd={sd} />}
                  <UpdateSection sd={SupportingDocumentsCtrl.getSdToUpdateById(sd.id)} />
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
    )
  }
}

export default inject('UserStore')(withTranslation()(observer(Slider)))
