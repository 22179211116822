import React from 'react'
import { observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { compose } from 'recompose'

import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'
import RecapRowLayout from '../DetailPackage/RecapRowLayout'
import {
  TotalWithoutVAT,
  LabelVAT,
  LabelObsolescence,
  TotalWithVAT,
} from '../DetailPackage/RecapComponents'
import { euro } from 'utils/currency'
// import Tooltip from 'components/Tooltip/Tooltip'

const Recap = ({ t, onChange }) => {
  const {
    checkPriceEnabled,
    furniture,
    furniture: {
      parentProduct,
      compensation,
      totalVAT,
      totalObsolescence,
      finalPrice,
      canCheckPrice,
      // obsRateFoundInDatabase,
    },
  } = AddFurnitureCtrl
  const compensationOptions = [
    { value: 'default', label: t('mission.calculation.furniture.compensationOptions.default') },
    { value: 'new', label: t('mission.calculation.furniture.compensationOptions.new') },
    { value: 'used', label: t('mission.calculation.furniture.compensationOptions.used') },
    { value: 'nonRated', label: t('mission.calculation.furniture.compensationOptions.nonRated') },
  ]
  const checkPrice = () => AddFurnitureCtrl.checkPrice()

  return (
    <div className={classNames('recap align-items-center col-12 col-lg-6')}>
      {canCheckPrice && !parentProduct && (
        <div className="row row-recap no-gutters mb-3">
          <button
            className={'btn btn-outline-primary btn-block'}
            onClick={checkPrice}
            disabled={!checkPriceEnabled}
          >
            {t('mission.calculation.furniture.checkPriceBtn')}
          </button>
        </div>
      )}

      <div className="row row-recap no-gutters align-items-center mb-2">
        <div className="col-6">
          <span className="recap-label">{t('mission.calculation.furniture.compensation')}</span>
        </div>
        <div className="col-6">
          <ResponsiveSelect
            name="compensation"
            value={compensation}
            options={compensationOptions}
            onChange={onChange}
          />
        </div>
      </div>

      <RecapRowLayout
        labelComponent={
          <span className="recap-label">
            {t('mission.calculation.relatedCost.totalWithoutVAT')}
          </span>
        }
        priceComponent={<TotalWithoutVAT packageData={furniture} onChange={onChange} />}
      />

      <RecapRowLayout
        labelComponent={<LabelVAT packageData={furniture} onChange={onChange} />}
        priceComponent={euro(totalVAT)}
        withoutUnit
        packageLayout={false}
      />

      <RecapRowLayout
        labelComponent={
          <span className="recap-label">{t('mission.calculation.cart.finalTotal')}</span>
        }
        priceComponent={<TotalWithVAT packageData={furniture} onChange={onChange} />}
      />

      <RecapRowLayout
        labelComponent={
          <LabelObsolescence
            packageData={furniture}
            onChange={onChange}
            controller={AddFurnitureCtrl}
            // TODO re activate
            // subLabel={
            //   !obsRateFoundInDatabase && (
            //     <Tooltip
            //       className="tooltip-action-bar"
            //       text={t('mission.calculation.furniture.obsRateNotFound')}
            //     >
            //       <i class="fa fa-exclamation-circle"></i>
            //     </Tooltip>
            //   )
            // }
          />
        }
        priceComponent={<span>{euro(totalObsolescence)}</span>}
        withoutUnit
        packageLayout={false}
      />

      <RecapRowLayout
        labelComponent={
          <span className="recap-label">
            {t('mission.calculation.furniture.totalWithVATWithObsolescence')}
          </span>
        }
        priceComponent={euro(finalPrice)}
        withoutUnit
        packageLayout={false}
        last
      />
    </div>
  )
}

export default compose(
  withTranslation(),
  observer,
)(Recap)
