import { getStickyNote } from 'services/stickyNoteViewer'
import { observable, action, runInAction, decorate, computed } from 'mobx'

class StickyNoteStore {
  loading = false
  wan = null
  content = ''
  readAt = null
  createdAt = null
  updatedAt = null

  get isAvailable() {
    return this.content !== '' && this.content !== null
  }

  async loadData(wan, withLoading = true) {
    if (withLoading) this.loading = true

    try {
      const stickyNote = await getStickyNote(wan)
      if (
        process.env.REACT_APP_SOLERA_ENV === 'TEST' ||
        process.env.REACT_APP_SOLERA_ENV === 'DEV'
      ) {
        console.log('sticky note data : ', stickyNote)
      }

      runInAction(() => {
        this.wan = wan

        //Read the sticky note data and assign
        this.content = stickyNote.content
        this.readAt = stickyNote.readAt
        this.createdAt = stickyNote.createdAt
        this.updatedAt = stickyNote.updatedAt

        this.loading = false
      })
    } catch (err) {
      runInAction(() => {
        this.loading = true
      })
    }
  }

  setProperty(key, value) {
    this[key] = value
  }

  getProperty(key) {
    return this[key]
  }
}

const DecoratedStickyNoteStore = decorate(StickyNoteStore, {
  loading: observable,
  wan: observable,
  content: observable,
  readAt: observable,
  updatedAt: observable,
  createdAt: observable,

  isAvailable: computed,

  loadData: action.bound,
  setProperty: action.bound,
  getProperty: action.bound,
})

export default new DecoratedStickyNoteStore()
