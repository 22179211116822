import React from 'react'
import { inject, observer } from 'mobx-react'
import CustomerConfigStore from 'stores/Common/domain/CustomerConfigStore'

import MobileMenu from './MobileMenu'
import DesktopMenu from './DesktopMenu'
import classNames from 'classnames'

class DashboardMenu extends React.Component {
  setDefaultValues() {
    this.customerConfigKey = this.props.UserStore.isExpert
      ? 'ExpertDashboardMenuTabs'
      : 'ManagerDashboardMenuTabs'

    this.defaultMenuValues = this.props.UserStore.isExpert
      ? ['Missions', 'Messages', 'Agenda', 'User'] //Expert role menu default values
      : ['Claims', 'Messages', 'Agenda', 'User'] //Manager role menu default values
  }

  constructor(props) {
    super(props)
    this.setDefaultValues()
    this.state = {
      loading: false,
      values: this.defaultMenuValues,
    }
  }

  async loadValuesFromCustomerConfig() {
    try {
      while (this.props.UserStore === null || this.props.UserStore.customer === null) {
        await new Promise(resolve => setTimeout(resolve, 500))
      }
      this.setDefaultValues()
      this.setState({ loading: true })
      await CustomerConfigStore.loadCustomerConfigKey(
        this.props.UserStore.customer.id,
        this.customerConfigKey,
      )

      const arrayValues = CustomerConfigStore.getValue(this.customerConfigKey)
      const newValues = []
      arrayValues.map(value => newValues.push(value))

      if (newValues !== undefined) this.setState({ values: newValues, loading: false })
    } catch (err) {
      this.setState({ values: this.defaultMenuValues, loading: false })
    }
  }

  componentDidMount() {
    this.loadValuesFromCustomerConfig()
  }

  render() {
    const { deviceType, onChangeLanguage } = this.props
    if (this.state.loading) return <span className={classNames('fa fa-spinner fa-spin')} />
    return deviceType === 'mobile' ? (
      <MobileMenu onChangeLanguage={onChangeLanguage} menu={this.state.values} />
    ) : (
      <DesktopMenu onChangeLanguage={onChangeLanguage} menu={this.state.values} />
    )
  }
}

export default inject('UserStore')(observer(DashboardMenu))
