import React from 'react'
import { observer } from 'mobx-react'

import './CookieDisclaimer.css'
import Button from 'components/Button/Button'
import LoginCtrl from 'stores/Common/view/LoginCtrl'
import { withTranslation } from 'react-i18next'

class CookieDisclaimer extends React.Component {
  closeCookiesDisclaimer = () => LoginCtrl.closeCookiesDisclaimer()

  render() {
    const { t } = this.props
    if (!LoginCtrl.cookiesDisclaimer) return null

    return (
      <div className="cookies">
        <div className="cookies-content container">
          <h3>{t('cookieDisclaimer.title')}</h3>
          <p>{t('cookieDisclaimer.disclaimer')}</p>
          <div className="d-flex justify-content-between align-items-center">
            <Button className="btn btn-primary" onClick={this.closeCookiesDisclaimer}>
              {t('common.close')}
            </Button>
            <a
              href="https://www.solera-home.com/politique-de-protection-des-donnees-a-caractere-personnel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('cookieDisclaimer.privacyPolicy')}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(observer(CookieDisclaimer))
