import request from './httpRequest'

export const getPartyInvolvedDB = async customerId => {
  try {
    const res = await request({ method: 'get', url: `/customerInvolvedParty/v1/${customerId}` })
    return res
  } catch (err) {
    return Promise.reject(err)
  }
}
