import React from 'react'
import { observer } from 'mobx-react'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({ condition, redirectTo, exact, path, component }) => {
  if (condition) {
    return <Redirect to={redirectTo} />
  }

  return <Route exact={exact} path={path} component={component} />
}

export default observer(ProtectedRoute)
