import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { format, startOfWeek, endOfWeek, subDays, isSameMonth, isPast } from 'date-fns'

const dateFnsLocales = {
  en: require('date-fns/locale/en-US'),
  fr: require('date-fns/locale/fr'),
}

function Toolbar({ date, onNavigate, UiCtrl: { language } }) {
  const lang = language[0] + language[1]
  const startDate = startOfWeek(date, { weekStartsOn: 1 })
  const endDate = subDays(endOfWeek(date, { weekStartsOn: 1 }), 2)
  const month = isSameMonth(startDate, endDate)
    ? format(startDate, 'MMM', { locale: dateFnsLocales[lang] })
    : `${format(startDate, 'MMM', { locale: dateFnsLocales[lang] })} - ${format(endDate, 'MMM', {
        locale: dateFnsLocales[lang],
      })}`

  return (
    <div className="custom-toolbar d-flex justify-content-between">
      {isPast(startDate) ? (
        <div />
      ) : (
        <i className="fa fa-caret-left clickable" onClick={() => onNavigate('PREV')} />
      )}

      <div className="info-week">{`${format(startDate, 'd')}-${format(
        endDate,
        'd',
      )} ${month} ${format(startDate, 'y')}`}</div>

      <i className="fa fa-caret-right clickable" onClick={() => onNavigate('NEXT')} />
    </div>
  )
}

export default compose(
  inject('UiCtrl'),
  withTranslation(),
  observer,
)(Toolbar)
