import React from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil } from 'ramda'

import Button from 'components/Button/Button'
import ReportButton from './ReportButton'
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

const ReportActions = ({
  MissionStore: { loading: loadingMission, id },
  ReportCtrl: {
    loading: loadingReport,
    toggleModal,
    downloadReportPdf,
    retreiveIrsiReportFromDarva,
    syncDarva,
    showSyncDarvaBtn,
    setProperty,

    currentReport,
    availableReportsForSelect,
    availableReports,
    currentReportObj,
  },
  CartStore: { saving },
}) => {
  const { t } = useTranslation()
  if (isNil(currentReportObj)) return null

  const isMultiReports = availableReports.length > 1

  return (
    <div>
      <div
        className={classNames('d-flex mb-3', {
          'justify-content-end': !isMultiReports,
          'justify-content-between': isMultiReports,
        })}
      >
        {/* SELECT REPORT TYPE */}
        {isMultiReports && (
          <div style={{ width: '250px' }}>
            <ResponsiveSelect
              name="currentReport"
              value={currentReport}
              options={availableReportsForSelect}
              onChange={e => setProperty(e.target.name, e.target.value)}
            />
          </div>
        )}

        <div className="d-flex flex-row align-items-center">
          {/* SYNC DARVA BUTTON */}
          {showSyncDarvaBtn && (
            <ReportButton>
              <Button className="btn btn-danger mr-2" onClick={() => syncDarva(id)}>
                {t('mission.report.syncDarva')}
              </Button>
            </ReportButton>
          )}

          {/* DOWNLOAD BUTTON */}
          <Button className="btn btn-primary mr-2" onClick={() => downloadReportPdf(id)}>
            {t('mission.report.download')}
          </Button>

          {/* SEND BY EMAIL BUTTON */}
          <Button
            className="btn btn-light"
            onClick={toggleModal}
            disabled={loadingReport || saving || loadingMission}
          >
            {t('mission.report.sendEmail')}
          </Button>
        </div>
      </div>
      {!isNil(currentReportObj.irsiReportMissing) && currentReportObj.irsiReportMissing === true && (
        <div className="text-right">
          <Button className="btn btn-primary mr-2" onClick={() => retreiveIrsiReportFromDarva(id)}>
            {t('mission.report.retreiveRecReportFromDarva')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default inject('ReportCtrl', 'MissionStore', 'CartStore')(observer(ReportActions))
