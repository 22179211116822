import { type } from 'ramda'

import { UserRolesExpert } from 'stores/Common/domain/UserStore'
import request from 'services/httpRequest'

export const fetchCustomer = async customerId => {
  try {
    const res = await request({
      method: 'get',
      url: `/customers/v1/${customerId}`,
    })

    return res.data.attributes
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchUsers = async ({
  customerId,
  recursive = false,
  filterOnlyExperts = false,
  filterOnlyActive = false,
}) => {
  let url = `/customers/v1/${customerId}/users`
  if (recursive) url += `?non-recursive=1`
  if (filterOnlyActive) {
    if (recursive) {
      url += `&active=true`
    } else {
      url += `?active=true`
    }
  }
  try {
    const res = await request({
      method: 'get',
      url,
    })
    let users = res.data.map(user => ({ id: user.id, ...user.attributes }))
    if (filterOnlyExperts) {
      users = users.filter(user => user.roles.some(role => UserRolesExpert.includes(role)))
    }

    return users
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchChildren = async customerId => {
  try {
    const res = await request({
      method: 'get',
      url: `/customers/v1/${customerId}/children`,
    })
    return res.data.map(user => user.attributes)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveUser = async ({ user, method, path = null }) => {
  try {
    const res = await request({
      method,
      url: `/users/v1/${method === 'patch' ? user.id : ''}${path ? '/' + path : ''}`,
      data: {
        data: {
          type: 'user',
          attributes: user,
        },
      },
    })
    return res.data.attributes
  } catch (err) {
    if (type(err.data.errors) === 'Array')
      return Promise.reject(
        err.data.errors.map(error => ({
          detail: error.detail,
          source: error.source.pointer.split('/').pop(),
        })),
      )
    return Promise.reject(err.data)
  }
}

export const fetchUserAreas = async userId => {
  try {
    const res = await request({
      method: 'get',
      url: `/userAreas/v1/${userId}`,
    })

    return res.data.map(userAreas => ({
      id: userAreas.id,
      areaValue: userAreas.attributes.areaValue,
      areaType: userAreas.attributes.areaType,
    }))
  } catch (err) {
    return Promise.reject(err)
  }
}

export const saveUserAreas = async ({ userId, areas }) => {
  try {
    const res = await request({
      method: 'post',
      url: `/userAreas/v1/${userId}`,
      data: { data: { type: 'userAreas', attributes: { areas } } },
    })
    return res
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchTechnicityLevels = async () => {
  try {
    const { data } = await request({
      method: 'get',
      url: '/bre/v1/technicityLevel',
    })

    return data.map(({ attributes }) => attributes.technicityLevel)
  } catch (err) {
    return Promise.reject(err)
  }
}
