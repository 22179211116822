import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect'

function SelectInvolvedParty({
  t,
  onChange,
  value,
  PartyInvolvedStore: { asSelectOptions },
  MissionStore: { IRSIInvolvedParty, noStatusInvolvedParties },
}) {
  const options = asSelectOptions.filter(
    option =>
      IRSIInvolvedParty.indexOf(option.value) !== -1 &&
      noStatusInvolvedParties.indexOf(option.value) === -1,
  )

  return (
    <ResponsiveSelect
      name="involvedParty"
      onChange={onChange}
      options={options}
      value={value}
      placeholder={t('mission.calculation.involvedParty.involvedParty')}
      nullable
    />
  )
}

export default compose(
  inject('PartyInvolvedStore', 'MissionStore'),
  withTranslation(),
  observer,
)(SelectInvolvedParty)
